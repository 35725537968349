import React, { useContext } from 'react'
import { DataEspecieReducida } from '../../../interfaces/dataCardsHome'
import { useCardMercadoHook } from '../../../hooks/useCardMercadoHook'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { ContainerDia, Dia, Titulo, ContainerData, ImgEEUU, ContainerImagenes, ContainerEspecieNombre, Especie, Nombre, ContainerUltimoVariacion, UltimoExterior, Variacion } from './elements';
import { ThemeContext } from 'styled-components';


type Props = {
    title: string,
    data: DataEspecieReducida[]
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => {
    const { primaryBackgroundColor } = useContext(ThemeContext);
    return ({
        backgroundColor: primaryBackgroundColor,
        borderRadius: '20px',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    })
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0em 0em',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => {
    const { primaryColor } = useContext(ThemeContext);
    return (
    <MuiAccordionSummary
        sx={{ display: 'flex', alignItems: 'start', borderRadius: '20px', }}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.5em', color: primaryColor, }} />}
        {...props}
    />
)})(({ theme }) => {
    const { primaryBackgroundColor } = useContext(ThemeContext);
    return ({
    backgroundColor: primaryBackgroundColor,
    minHeight: '2em',
    alignItems: 'center',
    '& .MuiAccordionSummary-expandIconWrapper': {
        marginTop: '0.3em',
        fontSize: '12px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: '0px',
    },
})});


const CardMercadoAcordion = ({ title, data }: Props) => {

    const { cutName, formatName, formatVariacion } = useCardMercadoHook();

    const [expanded, setExpanded] = React.useState<string | false>('');

    const { buyColor, rojoPorcentaje, accentColor } = useContext(ThemeContext);


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Titulo>{title}</Titulo>
            </AccordionSummary>
            <AccordionDetails>
                <ContainerDia>
                    <Dia>%dia</Dia>
                </ContainerDia>
                {data.map((d) => (
                    <ContainerData key={d.especie}>
                        <ContainerImagenes>
                            <ImgEEUU
                                alt="bandera-eeuu-mercado"
                                src={require('../../../images/bandera-usa-small.png')}
                            />
                            <ContainerEspecieNombre>
                                <Especie>{title === 'Dólar' ? cutName(d.especie) : formatName(d.especie)}</Especie>
                                <Nombre>{d.nombre}</Nombre>
                            </ContainerEspecieNombre>
                        </ContainerImagenes>
                        <ContainerUltimoVariacion>
                            <UltimoExterior>{formatCurrency(d.ultimo)}</UltimoExterior>
                            <Variacion
                                style={{ color: d.variacion > 0 ? buyColor : d.variacion < 0 ? rojoPorcentaje : accentColor }}
                            >
                                {formatVariacion((Math.round(d.variacion * 10) / 10))}%
                            </Variacion>
                        </ContainerUltimoVariacion>
                    </ContainerData>
                ))}
            </AccordionDetails>
        </Accordion >
    )
}

export default CardMercadoAcordion