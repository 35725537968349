import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomTheme } from '../../../theme/Themes';

interface CustomDatePickerProps {
    theme: CustomTheme;
}

const CustomDatePicker = styled(DatePicker)<CustomDatePickerProps>(({ theme }) => ({
    '& .MuiInputBase-root': {
        color: theme.primaryColor,
    },
    '& .MuiFormLabel-root': {
        color: theme.primaryColor,
        '&.Mui-focused': {
            color: theme.primaryColor,
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.primaryColor,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.primaryBorderColor, // Change border color on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.primaryColor, // Change border color when focused
        },
    },
    '& .MuiSvgIcon-root': {
        color: theme.primaryColor,
    },
}));

export default CustomDatePicker;