import { useContext, useMemo } from 'react'
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { TipoMercado } from '../enums/enums';

export const useCardMercadoHook = () => {

    const { tipoMercado } = useContext(MercadoContext)

    // corta el nombre de Dolar para que solo diga el tipo de dolar en el titulo
    const cutName = useMemo(() => (name: string) => {
        const searchTerm = 'R';
        const indexOf = name.indexOf(searchTerm);
        return name.slice(indexOf + 2);
    }, [])


    // formatea las variaciones de los porcentajes
    function formatVariacion(variacion: number) {
        let numberSeparado = Number(variacion).toFixed(2).toString().split('.');
        if (numberSeparado.length > 1) {
            numberSeparado[1] = numberSeparado[1].substring(0, 1);
        }
        let numeroDosDecimales = numberSeparado.join(',');
        return numeroDosDecimales
    }

    // formatea los nombres de los indices (le agrega tilde a Pais)
    function formatName(name: string): string {
        return tipoMercado === TipoMercado.Exterior
            ? name.slice(0, name.indexOf('_')).toLocaleUpperCase()
            : name === 'RIESGO PAIS'
                ? 'RIESGO PAÍS'
                : name;
    }

    return {
        cutName,
        formatVariacion,
        formatName,
    }
}

