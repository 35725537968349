import React, { useContext } from 'react'
import { ContainerSecundario, Titulo, ContainerTitulos, TitulosSecundarios, SinInfo, ContainerSinInfo, Container } from './elements'
import { ThemeContext } from 'styled-components';
import ResultadoDelDia from '../ResultadoDelDia/ResultadoDelDia';
import { TenenciaMercadoExterior, TenenciaMercadoLocal } from '../../../interfaces/tenenciaInterface';
import OrdenesDelDia from '../OrdenesDelDia/OrdenesDelDia';
import { Order } from '../../../interfaces/ordersInterface';
import useOrdenesDelDiaHook from '../../../hooks/useOrdenesDelDiaHook';
import { useNavigationHook } from '../../../hooks/useNavigationHook';
import { TipoNavigate } from '../../../enums/enums';
import CachedIcon from '@mui/icons-material/Cached';

interface Props {
    titulo: string;
    subtitulos: string[];
    dataTenencia?: TenenciaMercadoLocal[] | TenenciaMercadoExterior[] | undefined;
    dataOrdenes?: Order[];
    confirmarDatos?: boolean;
    cargarDatosOperar?: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
}

const CardInfoMercadoNuevo = ({
    titulo,
    subtitulos,
    dataTenencia,
    dataOrdenes,
    confirmarDatos = false,
    cargarDatosOperar,
}: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext);
    const { ordenesOrdenadasFiltradas, formatEstado, loadingRefresher, refrescarTenencia } = useOrdenesDelDiaHook(dataOrdenes);

    const { handleNavigate } = useNavigationHook();


    return (
        <Container>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
                <Titulo
                    onClick={() => {
                        titulo === "Órdenes del día" &&
                            handleNavigate(TipoNavigate.Ordenes)
                    }}
                    style={{ cursor: titulo === "Órdenes del día" ? 'pointer' : undefined }}
                >
                    {titulo}
                </Titulo>
                {titulo === "Resultados del día"
                    ? <CachedIcon
                        onClick={refrescarTenencia}
                        sx={{
                            color: primaryColor,
                            fontSize: '1.2em',
                            alignContent: 'center',
                            cursor: 'pointer',
                            marginRight: '0.3em',
                        }}
                    />
                    : <></>
                }
            </div>
            <ContainerSecundario>

                {(!dataTenencia) && titulo === "Resultados del día"
                    ? <ContainerSinInfo>
                        <SinInfo>No se puede mostrar la tenencia en este momento.</SinInfo>
                    </ContainerSinInfo>
                    : (dataTenencia && !dataTenencia.length) && titulo === "Resultados del día"
                        ? <ContainerSinInfo>
                            <SinInfo>No tiene tenencia en su cartera.</SinInfo>
                        </ContainerSinInfo>
                        : (!ordenesOrdenadasFiltradas || !ordenesOrdenadasFiltradas.length) && titulo === "Órdenes del día"
                            ? <ContainerSinInfo>
                                <SinInfo>No hay órdenes disponibles.</SinInfo>
                            </ContainerSinInfo>
                            :
                            <>
                                <div style={{ position: 'sticky', top: '0em', backgroundColor: primaryBackgroundColor }}>
                                    <ContainerTitulos style={{ paddingTop: '0.2em' }}>
                                        {subtitulos.map(t =>
                                            <TitulosSecundarios
                                                key={t}
                                                style={{
                                                    textAlign: t === 'Especie'
                                                        ? 'start'
                                                        : t === 'Tipo' || t === 'Estado'
                                                            ? 'center'
                                                            : undefined
                                                }}
                                            >
                                                {t}
                                            </TitulosSecundarios>
                                        )}
                                    </ContainerTitulos>
                                    <hr style={{ margin: '0.2em 0 0em' }} />
                                </div>
                                {
                                    titulo === "Resultados del día"
                                        ? <ResultadoDelDia
                                            data={dataTenencia}
                                            confirmarDatos={confirmarDatos}
                                            cargarDatosOperar={cargarDatosOperar}
                                            loadingRefresher={loadingRefresher}
                                        />
                                        : <OrdenesDelDia
                                            data={ordenesOrdenadasFiltradas}
                                            formatEstado={formatEstado}
                                        />
                                }
                            </>
                }
            </ContainerSecundario>
        </Container>
    )
}

export default CardInfoMercadoNuevo