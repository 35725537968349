export function isMarketOpen(
    openingHour: string | null,
    closingHour: string | null,
): boolean {
    if (!openingHour || !closingHour) {
        return false;
    }

    const ahora = new Date();
    const horaActual = ahora.getHours();
    const minActual = ahora.getMinutes();

    const [aperturaMercadoHora, aperturaMercadoMin] = openingHour.split(':');
    const [cierreMercadoHora, cierreMercadoMin] = closingHour.split(':');

    if (
        horaActual > Number(aperturaMercadoHora) &&
        horaActual < Number(cierreMercadoHora)
    ) {
        return true;
    } else if (
        horaActual === Number(aperturaMercadoHora) &&
        minActual >= Number(aperturaMercadoMin)
    ) {
        return true;
    } else if (
        horaActual === Number(cierreMercadoHora) &&
        minActual < Number(cierreMercadoMin)
    ) {
        return true;
    } else {
        return false;
    }
}
