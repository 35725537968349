import React, { useContext } from 'react'
import { ContainerButtons, Container, TextoBotones } from './elements';
import { ThemeContext } from 'styled-components';

interface Props {
    setBotonActual: React.Dispatch<React.SetStateAction<string>> | ((opcion: string) => void);
    botonActual: string;
    listaBotones: string[];
}

const BotonesPanelesNuevo = ({ setBotonActual, botonActual, listaBotones }: Props) => {

    const { primaryColor } = useContext(ThemeContext)

    return (
        <Container>
            {listaBotones.map(l =>
                <ContainerButtons
                    key={l}
                    onClick={() => setBotonActual(l)}
                    style={{
                        borderBottom: botonActual === l ? `3px solid ${primaryColor}` : `1px solid ${primaryColor}`,
                    }}
                >
                    <TextoBotones
                        style={{
                            fontWeight: botonActual === l ? 500 : 400,
                        }}
                    >
                        {l}
                    </TextoBotones>
                </ContainerButtons>
            )
            }
        </Container >
    )
}

export default BotonesPanelesNuevo