import styled from "styled-components";

export const Container = styled.div`
    min-height: 3em;
    display: flex;
    padding: 0.2em 0.1em 0.2em 1em;
    border-bottom: 0.1em solid ${({theme}) => theme.grayToDarkGrayBackground};
    @media (min-width: 992px) {
        border-bottom: none;
        border-radius: 10px;
        height: 3.5em;  
    }
`;

export const FirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    @media (min-width: 992px) {
        justify-content: center;
    }
`;

export const SecondColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 50%;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;


export const EspecieText = styled.p`
    font-size: 1.1em;
    font-weight: 500;
    margin: 0 0.5em 0 0;
`

export const PrecioActualText = styled.p`
    font-size: 0.9em;
    color: #A3A3A3;
    margin: 0;
`

export const PrecioText = styled.p`
    color: ${({theme}) => theme.redToWhiteColor};
    font-size: 1.1em;
    font-weight: 500;
    margin: 0 0.2em 0 0;
    @media (min-width: 992px) {
        margin-right: 2em;
        width: 8em;
        text-align: right;
    }
`

export const DescText = styled.p`
    color: #A3A3A3;
    font-size: 1em;
    margin: 0;
`

export const Button = styled.button`
    color: ${({theme}) => theme.primaryColor};
    background: none;
    border: none;
    @media (min-width: 992px) {
        margin-right: 0.5em;
    }
`;
