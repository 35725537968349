import styled from "styled-components";


export const Container = styled.div`
display: flex;
justify-content: space-between;
margin: 0.5em 1.2em 0em 1.2em;  
cursor: pointer;
`

export const ContainerButtons = styled.div`
width: 100%;
text-align: center;
`

export const TextoBotones = styled.p`
margin: 0.3em;
color: ${({ theme }) => theme.primaryColor};
`