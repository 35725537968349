import React, {useEffect, useState, useContext} from 'react';
import axios, {CancelTokenSource} from 'axios';
import { TipoMercado } from '../enums/enums';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { AuthContext } from '../context/authContext/AuthContext';
import { DataEspecieOpciones } from '../interfaces/mercadoInferfaces';
import { UserContext } from '../context/userContext/UserContext';
import { addAlert, editAlert } from '../api/userApi';
import { getDataPanel, getDataPanelOpciones, getNombreEspecies, getPrecioEspecieCorta } from '../api/mercadoApi';
import {Alert as AlertInterface} from '../interfaces/alertaInterface';
import { useNavigate } from 'react-router-dom';
import { DatosClaveEspecie } from '../interfaces/nombreEspeciesInterfaces';

const tipoAlertasStrArr = ['SOPORTE', 'RESISTENCIA'];

const arrMenuListMercadoLocalSinFavoritos = [
  'CEDEARS',
  'LÍDER',
  'GENERAL',
  'BONOS',
];

const arrMenuListMercadoInternacionalSinFavoritos = [
  // 'GLOBAL',
  '30 del DOW',
  "ETF",
  "ADR",
  // 'Otras'
];

interface Props {
  editarAlerta: boolean,
  alerta: AlertInterface | null,
}

export const useAgregarAlertaHook = (
  {
    editarAlerta,
    alerta,
  }: Props
) => {
  const {
    tipoMercado, 
  } = useContext(MercadoContext);
  const {tokenPublico, token, setToken, setTokenPublico } = useContext(AuthContext);
  const {setSettedAlerts} = useContext(UserContext);
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState<boolean>(false);


  // Panel seleccionado
  const [actualMenuEspecies, setActualMenuEspecies] = useState(
    tipoMercado === TipoMercado.Local ?
    'CEDEARS' : 'GLOBAL',
  );

  // Tipo de especie seleccionada
  const [actualTipoAlerta, setActualTipoAlerta] = useState(
    'SOPORTE',
  );
  // Especie seleccionada
  const [especieSeleccionada, setEspecieSeleccionada] = useState<string | undefined>('');
  // Opcion seleccionada ( Si el panel seleccionado es OPCIONES )
  const [opcionSeleccionada, setOpcionSeleccionada] = useState<string | undefined>('');
  // Precio establecido
  const [precio, setPrecio] = useState<string>('');


  const [listaEspeciesTitulos, setListaEspeciesTitulos] = useState<string[] | undefined>([]);
  const [listaOpciones, setListaOpciones] = useState<DataEspecieOpciones[]>([]);
  const [listaOpcionesTitulos, setListaOpcionesTitulos] = useState<string[] | undefined>([]);
  const [listaOpcionesFilt, setListaOpcionesFilt] = useState<string[] | undefined>([]);

  const [ultimoPrecio, setUltimoPrecio] = useState<number>(0);
  const [error, setError] = useState('');


  const [dataLocal, setDataLocal] = useState<DatosClaveEspecie[] | null>(null);
  const [dataExterior, setDataExterior] = useState<DatosClaveEspecie[] | null>(null);
  const [dataListaEspecies, setDataListaEspecies] = useState<DatosClaveEspecie[] | null>(null);

  useEffect(() => {
    const refresh = async () => {
      try {
            setLoading(true);
            const tokenStorage = localStorage.getItem("token");
            const tokenPublicoStorage = localStorage.getItem("tokenPublico");
            tokenPublicoStorage && setTokenPublico(tokenPublicoStorage)
            tokenStorage && setToken(tokenStorage);
            if(tokenPublicoStorage){
              const resp = await Promise.all([
                getNombreEspecies(TipoMercado.Local),
                getNombreEspecies(TipoMercado.Exterior),
              ]);
              setDataLocal(resp[0].data.body.data.body);
              setDataExterior(resp[1].data.body.data.body);
              setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    refresh();

}, [])

const [listaMenuEspecies, setListaMenuEspecies] = useState(
  tipoMercado === TipoMercado.Local
    ? arrMenuListMercadoLocalSinFavoritos
    : arrMenuListMercadoInternacionalSinFavoritos,
);

useEffect(() => {
  let aux = actualMenuEspecies === 'LÍDER' ? 'LIDER' : actualMenuEspecies === '30 del DOW' ? 'Las 30 del DOW' : actualMenuEspecies;
  const filter = (tipoMercado === TipoMercado.Local) ?
    dataLocal?.filter(d => d.panel && d.panel.toUpperCase() === aux.toUpperCase())
    :
    aux === 'Otras' ?
    dataExterior?.filter(d => d.panel === undefined)
    :
    dataExterior?.filter(d => d.panel && d.panel.toUpperCase() === aux.toUpperCase());
  if (filter) setDataListaEspecies(filter) 
    // console.log(actualMenuEspecies, filter)
}, [dataLocal, dataExterior, actualMenuEspecies])

useEffect(() => {
  const devolverTitulos = () => {
    if(tipoMercado === TipoMercado.Local){
      const filt = dataListaEspecies?.map(e => e.especie)
      setListaEspeciesTitulos(filt);
    } else {
      const filt = dataListaEspecies?.map(e => e.especie.slice(0, e.especie.length -3))
      setListaEspeciesTitulos(filt);
    }
  }

  devolverTitulos();

}, [tipoMercado, dataListaEspecies]);

useEffect(() => {
  if(!editarAlerta){
    if(tipoMercado === TipoMercado.Local){
      setListaMenuEspecies(arrMenuListMercadoLocalSinFavoritos);
      setActualMenuEspecies('CEDEARS');
      setEspecieSeleccionada("");
    } else {
      setListaMenuEspecies(arrMenuListMercadoInternacionalSinFavoritos);
      setActualMenuEspecies('CEDEARS');
      setActualMenuEspecies('30 del DOW');
      setEspecieSeleccionada("");
    }
  }
}, [tipoMercado, editarAlerta]);

  useEffect(() => {
    if(editarAlerta && alerta){
      setActualMenuEspecies(alerta.panel)
      setActualTipoAlerta(alerta.tipoAlerta)
      setEspecieSeleccionada( tipoMercado === TipoMercado.Exterior
        ? alerta.especie.slice(0, alerta.especie.length -3 ) : alerta.especie)
      setPrecio(alerta.precio.toString())
      if(alerta.panel === "OPCIONES"){
        setOpcionSeleccionada(alerta.opciones)
      }
    }
  }, [editarAlerta, alerta]);


  // useEffect(() => {
  //   const getOpciones = async() => {
  //     if(tokenPublico){
  //       const resp = await getDataPanelOpciones(tokenPublico);
  //       setListaOpciones(resp.data.body)
  //       const filt = resp.data.body?.map(e => e.especieopciones)
  //       let titulosOpciones = filt?.filter((item,index)=>{
  //         return filt.indexOf(item) === index && item !==null;
  //       })
  //       setListaOpcionesTitulos(titulosOpciones)
  //     }
      
  //   }
  //   getOpciones();

  // }, [tokenPublico]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getDataEspecies(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, [especieSeleccionada]);

  const getDataEspecies = async (cancelToken: CancelTokenSource) => {
    try {
      if (tokenPublico && especieSeleccionada) {
        const resp = await getPrecioEspecieCorta(
          tokenPublico,
          [
            tipoMercado === TipoMercado.Exterior
              ? especieSeleccionada + '_US'
              : especieSeleccionada,
          ],
          cancelToken,
        );
        setUltimoPrecio(resp.data.body[0].ultimo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const filtOpciones = () => {
      if(especieSeleccionada){
        const filt = listaOpciones.filter( e => e.especieopciones === especieSeleccionada)
        const filtTitulos = filt.map(e => e.especie)
        setListaOpcionesFilt(filtTitulos)
      }
      
    }
    filtOpciones();

  }, [listaOpciones, especieSeleccionada]);

  // Primer Menu
  const [tipoEspecie, setTipoEspecie] = React.useState<null | HTMLElement | string>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // Segundo Menu
  const [openTipoAlerta, setOpenTipoAlerta] = React.useState<null | HTMLElement>(null);
  const [selectedTipoAlertaIndex, setSelectedTipoAlertaIndex] = React.useState(0);

  // Menu Especie
  const [openEspecie, setOpenEspecie] = React.useState<null | HTMLElement>(null);
  const [selectedEspecieIndex, setSelectedEspecieIndex] = React.useState(0);
  // Menu Especie que se muestra cuando el tipoEspecie es OPCIONES
  const [openEspecieOpciones, setOpenEspecieOpciones] = React.useState<null | HTMLElement>(null);
  const [selectedEspecieOpcionesIndex, setSelectedEspecieOpcionesIndex] = React.useState(0);

  // Menu opciones
  const [openOpciones, setOpenOpciones] = React.useState<null | HTMLElement>(null);
  const [selectedOpcionesIndex, setSelectedOpcionesIndex] = React.useState(0);


  // Primer Menu
  const handleClickTipoEspecie = (event: React.MouseEvent<HTMLElement>) => {
    if(!editarAlerta){
      setTipoEspecie(event.currentTarget);
    }
  };

  const handleButtonTipoEspecie = (opcion: string) => {
    if(!editarAlerta){
      setTipoEspecie(opcion);
      setActualMenuEspecies(opcion);
      setEspecieSeleccionada("");
    }
  };

  const handleTipoEspecieClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setTipoEspecie(null);
    setActualMenuEspecies(listaMenuEspecies[index]);
    setEspecieSeleccionada("");
  };

  const handleCloseTipoEspecie = (op?: string) => {
    if (op) setActualMenuEspecies(op)
    setTipoEspecie(null);
  };

  //Segundo Menu
  const handleTipoAlertaClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedTipoAlertaIndex(index);
    setOpenTipoAlerta(null);
    setActualTipoAlerta(tipoAlertasStrArr[index])
  };

  const handleButtonTipoAlerta = (opcion: string) => {
    if(!editarAlerta){
      setActualTipoAlerta(opcion);
    }
  };

  const handleClickTipoAlerta = (event: React.MouseEvent<HTMLElement>) => {
    setOpenTipoAlerta(event.currentTarget);
  };

  const closeTipoAlerta = (op?: string) => {
    if (op) setActualTipoAlerta(op)
    setOpenTipoAlerta(null);
  };
  
  // Menu Especie
  const handleClickEspecie = (event: React.MouseEvent<HTMLElement>): void => {
    if(!editarAlerta){
      setOpenEspecie(event.currentTarget);
    }
  };

  const handleButtonClickEspecie = (opcion : string | null) => {
    if(!editarAlerta && opcion){
      setEspecieSeleccionada(opcion);
    }
  }


  const handleEspecieClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedEspecieIndex(index);
    setOpenEspecie(null);
    setEspecieSeleccionada(listaEspeciesTitulos && listaEspeciesTitulos[index])
  };

  const handleCloseEspecie = (op?: string) => {
    if (op) setEspecieSeleccionada(op);
    setOpenEspecie(null);
  };

  // Menu Especie que se muestra cuando el tipoEspecie es OPCIONES
  const handleClickEspecieOpciones = (event: React.MouseEvent<HTMLElement>) => {
    if(!editarAlerta){
      setOpenEspecieOpciones(event.currentTarget);
    }
  };

  const handleEspecieClickOpciones = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedEspecieOpcionesIndex(index);
    setOpenEspecieOpciones(null);
    setEspecieSeleccionada(listaOpcionesTitulos && listaOpcionesTitulos[index])
    setOpcionSeleccionada('');
  };

  const handleCloseEspecieOpciones = () => {
    setOpenEspecieOpciones(null);
  };

  // Menu opciones

  const handleClickOpciones = (event: React.MouseEvent<HTMLElement>) => {
    if(!editarAlerta){
      setOpenOpciones(event.currentTarget);
    }
  };

  const handleOpcionesClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedOpcionesIndex(index);
    setOpenOpciones(null);
    setOpcionSeleccionada(listaOpcionesFilt && listaOpcionesFilt[index])
  };

  const handleCloseOpciones = () => {
    setOpenOpciones(null);
  };

  function getSymbol(): string {
    return tipoMercado === TipoMercado.Exterior ? 'USD ' : '$';
  }
  
  const agregarAlerta = async () => {
    try {
      if (precio === '' || especieSeleccionada === '') {
        setError('Falta completar algún campo');
      } else if (
        actualTipoAlerta === 'SOPORTE' &&
        ultimoPrecio <= Number(precio)
      ) {
        setError(
          `El precio actual de ${especieSeleccionada} es de ${getSymbol() + ultimoPrecio
          }`,
        );
      } else if (
        actualTipoAlerta === 'RESISTENCIA' &&
        ultimoPrecio >= Number(precio)
      ) {
        setError(
          `El precio actual de ${especieSeleccionada} es de ${getSymbol() + ultimoPrecio
          }`,
        );
      } else {
        if (token && especieSeleccionada) {
          let resp;
          if (editarAlerta && alerta) {
            let id = alerta._id;
            resp = await editAlert(
              id,
              parseFloat(precio),
              actualTipoAlerta,
              token,
            );
          } else {
            resp = await addAlert(
              tipoMercado === TipoMercado.Exterior
              ? especieSeleccionada + '_US' : especieSeleccionada,
              parseFloat(precio),
              actualTipoAlerta,
              tipoMercado,
              actualMenuEspecies,
              actualMenuEspecies === 'OPCIONES' && opcionSeleccionada ? opcionSeleccionada : '',
              token,
            );
          }
          setEspecieSeleccionada('');
          setPrecio('');
          setError('');
          if (resp.status === 200) {
            const alertas = resp.data.body.alertsSettedList;
            setSettedAlerts(alertas);
            navigate("/mis-alertas");
          } else {
            setError('Hubo un error. Cod ' + resp.status);
          }
        } else {
          setError('Error');
        }
      }
    } catch (error) {
      setError('Error');
      console.log(error);
    }
  }



  return {
    tipoMercado,
    listaMenuEspecies,
    tipoAlertasStrArr,
    handleClickTipoEspecie,
    handleTipoEspecieClick,
    handleCloseTipoEspecie,
    handleTipoAlertaClick,
    handleClickTipoAlerta,
    closeTipoAlerta,
    tipoEspecie,
    openTipoAlerta,
    selectedIndex,
    selectedTipoAlertaIndex,
    dataListaEspecies,
    listaEspeciesTitulos,
    handleClickEspecie,
    handleEspecieClick,
    handleCloseEspecie,
    openEspecie,
    selectedEspecieIndex,
    actualMenuEspecies,
    especieSeleccionada,
    listaOpcionesTitulos,
    handleClickEspecieOpciones,
    handleEspecieClickOpciones,
    handleCloseEspecieOpciones,
    openEspecieOpciones,
    selectedEspecieOpcionesIndex,
    listaOpcionesFilt, 
    handleClickOpciones,
    handleOpcionesClick,
    handleCloseOpciones,
    openOpciones,
    selectedOpcionesIndex,
    actualTipoAlerta,
    opcionSeleccionada,
    precio,
    setPrecio,
    error,
    agregarAlerta,
    loading,
    handleButtonTipoEspecie,
    handleButtonTipoAlerta,
    handleButtonClickEspecie,
  };
};