import styled from "styled-components";

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 500px;
`;

export const TooltipText = styled.div`
  width: 500px;
  // height: 1em;
  background-color: ${({theme}) => theme.primaryBackgroundColor};
  color: ${({theme}) => theme.primaryColor};
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  bottom: 125%; /* Posición arriba del contenedor */
  left: 50%;
  margin-left: -110px; /* Para centrar el tooltip */
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%; /* Al final del tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({theme}) => theme.primaryBackgroundColor} transparent transparent transparent;
  }
`;
