import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { getDataMEP } from '../../api/mercadoApi';
import { Titular } from '../../interfaces/datosPersonalesInterface';
import { Cartera } from '../../interfaces/tenenciaInterface';
import { Tenencia } from '../../interfaces/tenenciaInterface';
import { AuthContext } from '../authContext/AuthContext';
import { userReducer, UserState } from './userReducer';
import { Order } from '../../interfaces/ordersInterface';
import { Alert } from '../../interfaces/alertaInterface';
import { Sent } from '../../interfaces/notificacionInterface';
import { deleteAlertApi } from '../../api/userApi';
import { PreguntaFrecuente } from '../../interfaces/pregFrecuentesInterface';
import { Cuenta } from '../../interfaces/getCuentasInterface';

interface UserData {
    dniContext: string,
    setDniContext: React.Dispatch<React.SetStateAction<string>>,
    nroCuentaContext: string,
    setNroCuentaContext: React.Dispatch<React.SetStateAction<string>>,
    setTenencia: (data: Tenencia | null) => void;
    setCartera: (data: Cartera | null) => void;
    setUserData: (data: Titular | null) => void;
    userData: Titular | null,
    tenencia: Tenencia | null,
    cartera: Cartera | null,
    porcentajeLocal: number | null;
    porcentajeExterior: number | null;
    settedAlerts: Alert[];
    setSettedAlerts: (data: Alert[]) => void;
    sentAlerts: Alert[];
    setSentAlerts: (data: Alert[]) => void;
    deleteAlert: (alert: Alert) => Promise<void>;
    notifications: Sent[];
    setNotifications: (data: Sent[]) => void;
    cancelOrder: (nroOrden: string) => void;
    setOrders: (data: Order[]) => void;
    orders: Order[];
    pregFrecuentes: PreguntaFrecuente[];
    setPregFrecuentes: (data: PreguntaFrecuente[]) => void;
    setUserCuentas: (data: Cuenta[]) => void;
    cuentas: Cuenta[];
}

const userInicialState: UserState = {
    userData: null,
    tenencia: null,
    cartera: null,
    porcentajeLocal: null,
    porcentajeExterior: null,
    orders: [],
    notifications: [],
    settedAlerts: [],
    sentAlerts: [],
    pregFrecuentes: [],
    cuentas: [],
}

export const UserContext = createContext({} as UserData);

export const UserProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(userReducer, userInicialState);
    const { tokenPublico, token } = useContext(AuthContext)

    useEffect(() => {
        setPorcentajes();
    }, [state.cartera]);

    const [dniContext, setDniContext] = useState('')
    const [nroCuentaContext, setNroCuentaContext] = useState('')

    const setTenencia = (data: Tenencia | null) => {
        if (data) {
            const orderedData = ordenarTenencias(data);
            dispatch({
                type: 'loadTenencia',
                payload: { orderedData }
            })
        } else {
            dispatch({
                type: 'loadTenencia',
                payload: { orderedData: data }
            })
        }

    }

    const ordenarTenencias = (data: Tenencia) => {
        data.tenenciaMercadoLocal.sort((a, b) => {
            if (a.ticker < b.ticker) return -1;
            if (a.ticker > b.ticker) return 1;
            return 0
        })
        data.tenenciaMercadoExterior.sort((a, b) => {
            if (a.ticker < b.ticker) return -1;
            if (a.ticker > b.ticker) return 1;
            return 0
        })
        return data;
    }

    const setOrders = (data: Order[]) => {
        data.sort((a, b) => {
            if (a.especie.endsWith('_US') && !b.especie.endsWith('_US')) {
                return 1; // mueve b antes de a
            } else if (!a.especie.endsWith('_US') && b.especie.endsWith('_US')) {
                return -1; // mueve a antes de b
            } else {
                return 0; // deja en el mismo orden
            }
        });
        dispatch({
            type: 'loadOrders',
            payload: {
                data,
            },
        });
    };

    const setCartera = (data: Cartera | null) => {
        dispatch({
            type: 'loadCartera',
            payload: { data }
        })
    }

    const setUserData = (data: Titular | null) => {
        dispatch({
            type: 'loadUserData',
            payload: { data }
        })
    }


    async function setPorcentajes() {
        let porcentajeLocal: number | null = null;
        let porcentajeExterior: number | null = null;
        if (tokenPublico && state.cartera) {
            let { pesos, dolarBillete, dolarExterior } = state.cartera?.actualWallet;
            if (pesos === 0 && dolarExterior === 0 && dolarBillete === 0) {
                porcentajeExterior = 0;
                porcentajeLocal = 0;
            } else {
                const dolarMep = await getDataMEP(tokenPublico);
                const precioMep = dolarMep.data.body[0].ultimo;
                let pesosDolarizado = pesos / precioMep;
                let totalMercadoLocal = pesosDolarizado + dolarBillete;
                totalMercadoLocal = totalMercadoLocal < 0 ? 0 : totalMercadoLocal;
                dolarExterior = dolarExterior < 0 ? 0 : dolarExterior;
                let totalDolares = totalMercadoLocal + dolarExterior;
                porcentajeLocal = Math.trunc((totalMercadoLocal * 100) / totalDolares);
                porcentajeExterior = Math.trunc((dolarExterior * 100) / totalDolares);
                let dif = 100 - (porcentajeExterior + porcentajeLocal);
                if (dif < 0) {
                    porcentajeExterior = porcentajeExterior - dif * -1;
                } else if (dif > 0) {
                    porcentajeLocal = porcentajeLocal + dif;
                }
            }
        }
        dispatch({
            type: 'loadPorcentajes',
            payload: { porcentajeLocal, porcentajeExterior, },
        });
    }

    const cancelOrder = (nroOrden: string) => {
        dispatch({
            type: 'cancelOrder',
            payload: {
                nroOrden,
            },
        });
    };

    const setSettedAlerts = (data: Alert[]) => {
        dispatch({
            type: 'loadSettedAlerts',
            payload: {
                data,
            },
        });
    };
    const setSentAlerts = (data: Alert[]) => {
        dispatch({
            type: 'loadSentAlerts',
            payload: {
                data,
            },
        });
    };
    const deleteAlert = async (alert: Alert) => {
        if (token) {
            try {
                const resp = await deleteAlertApi(alert._id, token);
                if (resp.status === 200) {
                    dispatch({
                        type: 'deleteAlert',
                        payload: {
                            alert,
                        },
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const setNotifications = (data: Sent[]) => {
        dispatch({
            type: 'loadNotifications',
            payload: {
                data,
            },
        });
    };

    const setPregFrecuentes = (data: PreguntaFrecuente[]) => {
        dispatch({
            type: 'loadPreguntas',
            payload: {
                data,
            },
        });
    };

    const setUserCuentas = (data: Cuenta[]) => {
        const uniqueCuentas = data.filter((obj, index, self) =>
            index === self.findIndex((o) => o.CuentaBancaria === obj.CuentaBancaria && o.Moneda === obj.Moneda)
        )
        dispatch({
            type: 'loadCuentas',
            payload: {
                data: uniqueCuentas,
            }
        })
    }

    return (
        <UserContext.Provider value={{
            dniContext,
            setDniContext,
            nroCuentaContext,
            setNroCuentaContext,
            setTenencia,
            setCartera,
            setUserData,
            setOrders,
            cancelOrder,
            setSettedAlerts,
            setSentAlerts,
            deleteAlert,
            setNotifications,
            setPregFrecuentes,
            setUserCuentas,
            ...state
        }}>
            {children}
        </UserContext.Provider>
    )
}