import { useContext, useState } from 'react';
import { AuthContext } from '../context/authContext/AuthContext';
import { UserContext } from '../context/userContext/UserContext';
import { changePin, signInApi } from '../api/userApi';
import { useNavigate } from 'react-router-dom';
import { useNavigationHook } from './useNavigationHook';
import { TipoNavigate } from '../enums/enums';

export const useIngresarPinHook = (instancia: string) => {
  const [primerPin, setPrimerPin] = useState<string>("");
  const [pinValue, setPinValue] = useState<string>("");
  const [error, setError] = useState('');
  const { dniContext, nroCuentaContext } = useContext(UserContext);
  const { token, singIn, userId, pinGeneradoRava, setStatus } = useContext(AuthContext);
  const { handleNavigate } = useNavigationHook();
  const navigate = useNavigate();


  const continuar = async () => {
    setError('');
    if (instancia === 'ingresa' || instancia === 'cambioPin') {
      try {
        if (dniContext && nroCuentaContext) {

          const resp = await signInApi(
            parseInt(nroCuentaContext),
            pinValue,
            dniContext,
          );
          if (resp.status === 200) {
            const token = resp.data.body.token;
            if (token && instancia === 'ingresa') {
              navigate('/cambia-pin');
            }else if (token && instancia === 'cambioPin'){
              setStatus('not-authenticated')
              navigate('/cambio-de-pin');
            }
          } else {
            setError('El pin ingresado es erroneo');
          }
        }
      } catch (error) {
        console.log(error);
        setError('El pin ingresado es erroneo')
      }
    } else if (instancia === 'noCambio') {
      try {
        if (dniContext && nroCuentaContext) {
          const resp = await signInApi(
            parseInt(nroCuentaContext),
            pinGeneradoRava,
            dniContext,
          );
          if (resp.status === 200) {
            const token = resp.data.body.token;
            if (token) {
              singIn(token, userId)
              handleNavigate(TipoNavigate.Home)
            }
          }
        }
      } catch (error) {
        console.log(error);

      }
    } else if (instancia === 'cambio' && primerPin === "") {
      setPrimerPin(pinValue);
      setPinValue('');
    } else if (instancia === 'cambio' && pinValue === primerPin) {
      if (token) {
        try {
          const resp = await changePin(primerPin, pinValue, token);
          if (resp.status === 200) {
            singIn(token, userId)
            handleNavigate(TipoNavigate.Home);
          } else {
            setError('Hubo un error');
          }
        } catch (error) {
          setError('Hubo un error');
        }
      }
    } else {
      setError('Los códigos no coinciden');
    }
  };

  return {
    error,
    pinValue,
    setPinValue,
    continuar,
    primerPin,
  };
};
