import { useContext } from 'react'
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material"
import { Button } from '@mui/material'
import { Icon } from '@iconify/react';
import { Container, ContainerButton, ContainerInput, ErrorText, Input, Label, ListMenu, ListMenuEspecies, Text } from './elements'
import { useAgregarAlertaHook } from '../../hooks/useAgregarAlertaHook';
import { TipoAlerta } from '../../enums/enums';
import { colors } from '../../theme/Themes';
import { useLocation } from 'react-router-dom';
import { Alert as AlertInterface } from '../../interfaces/alertaInterface';
import { StyledMenu } from '../Ordenes/Ordenes';
import { ThemeContext } from 'styled-components';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';
import MenuPopUp from '../../components/MenuPopUp/MenuPopUp';
import MenuEspeciesOpcionesSearchBar from '../../components/mercadoComponents/MenuEspeciesOpcionesSearchBar';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { PrimerMenu } from '../../components/AgregarAlertaComponents/PrimerMenu/PrimerMenu';
import { SegundoMenu } from '../../components/AgregarAlertaComponents/SegundoMenu/SegundoMenu';
import { TercerMenu } from '../../components/AgregarAlertaComponents/TercerMenu/TercerMenu';

interface Props {
    editarAlerta: boolean,
    alerta: AlertInterface | null,
}


export const AgregarAlerta = () => {

    const location = useLocation();

    const { editarAlerta, alerta }: Props = location.state;

    const themeContext = useContext(ThemeContext)

    const { width } = useWindowDimensions();

    const { primaryColor, primaryBackgroundColor, grayToDarkGrayBackground, loadingColor, hoverMenuBackrgound } = themeContext;

    const {
        tipoMercado,
        listaMenuEspecies,
        tipoAlertasStrArr,
        handleClickTipoEspecie,
        handleCloseTipoEspecie,
        handleClickTipoAlerta,
        closeTipoAlerta,
        tipoEspecie,
        openTipoAlerta,
        selectedIndex,
        selectedTipoAlertaIndex,
        listaEspeciesTitulos,
        handleClickEspecie,
        handleCloseEspecie,
        openEspecie,
        actualMenuEspecies,
        listaOpcionesTitulos,
        especieSeleccionada,
        handleClickEspecieOpciones,
        handleEspecieClickOpciones,
        handleCloseEspecieOpciones,
        openEspecieOpciones,
        selectedEspecieOpcionesIndex,
        listaOpcionesFilt,
        handleClickOpciones,
        handleOpcionesClick,
        handleCloseOpciones,
        openOpciones,
        selectedOpcionesIndex,
        actualTipoAlerta,
        opcionSeleccionada,
        precio,
        setPrecio,
        error,
        agregarAlerta,
        loading,
        handleButtonTipoEspecie,
        handleButtonTipoAlerta,
        handleButtonClickEspecie,
    } = useAgregarAlertaHook({ editarAlerta, alerta });

    const styles = {
        Menu: {
            height: '2.5em',
            borderRadius: "4px",
            padding: '0',
            margin: '1.5em 1em 1em 1em',
        },
        MenuEspecie: {
            height: '2.1em',
            borderRadius: "4px",
            padding: '0',
            margin: '0.5em 1em 1.5em 1em',
        },
        ListItem: {
            padding: '0.2em 0.5em',
            marginLeft: '0.5em'
        },
        MenuItem: {
            backgroundColor: grayToDarkGrayBackground,
            borderBottom: `0.2px solid ${grayToDarkGrayBackground}`,
            padding: '0.9em',
            width: '11em',
            color: primaryColor,
            '&:hover': {
                backgroundColor: `${hoverMenuBackrgound} !important`,
            }
        },
    }

    return (
        <Container>

            <ResponsiveHeader title='Configuración' showSwitch={true} />

            {
                loading ?
                    <Box sx={{ display: "flex", justifyContent: "center", margin: "0.5em", alignItems: 'center', height: '90%' }}>
                        <CircularProgress style={{ color: loadingColor }} />
                    </Box>
                    :
                    <div style={{ marginTop: '2.5em', width: '100%' }}>
                        {/* Primer Menu */}
                        <PrimerMenu
                            width={width} 
                            listaMenuEspecies={listaMenuEspecies} 
                            actualMenuEspecies={actualMenuEspecies} 
                            selectedIndex={selectedIndex} 
                            tipoEspecie={tipoEspecie}
                            handleClickTipoEspecie={handleClickTipoEspecie}
                            handleCloseTipoEspecie={handleCloseTipoEspecie}
                            handleButtonTipoEspecie={handleButtonTipoEspecie}
                            tipoMercado={tipoMercado}
                        />

                        {/* Segundo Menu */}
                        <SegundoMenu 
                            width={width}
                            handleClickTipoAlerta={handleClickTipoAlerta}
                            actualTipoAlerta={actualTipoAlerta}
                            tipoAlertasStrArr={tipoAlertasStrArr}
                            selectedTipoAlertaIndex={selectedTipoAlertaIndex}
                            openTipoAlerta={openTipoAlerta}
                            closeTipoAlerta={closeTipoAlerta}
                            handleButtonTipoAlerta={handleButtonTipoAlerta}
                        />
                    
                        {/* Especie Menu */}

                        <Label>Especie</Label>
                        {
                            actualMenuEspecies === 'OPCIONES' ?
                                <>
                                    <ListMenuEspecies>
                                        <ListItem
                                            onClick={handleClickEspecieOpciones}
                                            style={styles.ListItem}
                                        >
                                            <ListItemText primary={!especieSeleccionada ? 'Buscar' : listaOpcionesTitulos && listaOpcionesTitulos[selectedEspecieOpcionesIndex]}
                                                style={{ color: !especieSeleccionada ? '#A3A3A3' : primaryColor }}
                                            />
                                        </ListItem>
                                    </ListMenuEspecies>
                                    <StyledMenu
                                        id="lock-menu"
                                        anchorEl={openEspecieOpciones}
                                        keepMounted
                                        open={Boolean(openEspecieOpciones)}
                                        onClose={handleCloseEspecieOpciones}
                                        style={{ maxHeight: '20em' }}
                                    >
                                        {listaOpcionesTitulos?.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedEspecieOpcionesIndex}
                                                onClick={(event) => handleEspecieClickOpciones(event, index)}
                                                style={{ ...styles.MenuItem, backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `0.15em solid ${grayToDarkGrayBackground}` }}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                </>
                                :
                                <>
                                <TercerMenu 
                                    listaEspeciesTitulos={listaEspeciesTitulos} 
                                    handleClickEspecie={handleClickEspecie}
                                    especieSeleccionada={especieSeleccionada}
                                    handleButtonClickEspecie={handleButtonClickEspecie}
                                />
                                </>
                                // <>
                                //     <ListMenuEspecies>
                                //         <ListItem
                                //             onClick={handleClickEspecie}
                                //             style={styles.ListItem}
                                //         >
                                //             <ListItemText primary={!especieSeleccionada ? 'Buscar' : especieSeleccionada}
                                //                 style={{ color: !especieSeleccionada ? '#A3A3A3' : primaryColor }}
                                //             />

                                //         </ListItem>
                                //     </ListMenuEspecies>
                                //     <MenuEspeciesOpcionesSearchBar 
                                //         open={Boolean(openEspecie)}
                                //         anchorEl={openEspecie}
                                //         handleClose={handleCloseEspecie}
                                //         listaMenuEspecies={listaEspeciesTitulos ? listaEspeciesTitulos : []}
                                //     />
                                // </>
                        }

                        {
                            actualMenuEspecies === 'OPCIONES' ?
                                <>
                                    <Label>Opciones</Label>
                                    <ListMenuEspecies>
                                        <ListItem
                                            onClick={handleClickOpciones}
                                            style={styles.ListItem}
                                        >
                                            <ListItemText primary={!especieSeleccionada ? 'No hay especie seleccionada' : !opcionSeleccionada ? 'Elegir' : listaOpcionesFilt && listaOpcionesFilt[selectedOpcionesIndex]}
                                                style={{ fontWeight: 400, color: !especieSeleccionada ? '#A3A3A3' : primaryColor }}
                                            />
                                            <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '1.2em', height: '1.2em', marginRight: '1.5em' }} />
                                        </ListItem>
                                    </ListMenuEspecies>
                                    <StyledMenu
                                        id="lock-menu"
                                        anchorEl={openOpciones}
                                        keepMounted
                                        open={Boolean(openOpciones)}
                                        onClose={handleCloseOpciones}
                                        style={{ maxHeight: '20em' }}
                                    >
                                        {listaOpcionesFilt?.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedOpcionesIndex}
                                                onClick={(event) => handleOpcionesClick(event, index)}
                                                style={{ ...styles.MenuItem, backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `0.15em solid ${grayToDarkGrayBackground}` }}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                </>
                                : null
                        }

                        <Label>Precio</Label>
                        <ContainerInput>
                            <Input
                                type='number'
                                value={precio}
                                onChange={e => setPrecio(e.target.value)}
                            />
                        </ContainerInput>
                        <Text>Número sin puntos</Text>

                        {
                            error ?
                                <ErrorText>{error}</ErrorText>
                                : null
                        }

                        <ContainerButton>
                            <Button
                                variant="contained"
                                disabled={false}
                                style={{
                                    width: "100%",
                                    backgroundColor: colors.blueRava,
                                }}
                                onClick={agregarAlerta}
                            >
                                ALERTAR
                            </Button>
                        </ContainerButton>
                    </div>
            }
        </Container>
    )
}

