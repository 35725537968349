import styled from "styled-components";


export const Container = styled.div`
background-color: ${({ theme }) => theme.primaryBackgroundColor};
margin: 1em;
border-radius: 7px;
display: flex;
flex-direction: column;
height: auto;
@media (min-width: 992px) {
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 100%;
    margin: 1em 1em 0em;
}
`

export const ContainerTitle = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 0.5px solid ${({ theme }) => theme.bordesMercado};
height: 3em;
align-items: center;
`

export const StarTitle = styled.div`
display: flex;
margin-left: 0.6em;
height: 3em;
align-items: center;
`

export const Title = styled.p`
margin: 0px;
color: ${({ theme }) => theme.primaryColor};
font-size: 19px;
font-weight: 700;
margin-left: 0.8em;
`

export const ContainerOpciones = styled.div`
    margin-top: 0.2em; 
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const TituloEspecie = styled.p`
margin: 0.5em 0em 0.6em 0.5em ;
font-weight: bold;
color: ${({ theme }) => theme.primaryColor};
`

export const ContainerBuscador = styled.div`
background-color: ${({ theme }) => theme.secondaryBackgroundColor};
border-radius: 10px;
margin-left: 0.4em;
margin-right: 0.4em;
display: flex;
align-items: center;
height: 2em;
`

export const EspecieBuscador = styled.div`
cursor: pointer; 
padding: 0 0.5em 0.2em;
width: 100%;
font-weight: 500;
color: ${({ theme }) => theme.primaryColor};
`

export const ContainerSinFavoritos = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 1em;
height: 9em;
@media (min-width: 992px) {
    height: 18em;
}
`
export const ContainerNoFavoritos = styled.div`
@media (min-width: 992px){
    margin-top: 3em;
}
`

export const TextoNoFavoritos = styled.p`
margin: 0em 0em 0.5em;
font-size: 17px;
font-weight: 500;
color: ${({ theme }) => theme.accentColor};
@media (min-width: 992px) {
    margin: 1.5em 0em 0.5em;
    font-size: 21px;
    font-weight: 700;
}
`

export const TextoNoEspeciesOpciones = styled.p`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.accentColor};
    @media (min-width: 992px) {
        font-size: 18px;
    }
`


export const ContainerEspecies = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        height: 33vh;
        max-height: inherit;
        flex-grow: 1;
        overflow-y: scroll;
    }
`

export const ContainerSinEspeciesOpciones = styled.div`
    display: flex;
    flex-direction: column;
    height: 22vh;
    justify-content: center;
    align-items: center;
`

export const ContainerEspeciesOpciones = styled.div`
    @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        height: 33vh;
        max-height: inherit;
        flex-grow: 1;
        overflow-y: scroll;
    }
`