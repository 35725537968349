import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext/AuthContext';

export const NotFound = () => {

    //Hacer screen de error que setee el status y etc así se va al enrutado de not-authenticated

    const { setStatus, cleanStorage, status } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        const tokenStorage = localStorage.getItem("token");
        if (tokenStorage) {
            cleanStorage();
            setStatus("not-authenticated");
            navigate("/login");
        }
    }, [status])

    return (
        <div></div>
    )
}
