import styled from "styled-components";

export const Container = styled.div`
	margin-left: 1em;
	margin-top: 0.4em;
	margin-right: 1em;
	height: auto;
	@media (min-width: 992px) {
		width: 100%;
		margin-top: 0.5em;
		display: flex;
		flex-direction: column;
	}
`

export const TituloCartera = styled.p`
	margin: 0px;
	font-weight: bold;
	font-size: 19px;
	color: ${({ theme }) => theme.primaryColor};
	@media (min-width: 992px) {
		margin-left: 0.5em;
		margin-bottom: 0.5em;
	}
`

export const ContainerSinSaldoCartera = styled.div`
	margin-top: 1em;
`

export const ContainerConSaldoCartera = styled.div`
	height: 100%;
	@media (min-width: 992px) {
		display: flex;
		flex-direction: column;
	}
`

export const ContainerEspecies = styled.div`
	margin-top: 0.5em;
	padding-bottom: 1em;
	@media (min-width: 992px) {
		height: 33vh;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		margin-top: 0.2em;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow-y: scroll;	
		padding-bottom: 0em;
		scrollbar-width: none;
		::-webkit-scrollbar {
			display: none;
		}
	}
`

