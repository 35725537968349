import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
margin-top: 0em;
margin-left: 1em;
@media (min-height: 780px) {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}
@media (min-width: 992px) {
    margin-bottom: 0em;
    margin-top: 0.65em;
}
`

export const ContainerButton = styled.div`
padding: 0.5em 0.5em 0;
cursor: pointer;
@media (min-height: 780px) {
    padding: 0.5em;
}
@media (min-width: 992px) {
    padding-bottom: 0em;
}
`

export const FontStylesSelected = styled.p`
margin: 0px;
font-size: 14px;
font-weight: 600;
border-bottom: 2px solid #F6B40E;
color: ${({theme}) => theme.primaryColor};
@media (min-width: 992px) {
    font-size: 16px;
}
`

export const FontStylesNotSelected = styled.p`
margin: 0px;
font-size: 14px;
font-weight: 600;
color: ${({theme}) => theme.alternativeAccentColor};
@media (min-width: 992px) {
    font-size: 16px;
}
`