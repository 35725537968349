import styled from "styled-components";

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 1em;
    gap: 2.5em;
`;

export const Button = styled.button`
    border-radius: 0.5em;
    border: 2px solid ${({ theme }) => theme.primaryColor};
    margin: 0;
    padding: 0.7em 0;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
`;

export const ButtonTipoAlertaContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1em 0 0 0;
    gap: 2.5em;
    padding: 0.2em 1em;
`;

export const ButtonTipoAlerta = styled.button`
    border-radius: 0.5em;
    border: 2px solid ${({ theme }) => theme.primaryColor};
    margin: 0;
    padding: 0.7em 0;
    font-size: 0.8em;
    font-weight: bold;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    padding-left: 1.2em;
`;