import React, { useContext, useEffect, useState } from 'react'
import { Container, ContainerDerecha, ContainerIzquierda, ContainerSearchBarOperadas, SecondContainer } from './elements'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import SearchBar from '../../components/mercadoComponents/SearchBar/SearchBar'
// import BotonesPaneles from '../../components/mercadoNuevoComponents/BotonesPaneles/BotonesPaneles'
import { useMercadoHook } from '../../hooks/useMercadoHook'
import { useEspeciesHook } from '../../hooks/useEspeciesHook'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import OperarNuevo from '../OperarNuevo/OperarNuevo'
import OfertasNuevo from '../../components/mercadoNuevoComponents/OfertasNuevo/OfertasNuevo'
import { DetalleContext } from '../../context/detalleEspecieContext/DetalleContext'
import { UserContext } from '../../context/userContext/UserContext'
import { TipoMercado } from '../../enums/enums'
import CardInfoMercadoNuevo from '../../components/mercadoNuevoComponents/CardInfoMercadoNuevo/CardInfoMercadoNuevo'
import { OperarContext } from '../../context/operarContext/OperarContext'
import { formatCurrency } from '../../helpers/formatCurrency'
import BotonesPanelesNuevo from '../../components/mercadoNuevoComponents/BotonesPanelesNuevo/BotonesPanelesNuevo'
import CardContainerEspecies from '../../components/mercadoNuevoComponents/CardContainerEspecies/CardContainerEspecies'
import CheckboxOperadas from '../../components/mercadoNuevoComponents/CheckboxOperadas/CheckboxOperadas'

const MercadoNuevo = () => {

    const { setActualMenuEspecies, actualMenuEspecies, listaMenuEspecies, reloadOrders } = useMercadoHook();
    const {
        hayFavoritos,
        filtrado,
        filtradoSearhBar,
        loadingEspecies,
        noHayData,
        colorMap,
        handleChangeTextoSearchBar,
        setCheckedOperadas,
        checkedOperadas
    } = useEspeciesHook(actualMenuEspecies);
    const { tipoMercado } = useContext(MercadoContext);
    const { ofertas, loadingOfertas, setOfertas, getOfertasEspecie, noHayOfertas } = useContext(DetalleContext);
    const { tenencia, orders } = useContext(UserContext);
    const { setTextoTitulos, onChangePrecio, onChangeCantidad, deskOperar } = useContext(OperarContext);

    const [nombreEspecieOfertas, setNombreEspecieOfertas] = useState('');

    const titulosResultadoDelDia = [
        'Especie',
        'Cantidad',
        'Precio ant.',
        'Ult. Precio',
        '% variación',
    ];

    const titulosOrdenesDelDia = [
        'Especie',
        'Tipo',
        'Cantidad',
        'Precio',
        'Estado',
    ];

    const cargarDatosOperar = (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => {
        if (!deskOperar) {
            if (especie) {
                getOfertasEspecie(especie);
                setNombreEspecieOfertas(simbolo);
                setTextoTitulos(simbolo);
                onChangePrecio(formatCurrency(precio));
                onChangeCantidad(String(cantidad));
            } else {
                setTextoTitulos(simbolo);
                onChangePrecio(formatCurrency(precio));
                onChangeCantidad(String(cantidad));
            }
        }
    }

    useEffect(() => {
        setNombreEspecieOfertas('');
        setOfertas(undefined);

        const interval = setInterval(reloadOrders, 60000);
        return () => clearInterval(interval)
    }, [])


    return (
        <Container>
            <ResponsiveHeader
                title='Mercado'
                showSwitch={true}
            />
            <SecondContainer>
                <ContainerIzquierda>
                    <ContainerSearchBarOperadas>
                        <SearchBar
                            funcionFiltrado={filtradoSearhBar}
                            textoSearchBar={handleChangeTextoSearchBar}
                            actualMenu={actualMenuEspecies}
                        />
                        <CheckboxOperadas
                            setCheckedOperadas={setCheckedOperadas}
                            checkedOperadas={checkedOperadas}
                        />
                    </ContainerSearchBarOperadas>
                    <BotonesPanelesNuevo
                        listaBotones={listaMenuEspecies}
                        botonActual={actualMenuEspecies}
                        setBotonActual={setActualMenuEspecies}
                    />
                    <CardContainerEspecies
                        tipoMercado={tipoMercado}
                        filtrado={filtrado}
                        loadingEspecies={loadingEspecies}
                        actualMenuEspecies={actualMenuEspecies}
                        hayFavoritos={hayFavoritos}
                        cargarDatosOperar={cargarDatosOperar}
                        noHayData={noHayData}
                        colorMap={colorMap}
                    />
                </ContainerIzquierda>
                <ContainerDerecha style={{ flexGrow: 1 }}>
                    <OperarNuevo />
                    <OfertasNuevo
                        ofertas={ofertas}
                        nombreEspecie={nombreEspecieOfertas}
                        loadingOfertas={loadingOfertas}
                        setPrecioOperar={onChangePrecio}
                        setCantidadOperar={onChangeCantidad}
                        confirmarDatos={deskOperar}
                        setTextoTitulos={setTextoTitulos}
                        tipoMercado={tipoMercado}
                        noHayOfertas={noHayOfertas}
                    />
                    <CardInfoMercadoNuevo
                        titulo={"Resultados del día"}
                        subtitulos={titulosResultadoDelDia}
                        dataTenencia={
                            tipoMercado === TipoMercado.Local
                                ? tenencia?.tenenciaMercadoLocal
                                : tenencia?.tenenciaMercadoExterior
                        }
                        confirmarDatos={deskOperar}
                        cargarDatosOperar={cargarDatosOperar}
                    />
                    <CardInfoMercadoNuevo
                        titulo={"Órdenes del día"}
                        subtitulos={titulosOrdenesDelDia}
                        dataOrdenes={orders}
                    />
                </ContainerDerecha>
            </SecondContainer>
        </Container>
    )
}

export default MercadoNuevo
