import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DataEspecie, DataEspecieOpciones } from '../../../interfaces/mercadoInferfaces';
import { Container, ContainerSinInfo, NoFavsInfo } from './elements';
import { LoadingContainer } from '../../../elements/loadingContainer';
import { CircularProgress } from '@mui/material';
import { ThemeContext } from 'styled-components';
import CardEspecieLocalNuevo from '../CardEspecieLocalNuevo/CardEspecieLocalNuevo';
import { FixedSizeList as List } from 'react-window';

interface Props {
    dataEspecies: DataEspecie[] | null | undefined;
    loading: boolean;
    actualMenu: string;
    hayFav: () => boolean;
    cargarDatosOperar: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    noHayData: boolean;
    colorMap: {};
}

const CardInfoPanel = ({
    dataEspecies,
    loading,
    actualMenu,
    hayFav,
    cargarDatosOperar,
    noHayData,
    colorMap,
}: Props) => {

    const [data, setData] = useState<DataEspecie[] | DataEspecieOpciones[] | null | undefined>([])

    const { loadingColor } = useContext(ThemeContext);

    useEffect(() => {
        setData(dataEspecies)
    }, [actualMenu, dataEspecies])

    const renderRow = useCallback(({ index, style, data }: { index: number, style: React.CSSProperties, data: any }) => {
        const { items, colorMap } = data
        if (items) {
            const item = items[index];
            const color = colorMap[item.especie];
            return (
                <CardEspecieLocalNuevo
                    style={style}
                    especie={item}
                    index={index}
                    cargarDatosOperar={cargarDatosOperar}
                    colorUltimo={color?.ultimo ? color.ultimo : undefined}
                    colorCantCompra={color?.cantCompra ? color.cantCompra : undefined}
                    colorPrecioCompra={color?.precioCompra ? color.precioCompra : undefined}
                    colorPrecioVenta={color?.precioVenta ? color.precioVenta : undefined}
                    colorCantVenta={color?.cantVenta ? color.cantVenta : undefined}
                    arrayLength={items.length}
                />
            );
        }
        return <></>
    }, [data])


    return (
        <Container>
            {
                loading
                    ? <LoadingContainer style={{ height: '100%' }}>
                        <CircularProgress style={{ color: loadingColor }} />
                    </LoadingContainer>
                    : noHayData
                        ? <ContainerSinInfo>
                            <NoFavsInfo>Actualmente no contamos con información disponible.</NoFavsInfo>
                        </ContainerSinInfo>
                        : actualMenu === "FAVORITOS" && !data?.length
                            ? <ContainerSinInfo>
                                <NoFavsInfo>No tenes ningún favorito asignado.</NoFavsInfo>
                            </ContainerSinInfo>
                            : data && data.length > 0 && (
                                <List
                                    height={1000} // Altura del contenedor
                                    itemCount={data.length} // Número de elementos
                                    itemSize={20} // Altura de cada elemento
                                    width={'100%'} // Ancho del contenedor
                                    overscanCount={20}
                                    style={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        // flexGrow: 1
                                    }}
                                    itemData={{ items: data, colorMap }}
                                >
                                    {renderRow}
                                </List>
                            )
            }
        </Container >
    )
}

export default CardInfoPanel