import { DataEspecie } from '../../../interfaces/mercadoInferfaces'
import { useCardEspecieHook } from '../../../hooks/useCardEspecieHook'
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { useModalHook } from '../../../hooks/useModalHook';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import { Container, ContainerButtomDerecha, ContainerButtomIzquierda, ContainerCompra, ContainerInfo, ContainerVenta, Hora, InfoButtomContainer, InfoTopContainer, NumeroCantidadDerecha, NumeroCantidadIzquierda, PrecioCompra, PrecioVenta, Simbolo, SimboloOpciones, TextoCantidadDerecha, TextoCantidadIzquierda, TituloCompraVenta, Ultimo, Variacion } from './elements';
import { useContext } from 'react';
import { TipoOperacion } from '../../../enums/enums';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import { ThemeContext } from 'styled-components';
import { useOperarHook } from '../../../hooks/useOperarHook';



interface Props {
    especie: DataEspecie;
    actualMenuEspecies: string
}

const CardEspecieLocal = ({ especie, actualMenuEspecies }: Props) => {

    const { esFav, addFav, deleteFav, cutHour, onClickEspecies } = useCardEspecieHook(especie);

    const { open, handleOpen, handleClose, handleAceptarModal } = useModalHook(deleteFav);

    const { onClickCompraVenta } = useOperarHook();

    const { primaryColor, secundaryBorderColor, buyColor, rojoPorcentaje } = useContext(ThemeContext);


    return (
        <Container>
            <div>
                {esFav ?
                    <div>
                        <StarRoundedIcon
                            onClick={handleOpen}
                            sx={{
                                color: primaryColor,
                                border: `0.1px solid ${secundaryBorderColor}`,
                                borderRadius: "20px",
                                padding: "3px",
                                marginLeft: "0.3em",
                                cursor: 'pointer'
                            }} />
                        <ModalConfirmacion
                            open={open}
                            handleClose={handleClose}
                            handleConfirmarModal={handleAceptarModal}
                            tituloModal={'Eliminar'}
                            textoModal={`¿Quieres eliminar ${especie.nombre} de tus favoritos?`}
                            textoConfirmarModal={'Eliminar'}
                        />
                    </div>
                    :
                    <StarBorderRoundedIcon
                        onClick={() => addFav()}
                        sx={{
                            color: primaryColor,
                            border: `0.1px solid ${secundaryBorderColor}`,
                            borderRadius: "20px",
                            padding: "3px",
                            marginLeft: "0.3em",
                            cursor: 'pointer'
                        }} />
                }
            </div>
            <ContainerInfo>
                <InfoTopContainer>
                    {actualMenuEspecies !== "OPCIONES" ?
                        <Simbolo
                            onClick={(e) => onClickEspecies(e)}
                        >{especie.simbolo}</Simbolo>
                        :
                        <SimboloOpciones onClick={(e) => onClickEspecies(e)}>{especie.simbolo}</SimboloOpciones>
                    }
                    <div style={{ display: 'flex' }}>
                        <Ultimo>{especie.ultimo ? formatCurrency(especie.ultimo) : '-'}</Ultimo>
                        <Variacion
                            style={{
                                color: especie.variacion > 0 ? buyColor : especie.variacion < 0 ? rojoPorcentaje : primaryColor
                            }}
                        >
                            {especie.variacion ? formatPorcentaje(especie.variacion) + '%' : '- %'}</Variacion>
                    </div>
                    <Hora>{cutHour(especie.hora)}</Hora>
                </InfoTopContainer>
                <InfoButtomContainer>
                    <ContainerButtomIzquierda>
                        <NumeroCantidadIzquierda>
                            {especie.cantcompra ? especie.cantcompra?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-'}
                        </NumeroCantidadIzquierda>
                        <TextoCantidadIzquierda> Cant.</TextoCantidadIzquierda>
                    </ContainerButtomIzquierda>
                    <div style={{ display: "flex" }}>
                        <ContainerCompra
                            onClick={() => onClickCompraVenta(especie, TipoOperacion.Venta, especie.preciocompra, especie.cantcompra)}
                        >
                            <PrecioCompra>
                                {especie.preciocompra ? formatCurrency(especie.preciocompra) : '-'}
                            </PrecioCompra>
                            <TituloCompraVenta>Compra</TituloCompraVenta>
                        </ContainerCompra>
                        <ContainerVenta
                            onClick={() => onClickCompraVenta(especie, TipoOperacion.Compra, especie.precioventa, especie.cantventa)}
                        >
                            <PrecioVenta>
                                {especie.precioventa ? formatCurrency(especie.precioventa) : '-'}
                            </PrecioVenta>
                            <TituloCompraVenta>Venta</TituloCompraVenta>
                        </ContainerVenta>
                    </div>
                    <ContainerButtomDerecha>
                        <TextoCantidadDerecha>Cant.</TextoCantidadDerecha>
                        <NumeroCantidadDerecha>
                            {especie.cantventa ? especie.cantventa?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-'}
                        </NumeroCantidadDerecha>
                    </ContainerButtomDerecha>
                </InfoButtomContainer>
            </ContainerInfo>
        </Container>
    )
}

export default CardEspecieLocal
