import useWindowDimensions from '../../hooks/useWindowDimensions';
import { HeaderDesk } from './HeaderDesk/HeaderDesk';
import { Headers } from './Headers/Headers';
import { Box } from '@mui/material';

type Props = {
    title: string;
    showSwitch: boolean;
    esDetalleEspecie?: boolean;
    esMiBilletera?: boolean;
}

export const ResponsiveHeader = ({ title, showSwitch, esDetalleEspecie, esMiBilletera }: Props) => {

    const { width } = useWindowDimensions();

    return (
        width > 992 ?
            <HeaderDesk titulo={title} showSwitch={showSwitch} esDetalleEspecie={esDetalleEspecie} esMiBilletera={esMiBilletera} />
            :
            <Box>
                <Headers titulo={title} showSwitch={showSwitch} esDetalleEspecie={esDetalleEspecie} />
            </Box>
    )
}

