import React from 'react';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../theme/Themes';

interface WithThemeProps {
    theme: CustomTheme;
}

const withTheme = <P extends object>(Component: React.ComponentType<P & WithThemeProps>) => {
    return (props: P) => {
        const theme = React.useContext(ThemeContext);
        return <Component {...props} theme={theme} />;
    };
};

export default withTheme;