import dayjs, { Dayjs } from "dayjs";
import { useContext, useState } from "react";
import { formatearFecha } from "../helpers/formatearFecha";
import { getTenenciaPorFecha } from "../api/userApi";
import { AuthContext } from "../context/authContext/AuthContext";
import * as XLSX from 'xlsx-js-style'
import { BodyTenenciaValorizada, Detalle, Posicion } from "../interfaces/tenenciaValorizadaInterface";
import { UserContext } from "../context/userContext/UserContext";

interface NewData {
    infoCuenta: {
        Comitente: string | undefined;
        Nombre: string | undefined;
        Fecha: string;
    }[];
    posicion: Posicion[];
    detalle: Detalle[];
}

const useTenenciaValorizadaHook = () => {
    const { token } = useContext(AuthContext);
    const { userData } = useContext(UserContext);
    const [loadingDescarga, setLoadingDescarga] = useState(false);
    const [fecha, setFecha] = useState<Dayjs | null>(dayjs());

    const tenenciaPorFecha = async () => {
        try {
            setLoadingDescarga(true)
            const fechaFormateada = formatearFecha(fecha);
            const resp = await getTenenciaPorFecha(token, fechaFormateada);
            createExcelFile(ordenarArchivoExcel(resp.data.body, fechaFormateada), fechaFormateada)
        } catch (error) {
            console.log();
        } finally {
            setLoadingDescarga(false);
        }
    };

    const ordenarArchivoExcel = (data: BodyTenenciaValorizada, fecha: string) => {
        const newData = {
            infoCuenta: [
                {
                    Comitente: userData?.nroCuenta,
                    Nombre: userData?.nombre,
                    Fecha: fecha,
                }
            ],
            posicion: data.posicion,
            detalle: data.detalle
        }
        return newData
    }

    function createExcelFile(data: NewData, fechaConFormato: string) {
        // Convierte tus datos en un formato adecuado para el archivo de Excel
        const workbook = XLSX.utils.book_new();
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } }, // Bold font, white color
            fill: {
                patternType: "solid",
                fgColor: { rgb: "00386E" } // Blue background color
            },
            alignment: { horizontal: "center" }
        };

        const boldStyle = {
            font: { bold: true }
        };

        const boldAndGrayStyle = {
            font: { bold: true }, // Fuente en negrita
            fill: {
                patternType: "solid",
                fgColor: { rgb: "D3D3D3" } // Color de fondo gris
            },
            alignment: { horizontal: "center" }
        };

        const header = ["Fecha", "Comitente", "Nombre"];
        const headerPosicion = ['Detalle', 'Cantidad USD', 'Tipo de Cambio', 'Importe'];
        const headerDetalle = ['Especie', 'Cantidad', 'Precio', 'Importe', '% S/T', 'Costo', '% Variacion', 'Resultado']

        const worksheetDataCombinada = XLSX.utils.aoa_to_sheet([]);

        worksheetDataCombinada["!cols"] = headerDetalle.map(() => ({ wch: 20 }));

        XLSX.utils.sheet_add_aoa(worksheetDataCombinada, [header], { origin: "A1", });

        // Apply the style to the header row
        header.forEach((col, index) => {
            const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
            if (!worksheetDataCombinada[cellAddress]) worksheetDataCombinada[cellAddress] = { v: col };
            worksheetDataCombinada[cellAddress].s = headerStyle;
        });

        XLSX.utils.sheet_add_aoa(worksheetDataCombinada,
            [[
                data.infoCuenta[0].Fecha,
                data.infoCuenta[0].Comitente,
                data.infoCuenta[0].Nombre
            ]], { origin: "A2" });


        XLSX.utils.sheet_add_aoa(worksheetDataCombinada, [['POSICIÓN']], { origin: "A5" });
        const posCellAddress = XLSX.utils.encode_cell({ c: 0, r: 4 });
        if (!worksheetDataCombinada[posCellAddress]) worksheetDataCombinada[posCellAddress] = { v: 'POSICIÓN' };
        worksheetDataCombinada[posCellAddress].s = boldAndGrayStyle;

        XLSX.utils.sheet_add_aoa(worksheetDataCombinada, [headerPosicion], { origin: "A6" });
        // Aplicar estilo a la fila de encabezados de la sección "Posición"
        headerPosicion.forEach((col, index) => {
            const cellAddress = XLSX.utils.encode_cell({ c: index, r: 5 });
            if (!worksheetDataCombinada[cellAddress]) worksheetDataCombinada[cellAddress] = { v: col };
            worksheetDataCombinada[cellAddress].s = headerStyle;
        });

        data.posicion.forEach((p, i) => {
            XLSX.utils.sheet_add_aoa(worksheetDataCombinada,
                [[
                    p.Detalle,
                    p.Cantidad,
                    p.TipoCambio,
                    p.Importe,
                ]], { origin: `A${i + 7}` });
        })


        XLSX.utils.sheet_add_aoa(worksheetDataCombinada, [['DETALLE']], { origin: "A14" });
        const detalleCellAddress = XLSX.utils.encode_cell({ c: 0, r: 13 });
        if (!worksheetDataCombinada[detalleCellAddress]) worksheetDataCombinada[detalleCellAddress] = { v: 'DETALLE' };
        worksheetDataCombinada[detalleCellAddress].s = boldAndGrayStyle;
        XLSX.utils.sheet_add_aoa(worksheetDataCombinada, [headerDetalle], { origin: "A15" });

        headerDetalle.forEach((col, index) => {
            const cellAddress = XLSX.utils.encode_cell({ c: index, r: 14 });
            if (!worksheetDataCombinada[cellAddress]) worksheetDataCombinada[cellAddress] = { v: col };
            worksheetDataCombinada[cellAddress].s = headerStyle;
        });

        let indice = 15
        data.detalle.forEach((p) => {
            indice += 1
            p.Subtotal.forEach((s) => {
                XLSX.utils.sheet_add_aoa(worksheetDataCombinada,
                    [[
                        s.Especie.length ? s.Especie : s.Ticker,
                        s.Cantidad,
                        s.Precio,
                        s.Importe,
                        s.Participacion,
                        s.Costo,
                        s.Variacion,
                        s.Resultado,
                    ]], { origin: `A${indice}` });
                indice += 1
            })
            const arregloDetalle = [
                p.TipoActivo,
                '',
                '',
                p.Importe,
                p.Participacion,
                '',
                '',
                p.Resultado,
            ]
            XLSX.utils.sheet_add_aoa(worksheetDataCombinada, [arregloDetalle], { origin: `A${indice}` });

            arregloDetalle.forEach((col, index) => {
                const cellAddress = XLSX.utils.encode_cell({ c: index, r: indice - 1 });
                if (!worksheetDataCombinada[cellAddress]) worksheetDataCombinada[cellAddress] = { v: col };
                worksheetDataCombinada[cellAddress].s = boldStyle;
            })

            indice += 1;
        });

        XLSX.utils.book_append_sheet(workbook, worksheetDataCombinada, "Reporte");

        // Generar el archivo Excel y descargarlo
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        saveExcelFile(excelBuffer, `Tenencia valorizada ${fechaConFormato}.xlsx`);
    }

    function saveExcelFile(buffer: any, filename: string) {
        const data = new Blob([buffer], { type: "application/octet-stream" });

        // Crear una URL para el Blob
        const blobUrl = window.URL.createObjectURL(data);

        // Crear un elemento <a> para la descarga
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename; // Nombre del archivo que se descargará

        // Simular un clic en el enlace
        document.body.appendChild(link);
        link.click();

        // Eliminar el enlace después de la descarga
        document.body.removeChild(link);

        // Revocar el objeto URL
        window.URL.revokeObjectURL(blobUrl);
    }

    return {
        tenenciaPorFecha,
        fecha,
        setFecha,
        loadingDescarga,
    };
};

export default useTenenciaValorizadaHook;
