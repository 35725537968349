import styled from "styled-components";

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
        width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
    }
`;