import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material"
import { TipoMercado } from "../../../enums/enums";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { UserContext } from "../../../context/userContext/UserContext";
import { useContext } from "react";
import { ContainerMercados, ContainerSecundario, ContainerTitulos, Img, Mercado, Porcentaje } from "./elements";
import { CenterContainer } from "../../../elements/centerContainer";
import { ThemeContext } from "styled-components";


interface Props {
    handleChangeSwitch: (e: boolean) => void;
    tipoMercado: TipoMercado;
    setTipoMercado: React.Dispatch<React.SetStateAction<TipoMercado>>;
    loadingTenencia: boolean,
    errorTenencia: boolean,
}

export const SwitchMercadoAmpliado = ({ handleChangeSwitch, tipoMercado, setTipoMercado, loadingTenencia, errorTenencia }: Props) => {

    const { porcentajeExterior, porcentajeLocal } = useContext(UserContext);

    const { loadingColor, primaryColor } = useContext(ThemeContext);

    const handlerTipoMercado = (tipoMercado: TipoMercado) => {
        localStorage.setItem('tipoMercado', tipoMercado);
        setTipoMercado(tipoMercado);
        handleChangeSwitch(false);
    }

    const styles = {
        mercado: {
            fontSize: '10px',
            fontWeight: 700,
            letterSpacing: '0.04em',
            color: primaryColor,
        },
        titulos: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1em',
        },
        arrow: {
            fontSize: 'small',
            color: primaryColor,
            marginRight: '0.4em',
        },
        arrowContainer: {
            display: 'flex',
            alignSelf: 'flex-start',
            padding: '0.5em',
            paddingTop: '0.7em',
        }
    }

    const ItemLocal = () => {
        return (
            <div onClick={() => handlerTipoMercado(TipoMercado.Local)} style={{ display: "flex", height: '2.2em', alignItems: 'center' }}>
                <Box sx={{ display: 'flex' }}>
                    <Img
                        alt="Bandera"
                        src={require('../../../images/bandera-argentina-cuenta.png')}
                    />
                    <ContainerTitulos>
                        <Porcentaje>{
                            loadingTenencia
                                ? <CircularProgress style={{ color: loadingColor, width: '1em', height: '1em' }} />
                                : errorTenencia
                                    ? '-'
                                    : porcentajeLocal || porcentajeLocal === 0
                                        ? porcentajeLocal
                                        : '-'
                        }%</Porcentaje>
                        <Mercado>MERCADO LOCAL</Mercado>
                    </ContainerTitulos>
                </Box>
            </div>
        )
    }

    const ItemExt = () => {
        return (
            <div onClick={() => handlerTipoMercado(TipoMercado.Exterior)} style={{ display: "flex", height: '2.2em', alignItems: 'center' }}>
                {/* <Box sx={{ display: 'flex' }}> */}
                <Img
                    alt="Bandera"
                    src={require('../../../images/bandera-usa.png')}
                />
                <ContainerTitulos>
                    <Porcentaje>{
                        loadingTenencia
                            ? <CircularProgress style={{ color: loadingColor, width: '1em', height: '1em' }} />
                            : errorTenencia
                                ? '-'
                                : porcentajeExterior || porcentajeExterior === 0
                                    ? porcentajeExterior
                                    : '-'
                    }%</Porcentaje>
                    <Mercado>MERCADO EXTERIOR</Mercado>
                </ContainerTitulos>
                {/* </Box> */}
            </div>
        )
    }

    return (
        <CenterContainer>
            <ContainerMercados>
                <ContainerSecundario>
                    {tipoMercado === TipoMercado.Local ? <ItemLocal /> : <ItemExt />}
                    {tipoMercado !== TipoMercado.Local ? <ItemLocal /> : <ItemExt />}
                </ContainerSecundario>
                <Box sx={styles.arrowContainer}>
                    <ArrowForwardIosOutlinedIcon sx={styles.arrow} />
                </Box>
            </ContainerMercados>
        </CenterContainer>
    )
}


