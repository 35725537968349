import { useContext } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Themes, colors } from '../../theme/Themes';
import { AuthContext } from '../../context/authContext/AuthContext';
import { Container, ContentContainer, TextContainer, ButtonContainer } from './elements';
import { useNavigationHook } from '../../hooks/useNavigationHook';
import { TipoNavigate } from '../../enums/enums';
import { ThemeContext } from 'styled-components';
import { ContainerSwitcher } from '../../elements/containerSwitcher';
import SwitchTheme from '../../components/SwitchTheme/SwitchTheme';
import { useIngresarPinHook } from '../../hooks/useIngresarPinHook';


export const Pin = () => {

  let url = window.location.href;
  let instancia = url.includes("genera-pin") ? "generaPin" : "cambiaPin";
  // const { setStatus } = useContext(AuthContext);
  // const { handleNavigate } = useNavigationHook();
  const navigate = useNavigate();
  const {continuar} = useIngresarPinHook('noCambio')

  const handleOmitir = () => {
      // setStatus("authenticated");
      // handleNavigate('login');
      continuar();
      // navigate('/login');
  }

  
  const themeContext = useContext(ThemeContext);
  const { primaryColor } = themeContext;

  return (
    <Container>
      <ContainerSwitcher>
        <SwitchTheme />
      </ContainerSwitcher>
      <div>
        <ContentContainer>
          {instancia === 'generaPin' ? (
            themeContext === Themes.light ?
            <img
              src={require('../../images/pin-1.png')}
              alt='pin1'
            />
            :
            <img
              src={require('../../images/pin-1-dark.png')}
              alt='pin1-dark'
            />
          ) : (
            themeContext === Themes.light ?
            <img
              src={require('../../images/pin-2.png')}
              alt='pin2'
            />
            :
            <img
              src={require('../../images/pin-2-dark.png')}
              alt='pin2-dark'
            />
          )}
          <TextContainer>
            {
              instancia === 'generaPin' ?
                <h4>GENERACIÓN DE PIN</h4>
                :
                <p>Podés conservar el PIN que te asignamos automáticamente, o modificarlo.</p>
            }

            {
              instancia === 'generaPin' ?
                <p>Te enviamos un número de
                  6 dígitos por e-mail.</p>
                :
                <h4>¿Querés cambiar el PIN
                  de acceso?</h4>
            }

          </TextContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button
            variant="contained"
            sx={styles.boton}
            onClick={instancia === 'generaPin' ? () => navigate("/ingresa-pin") : () => navigate("/cambio-de-pin")}
          >
            {
              instancia === 'generaPin' ?
                'SIGUIENTE' :
                'ACEPTAR'
            }
          </Button>
          {instancia === 'cambiaPin' ? (
            <Button
              sx={{...styles.botonOmitir, color: primaryColor}}
              onClick={handleOmitir}
            >
              OMITIR
            </Button>
          ) : null}
        </ButtonContainer>
      </div>
    </Container>
  );
};

const styles = {
  boton: {
    width: "60%",
    backgroundColor: colors.blueRava,
    marginTop: "1em",
    '@media (min-width: 992px)': {
      width: "15%",
    }
  },
  botonOmitir: {
    width: "60%",
    marginTop: "1em",
    '@media (min-width: 992px)': {
      width: "15%",
    }
  },
}
