import { useContext } from "react";
import { UserContext } from "../../../context/userContext/UserContext";
import { formatCurrency } from "../../../helpers/formatCurrency";
import { useState } from "react";
import { MercadoContext } from "../../../context/mercadoContext/MercadoContext";
import { TipoMercado } from "../../../enums/enums";
import { Container } from "./elements";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { isMarketOpen } from "../../../helpers/marketOpen";
import InfoMercadoDesk from "../InfoMercado/InfoMercadoDesk";
import InfoMercadoPhone from "../InfoMercado/InfoMercadoPhone";

interface Props {
    errorTenencia: boolean;
}

export const CardInfoCuenta = ({ errorTenencia }: Props) => {

    const { cartera, tenencia } = useContext(UserContext);
    const { tipoMercado, horarios } = useContext(MercadoContext);

    const storageShowPassword = localStorage.getItem('showPassword');

    const [showPassword, setShowPassword] = useState(
        storageShowPassword === 'false' ?
            false :
            true
    );

    const handleClickShowPassword = () => {
        let show = showPassword === true ? false : true
        setShowPassword(show);
        localStorage.setItem("showPassword", String(show))
    }

    const { width } = useWindowDimensions();

    const showData = () => {
        return showPassword ?
            {
                pesosEnCuenta: formatCurrency(cartera ? cartera.actualWallet.pesos : 0),
                dolaresMercadoLocal: formatCurrency(
                    cartera ? cartera.actualWallet.dolarBillete : 0,
                ),
                dolaresEnCuenta: formatCurrency(
                    cartera ? cartera.actualWallet.dolarExterior : 0,
                ),
                diferenciaMercadoLocal: formatCurrency(tenencia && tenencia?.variacionMercadoLocal),
                diferenciaMercadoExterior: formatCurrency(tenencia && tenencia.variacionMercadoExterior),
            } :
            {
                pesosEnCuenta: '*******',
                dolaresMercadoLocal: '****',
                dolaresEnCuenta: '*******',
                diferenciaMercadoLocal: '*******',
                diferenciaMercadoExterior: '*******',
            };
    }

    function mercadoAbierto() {
        return isMarketOpen(
            tipoMercado === TipoMercado.Local
                ? horarios.mercadoLocal.apertura
                : horarios.mercadoExterior.apertura,
            tipoMercado === TipoMercado.Local
                ? horarios.mercadoLocal.cierre
                : horarios.mercadoExterior.cierre,
        );
    }

    return (
        <Container>
            {tipoMercado === TipoMercado.Local ? (
                width < 992 ?
                    <InfoMercadoPhone
                        mercado='LOCAL'
                        variacion={tenencia && tenencia.variacionPorcentajeLocal}
                        errorTenencia={errorTenencia}
                        imgBandera={require('../../../images/bandera-argentina-cuenta.png')}
                        showPassword={showPassword}
                        handleClickShowPassword={handleClickShowPassword}
                        mercadoAbierto={mercadoAbierto}
                        moneda={'$'}
                        diferenciaMercado={showData().diferenciaMercadoLocal}
                        pesosEnCuenta={showData().pesosEnCuenta}
                        dolaresMercadoLocal={showData().dolaresMercadoLocal}
                    />
                    :
                    <InfoMercadoDesk
                        mercado='LOCAL'
                        variacion={tenencia && tenencia.variacionPorcentajeLocal}
                        errorTenencia={errorTenencia}
                        imgBandera={require('../../../images/bandera-argentina-cuenta.png')}
                        showPassword={showPassword}
                        handleClickShowPassword={handleClickShowPassword}
                        mercadoAbierto={mercadoAbierto}
                        moneda={'$'}
                        diferenciaMercado={showData().diferenciaMercadoLocal}
                        pesosEnCuenta={showData().pesosEnCuenta}
                        dolaresMercadoLocal={showData().dolaresMercadoLocal}
                    />
            ) : (
                width < 992 ?
                    <InfoMercadoPhone
                        mercado='EXTERIOR'
                        variacion={tenencia && tenencia.variacionPorcentajeExterior}
                        errorTenencia={errorTenencia}
                        imgBandera={require('../../../images/bandera-usa.png')}
                        showPassword={showPassword}
                        handleClickShowPassword={handleClickShowPassword}
                        mercadoAbierto={mercadoAbierto}
                        moneda={'USD'}
                        diferenciaMercado={showData().diferenciaMercadoExterior}
                        dolaresEnCuenta={showData().dolaresEnCuenta}
                    />
                    :
                    <InfoMercadoDesk
                        mercado='EXTERIOR'
                        variacion={tenencia && tenencia.variacionPorcentajeExterior}
                        errorTenencia={errorTenencia}
                        imgBandera={require('../../../images/bandera-usa.png')}
                        showPassword={showPassword}
                        handleClickShowPassword={handleClickShowPassword}
                        mercadoAbierto={mercadoAbierto}
                        moneda={'USD'}
                        diferenciaMercado={showData().diferenciaMercadoExterior}
                        dolaresEnCuenta={showData().dolaresEnCuenta}
                    />
            )}
        </Container >
    )
}
