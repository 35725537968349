import { useContext, useEffect, useState } from 'react'
import { DetalleContext } from '../context/detalleEspecieContext/DetalleContext'
import moment from 'moment';
import { CotizacionEspecie } from '../interfaces/cotizacionesEspecies';
import { dayOfDate, formatDates, returnMonth, returnYear, ultimosXMeses } from '../helpers/dateHelper';


interface GraficoData {
    name: string,
    precio: number,
}

export const useGraficoEspecieHook = () => {

    const { dataGrafico, especie, setLoadingGrafico } = useContext(DetalleContext)
    const [margen, setMargen] = useState('dia');
    const [data, setData] = useState<GraficoData[]>([]);
    const [minValue, setMinValue] = useState<number>(Number.MAX_VALUE)
    const [maxValue, setMaxValue] = useState<number>(Number.MIN_VALUE)

    const date = moment()

    useEffect(() => {
        setLoadingGrafico(true);
        const timeOut = setTimeout(() => {
            setLoadingGrafico(false);
        }, 2000);
        return () => {
            clearTimeout(timeOut);
        };
    }, [especie]);


    useEffect(() => {
        data?.forEach(item => {
            if (item.precio > maxValue) {
                setMaxValue(item.precio);
            }
            if (item.precio < minValue) {
                setMinValue(item.precio);
            }
        })
    }, [data])


    useEffect(() => {
        setearDatos();
        setMaxValue(Number.MIN_VALUE)
        setMinValue(Number.MAX_VALUE)
    }, [dataGrafico, margen])


    const setearDatos = () => {
        setData([])
        if (margen === 'dia') {
            graficoPorDia();
        } else if (margen === 'semana') {
            graficoPorSemana();
        } else if (margen === 'mes') {
            graficoPorMes();
        } else if (margen === 'anio') {
            graficoPorAnio();
        }
    }


    function graficoPorDia() {
        let arrayData: GraficoData[] = [];
        let arr: CotizacionEspecie[] = [];

        //se chequea si hacer reverse del array o no. a veces el array viene al principio con la ult fecha y a veces con la primer, no se por que

        if (dataGrafico) {
            if (date.diff(dataGrafico[0].fecha, 'month') < 2) {
                arr = dataGrafico.reverse();
            } else {
                arr = dataGrafico;
            }
            arrayData.push({ name: dayOfDate(arr[arr.length - 2].fecha), precio: Number(arr[arr.length - 2].cierre.toFixed(2)) })
            arrayData.push({ name: dayOfDate(arr[arr.length - 1].fecha), precio: Number(arr[arr.length - 1].cierre.toFixed(2)) })
            setData(arrayData);
        }
    }

    function graficoPorSemana() {
        let arrIterable: CotizacionEspecie[] = [];
        if (dataGrafico) {
            let arrayData: GraficoData[] = [];
            let idx = 0;

            //se chequea si hacer reverse del array o no. a veces el array viene al principio con la ult fecha y a veces con la primer, no se por que

            if (date.diff(dataGrafico[0].fecha, 'month') < 2) {
                arrIterable = dataGrafico.reverse();
            } else {
                arrIterable = dataGrafico;
            }

            while (idx < 7) {
                let index2 = arrIterable.length - idx - 1;
                let element = arrIterable[index2].cierre;
                let date = arrIterable[index2].fecha;
                arrayData.push({ name: dayOfDate(date), precio: Number(element.toFixed(2)) });
                idx++;
            }
            setData(arrayData.reverse());
        }
    }

    function graficoPorMes() {
        let array = createArrayData('month').arrDataCierre;
        setData(array);
    }


    function graficoPorAnio() {
        let { arrDataCierre, mesUltCotizacion } = createArrayData('year');

        const labelArray = ultimosXMeses(arrDataCierre.length, mesUltCotizacion);
        for (let index = 0; index < arrDataCierre.length; index++) {
            arrDataCierre[index] = { ...arrDataCierre[index], name: labelArray[index] };
        }


        setData(arrDataCierre);

    }

    function createArrayData(rango: string) {
        let arrDataCierre: GraficoData[] = [];
        let mesUltCotizacion = 0;
        if (dataGrafico) {
            let pasoUnMes = false;
            let idx = 0;
            let iterableArray;

            //se chequea si hacer reverse del array o no. a veces el array viene al principio con la ult fecha y a veces con la primer, no se por que
            if (date.diff(dataGrafico[0].fecha, 'month') < 2) {
                iterableArray = dataGrafico;
            } else {
                iterableArray = dataGrafico.reverse();
            }

            if (rango === 'month') {
                while (idx < iterableArray.length && !pasoUnMes) {
                    let element = iterableArray[idx];
                    if (date.diff(element.fecha, rango) < 1) {
                        arrDataCierre.push({ name: dayOfDate(element.fecha), precio: Number(element.cierre.toFixed(2)) });
                    } else {
                        pasoUnMes = true;
                    }
                    idx++;
                }
            } else {
                mesUltCotizacion = parseInt(returnMonth(iterableArray[0].fecha));
                arrDataCierre = cargarDatosDelAño(dataGrafico);
            }
        }
        return {
            mesUltCotizacion,
            arrDataCierre: arrDataCierre.reverse(),
        };
    }

    function cargarDatosDelAño(dataGrafico: CotizacionEspecie[]) {
        let arrReturn: GraficoData[] = [];
        let dia = 1;
        let mes = parseInt(returnMonth(dataGrafico[0].fecha));
        let anio = parseInt(returnYear(dataGrafico[0].fecha));
        let idx = 0;
        let toAdd;

        //recorro hasta llegar a 12 items
        while (idx < 12) {
            toAdd = dataGrafico.find(
                element => element.fecha === formatDates(dia, mes, anio),
            );
            if (!toAdd) {
                //si no encuentro dato en el dia 1 del mes empiezo a buscar hasta encontrar dato en el primer dia del mes
                while (!toAdd && dia < 31) {
                    dia++;
                    toAdd = dataGrafico.find(
                        element => element.fecha === formatDates(dia, mes, anio),
                    );
                }
                if (toAdd) arrReturn.push({ name: "", precio: Number(toAdd.cierre.toFixed(2)) })
                dia = 1;
            } else {
                //si encontre dato en el dia 1 del mes lo agrego
                arrReturn.push({ name: "", precio: Number(toAdd.cierre.toFixed(2)) });
            }
            //sumo cuando corresponde el mes y el año para seguir el recorrido
            if (mes > 1) {
                mes--;
            } else {
                mes = 12;
                anio = anio - 1;
            }
            idx++;
        }
        return arrReturn;
    }




    return {
        dataGrafico,
        margen,
        setMargen,
        data,
        minValue,
        maxValue,
    }
}
