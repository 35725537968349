import React, { useContext } from 'react'
import { LimiteOperacion, TipoOperacion } from '../../../enums/enums'
import { useComprarVenderHook } from '../../../hooks/useComprarVenderHook';
import ConfirmarDatosInfo from '../ConfirmarDatosInfo/ConfirmarDatosInfo';
import ConfirmarDDJJ from '../ConfirmarDDJJ/ConfirmarDDJJ';
import { Container, ContainerExitoError, ContainerLoader, MensajeError, MensajeExito, TituloExitoError } from './elements';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ThemeContext } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';


interface Props {
    tipoOperacion: TipoOperacion | null;
    especie: string | undefined;
    tipoOrden: LimiteOperacion;
    precio: string;
    cantidad: string;
    importe: string;
    getSymbol: () => string;
}

const ConfirmarDatosOperar = ({ tipoOperacion, especie, tipoOrden, precio, cantidad, importe, getSymbol }: Props) => {


    const {
        condicionddjj,
        getTextoDDJJ,
        setAceptoDDJJ,
        setCancelo,
        confirmo,
        error,
        setConfirmo,
        loading,
        nroOrden,
        tituloError,
        mensajeError,
    } = useComprarVenderHook();

    const { primaryColor, rojoPorcentaje, loadingColor } = useContext(ThemeContext);

    return (
        condicionddjj()
            ? <ConfirmarDDJJ
                getTextoDDJJ={getTextoDDJJ}
                setAceptoDDJJ={setAceptoDDJJ}
                setCancelo={setCancelo}
            />
            : !confirmo
                ? <ConfirmarDatosInfo
                    tipoOperacion={tipoOperacion}
                    especie={especie}
                    tipoOrden={tipoOrden}
                    precio={precio}
                    cantidad={cantidad}
                    importe={importe}
                    setConfirmo={setConfirmo}
                    setCancelo={setCancelo}
                    getSymbol={getSymbol}
                />
                : !loading
                    ? <Container>
                        {
                            !error
                                ? <ContainerExitoError>
                                    <TaskAltIcon sx={{ fontSize: '50px', color: primaryColor }} />
                                    <TituloExitoError
                                        style={{
                                            color: primaryColor
                                        }}
                                    >
                                        Enviaste tu orden con éxito.
                                    </TituloExitoError>
                                    <MensajeExito>Estamos procesando tu orden.</MensajeExito>
                                    <MensajeExito>{`Orden N° ${nroOrden}`}</MensajeExito>
                                </ContainerExitoError>
                                :
                                <ContainerExitoError>
                                    <CancelOutlinedIcon sx={{ fontSize: '50px', color: rojoPorcentaje }} />
                                    <TituloExitoError
                                        style={{
                                            color: rojoPorcentaje
                                        }}
                                    >
                                        {tituloError}
                                    </TituloExitoError>
                                    <MensajeError>{mensajeError}</MensajeError>
                                </ContainerExitoError>
                        }
                    </Container>
                    : <ContainerLoader>
                        <CircularProgress size={40} thickness={4} style={{ color: loadingColor }} />
                    </ContainerLoader>

    )
}

export default ConfirmarDatosOperar