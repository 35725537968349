import styled from "styled-components";


export const Container = styled.div`
display: flex;
// border-bottom: 0.5px solid #C6C6C8;
border-bottom: 1px solid ${({theme}) => theme.bordesMercado};
// border-top: 1px solid ${({theme}) => theme.bordesMercado};
align-items: center;
height: 4.5em;
`

export const ContainerSecundario = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
@media (min-width: 992px){
    width: 90%;
    margin-left: 1.5em;
    height: 4.5em;
}
`

export const NombreEspecie = styled.p`
margin: 0.1em;
color: ${({theme}) => theme.primaryColor};
font-weight: 700;
font-size: 18px;
letter-spacing: 0.44px;
display: flex;
align-items: center;
margin-left: 1em;
cursor: pointer;
`

export const ContainerUltimoVariacion = styled.div`
margin-right: 0.5em;
padding-right: 0.5em;
padding-left: 1.5em;
background: ${({theme}) => theme.containerCardExterior};
border-radius: 5px;
width: 3.5em;
`

export const UltimoVariacion = styled.p`
margin: 0.1em;
font-weight: 700;
font-size: 16px;
text-align: right;
color: ${({theme}) => theme.primaryColor};
margin-right: 0.3em;
`

export const Hora = styled.p`
color: ${({theme}) => theme.fontColorWhiteToBlack};
margin: 0.1em;
text-align: center;
font-size: 14px;
margin-right: 0.5em;
`