import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  height: 92vh;
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
  margin-left: 12vw;
  width: 87vw;
  padding: 0vh 0.5vw 0 0.5vw;
`;


export const SecondContainer = styled.div`
  display: flex;
  flex-grow: 1; 
  height: 100%;
`

export const ContainerIzquierda = styled.div`
display: flex; 
flex-direction: column; 
flex-grow: 1; 
width: 75%; 
margin-bottom: 0.5em;
`

export const ContainerDerecha = styled.div`
display: flex;
flex-direction: column;
width: 25%;
margin-right: 1em;
margin-bottom: 0.5em;
`

export const ContainerSearchBarOperadas = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`