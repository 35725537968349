import { Autocomplete, autocompleteClasses, createFilterOptions, ListSubheader, Popper, styled, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { ThemeContext } from 'styled-components';



function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number),
    };

    const { key, ...optionProps } = dataSet[0];

    return (
        <Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        },
    );

    const itemCount = itemData.length;

    const gridRef = useResetCache(itemCount);
    const itemSize = 35

    const getChildSize = (child: React.ReactElement) => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});



const filterOptions = createFilterOptions({
    matchFrom: 'start',
});

interface Props {
    especies: string[];
    setEspecieSeleccionada: React.Dispatch<React.SetStateAction<string>>;
}

const AutocompleteEspecies = ({ especies, setEspecieSeleccionada }: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext);

    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            backgroundColor: primaryBackgroundColor,
            boxSizing: 'border-box',

            '& ul': {
                padding: 0,
                margin: 0,
                backgroundColor: primaryBackgroundColor,
                color: primaryColor
            },
        },
    });


    return (
        <Autocomplete
            id="virtualize-demo"
            sx={{
                width: '11em',
                "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    padding: "0",
                    color: primaryColor,
                    '& fieldset': {
                        borderColor: primaryColor, // Border color when not focused
                    },
                    '&:hover fieldset': {
                        borderColor: primaryColor, // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                        border: `1px solid ${primaryColor}`
                    },
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: primaryColor
                },
                '& .MuiInputBase-input': {
                    color: primaryColor, // Text color
                },
                '& .MuiInputLabel-outlined': {
                    color: primaryColor,
                    '&.Mui-focused': {
                        color: primaryColor,
                    }
                },
            }}
            disableListWrap
            freeSolo
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            size='small'
            disableClearable
            options={especies}
            filterOptions={filterOptions}
            popupIcon={
                <KeyboardArrowRightRoundedIcon
                    sx={{
                        color: primaryColor
                    }}
                />
            }
            renderInput={
                (params) =>
                    <TextField
                        {...params}
                        label="Especies"
                        onChange={(e) => {
                            setEspecieSeleccionada(e.target.value);
                        }}
                    />}
            renderOption={(props, option, state) =>
                [props, option, state.index] as React.ReactNode
            }
            onChange={(event, value) => setEspecieSeleccionada(value as string)}
        />
    )
}

export default AutocompleteEspecies