export const dates = () => {
  let date: Date = new Date();
  let dia = date.getDate();
  let mes = date.getMonth();
  let anio = date.getFullYear();

  let diaFormat = dia.toString();
  let mesFormat = (mes + 1).toString();

  if (dia < 10) {
    diaFormat = '0' + diaFormat;
  }
  if (mes < 10) {
    mesFormat = '0' + (mes + 1).toString();
  }
  return {
    fecha_inicio: [anio - 1, mesFormat, diaFormat].join('-'),
    fecha_fin: [anio, mesFormat, diaFormat].join('-'),
  };
};

export const formatDates = (dia: number, mes: number, anio: number) => {
  let diaFormat = dia.toString();
  let mesFormat = mes.toString();

  if (dia < 10) {
    diaFormat = '0' + diaFormat;
  }
  if (mes < 10) {
    mesFormat = '0' + mesFormat;
  }
  return [anio, mesFormat, diaFormat].join('-');
};

export const pasoMasDeUnMes = (dateAnterior: string, dateNuevo: string) => {
  // let yearDateAnterior = parseInt(returnYear(dateAnterior));
  let monthDateAnterior = parseInt(returnMonth(dateAnterior));
  // let yearDateNuevo = parseInt(returnYear(dateNuevo));
  let monthDateNuevo = parseInt(returnMonth(dateNuevo));
  if (monthDateAnterior - monthDateNuevo === 1) {
    return false;
  } else if (monthDateAnterior - monthDateNuevo === -11) {
    return false;
  } else {
    return true;
  }
};

export const dayOfDate = (date: string) => {
  let arrDate = date.split('-');
  let dia = deleteZero(arrDate[2]);
  let mes = deleteZero(arrDate[1]);
  return dia + ' ' + meses[parseInt(mes) - 1];
};

export const returnDay = (date: string) => {
  let arrDate = date.split('-');
  let dia = arrDate[2];
  return dia;
};

export const returnMonth = (date: string) => {
  let arrDate = date.split('-');
  let mes = arrDate[1];
  return mes;
};
export const returnYear = (date: string) => {
  let arrDate = date.split('-');
  let year = arrDate[0];
  return year;
};
const deleteZero = (str: string) => {
  if (str[0] === '0') {
    str = str.substring(1);
  }
  return str;
};

export const ultimosXMeses = (length: number, mes: number) => {
  mes = mes - 1;
  let arr = [];
  let contador = 0;
  let indiceDos = 11;
  while (contador < length) {
    let indice = mes - contador;
    if (indice >= 0) {
      arr.push(meses[indice]);
    } else {
      arr.push(meses[indiceDos]);
      indiceDos--;
    }
    contador++;
  }
  return arr.reverse();
};

const meses = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

//cuando llegue a 11 en array el idx va a estar en 4, entonces en el 5 tiene que estar en 0
