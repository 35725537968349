import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    border-bottom: 1.5px solid ${({ theme }) => theme.alternativeBorderColor};
    grid-template-columns: 2fr 1.4fr 0.8fr repeat(4, 1.6fr) 2fr;
    align-items: center;
    @media (max-width: 1400px) {
    grid-template-columns: 2fr 1.4fr 0.8fr repeat(4, 1.6fr) 2.5fr;
    }
`

export const ContainerTexto = styled.div`
    text-align: end;
    padding: 0.2em 0.8em 0.2em 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const Texto = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0.3em 0 0.3em;
    font-size: 14px;
`