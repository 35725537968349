import styled from "styled-components";

export const ContainerMercados = styled.div`
    display: flex;
    align-items: center;
    border-radius: 15px;
    width: 90%;
    justify-content: space-between;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    cursor: pointer;
    height: 100%;
`;

export const ContainerSecundario = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.2em;
`


export const Img = styled.img`
    width: 1em;
    height: 1em;
    padding: 0.4em;
    margin-left: 0.6em;
`;

export const ImgArg = styled.img`
    width: 1em;
    height: 1em;
    padding: 0.4em;
    margin-left: 0.6em;
`;

export const ContainerTitulos = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.5em;
`;

export const Porcentaje = styled.p`
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({theme}) => theme.primaryColor};
    margin: 0px;
`;

export const Mercado = styled.p`
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({theme}) => theme.primaryColor};
    margin: 0px;
    margin-left: 0.4em;
`;