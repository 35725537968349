import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({theme}) => theme.alternativeBackgroundColor};  
    height: 6em;
    overflow: none;
`;

export const ContainerSuperior = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em; 
`;

export const Img = styled.img`
    width: 40%; 
    height: 100%;
    margin-left: 20px;
    margin-top: 5px;
`;

export const Hr = styled.hr`
    background-color: #E5E5E5;
`;

export const ContainerSwitchMercado = styled.div`
    height: 2em;    
`;