import React, { useContext, useState } from "react";
import { AuthContext } from "../context/authContext/AuthContext";
import { getTenencia, retirarDolares } from "../api/userApi";
import { UserContext } from "../context/userContext/UserContext";

const useRetirarDolaresHook = () => {
    const { tokenPublico } = useContext(AuthContext);
    const { setTenencia } = useContext(UserContext);

    const [tipoDolarImporte, setTipoDolarImporte] = useState({
        cantidad: 0,
        especie: "",
    });

    const transferirDolares = async () => {
        try {
        if (tokenPublico) {
            const resp = await retirarDolares({
            token: tokenPublico,
            ...tipoDolarImporte,
            });
            const tenencia = await getTenencia(tokenPublico);
            setTenencia(tenencia.data.body);
            if (resp.data.error === false) {
            }
        }
        } catch (error) {
        console.log(error);
        }
    };
    return {
        setTipoDolarImporte,
        transferirDolares,
    };
};

export default useRetirarDolaresHook;
