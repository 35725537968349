import { useContext, useState } from "react";
import { UserContext } from "../../../context/userContext/UserContext";
import { TipoMercado, TipoOpcion } from "../../../enums/enums";
import { CardEspecieCartera } from "../CardEspecieCartera/CardEspecieCartera";
import { BotonesActualHistorico } from "../BontonesActualHistorico/BotonesActualHistorico";
import { CardInfoCuenta } from "../CardInfoCuenta/CardInfoCuenta";
import { CardSinSaldo } from "../CardSinSaldo/CardSinSaldo";
import { SaldoCartera } from "../SaldoCartera/SaldoCartera";
import { MercadoContext } from "../../../context/mercadoContext/MercadoContext";
import { Container, ContainerConSaldoCartera, ContainerEspecies, ContainerSinSaldoCartera, TituloCartera } from "./elements";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { TenenciasSeparadas } from "../../../interfaces/tenenciaInterface";
import { changeColor } from "../../../helpers/changeColor";
import InfoDetallesHome from "../InfoDetallesHome/InfoDetallesHome";
import InfoDetallesHomeExterior from "../InfoDetallesHome/InfoDetallesHomeExterior";

interface Props {
    errorTenencia: boolean;
    tenenciasSeparadas: TenenciasSeparadas;
    loadingTenencia: boolean;
}
export const CarteraCardHome = ({
    errorTenencia,
    tenenciasSeparadas,
    loadingTenencia,
}: Props) => {

    const { tenencia } = useContext(UserContext);

    const { tipoMercado } = useContext(MercadoContext);

    const { width } = useWindowDimensions()

    const [actualOption, setActualOption] = useState<TipoOpcion>(TipoOpcion.Actual);

    const tienePedidoDolarRenta = () => {
        const pedidoDolarRenta = {
            dolar7000: false,
            dolar10000: false,
        }

        tenencia?.pedidosDolarRenta?.forEach(p => {
            if (p.especie.includes('7000')) {
                pedidoDolarRenta.dolar7000 = true;
            }
            if (p.especie.includes('10000')) {
                pedidoDolarRenta.dolar10000 = true;
            }
        })

        return pedidoDolarRenta;
    }

    return tipoMercado === TipoMercado.Local ?
        !tenencia?.tenenciaMercadoLocal.length ?
            //Es Mercado Local y NO tiene tenencia en dicho mercado
            (
                <Container>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TituloCartera>Tu cartera</TituloCartera>
                        <CardInfoCuenta errorTenencia={errorTenencia} />
                    </div>
                    <ContainerSinSaldoCartera>
                        <SaldoCartera />
                        <CardSinSaldo mercado={tipoMercado} />
                    </ContainerSinSaldoCartera>
                </Container>
            ) :
            //Es Mercado Local y tiene tenencia en dicho mercado
            (
                <Container>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TituloCartera>Tu cartera</TituloCartera>
                        <CardInfoCuenta errorTenencia={errorTenencia}></CardInfoCuenta>
                    </div>
                    <ContainerConSaldoCartera>
                        {width > 992 ?
                            <>
                                <SaldoCartera />
                                <InfoDetallesHome
                                    tenenciaVariable={tenenciasSeparadas.localRentaVariable.tenencia}
                                    tenenciaFija={tenenciasSeparadas.localRentaFija.tenencia}
                                    tenenciaDolares={tenenciasSeparadas.dolares.tenencia}
                                    subtotalVariable={tenenciasSeparadas.localRentaVariable.subtotalRentaVariable}
                                    subtotalFija={tenenciasSeparadas.localRentaFija.subtotalRentaFija}
                                    subtotalDolares={tenenciasSeparadas.dolares.subtotalDolares}
                                    subtotalPorcentajeVariable={tenenciasSeparadas.localRentaVariable.subtotalPorcentajeTenencia}
                                    subtotalPorcentajeFija={tenenciasSeparadas.localRentaFija.subtotalPorcentajeTenencia}
                                    subtotalPorcentajeDolares={tenenciasSeparadas.dolares.subtotalPorcentajeTenencia}
                                    loading={loadingTenencia}
                                    dolarRenta={tienePedidoDolarRenta()}
                                />
                            </>
                            :
                            <>
                                <BotonesActualHistorico
                                    actualOption={actualOption}
                                    setActualOption={setActualOption}
                                />
                                <SaldoCartera />
                                <ContainerEspecies>
                                    {
                                        tenencia.tenenciaMercadoLocal.map((t, i) => (
                                            <CardEspecieCartera
                                                especie={t.ticker}
                                                precio={t.precioActual}
                                                cantidad={t.cantidad}
                                                importe={t.importe}
                                                porcentaje={t.variacionDia}
                                                costo={t.costoCompra}
                                                variacionCosto={t.variacionCosto}
                                                tipoOpcion={actualOption}
                                                key={t.nombreEspecie}
                                                pintar={changeColor(i)}
                                            />
                                        ))
                                    }
                                </ContainerEspecies>
                            </>
                        }
                    </ContainerConSaldoCartera>
                </Container>
            ) :
        !tenencia?.tenenciaMercadoExterior.length ?
            //Es Mercado Exterior y NO tiene tenencia en dicho mercado
            (
                <Container>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TituloCartera>Tu cartera</TituloCartera>
                        <CardInfoCuenta errorTenencia={errorTenencia} />
                    </div>
                    <ContainerSinSaldoCartera>
                        <SaldoCartera />
                        <CardSinSaldo mercado={tipoMercado} />
                    </ContainerSinSaldoCartera>
                </Container>
            ) :
            //Es Mercado Exterior y tiene tenencia en dicho mercado
            (
                <Container>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TituloCartera>Tu cartera</TituloCartera>
                        <CardInfoCuenta errorTenencia={errorTenencia} />
                    </div>
                    <ContainerConSaldoCartera>
                        {width > 992 ?
                            <>
                                <SaldoCartera />
                                <InfoDetallesHomeExterior
                                    tenenciaExterior={tenenciasSeparadas.exterior}
                                    loading={loadingTenencia}
                                />
                            </>
                            :
                            <>
                                <BotonesActualHistorico
                                    actualOption={actualOption}
                                    setActualOption={setActualOption}
                                />
                                <SaldoCartera />
                                <ContainerEspecies>
                                    {
                                        tenenciasSeparadas.exterior.map((t, i) => (
                                            <CardEspecieCartera
                                                especie={t.ticker}
                                                precio={t.precioActual}
                                                cantidad={t.cantidad}
                                                importe={t.importe}
                                                porcentaje={t.variacionDia}
                                                costo={t.costoCompra}
                                                variacionCosto={t.variacionCosto}
                                                tipoOpcion={actualOption}
                                                key={t.nombreEspecie}
                                                pintar={changeColor(i)}
                                            />
                                        ))
                                    }
                                </ContainerEspecies>
                            </>
                        }
                    </ContainerConSaldoCartera>
                </Container>
            )

}
