import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  border-radius: 5px;
  margin: 1em;
  height: 16em;
  @media (max-width: 992px) {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    height: 80%;
  }
`;

export const ContainerMensajes = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const Titulo = styled.h2`
  color: ${({ theme }) => theme.primaryColor};
  padding: 1.5rem 1rem 0rem 1.5rem;
  @media (max-width: 992px) {
    padding: 1em 0 0 0;
  }
`;
export const ContenedorDatos = styled.div`
  padding-left: 1.5em;
  padding-bottom: 1.5em;
  @media (max-width: 992px) {
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 5px;
    padding: 1.5em;
  }
`;

export const Datos = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  margin: 0;
`;

export const ContenedorBotones = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
`;
export const BotonContinuar = styled.button`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme }) => theme.primaryColor};
  border-radius: 5px;
  border: 3px solid ${({ theme }) => theme.primaryColor};
  font-size: 16px;
  font-weight: 600;
  padding: 0.5em;
  margin-top: 1em;
  cursor: pointer;
  width: 20%;
  @media (max-width: 992px) {
    margin-top: 2em;
    width: 100%;
  }
`;

export const BotonCancelar = styled.button`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme }) => theme.accentColor};
  border-radius: 5px;
  border: 3px solid ${({ theme }) => theme.accentColor};
  font-size: 16px;
  font-weight: 600;
  padding: 0.5em;
  margin-top: 1em;
  width: 20%;
  cursor: pointer;
  @media (max-width: 992px) {
    margin-top: 2em;
    width: 100%;
  }
`;

export const ContainerExitoError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 992px) {
    justify-content: center;
  }
`;

export const TituloExitoError = styled.p`
  margin: 0px;
  padding: 0.1em;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
  line-height: 33px;
`;

export const MensajeExito = styled.p`
  margin: 0px;
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  line-height: 33px;
`;

export const MensajeError = styled.p`
  margin: 0px;
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.rojoPorcentaje};
  line-height: 33px;
`;

export const ContainerLoader = styled.div`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16em;
  margin: 1em;
  border-radius: 5px;
  @media (max-width: 992px) {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    height: 100%;
    
  }
`;
