import { Box, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useSearchMercadoHook } from '../../../hooks/useSearchMercadoHook';
import MenuEspeciesSearchBar from '../MenuEspeciesSearchBar';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import { Container } from './elements';
import { useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface Props {
    especie?: string;
    funcionFiltrado?: (especie: string) => void;
    textoSearchBar?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    actualMenu?: string;
}

const SearchBar = ({ especie, funcionFiltrado, textoSearchBar, actualMenu }: Props) => {

    const { text, setText, handleChangeInputPhone, filterData, openMenu, anchorElMenu, handleCloseMenu } = useSearchMercadoHook();

    const { primaryColor, fontColorWhiteToBlack, } = useContext(ThemeContext)

    const { width } = useWindowDimensions();

    useEffect(() => {
        especie && setText(especie)
    }, [])

    useEffect(() => {
        setText('');
    },[actualMenu])

    useEffect(() => {
        funcionFiltrado && funcionFiltrado(text)
    }, [text])

    const styles = {
        lupa: {
            marginLeft: '0.5em',
            color: primaryColor,
        },
        buscar: {
            marginLeft: '0.3em',
            width: '100%',
            color: fontColorWhiteToBlack,
        }
    }

    return (
        <Container>
            <Box sx={styles.lupa}>
                <SearchIcon sx={{ fontSize: '1.2em' }} />
            </Box>
            <InputBase
                sx={styles.buscar}
                placeholder="Buscar"
                className='inputSearchBar'
                onChange={(e) => {
                    handleChangeInputPhone(e)
                    textoSearchBar && textoSearchBar(e);
                }}
                value={formatEspeciesExterior(text.toLocaleUpperCase())}
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            {
                width < 992
                    ? <MenuEspeciesSearchBar
                        open={openMenu}
                        anchorEl={anchorElMenu}
                        handleClose={handleCloseMenu}
                        listaMenuEspecies={filterData}
                    />
                    : null
            }
        </Container>
    )
}

export default SearchBar


