import { Box } from '@mui/material'
import { useGraficoEspecieHook } from '../../../hooks/useGraficoEspecieHook';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useContext, useEffect, useRef, useState } from 'react';
import { DetalleContext } from '../../../context/detalleEspecieContext/DetalleContext';
import Lottie from 'lottie-react';
import { Container, ContainerGrafico, ContainerMargen, Margen } from './elements';
import { SinDatos } from '../OperacionesEspecie/elements';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { ThemeContext } from 'styled-components';
import { ThemeContext as ContextTheme } from '../../../context/themeContext/ThemeContext';
import { Theme } from '../../../enums/theme';



const GraficoEspecie = () => {

    const { data, minValue, maxValue, setMargen, margen } = useGraficoEspecieHook();
    const { loadingGrafico } = useContext(DetalleContext);
    const textosBotones = [
        { texto: '1d', seteo: 'dia' },
        { texto: '1s', seteo: 'semana' },
        { texto: '1m', seteo: 'mes' },
        { texto: '1a', seteo: 'anio' },
    ];
    const chartRef = useRef<HTMLDivElement | null>(null);
    const [chartWidth, setChartWidth] = useState(0);
    const [chartHeight, setChartHeight] = useState(0);

    const { backgroundColorButtonsGrafico } = useContext(ThemeContext);

    const { theme } = useContext(ContextTheme);

    // Get the width and height of the parent div when the component mounts or when the window is resized
    useEffect(() => {
        const handleResize = () => {
            const chartContainer = chartRef.current;
            if (chartContainer) {
                const { width, height } = chartContainer.getBoundingClientRect();
                setChartWidth(width);
                setChartHeight(height);
            }
        };      
        handleResize(); // Call the resize function initially
        window.addEventListener('resize', handleResize); // Add event listener for window resize
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
        };
    }, []);


    return (
        <Container>
            {
                loadingGrafico ?
                    <div style={{overflow: 'hidden'}}>
                        <Lottie
                            animationData={
                                theme === Theme.Light
                                    ? require('../../../jsons-animation/loading-pagina-completa.json')
                                    : require('../../../jsons-animation/loading-pagina-completa-dark.json')}
                            autoPlay={true}
                            loop={true}
                            width={'100%'}
                            height={'100%'}
                        />
                    </div>
                    :
                    (
                        <>
                            <ContainerMargen>
                                {textosBotones.map(b =>
                                (
                                    <Box
                                        onClick={() => setMargen(b.seteo)}
                                        sx={{
                                            backgroundColor: b.seteo === margen ? backgroundColorButtonsGrafico : null,
                                            color: b.seteo === margen ? 'rgba(246, 180, 14, 1)' : "rgba(163, 163, 163, 1)",
                                            marginTop: "0.5em",
                                            borderRadius: "5px",
                                            cursor: 'pointer'
                                        }}
                                        key={b.texto}
                                    >
                                        <Margen>{b.texto}</Margen>
                                    </Box>
                                )
                                )}
                            </ContainerMargen>

                            <ContainerGrafico ref={chartRef}>
                                {
                                    data?.length ?
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart width={chartWidth} height={chartHeight} data={data} margin={{ top: 8, right: 30, bottom: 5 }} >
                                                <XAxis interval={data && data?.length > 13 ? 'preserveEnd' : 0} tickSize={8} fontSize="12px" dataKey="name" stroke="#A3A3A3" />
                                                <YAxis interval={0} tickCount={10} tickSize={5} tickFormatter={(label) => `${formatCurrency(label)}`} fontSize="11px" stroke="#A3A3A3" domain={[minValue, maxValue]} />
                                                <CartesianGrid strokeDasharray="4 4" />
                                                <Line type="monotone" dataKey="precio" cursor="pointer" stroke="rgba(3, 218, 197, 1)" activeDot={{ r: 8 }} strokeWidth={5} />
                                                <Tooltip />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        :
                                        <SinDatos>No hay datos</SinDatos>
                                }
                            </ContainerGrafico>
                        </>
                    )
            }
        </Container >
    )
}

export default GraficoEspecie

