import styled from "styled-components";


export const Container = styled.div`
    margin: 0.8em 0 0 0em;
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    border-radius: 5px;
    width: 75%;
    align-items: center;
    @media (min-width: 992px){
        width: 100%;
        margin: 0;
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
        box-shadow: inset 0px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
    }
`

export const ContainerSearchLupa = styled.div`
    display: flex; 
    width: 100%; 
    border-radius: 5px; 
    align-items: center; 
    @media (min-width: 992px) {
        box-shadow: inset -10px -10px 10px ${({theme}) => theme.secondaryBackgroundColor}, inset 10px 10px 10px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
    }
`

export const SearchInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 400;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    color: ${({theme}) => theme.primaryColor};
    padding: 0.6em;
    @media (min-width: 992px) {
        padding: 0.2em 0 0.2em;
        font-size: 15px;
        margin-left: 1em;
        box-shadow: inset 10px -5px 10px ${({theme}) => theme.secondaryBackgroundColor}, inset 0px 5px 10px ${({theme}) => theme.secondaryBackgroundColor};
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
	}
`

export const Lupa = styled.div`
    margin-right: 0.8em;
    color: ${({theme}) => theme.primaryColor};
`

