import axios from "axios";

export async function isImageValidImageUrl(url: string | undefined): Promise<boolean> {
    // Check if the URL is a valid string and has non-zero length
    if (typeof url !== 'string' || url.length === 0) {
        return false;
    }

    // Check if the URL ends with an image file extension
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const lowercaseUrl = url.toLowerCase();
    const hasValidExtension = imageExtensions.some(ext => lowercaseUrl.endsWith(ext));
    if (!hasValidExtension) {
        return false;
    }

    try {
        // Make a HEAD request to the URL to check if it's a valid image
        const response = await axios.head(url);
        const contentType = response.headers['content-type'];

        // Check if the response content type indicates an image
        const isImage = contentType.startsWith('image/');
        return isImage;
    } catch (error) {
        // An error occurred while making the HTTP request (e.g., invalid URL or network issue)
        console.error('Error checking image validity:', error);
        return false;
    }
}
