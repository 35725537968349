import { useContext } from 'react'
import { Box, Modal } from '@mui/material'
import { Title, Text, ButtonsContainer, ButtonCancelar, ButtonConfirmarAccion, TextsContainer } from './elements'
import { ThemeContext } from 'styled-components';
import CalendarioFechas from '../../elements/CalendarioFechas';
import dayjs from 'dayjs';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleConfirmarModal: () => void,
    tituloModal?: string,
    textoModal?: string,
    textoConfirmarModal: string,
    tenencia?: boolean;
    fechaTenencia?: dayjs.Dayjs | null;
    setFechaTenencia?: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
}

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'center',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    minHeight: 130,
    boxShadow: 24,
    borderRadius: 2,
    padding: 0,
    margin: 0,
};

const ModalConfirmacion = ({
    open,
    handleClose,
    handleConfirmarModal,
    tituloModal,
    textoModal,
    textoConfirmarModal,
    tenencia,
    fechaTenencia,
    setFechaTenencia,
}: Props) => {

    const themeContext = useContext(ThemeContext)

    const { primaryBackgroundColor } = themeContext;


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, bgcolor: primaryBackgroundColor, }}>
                    <TextsContainer>
                        <Title>{tituloModal}</Title>
                        <Text>{textoModal}</Text>
                    </TextsContainer>

                    {tenencia && fechaTenencia && setFechaTenencia ?
                        <div style={{ marginTop: '0.7em' }}>
                            <CalendarioFechas
                                fechaPrimaria={fechaTenencia}
                                seteoFecha={setFechaTenencia}
                                tituloCalendario='Fecha'
                            />
                        </div>
                        : null
                    }

                    <ButtonsContainer>
                        <ButtonCancelar
                            onClick={handleClose}
                        >
                            Cancelar
                        </ButtonCancelar>
                        <ButtonConfirmarAccion
                            onClick={handleConfirmarModal}
                        >
                            {textoConfirmarModal}
                        </ButtonConfirmarAccion>
                    </ButtonsContainer>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalConfirmacion
