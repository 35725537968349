import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { SwitchMercado } from "../../SwitchMercado/SwitchMercado";
import { TipoSwitcher } from "../../../enums/enums";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useSocketConnection } from "../../../hooks/useSocketHook";
import { NotificacionContext } from "../../../context/notificacionContext/NotificacionContext";
import { Container, ContainerSuperior, ContainerSwitchMercado, Hr, Img } from "./elements";
import { ThemeContext } from "styled-components";
import SwitchTheme from "../../SwitchTheme/SwitchTheme";

interface Props {
    titulo: String,
}

export const HeaderBar = ({ titulo }: Props) => {

    const navigate = useNavigate();

    const { logoOn } = useContext(NotificacionContext);

    const { primaryColor, rojoPorcentaje } = useContext(ThemeContext);

    useSocketConnection();

    const styles = {
        notification: {
            color: primaryColor,
            padding: "0.25em"
        },
        user: {
            color: primaryColor,
            padding: "0.25em",
            marginRight: "0.5em"
        },
    };

    return (
        <Container>

            {
                titulo === "" &&
                <>
                    <ContainerSuperior>
                        <Img
                            alt="Logo Rava"
                            src={require("../../../images/logo-rava.png")}
                        />
                        <Box display='flex-end'>
                            <SwitchTheme />
                            <IconButton
                                onClick={() => {
                                    navigate("/notificaciones")
                                }}
                                sx={styles.notification}
                            >
                                {logoOn ?
                                    <NotificationsActiveOutlinedIcon
                                        style={{ color: rojoPorcentaje }}
                                    />
                                    :
                                    <NotificationsNoneIcon />
                                }

                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    navigate("/perfil")
                                }}
                                sx={styles.user}
                            >
                                <PersonOutlineIcon ></PersonOutlineIcon>
                            </IconButton>
                        </Box>
                    </ContainerSuperior>
                    <Hr></Hr>
                    <ContainerSwitchMercado>
                        <SwitchMercado type={TipoSwitcher.Home} />
                    </ContainerSwitchMercado>
                </>
            }

        </Container>
    )
};



