import styled from "styled-components";


export const Container = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 7px;
    display: flex;
    margin-top: 0.2em;
    padding: 0.2em 0em 0.2em 0em;
    justify-content: center;
    align-items: center;
    @media (min-width: 992px) {
		height: 100px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
	}
`
