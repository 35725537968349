import React, { useContext } from 'react'
import { Movimiento } from '../../../interfaces/MovimientosInterface';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ContainerElements, ContainerMovimientos, TextoDatos, TextoDatosNumeros } from './elements';
import { ThemeContext } from 'styled-components';
import AlertCopyToClipboard from '../../../alerts/AlertCopyToClipboard';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { separacionDecimal } from '../../../helpers/separacionDecimal';


interface Props {
    movimientosFiltrados: Movimiento[];
    formatearCadena: (cadena: string) => string;
    traerDocumentos: (id: string) => Promise<void>;
    loadingDescarga: boolean;
    especieSeleccionada?: string;
}

const TablaMovimientos = ({ movimientosFiltrados, formatearCadena, traerDocumentos, loadingDescarga, especieSeleccionada }: Props) => {

    const { width } = useWindowDimensions();
    const { alterHomeBackgrooundColor, secondaryBackgroundColor, primaryColor } = useContext(ThemeContext);

    return (
        <ContainerMovimientos>
            {movimientosFiltrados.map((m, i) => (
                width < 992 ?
                    <ContainerElements key={m.numeroOperacion} style={{ backgroundColor: i % 2 === 0 ? alterHomeBackgrooundColor : secondaryBackgroundColor }}>
                        <TextoDatos>{m.fecha ? m.fecha : '-'}</TextoDatos>
                        <TextoDatos>{m.fechaLiquidacion ? m.fechaLiquidacion : '-'}</TextoDatos>
                        <TextoDatos>{m.operacion === 'S.A.' ? 'SALDO ANTERIOR' : m.operacion}</TextoDatos>
                        <TextoDatos>{m.especie ? formatearCadena(m.especie) : '-'}</TextoDatos>
                        <div style={{ width: '80%' }}>
                            <TextoDatosNumeros>{m.importe ? formatCurrency(m.importe) : '-'}</TextoDatosNumeros>
                        </div>
                    </ContainerElements>
                    :
                    <ContainerElements key={m.numeroOperacion} style={{ backgroundColor: i % 2 === 0 ? alterHomeBackgrooundColor : secondaryBackgroundColor }}>
                        <TextoDatos>{m.fecha ? m.fecha : '-'}</TextoDatos>
                        <TextoDatos>{m.fechaLiquidacion ? m.fechaLiquidacion : '-'}</TextoDatos>
                        <TextoDatos>{m.operacion ? m.operacion : '-'}</TextoDatos>
                        <TextoDatos >{m.referencia ? m.referencia : '-'}</TextoDatos>
                        <TextoDatosNumeros
                            style={{
                                paddingRight: '2em',
                            }}
                        >{m.cantidad ? separacionDecimal(Number(m.cantidad)) : '-'}</TextoDatosNumeros>
                        <TextoDatos>{
                            m.especie
                                ? formatearCadena(m.especie)
                                : especieSeleccionada
                                    ? especieSeleccionada
                                    : '-'
                        }</TextoDatos>
                        <TextoDatosNumeros
                            style={{
                                paddingRight: '2em',
                            }}
                        >{m.precio ? formatCurrency(m.precio) : '-'}</TextoDatosNumeros>
                        <TextoDatosNumeros
                            style={{
                                paddingRight: '3em',
                            }}
                        >{m.importe ? formatCurrency(m.importe) : '-'}</TextoDatosNumeros>
                        <TextoDatosNumeros
                            style={{
                                paddingRight: '3em',
                            }}
                        >{formatCurrency(m.saldo)}</TextoDatosNumeros>
                        <div style={{ display: 'flex', alignItems: 'start' }}>
                            {
                                m.clave?.length
                                    ? <SaveAltRoundedIcon
                                        style={{
                                            color: primaryColor,
                                            cursor: 'pointer',
                                            fontSize: '17px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            traerDocumentos(m.clave)
                                        }}
                                    />
                                    : null
                            }
                        </div>
                    </ContainerElements>
            ))}
            <AlertCopyToClipboard
                open={loadingDescarga}
                texto='Descargando documento...'
            />
        </ContainerMovimientos>
    )
}

export default TablaMovimientos