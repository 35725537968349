import { useContext } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useCardEspecieOrdenesHook } from '../../hooks/useCardEspecieOrdenesHook';
import { formatCurrency } from '../../helpers/formatCurrency';
import { AccionText, Button, ColumnContainer, ColumnData, ColumnTitles, Container, Divider, EstadoContainer, EstadoText, FirstColumn, HoraText, Img, NombreText, ProgressbarContainer, Row, SecondColumn, Text, TextData, TextRow, ThirdColumn } from './elements';
import { TipoMercado } from '../../enums/enums';
import { MercadoContext } from '../../context/mercadoContext/MercadoContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';


interface Props {
  estado: string;
  accion: string;
  nombre: string;
  hora: string;
  cantidad: string;
  precio: string;
  importe: string;
  nroOrden: string;
  porcentaje: number;
  ejercido: number;
}

export const CardEspecieOrdenes = ({
  estado,
  accion,
  nombre,
  hora,
  cantidad,
  precio,
  importe,
  nroOrden,
  porcentaje,
  ejercido,
}: Props) => {
  const {
    formatTipoAccion,
    formatHour,
    esExterior,
    cancelarOrden
  } =
    useCardEspecieOrdenesHook({ nombre, nroOrden });


  const { width } = useWindowDimensions();

  const { tipoMercado } = useContext(MercadoContext);

  function getSymbol(): string {
    return tipoMercado === TipoMercado.Exterior ? 'USD ' : '$';
  }

  const themeContext = useContext(ThemeContext)

  const { primaryColor } = themeContext;


  return (
    <Container>
      <Divider>
        {/* Primera columna TIPO/PORCENTAJE/HORA */}
        <FirstColumn>
          <AccionText>{formatTipoAccion(accion)}</AccionText>
          <ProgressbarContainer>
            <CircularProgressbar
              value={porcentaje}
              text={`${porcentaje}%`}
              styles={buildStyles({
                textColor: primaryColor,
                pathColor: estado === 'TERMINADA' ? '#03DAC5' : estado === 'EN PROCESO' || estado === 'PARCIAL' ? '#F6B40E' : 'rgba(60, 60, 67, 0.6)',
              })}
            />
          </ProgressbarContainer>
          <HoraText>{formatHour(hora)}</HoraText>
        </FirstColumn>

        {/* SEGUNDA COLUMNA ESPECIE/DATA ORDEN */}
        <SecondColumn>
          <Row>
            <NombreText>{nombre.endsWith('_US') ? nombre.slice(0, nombre.length - 3) : nombre}</NombreText>
            <Img
              src={
                esExterior()
                  ? require('../../images/bandera-usa.png')
                  : require('../../images/bandera-argentina-cuenta.png')
              }
            />
          </Row>
          <ColumnContainer>
            {
              width > 992 ?

                <>
                  <ColumnTitles>
                    <Text>Cantidad</Text>
                    <Text>
                      Precio
                    </Text>
                    <Text>
                      Importe
                    </Text>
                  </ColumnTitles>

                  <ColumnData>
                    <TextData>{`${ejercido}/${cantidad}`}</TextData>
                    <TextData>
                      {getSymbol() + formatCurrency(parseFloat(precio).toFixed(2))}
                    </TextData>
                    <TextData>
                      {getSymbol() + formatCurrency(parseFloat(importe).toFixed(2))}
                    </TextData>
                  </ColumnData>
                </>

                :

                <>
                  <ColumnTitles>
                    <TextRow>
                      <Text>Cantidad</Text>
                      <TextData>{`${ejercido}/${cantidad}`}</TextData>
                    </TextRow>

                    <TextRow>
                      <Text>
                        Precio
                      </Text>
                      <TextData>
                        {getSymbol() + formatCurrency(parseFloat(precio).toFixed(2))}
                      </TextData>
                    </TextRow>

                    <TextRow>
                      <Text>
                        Importe
                      </Text>
                      <TextData>
                        {getSymbol() + formatCurrency(parseFloat(importe).toFixed(2))}
                      </TextData>
                    </TextRow>

                  </ColumnTitles></>
            }
          </ColumnContainer>
        </SecondColumn>
      </Divider>
      {/* TERCERA COLUMNA ESTADO */}
      <ThirdColumn>
        <div style={{ marginTop: '2em' }}>
          <EstadoContainer
            style={{
              backgroundColor:
                estado === 'EN PROCESO' || estado === 'PARCIAL'
                  ? '#F6B40E'
                  : estado === 'TERMINADA'
                    ? '#03DAC5'
                    : estado === 'CANCELADA' || estado === 'RECHAZADA'
                      // ? '#47AAFA' 
                      // :  estado === 'RECHAZADA' 
                      ? '#B00020'
                      : 'rgba(176, 0, 32, 0.38)',
            }}
          >
            <EstadoText
              style={{
                color: estado === 'RECHAZADA' ? 'white' : '#000000'
              }}
            >
              {estado === 'PARCIAL' ? 'EN PROCESO' : estado}
            </EstadoText>
          </EstadoContainer>
          {estado === 'EN PROCESO' || estado === 'PARCIAL' ? (
            <Button
              onClick={() => cancelarOrden()}
            >
              <p>CANCELAR</p>
            </Button>
          ) : null}
        </div>
      </ThirdColumn>
    </Container>
  );
};
