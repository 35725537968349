import styled, { css } from "styled-components";
import { Icon } from "@iconify/react";

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
        width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Button = styled.button`
    display: flex;
    height: 3.1em;
    width: 92%;
    justify-content: space-between;
    align-items: center;
    border: none;
    font-size: 1.1em;
    color: ${({theme}) => theme.primaryColor};
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    border-radius: 0.5em;
    padding: 0 0.5em;
    margin: 0.5em 1em;
    cursor: pointer;
    @media (min-width: 992px) {
        box-shadow: none;
        height: 5.5em;
        width: 97%;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ButtonText = styled.p`
    font-size: 0.9em;
    margin: 0em 0.5em 0 0.8em;
    color: ${({theme}) => theme.primaryColor};
    font-weight: 700;
    @media (min-width: 992px) {
        font-size: 1.3em;
        margin: 0 0.5em 0 1.5em;
    }
`;

export const IconWithMediaQuery = styled(Icon)`
    width: 1.6em;
    height: 1.6em;
    padding: 0.2em;
    border: ${({theme}) => `0.1em solid ${theme.grayToDarkGrayBackground}`};
    border-radius: 0.6em;
    color: ${({theme}) => theme.primaryColor};
    @media (min-width: 992px) {
        margin-left: 1.2em;
    }
`;

export const ArrowIcon = styled(Icon)`
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.2em;
    color: ${({theme}) => theme.primaryColor};
    @media (min-width: 992px) {
        margin-right: 1em;
        font-size: 1.2em;
    }
`

export const ContainerAltaCbu = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`

export const SecondaryContainer = styled.div`
    margin-top: 1em;
`

export const AltaCbuText = styled.p`
    margin: 0em 1.2em 0 0.4em;
    font-size: 1em;
    color: ${({theme}) => theme.primaryColor};
    font-weight: 600;
`