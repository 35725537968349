import React, { createContext, useContext, useEffect, useState } from 'react'
import { UserContext } from '../userContext/UserContext';
import { Cuenta } from '../../interfaces/getCuentasInterface';
import { AuthContext } from '../authContext/AuthContext';
import { getUserCuentas } from '../../api/userApi';

type RetirarDineroContextProps = {
    importeRetirar: string,
    moneda: string,
    setMoneda: React.Dispatch<React.SetStateAction<string>>,
    cuentaBancariaSeleccionada: Cuenta | null,
    handleChangeRadioButton: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleChangeImporte: (e: React.ChangeEvent<HTMLInputElement>) => void,
    disabled: boolean,
    loadingCuentas: boolean,
}

export const RetirarDineroContext = createContext({} as RetirarDineroContextProps);

export const RetirarDineroProvider = ({ children }: any) => {

    const [moneda, setMoneda] = useState('');
    const [cuentaBancariaSeleccionada, setCuentaBancariaSeleccionada] = useState<Cuenta | null>(null);
    const [importeRetirar, setImporteRetirar] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [loadingCuentas, setLoadingCuentas] = useState(false);
    const { cuentas, setUserCuentas } = useContext(UserContext);
    const { setToken } = useContext(AuthContext);


    // carga las cuentas 
    useEffect(() => {
        const refresh = async () => {
            try {
                setLoadingCuentas(true);
                const tokenStorage = localStorage.getItem("token");
                tokenStorage && setToken(tokenStorage);
                if (tokenStorage) {
                    const getCuentas = await getUserCuentas(tokenStorage);
                    setUserCuentas(getCuentas.data.body.cuentas);
                }
                setLoadingCuentas(false)
            } catch (error) {
                setLoadingCuentas(false)
                console.log(error);
            }
        }
        refresh();
    }, [])

    // cambia el estado de disabled para habilitar o deshabilitar el boton de continuar
    useEffect(() => {
        if (moneda !== '' &&
            cuentaBancariaSeleccionada &&
            importeRetirar !== '')
            setDisabled(false)
    }, [moneda, importeRetirar, cuentaBancariaSeleccionada])


    const handleChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cuentaSeleccionada =
            // filtra la cuenta seleccionada, segun la moneda y el CBU
            cuentas.filter(c =>
                (c.Moneda === moneda && c['CBU-CVU'] === (event.target as HTMLInputElement).value)
                || (moneda === 'DÓLAR LOCAL' && c.Moneda === 'DOLARES' && c['CBU-CVU'] === (event.target as HTMLInputElement).value)
            )
        setCuentaBancariaSeleccionada(cuentaSeleccionada[0]);
    };

    const handleChangeImporte = (e: React.ChangeEvent<HTMLInputElement>) => {
        setImporteRetirar(e.target.value)
    }

    return (
        <RetirarDineroContext.Provider value={{
            importeRetirar,
            moneda,
            setMoneda,
            cuentaBancariaSeleccionada,
            handleChangeRadioButton,
            handleChangeImporte,
            disabled,
            loadingCuentas,
        }}>
            {children}
        </RetirarDineroContext.Provider>

    )
}
