import { useContext } from 'react'
import { Container, ContainerTipoMoneda, InputField, SecondContainer, Titulo, Texto, ButtonConfirmar, ButtonCancelar } from './elements';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ThemeContext } from 'styled-components';
import { CustomRadioButtons } from '../../components/retirarDineroComponents/CustomRadioButtons/CustomRadioButtons';
import { RetirarDineroContext } from '../../context/retirarDineroContext/RetirarDineroContext';
import MenuPopUp from '../../components/MenuPopUp/MenuPopUp';
import useMenuRetirarDineroHook from '../../hooks/useMenuRetirarDineroHook';
import { useNavigate } from 'react-router-dom';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';


export const RetirarDinero = () => {

    const { primaryColor } = useContext(ThemeContext);

    const { handleChangeImporte, disabled, moneda } = useContext(RetirarDineroContext);

    const { open, listaTipoMoneda, handleOpenMenu, handleCloseMenuRetirarDinero, anchorEl, cuentasFiltradas } = useMenuRetirarDineroHook();

    const navigate = useNavigate();


    return (
        <Container>
            <ResponsiveHeader title='Retirar dinero' showSwitch={false} />
            <div style={{ paddingTop: '0.1em' }}>
                <SecondContainer>
                    <div>
                        <Titulo>Solicitar retirar dinero de la cuenta</Titulo>
                    </div>
                    {/* container para seleccionar el tipo de moneda */}
                    <ContainerTipoMoneda onClick={handleOpenMenu}>
                        <Texto>
                            {
                                moneda.length ?
                                    `Solicitar transferir en ${moneda}`
                                    :
                                    'Seleccione el tipo de moneda'
                            }
                        </Texto>
                        <KeyboardArrowRightIcon
                            sx={{
                                color: primaryColor,
                                marginRight: '0.4em',
                                marginTop: '0.2em',
                                cursor: 'pointer',
                                fontSize: '32px',
                            }} />
                    </ContainerTipoMoneda>
                    <MenuPopUp
                        open={open}
                        anchorEl={anchorEl}
                        handleCloseMenu={handleCloseMenuRetirarDinero}
                        listaMenu={listaTipoMoneda}
                        actualMenu={moneda}
                    />
                    <div>
                        <InputField
                            placeholder='Agregar importe'
                            type='number'
                            onChange={handleChangeImporte}
                        />
                    </div>
                </SecondContainer>
                <CustomRadioButtons cuentas={cuentasFiltradas}/>
                {
                    disabled ?
                        <ButtonCancelar>CONTINUAR</ButtonCancelar>
                        :
                        <ButtonConfirmar onClick={() => navigate('/confirmar-retirar-dinero')}>CONTINUAR</ButtonConfirmar>
                }
            </div>
        </Container>
    )
}
