import React, { useContext } from 'react'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import { ButtonContainer, Container, ContainerTitulo, SecondaryContainer, StyledReactInputVerificationCode, Titulo } from './elements'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useIngresarPinHook } from '../../hooks/useIngresarPinHook'
import { Button } from '@mui/material'
import { ThemeContext } from 'styled-components'

const CambiarPin = () => {

    const {
        error,
        pinValue,
        setPinValue,
        continuar,
    } =
        useIngresarPinHook('cambioPin');

    const { primaryColor, errorColor } = useContext(ThemeContext)

    return (
        <Container>
            <ResponsiveHeader title='Cambio de PIN ' showSwitch={false} />
            <SecondaryContainer>
                <ContainerTitulo>

                    <Titulo>Ingrese su PIN actual</Titulo>

                </ContainerTitulo>
                <StyledReactInputVerificationCode>
                    <ReactInputVerificationCode
                        length={6}
                        onCompleted={(newValue: string) => {
                            setPinValue(newValue);
                        }}
                        value={pinValue}
                    />
                </StyledReactInputVerificationCode>
            </SecondaryContainer>


            <ButtonContainer>
                <Button
                    variant="contained"
                    sx={{
                        width: "60%",
                        backgroundColor: !pinValue || pinValue.includes("·") ? 'gray' : primaryColor,
                        marginTop: "0em",
                        color: 'white',
                        '@media (min-width: 992px)': {
                            width: "15%",
                        }
                    }}
                    disabled={!pinValue || pinValue.includes("·") ? true : false}
                    onClick={continuar}
                >

                    SIGUIENTE
                </Button>
                {
                    error.length
                        ? <p style={{ color: errorColor }}>{error}</p>
                        : null
                }
            </ButtonContainer>
        </Container>
    )
}

export default CambiarPin