import React, { useContext } from "react";
import { ListMenu } from '../../../views/AgregarAlerta/elements'
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MenuPopUp from '../../MenuPopUp/MenuPopUp';
import { Icon } from '@iconify/react';
import { Button, ButtonContainer } from "../elements";
import { ThemeContext } from "styled-components";
import { TipoMercado } from "../../../enums/enums";
import BotonesPanelesNuevo from "../../mercadoNuevoComponents/BotonesPanelesNuevo/BotonesPanelesNuevo";

interface Props {
    width: number,
    listaMenuEspecies: string[],
    actualMenuEspecies: string,
    selectedIndex: number,
    tipoEspecie: HTMLElement | null | string,
    handleClickTipoEspecie: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseTipoEspecie: (op?: string) => void,
    handleButtonTipoEspecie: (opcion: string) => void,
    tipoMercado: string,
}

export const PrimerMenu = ({ width, listaMenuEspecies, actualMenuEspecies, selectedIndex, tipoEspecie, handleClickTipoEspecie, handleCloseTipoEspecie, handleButtonTipoEspecie, tipoMercado }: Props) => {

    const themeContext = useContext(ThemeContext)
    const { primaryBackgroundColor, primaryColor } = themeContext;

    return (
        <div>
            {
                width < 992 ?
                    <div>
                        <ListMenu >
                            <ListItem
                                onClick={handleClickTipoEspecie}
                                style={styles.ListItem}
                            >
                                <Icon icon="ph:circle-bold" style={{ width: '1.2em', height: '1.2em', marginRight: '0.5em' }} />
                                <ListItemText primary={actualMenuEspecies ? actualMenuEspecies : listaMenuEspecies[selectedIndex]} />
                                <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '1.2em', height: '1.2em', marginRight: '1.5em' }} />
                            </ListItem>
                        </ListMenu>
                        <MenuPopUp
                            open={Boolean(tipoEspecie)}
                            anchorEl={typeof (tipoEspecie) !== 'string' ? tipoEspecie : null}
                            handleCloseMenu={handleCloseTipoEspecie}
                            listaMenu={listaMenuEspecies}
                            actualMenu={actualMenuEspecies}
                        />
                    </div>
                    :
                    // <ButtonContainer style={{gridTemplateColumns: tipoMercado === TipoMercado.Local ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'}}>
                    //     {listaMenuEspecies.map((opcion, index) => (
                    //         <Button
                    //             key={index}
                    //             onClick={() => handleButtonTipoEspecie(opcion)}
                    //             style={{ 
                    //                 backgroundColor: opcion === actualMenuEspecies ? primaryColor : primaryBackgroundColor, 
                    //                 color: opcion === actualMenuEspecies ? primaryBackgroundColor : primaryColor, 
                    //             }}
                    //         >
                    //             {opcion}
                    //         </Button>
                    //     ))}
                    // </ButtonContainer>
                    <BotonesPanelesNuevo
                        setBotonActual={handleButtonTipoEspecie}
                        botonActual={actualMenuEspecies}
                        listaBotones={listaMenuEspecies}
                    />
            }
        </div>
    );
};

const styles = {
    ListItem: {
        padding: '0.2em 0.5em',
        marginLeft: '0.5em'
    },
}