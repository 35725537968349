import React, { useContext, useEffect, useState } from 'react'
import { RetirarDineroContext } from '../context/retirarDineroContext/RetirarDineroContext';
import { UserContext } from '../context/userContext/UserContext';
import { Cuenta } from '../interfaces/getCuentasInterface';

const useMenuRetirarDineroHook = () => {

    const listaTipoMoneda = ['PESOS', 'DÓLAR LOCAL'];
    const { setMoneda, moneda } = useContext(RetirarDineroContext);
    const { cuentas } = useContext(UserContext);
    const [cuentasFiltradas, setCuentasFiltradas] = useState<Cuenta[]>([])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    useEffect(() => {
        seteoCuentas();
    }, [moneda])

    const seteoCuentas = () => {
        const filteredCuentas = cuentas.filter(c => moneda === c.Moneda || (moneda === 'DÓLAR LOCAL' && c.Moneda === 'DOLARES'))
        setCuentasFiltradas(filteredCuentas);
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuRetirarDinero = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            setMoneda(op);
        }
    };

    return {
        open,
        anchorEl,
        listaTipoMoneda,
        cuentasFiltradas,
        handleOpenMenu,
        handleCloseMenuRetirarDinero,
    }
}

export default useMenuRetirarDineroHook