import React, { useContext } from 'react'
import { Container, ContainerSinOfertas, SecondaryContainer, SinOfertas, TextoDatos, Titulo, TitulosSecundarios } from './elements'
import { OfertasInterface } from '../../../interfaces/mercadoInferfaces';
import { CircularProgress } from '@mui/material';
import { ThemeContext } from 'styled-components';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { TipoMercado } from '../../../enums/enums';

interface Props {
    ofertas: OfertasInterface[] | undefined;
    nombreEspecie?: string;
    loadingOfertas: boolean;
    setTextoTitulos: React.Dispatch<React.SetStateAction<string>>;
    setPrecioOperar: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    setCantidadOperar: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    confirmarDatos: boolean;
    tipoMercado: TipoMercado;
    noHayOfertas: boolean;
}

const OfertasNuevo = ({
    ofertas,
    nombreEspecie,
    loadingOfertas,
    setTextoTitulos,
    setPrecioOperar,
    setCantidadOperar,
    confirmarDatos,
    tipoMercado,
    noHayOfertas,
}: Props) => {

    const { loadingColor, buyColor, rojoPorcentaje, primaryBackgroundColor, alterBackgroundCardsHome } = useContext(ThemeContext);

    return (
        <Container>
            {
                tipoMercado === TipoMercado.Exterior
                    ? <ContainerSinOfertas>
                        <SinOfertas>No existen ofertas disponibles en mercado exterior.</SinOfertas>
                    </ContainerSinOfertas>
                    : (noHayOfertas) && nombreEspecie
                        ? <>
                            <div>
                                <Titulo>{nombreEspecie}</Titulo>
                                <hr style={{ margin: '0em 0 0.2em' }} />
                            </div>
                            <ContainerSinOfertas>
                                <SinOfertas>No hay ofertas disponibles para la especie seleccionada.</SinOfertas>
                            </ContainerSinOfertas>
                        </>
                        : !ofertas || !ofertas.length
                            ? <ContainerSinOfertas>
                                <SinOfertas>Seleccione una especie para mostrar sus ofertas.</SinOfertas>
                            </ContainerSinOfertas>
                            : <>
                                <div>
                                    <Titulo>{nombreEspecie}</Titulo>
                                    <hr style={{ margin: '0em 0 0.2em' }} />
                                </div>
                                <div style={{ height: '100%' }}>
                                    <SecondaryContainer>
                                        <TitulosSecundarios>
                                            C. Compra
                                        </TitulosSecundarios>
                                        <TitulosSecundarios>
                                            P. Compra
                                        </TitulosSecundarios>
                                        <TitulosSecundarios>
                                            P. Venta
                                        </TitulosSecundarios>
                                        <TitulosSecundarios>
                                            C. Venta
                                        </TitulosSecundarios>
                                    </SecondaryContainer>

                                    {loadingOfertas
                                        ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <CircularProgress style={{ color: loadingColor, width: '20px' }} />
                                        </div>
                                        : <>
                                            {ofertas.map((o, i: number) =>
                                                <SecondaryContainer
                                                    key={i}
                                                    style={{
                                                        backgroundColor: i % 2 !== 0
                                                            ? primaryBackgroundColor
                                                            : alterBackgroundCardsHome
                                                    }}
                                                >
                                                    <TextoDatos
                                                        onClick={(e) => {
                                                            if (!confirmarDatos && nombreEspecie) {
                                                                setTextoTitulos(nombreEspecie);
                                                                setCantidadOperar(String(o.cantidadCompra))
                                                                setPrecioOperar(String(formatCurrency(o.precioCompra)))
                                                            }
                                                        }}
                                                    >
                                                        {o.cantidadCompra ? separacionDecimal(o.cantidadCompra) : '-'}
                                                    </TextoDatos>
                                                    <TextoDatos
                                                        style={{ color: buyColor }}
                                                        onClick={(e) => {
                                                            if (!confirmarDatos && nombreEspecie) {
                                                                setTextoTitulos(nombreEspecie);
                                                                setPrecioOperar(String(formatCurrency(o.precioCompra)))
                                                                setCantidadOperar(String(o.cantidadCompra))
                                                            }
                                                        }}
                                                    >
                                                        {o.precioCompra ? formatCurrency(o.precioCompra) : '-'}
                                                    </TextoDatos>
                                                    <TextoDatos
                                                        style={{ color: rojoPorcentaje }}
                                                        onClick={(e) => {
                                                            if (!confirmarDatos && nombreEspecie) {
                                                                setTextoTitulos(nombreEspecie);
                                                                setPrecioOperar(String(formatCurrency(o.precioVenta)))
                                                                setCantidadOperar(String(o.cantidadVenta))
                                                            }
                                                        }}
                                                    >
                                                        {o.precioVenta ? formatCurrency(o.precioVenta) : '-'}
                                                    </TextoDatos>
                                                    <TextoDatos
                                                        onClick={(e) => {
                                                            if (!confirmarDatos && nombreEspecie) {
                                                                setTextoTitulos(nombreEspecie);
                                                                setCantidadOperar(String(o.cantidadVenta))
                                                                setPrecioOperar(String(formatCurrency(o.precioVenta)))
                                                            }
                                                        }}
                                                    >
                                                        {o.cantidadVenta ? separacionDecimal(o.cantidadVenta) : '-'}
                                                    </TextoDatos>
                                                </SecondaryContainer>
                                            )}
                                        </>
                                    }
                                </div>
                            </>
            }
        </Container>
    )
}

export default OfertasNuevo