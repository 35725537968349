import { CircularProgress } from "@mui/material"
import { TipoMercado } from '../../../enums/enums';
import { UserContext } from '../../../context/userContext/UserContext';
import { Box } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useContext } from 'react';
import { Container, ContainerMercados, ContainerPorcentaje, Img, Porcentaje } from './elements';
import { CenterContainer } from '../../../elements/centerContainer';
import { ThemeContext } from "styled-components";


interface Props {
    tipoMercado: TipoMercado;
    handleChangeSwitch: (e: boolean) => void;
    loadingTenencia: boolean,
    errorTenencia: boolean,
}

export const SwitchMercadoNavReducido = ({ tipoMercado, handleChangeSwitch, loadingTenencia, errorTenencia }: Props) => {

    const { porcentajeExterior, porcentajeLocal } = useContext(UserContext);

    const { primaryColor } = useContext(ThemeContext);

    const styles = {
        arrow: {
            color: primaryColor,
            height: '10px',
            width: '10px'
        }
    }

    return (
        <Container onClick={() => handleChangeSwitch(true)}>
            <ContainerMercados>
                {tipoMercado === 'LOCAL' ? (
                    <Box sx={{ display: 'flex' }}>
                        <Img
                            alt="Bandera"
                            src={require('../../../images/bandera-argentina-cuenta.png')}
                        />
                        <ContainerPorcentaje>
                            <Porcentaje>{
                                loadingTenencia
                                    ? <CircularProgress style={{ color: "#004B6F", width: '1em', height: '1em' }} />
                                    : errorTenencia
                                        ? '-'
                                        : porcentajeLocal || porcentajeLocal === 0
                                            ? porcentajeLocal
                                            : 0
                            }%</Porcentaje>
                        </ContainerPorcentaje>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex' }}>
                        <Img
                            alt="Bandera"
                            src={require('../../../images/bandera-usa.png')}
                        />
                        <ContainerPorcentaje>
                            <Porcentaje>{
                                loadingTenencia
                                    ? <CircularProgress style={{ color: "#004B6F", width: '1em', height: '1em' }} />
                                    : errorTenencia
                                        ? '-'
                                        : porcentajeExterior || porcentajeExterior === 0
                                            ? porcentajeExterior
                                            : 0
                            }%</Porcentaje>
                        </ContainerPorcentaje>
                    </Box>
                )
                }
                <CenterContainer>
                    <ArrowForwardIosOutlinedIcon sx={styles.arrow} />
                </CenterContainer>
            </ContainerMercados>
        </Container>
    )
}


