import styled from "styled-components";
import { colors } from "../../../theme/Themes";


export const Container = styled.div`
flex: 1;
height: 100vh;
background-color:  ${({ theme }) => theme.secondaryBackgroundColor};
@media (min-width: 992px) {
    margin-top: 8vh;
    margin-left: 12vw;
    height: 92vh;
    width: 87vw;
    padding: 0vh 0.5vw 0 0.5vw;
}
`

export const SecondContainer = styled.div`
margin: 2em 1em 1em 1em;
background-color: ${({ theme }) => theme.primaryBackgroundColor};
border-radius: 10px;
display: grid;
height: 14em;
@media (min-width: 992px){
    margin: 1em 4em 1em 1em;
    height: 18em;
}
`

export const ExitoErrorContainer = styled.div`
margin: 2em 1em 1em 1em;
background-color: ${({ theme }) => theme.primaryBackgroundColor};
justify-content: center;
text-align:center;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
@media (min-width: 992px){
    margin: 1em 4em 1em 1em;
    height: 18em;
}
`

export const ExitoErrorTitle = styled.p`
margin: 0;
font-size: 25px; 
font-weight: 700;
`

export const ExitoErrorText = styled.p`
font-size: 22px; 
font-weight: 500;
`

export const Title = styled.p`
color: ${({ theme }) => theme.primaryColor};
font-size: 22px;
font-weight: 700;
margin-top: 2em;
margin-bottom: 0em;
@media(min-width: 992px){
    margin: 1em 0 1em 0; 
}
`

export const ContainerTitle = styled.div`
display: flex;
justify-content: center;
@media (min-width: 992px){
    margin-left: 1.5em;
    display: block;
}
`

export const ContainerTexts = styled.div`
margin: 1.5em 0em 1.5em 1.5em;
@media (min-width: 992px){
    margin: 0em 0em 2em 1.5em;
}
`


export const Texto = styled.p`
color: ${({ theme }) => theme.primaryColor};
margin: 0;
font-size: 18px;
`

export const TextoMoneda = styled.p`
color: ${({ theme }) => theme.primaryColor};
margin: 0 0.4em;
font-size: 18px;
font-weight: 700;
`

export const ContainerButtons = styled.div`
margin: 2.5em 1em;
display: flex;
justify-content: center; 
gap: 15px;
@media (min-width: 992px) {
    margin: 1em;
}
`

export const BotonConfirmar = styled.button`
    background-color: ${({ theme }) => theme.fontColorWhiteToBlue};
    letter-spacing: 1.25px;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    font-weight: 600;
    border: 2px solid ${({ theme }) => theme.backgroundColorButtonsHome};
    border-radius: 5px;
    width: 8em;
    height: 2.5em;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 18px;
        width: 10em;
        margin: 1em;
    }
    @media (min-width: 1490px) {
        font-size: 18px;
        letter-spacing: 1.25px;
        height: 2.5em;
        white-space:normal !important;
        word-wrap:break-word;
    }
`

export const ButtonCancelar = styled.button`
    background-color: ${({ theme }) => theme.backgroundWhiteToGray};
    letter-spacing: 1.25px;
    color: ${({ theme }) => theme.accentColor};
    font-size: 18px;
    font-weight: 600;
    border: 2px solid ${colors.grayRava};
    border-radius: 5px;
    width: 8em;
    height: 2.5em;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 18px;
        width: 10em;
        margin: 1em;
    }
    @media (min-width: 1490px) {
        font-size: 18px;
        letter-spacing: 1.25px;
        height: 2.5em;
        white-space:normal !important;
        word-wrap:break-word;
    }
`