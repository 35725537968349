import React, { useContext, useEffect, useState } from "react";
import { Order } from "../interfaces/ordersInterface";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { TipoMercado } from "../enums/enums";
import { getTenencia } from "../api/userApi";
import { UserContext } from "../context/userContext/UserContext";

const useOrdenesDelDiaHook = (dataOrdenes: Order[] | undefined) => {

    const [ordenesOrdenadasFiltradas, setOrdenesOrdenadasFiltradas] = useState<Order[]>([])
    const [loadingRefresher, setLoadingRefresher] = useState(false);
    const { tipoMercado } = useContext(MercadoContext);

    const {setTenencia} = useContext(UserContext);


    useEffect(() => {
        const CompararEstados = (a: Order, b: Order) => {
            const ordenEspecifico = ["Recibida", "Pendiente Operador", "Cumplida", "Anulada"];
            return ordenEspecifico.indexOf(a.estado) - ordenEspecifico.indexOf(b.estado);
        }
        if (dataOrdenes) {
            dataOrdenes.sort(CompararEstados);
        }
        filtrarPorMercado()
    }, [dataOrdenes, tipoMercado]);

    const refrescarTenencia = async () => {
        setLoadingRefresher(true)
        const tokenStorage = localStorage.getItem("token");
        if (tokenStorage){
            try {
                const tenencia = await getTenencia(tokenStorage);
                setTenencia(tenencia.data.body);
            } catch (error) {
                console.log(error);
            } finally { 
                setLoadingRefresher(false);
            }
        }
    }

    function filtrarPorMercado() {
        let ordenesPorMercado;
        if (dataOrdenes) {
            if (tipoMercado === TipoMercado.Exterior) {
                ordenesPorMercado = dataOrdenes.filter(e => esExterior(e.especie));
            } else {
                ordenesPorMercado = dataOrdenes.filter(e => !esExterior(e.especie));
            }
            setOrdenesOrdenadasFiltradas(ordenesPorMercado);
        }
    }
    function esExterior(nombre: string): boolean {
        return nombre.endsWith('_US');
    }

    function formatEstado(estado: string) {
        let formatValue;
        switch (estado) {
            case 'Pendiente Operador':
                formatValue = 'E/P';
                break;
            case 'Recibida':
                formatValue = 'E/P';
                break;
            case 'Anulada':
                formatValue = 'C';
                break;
            case 'Cumplida':
                formatValue = 'T';
                break;
            default:
                formatValue = estado.toUpperCase();
                break;
        }
        return formatValue;
    }


    return {
        ordenesOrdenadasFiltradas,
        formatEstado,
        loadingRefresher,
        refrescarTenencia,
    };
};

export default useOrdenesDelDiaHook;
