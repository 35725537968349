import styled from "styled-components";


export const ContainerButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0.5em 1em 0em 1em;
    gap: 0.5em;
    padding-bottom: 0.5em;
`;

export const BotonComprarVender = styled.button`
    font-weight: 600;
    font-size: 16px;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 5px;
    height: 1.8em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`