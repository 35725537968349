import React from 'react'
import { Container } from './elements'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import IngresoDatosCuenta from '../../components/AgregarCuentaComponents/IngresoDatosCuenta/IngresoDatosCuenta'
import useAgregarCuentaHook from '../../hooks/useAgregarCuentaHook'
import useAgregarCuentaMenusHook from '../../hooks/useAgregarCuentaMenusHook'
import ConfirmarDatosCuenta from '../../components/AgregarCuentaComponents/ConfirmarDatosCuenta/ConfirmarDatosCuenta'

const AgregarCuenta = () => {

    const {
        form,
        handleChangeInputs,
        habilitarContinuar,
        error,
        continuar,
        confirmarScreen,
        cancelar,
        setConfirmoCuenta,
        confirmoCuenta,
        loading,
        errorCrearCuenta,
    } = useAgregarCuentaHook()
    const {
        openMoneda,
        openCuenta,
        anchorElMoneda,
        anchorElCuenta,
        listaTipoMoneda,
        handleCloseTipoMoneda,
        handleOpenMenuMoneda,
        handleOpenMenuCuenta,
        listaTipoCuenta,
        handleCloseTipoCuenta
    } = useAgregarCuentaMenusHook(handleChangeInputs);

    return (
        <Container>
            <ResponsiveHeader
                title='Agregá una cuenta'
                showSwitch={false}
            />


            {!confirmarScreen
                ? <IngresoDatosCuenta
                    form={form}
                    openMoneda={openMoneda}
                    openCuenta={openCuenta}
                    anchorElMoneda={anchorElMoneda}
                    anchorElCuenta={anchorElCuenta}
                    listaTipoMoneda={listaTipoMoneda}
                    handleCloseTipoMoneda={handleCloseTipoMoneda}
                    handleOpenMenuMoneda={handleOpenMenuMoneda}
                    handleOpenMenuCuenta={handleOpenMenuCuenta}
                    listaTipoCuenta={listaTipoCuenta}
                    handleCloseTipoCuenta={handleCloseTipoCuenta}
                    handleChangeInputs={handleChangeInputs}
                    habilitarContinuar={habilitarContinuar}
                    error={error}
                    continuar={continuar}
                />
                : <ConfirmarDatosCuenta
                    form={form}
                    cancelar={cancelar}
                    confirmarCuenta={setConfirmoCuenta}
                    confirmoCuenta={confirmoCuenta}
                    loading={loading}
                    error={errorCrearCuenta}
                />
            }
        </Container>
    )
}

export default AgregarCuenta