import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";
import { Icon } from "@iconify/react";

export const Container = styled.div`
    background-color: ${colors.blueRava};
    height: 100vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
		width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
        background-color: ${({theme}) => theme.secondaryBackgroundColor};
        display: flex;
        flex-direction: column;
	}
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1.2em 0;
    @media (min-width: 992px) { 
        background-color: ${colors.blueRava};
        padding: 1em 0 1em 0;
        border-radius: 10px;
        margin: 0 1em 0 1em;
        width: 97%;
    }
`;

export const Button = styled.button`
    width: 50%;
    background: none;
    border: none;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
    @media (min-width: 992px){
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const Linea = styled.div`
    width: 6em;
    height: 0.1em;
    margin-left: 2.2em;
    margin-top: 0.5em;
    background-color: white;
    @media (min-width: 992px){
        display: flex;
        justify-content: center;
        width: 25%;
        margin-left: 0;
        height: 0.2em;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
    }
`;

export const ListContainer = styled.div`
    overflow-y: scroll;
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    border-radius: 1.5em;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const ExtraSpace = styled.div`
    height: 1em;
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
`

export const EmptyText = styled.p`
    color: #8A8A8A;
    text-align: center;
    margin-top: 3em;
    @media (min-width: 992px){
        font-size: 1.2em;
        margin-top: 2em;
    }
`

export const MisAlertasButton = styled.button`
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: none;
    border: none;
    font-size: 1.1em;
    color: ${({theme}) => theme.primaryColor};
    border-bottom: 0.1em solid #EBEBEB;
    padding: 1.8em 0.8em 0.8em 0.8em;
    font-weight: 600;
    cursor: pointer;
    @media (min-width: 992px) {
        border: none;
        padding: 0.8em 0.8em;
        background-color:  ${({theme}) => theme.primaryBackgroundColor};
        border-radius: 10px;
        width: 98%;
        margin: 0.5em 0em 0 1em;
        letter-spacing: 0.44px;
        align-items: center;
    }
`;

export const ArrowIcon = styled(Icon)`
@media (min-width: 992px){
    margin-right: 1em;
    font-size: 1.1em;
}
`


export const MisAlertasText = styled.p`
    margin: 0;
`
