import {
    ActualMenu,
    Arrow,
    Circle,
    Container,
    ContainerOperacion,
    ContainerDiaria,
    ContainerDiariaLimite,
    ContainerTitulo,
    Titulo,
    ContainerLimit,
    Limit,
    ContainerTituloPrecioCantidadImporte,
    TituloPrecioCantidad,
    InputsPrecioCantidad,
    TextoImporte,
    InputsPrecioCantidadDisabled,
    ErrorText,
    OperarTitle,
    TenenciaTotalText,
    ContainerOperacionTexto,
    BotonComprarVender,
    ContainerLimitLocal,
    ContainerCardOfertas
} from './elements'
import { Headers } from '../../components/HeadersComponents/Headers/Headers'
import { TipoMercado, TipoOperacion } from '../../enums/enums'
import SearchOperar from '../../components/operarComponents/SearchOperar/SearchOperar'
import { useMenuOperarHook } from '../../hooks/useMenuOperarHook'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CenterContainer } from '../../elements/centerContainer'
import { useContext, useEffect } from 'react'
import { OperarContext } from '../../context/operarContext/OperarContext'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import { useValidacionDatosOperar } from '../../hooks/useValidacionDatosOperar'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { separacionDecimal } from '../../helpers/separacionDecimal'
import MenuPopUp from '../../components/MenuPopUp/MenuPopUp'
import CardOfertas from '../../components/detalleEspecieComponents/CardOfertas/CardOfertas'

const Operar = () => {

    const { width } = useWindowDimensions();

    const {
        listaMenuLimit,
        handleOpenMenu: handleOpenMenuLimit,
        handleCloseMenuLimit,
        open: openLimit,
        anchorEl: anchorElLimit,
    } = useMenuOperarHook();

    const {
        listaMenuOperaciones,
        handleOpenMenu: handleOpenMenuOperaciones,
        open: openOperaciones,
        anchorEl: anchorElOperaciones,
        handleCloseMenuOperaciones,
    } = useMenuOperarHook();

    const {
        actualOption,
        actualMenuLimit,
        onChangeCantidad,
        importe,
        onChangePrecio,
        cantidad,
        precio,
        tenenciaTotal,
        setDisabled,
        choiced,
    } = useContext(OperarContext);

    const { tipoMercado } = useContext(MercadoContext);

    const { error, continuar } = useValidacionDatosOperar();


    useEffect(() => {
        setDisabled(false)
        return () => {
            setDisabled(true)
        }
    }, [])


    return (
        <Container>
            {
                width < 992 ?
                    <Headers titulo='Operar' showSwitch={true} />
                    :
                    <OperarTitle>Operar</OperarTitle>
            }

            <CenterContainer>
                <ContainerOperacion >
                    <ContainerOperacionTexto onClick={handleOpenMenuOperaciones}>
                        <Limit>
                            {actualOption ? actualOption : 'Tipo de operación'}
                        </Limit>
                        <Arrow>
                            <KeyboardArrowRightIcon />
                        </Arrow>
                    </ContainerOperacionTexto>
                    <MenuPopUp
                        open={openOperaciones}
                        anchorEl={anchorElOperaciones}
                        handleCloseMenu={handleCloseMenuOperaciones}
                        listaMenu={listaMenuOperaciones}
                        actualMenu={actualOption}
                    />
                </ContainerOperacion>
            </CenterContainer>

            <CenterContainer>
                <ContainerTitulo>
                    <Circle />
                    <Titulo>TÍTULOS</Titulo>
                </ContainerTitulo>
            </CenterContainer>

            <CenterContainer>
                <SearchOperar />
            </CenterContainer>

            <CenterContainer>
                <ContainerDiariaLimite>
                    <ContainerDiaria>
                        <ActualMenu>Diaria</ActualMenu>
                    </ContainerDiaria>
                    {tipoMercado === TipoMercado.Exterior ?
                        <ContainerLimit onClick={handleOpenMenuLimit}>
                            <Limit>{actualMenuLimit}</Limit>
                            <Arrow >
                                <KeyboardArrowRightIcon />
                            </Arrow>
                        </ContainerLimit>
                        :
                        <ContainerLimitLocal>
                            <Limit>{actualMenuLimit}</Limit>
                        </ContainerLimitLocal>
                    }
                    <MenuPopUp
                        open={openLimit}
                        anchorEl={anchorElLimit}
                        handleCloseMenu={handleCloseMenuLimit}
                        listaMenu={listaMenuLimit}
                        actualMenu={actualMenuLimit}
                    />
                </ContainerDiariaLimite>
            </CenterContainer>

            {/* Container Precio-Cantidad */}
            <CenterContainer>
                <ContainerTituloPrecioCantidadImporte>
                    <TituloPrecioCantidad>Cantidad</TituloPrecioCantidad>
                    <TituloPrecioCantidad>Precio</TituloPrecioCantidad>
                </ContainerTituloPrecioCantidadImporte>
            </CenterContainer>

            <CenterContainer>
                <ContainerTituloPrecioCantidadImporte>
                    {/* Input Cantidad */}
                    <InputsPrecioCantidad
                        type='number'
                        onChange={(e) => onChangeCantidad(e)}
                        aria-label='cantidad'
                        value={cantidad.toString()}
                    />
                    {/* Input Precio */}
                    {actualMenuLimit === 'Limit' ?
                        <InputsPrecioCantidad
                            type='number'
                            onChange={(e) => onChangePrecio(e)}
                            aria-label='precio'
                            value={precio.toString()}
                        />
                        :
                        <InputsPrecioCantidadDisabled />
                    }
                </ContainerTituloPrecioCantidadImporte>
            </CenterContainer>

            {actualOption === TipoOperacion.Venta ?
                <CenterContainer>
                    <TenenciaTotalText>
                        Tenencia actual: {separacionDecimal(tenenciaTotal)}
                    </TenenciaTotalText>
                </CenterContainer>
                :
                null
            }

            {error !== '' ? (
                <ErrorText>
                    {error}
                </ErrorText>
            ) : null}

            {/* Container Importe */}
            <CenterContainer>
                <ContainerTituloPrecioCantidadImporte>
                    <TextoImporte>IMPORTE</TextoImporte>
                    <TextoImporte>{importe}</TextoImporte>
                </ContainerTituloPrecioCantidadImporte>
            </CenterContainer>

            <CenterContainer>
                {
                    actualOption &&
                    <BotonComprarVender onClick={() => continuar(actualOption)}>{actualOption}</BotonComprarVender>
                }
            </CenterContainer>

            {
                choiced
                    ? <ContainerCardOfertas>
                        <CardOfertas />
                    </ContainerCardOfertas>
                    : null
            }
        </Container>
    )
}

export default Operar


