import React, { useContext, useEffect, useState } from 'react'
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { LimiteOperacion, TipoOperacion } from '../enums/enums';
import { OperarContext } from '../context/operarContext/OperarContext';


export const useMenuOperarHook = () => {

    const { setActualMenuLimit, setActualOption } = useContext(OperarContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const listaMenuOperaciones = [TipoOperacion.Compra, TipoOperacion.Venta];
    const listaMenuLimit = [LimiteOperacion.Limit, LimiteOperacion.MARKET];

    const open = Boolean(anchorEl);
    const { tipoMercado } = useContext(MercadoContext);

    useEffect(() => {
        setActualMenuLimit(LimiteOperacion.Limit);
    }, [tipoMercado])

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuLimit = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            op === LimiteOperacion.Limit ?
                setActualMenuLimit(LimiteOperacion.Limit)
                :
                setActualMenuLimit(LimiteOperacion.MARKET)
        }
    };

    const handleCloseMenuOperaciones = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            op === TipoOperacion.Compra ?
                setActualOption(TipoOperacion.Compra)
                :
                setActualOption(TipoOperacion.Venta)
        }
    };

    return {
        setActualMenuLimit,
        listaMenuLimit,
        handleOpenMenu,
        handleCloseMenuLimit,
        open,
        anchorEl,
        listaMenuOperaciones,
        handleCloseMenuOperaciones,
    }
}

