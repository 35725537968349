import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 1em;
  @media (max-width: 992px) {
    margin-top: 2em;
  }
`;

export const ContainerButtonSeleccionar = styled.div`
  border-radius: 5px;
  box-shadow: inset -4px -4px 4px
      ${({ theme }) => theme.grayToDarkGrayBackground},
    inset 4px 2px 14px ${({ theme }) => theme.grayToDarkGrayBackground};
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  border: 1px solid rgba(74, 69, 85, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextoButtonSeleccionar = styled.p`
  color: ${({ theme }) => theme.alternativeAccentColor};
  margin: 0;
  padding: 0.8em 1em;
`;

export const TextoButtonSeleccionado = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  margin: 0;
  padding: 0.7em 1em;
`;

export const ContainerInputs = styled.div`
  box-shadow: inset -4px -4px 4px
      ${({ theme }) => theme.grayToDarkGrayBackground},
    inset 4px 2px 14px ${({ theme }) => theme.grayToDarkGrayBackground};
  border: 1px solid rgba(74, 69, 85, 0.2);
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: ${({ theme }) => theme.primaryColor};
  background-color: transparent;
  padding: 1em 1.2em;
  ::placeholder {
    color: ${({ theme }) => theme.alternativeAccentColor};
    opacity: 1; /* Firefox */
  }
`;

export const ContainerBotonContinuar = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: 992px) {
    width: 35%;
  }
`;

export const BotonContinuar = styled.button`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme }) => theme.primaryColor};
  border-radius: 5px;
  border: 3px solid ${({ theme }) => theme.primaryColor};
  font-size: 16px;
  font-weight: 600;
  padding: 0.6em;
  margin-top: 1.5em;
  width: 100%;
  cursor: pointer;
`;

export const BotonContinuarDisabled = styled.button`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme }) => theme.accentColor};
  border-radius: 5px;
  border: 3px solid ${({ theme }) => theme.accentColor};
  font-size: 16px;
  font-weight: 600;
  padding: 0.6em;
  margin-top: 1.5em;
  width: 100%;
`;

export const MensajeError = styled.p`
  color: ${({ theme }) => theme.rojoPorcentaje};
  margin: 0;
`;
