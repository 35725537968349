export function formatPorcentaje(x: number | null | string, fixed?: number) {
    if (x === null) {
        return '';
    } else {

        let numberSeparado = Number(x).toFixed(fixed ? fixed : 1).toString().split('.');
        //let numberSeparado = x.toString().split('.');

        if (numberSeparado.length > 1) {
            numberSeparado[1] = numberSeparado[1].substring(0, 2);
        }
        let numeroDosDecimales = numberSeparado.join(',');


        return numeroDosDecimales.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}