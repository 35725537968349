import React, { useContext } from 'react'
import { Container, ContainerTexto, Texto } from './elements';
import { ThemeContext } from 'styled-components';

interface Props {
    titulos: string[];
}

const CardTitulos = ({ titulos }: Props) => {

    const { backgroundMercadoNuevo } = useContext(ThemeContext);

    const esVentaCompra = (texto: string) => {
        return texto.includes('Compra') || texto.includes('Venta');
    }

    return (
        <Container>
            {titulos.map(t =>
                <ContainerTexto
                    key={t}
                    style={{
                        backgroundColor: esVentaCompra(t) ? backgroundMercadoNuevo : undefined,
                        borderRadius: t === 'Cant. Compra' ? '10px 0 0 0' : t === 'Cant. Venta' ? '0 10px 0 0' : undefined,
                    }}
                >
                    <Texto style={{
                        fontWeight: t === 'Especie' ? 'bold' : 600,
                        paddingLeft: t === 'Especie' ? '4.3em' : undefined,
                        paddingRight: t === 'Volumen' ? '2em' : undefined,
                        textAlign: t === 'Especie' ? 'start' : undefined,
                    }}>{t}</Texto>
                </ContainerTexto>
            )}
        </Container>
    )
}

export default CardTitulos