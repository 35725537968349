import React, { ChangeEvent, useContext } from 'react'
import { BotonContinuar, BotonContinuarDisabled, Container, ContainerBotonContinuar, ContainerButtonSeleccionar, ContainerInputs, Input, MensajeError, TextoButtonSeleccionado, TextoButtonSeleccionar } from './elements'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ThemeContext } from 'styled-components';
import MenuPopUp from '../../MenuPopUp/MenuPopUp';
import { FormAgregarCuentaProps } from '../../../interfaces/agregarCuentaInterface';

interface Props {
    form: FormAgregarCuentaProps;
    openMoneda: boolean;
    openCuenta: boolean;
    anchorElMoneda: HTMLElement | null;
    anchorElCuenta: HTMLElement | null;
    listaTipoMoneda: string[];
    handleCloseTipoMoneda: (op?: string) => void;
    handleOpenMenuMoneda: (event: React.MouseEvent<HTMLElement>) => void;
    handleOpenMenuCuenta: (event: React.MouseEvent<HTMLElement>) => void;
    listaTipoCuenta: string[];
    handleCloseTipoCuenta: (op?: string) => void;
    handleChangeInputs: (e: ChangeEvent<HTMLInputElement>, tipo?: string) => void;
    habilitarContinuar: boolean;
    error: string;
    continuar: () => void;
}


const IngresoDatosCuenta = ({
    form,
    handleOpenMenuMoneda,
    openMoneda,
    anchorElMoneda,
    handleCloseTipoMoneda,
    listaTipoMoneda,
    handleOpenMenuCuenta,
    openCuenta,
    anchorElCuenta,
    handleCloseTipoCuenta,
    listaTipoCuenta,
    handleChangeInputs,
    habilitarContinuar,
    error,
    continuar,
}: Props) => {

    const { primaryColor } = useContext(ThemeContext);


    return (
        <Container>
            <ContainerButtonSeleccionar onClick={handleOpenMenuMoneda}>
                {
                    !form.tipoMoneda.length
                        ? <TextoButtonSeleccionar>
                            Seleccione el tipo de moneda de la cuenta
                        </TextoButtonSeleccionar>
                        : <TextoButtonSeleccionado>
                            {form.tipoMoneda}
                        </TextoButtonSeleccionado>
                }
                <KeyboardArrowDownIcon
                    style={{
                        color: primaryColor,
                        fontSize: '1.8em',
                        paddingRight: '0.5em',
                    }}
                />
            </ContainerButtonSeleccionar>
            <MenuPopUp
                open={openMoneda}
                anchorEl={anchorElMoneda}
                handleCloseMenu={handleCloseTipoMoneda}
                listaMenu={listaTipoMoneda}
                actualMenu={form.tipoMoneda}
            />
            <ContainerInputs>
                <Input
                    type='text'
                    name='cbu'
                    placeholder='Ingresá el CBU'
                    maxLength={22}
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.cbu}
                />
            </ContainerInputs>
            <ContainerButtonSeleccionar onClick={handleOpenMenuCuenta}>
                {
                    !form.tipoCuenta.length
                        ? <TextoButtonSeleccionar>
                            Seleccione el tipo de cuenta
                        </TextoButtonSeleccionar>
                        : <TextoButtonSeleccionado>
                            {form.tipoCuenta}
                        </TextoButtonSeleccionado>
                }
                <KeyboardArrowDownIcon
                    style={{
                        color: primaryColor,
                        fontSize: '1.8em',
                        paddingRight: '0.5em',
                    }}
                />
            </ContainerButtonSeleccionar>
            <MenuPopUp
                open={openCuenta}
                anchorEl={anchorElCuenta}
                handleCloseMenu={handleCloseTipoCuenta}
                listaMenu={listaTipoCuenta}
                actualMenu={form.tipoCuenta}
            />
            <ContainerInputs>
                <Input
                    type='text'
                    name='nroCuenta'
                    placeholder='Ingresá el número de cuenta'
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.nroCuenta}
                />
            </ContainerInputs>
            <ContainerInputs>
                <Input
                    type='text'
                    name='cuit'
                    placeholder='Ingresá el cuit'
                    maxLength={11}
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.cuit}
                />
            </ContainerInputs>
            <ContainerInputs>
                <Input
                    type='text'
                    name='alias'
                    placeholder='Ingresá el alias'
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.alias}
                />
            </ContainerInputs>

            {
                habilitarContinuar
                    ? <ContainerBotonContinuar>
                        <BotonContinuar
                            onClick={continuar}
                        >CONTINUAR</BotonContinuar>
                    </ContainerBotonContinuar>
                    : <ContainerBotonContinuar>
                        <BotonContinuarDisabled>CONTINUAR</BotonContinuarDisabled>
                    </ContainerBotonContinuar>
            }

            {
                error && <MensajeError>{error}</MensajeError>
            }
        </Container >
    )
}

export default IngresoDatosCuenta