import { MercadoContext } from "../../context/mercadoContext/MercadoContext";
import { useContext, useState, useEffect } from 'react';
import { SwitchMercadoReducido } from "./SwitchMercadoHome/SwitchMercadoReducido";
import { SwitchMercadoAmpliado } from "./SwitchMercadoHome/SwitchMercadoAmpliado";
import { TipoMercado, TipoSwitcher } from "../../enums/enums";
import { SwitchMercadoNavReducido } from "./SwitchMercadoNav/SwitchMercadoNavReducido";
import { SwitchMercadoNavAmpliado } from "./SwitchMercadoNav/SwitchMercadoNavAmpliado";
import { UserContext } from "../../context/userContext/UserContext";
import { getTenencia } from '../../api/userApi';
import { isTenencia } from '../../interfaces/tenenciaInterface';

type Props = {
    type: TipoSwitcher,
}

export const SwitchMercado = ({ type }: Props) => {

    const { tipoMercado, setTipoMercado } = useContext(MercadoContext);
    const [selectAbierto, setSelectAbierto] = useState(false);

    const handleChangeSwitch = (e: boolean) => {
        setSelectAbierto(e)
    }

    const { setTenencia, setCartera, tenencia } = useContext(UserContext)
    const [ loadingTenencia, setLoadingTenencia ] = useState<boolean>(false)
    const [ errorTenencia, setErrorTenencia ] = useState<boolean>(false)
    
    useEffect(() => {
        const refresh = async () => {
            const mercadoStorage = localStorage.getItem('tipoMercado');
            setTipoMercado(mercadoStorage === TipoMercado.Local ? TipoMercado.Local : TipoMercado.Exterior)
            try {
                const tokenStorage = localStorage.getItem("token");
                if (tokenStorage) {
                    setLoadingTenencia(true)
                    const tenencia = await getTenencia(tokenStorage);
                    setTenencia(isTenencia(tenencia.data.body) ? tenencia.data.body : null);
                    setCartera(tenencia.data.body.cartera);
                }
                setLoadingTenencia(false)
            } catch (error) {
                console.log(error);
                setLoadingTenencia(false)
                setErrorTenencia(true)
            }
        }
        if(!tenencia){
            refresh();
        }
    }, [tenencia])


    if (!selectAbierto) {
        return (
            <div>
                {
                    type === TipoSwitcher.Home ?
                        <SwitchMercadoReducido
                            tipoMercado={tipoMercado}
                            handleChangeSwitch={handleChangeSwitch}
                            loadingTenencia={loadingTenencia}
                            errorTenencia={errorTenencia}
                        />
                        :
                        <SwitchMercadoNavReducido
                            tipoMercado={tipoMercado}
                            handleChangeSwitch={handleChangeSwitch}
                            loadingTenencia={loadingTenencia}
                            errorTenencia={errorTenencia}
                        />
                }
            </div>
        )
    } else {
        return (
            <div>
                {
                    type === TipoSwitcher.Home ?
                        <SwitchMercadoAmpliado
                            handleChangeSwitch={handleChangeSwitch}
                            tipoMercado={tipoMercado}
                            setTipoMercado={setTipoMercado}
                            loadingTenencia={loadingTenencia}
                            errorTenencia={errorTenencia}
                        />
                        :
                        <SwitchMercadoNavAmpliado
                            tipoMercado={tipoMercado}
                            handleChangeSwitch={handleChangeSwitch}
                            setTipoMercado={setTipoMercado}
                            loadingTenencia={loadingTenencia}
                            errorTenencia={errorTenencia}
                        />
                }
            </div>
        )
    }
}
