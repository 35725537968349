// export interface RespNombreEspecies {
//   body: DatosClaveEspecie[];
//   count: number;
//   exectime: number;
// }

// export interface DatosClaveEspecie {
//   especie: string;
//   tipo: Tipo;
//   especie_completa?: string;
//   ddjj_compra?: DdjjCompra;
//   ddjj_venta?: DdjjVenta;
// }

// export enum DdjjCompra {
//   CpraCedear = 'CPRA_CEDEAR',
//   CpraLiqUsd = 'CPRA_LIQ_USD',
// }

// export enum DdjjVenta {
//   VtaLiqUsd = 'VTA_LIQ_USD',
// }

// export enum Tipo {
//   Accion = 'accion',
//   Bono = 'bono',
//   Cedear = 'cedear',
//   Criptomoneda = 'criptomoneda',
//   Empresa = 'Empresa',
//   Empty = '',
//   Exterior = 'exterior',
//   Indice = 'indice',
//   Letras = 'Letras',
//   Opcion = 'opcion',
// }

export interface RespNombreEspecies {
  error: boolean;
  status: number;
  body: RespNombreEspeciesBody;
}

export interface RespNombreEspeciesBody {
  data: DataNombreEspecies;
}

export interface DataNombreEspecies {
  body: DatosClaveEspecie[];
  count: number;
  exectime: number;
}

export interface DatosClaveEspecie {
  especie: string;
  tipo: Tipo;
  especie_completa?: string;
  ddjj_compra?: DdjjCompra;
  ddjj_venta?: DdjjVenta;
  mercado?: string;
  panel?: Panel;
}

export enum Panel {
  Adr = "ADR",
  Cedears = "Cedears",
  Etf = "ETF",
  Global = "Global",
  Las30DelDow = "Las 30 del Dow",
  Bonos = 'Bonos',
  General = 'General',
  Lider = 'Lider',
}

export enum Tipo {
  Accion = 'accion',
  Bono = 'bono',
  Cedear = 'cedear',
  Criptomoneda = 'criptomoneda',
  Empresa = 'Empresa',
  Empty = '',
  Exterior = 'exterior',
  Indice = 'indice',
  Letras = 'Letras',
  Opcion = 'opcion',
}

export enum DdjjCompra {
  CpraCedear = 'CPRA_CEDEAR',
  CpraLiqUsd = 'CPRA_LIQ_USD',
}

export enum DdjjVenta {
  VtaLiqUsd = 'VTA_LIQ_USD',
}