import { TipoOpcion } from "../../../enums/enums"
import { Container, ContainerButton, TextoNotSelected, TextoSelected } from "./elements"


interface Props {
    actualOption: TipoOpcion,
    setActualOption: React.Dispatch<React.SetStateAction<TipoOpcion>>
}

export const BotonesActualHistorico = ({ actualOption, setActualOption }: Props) => {

    return (
        <Container>
            {/* Boton Actual */}
            <ContainerButton
                onClick={() => setActualOption(TipoOpcion.Actual)}
            >
                {
                    actualOption === TipoOpcion.Actual ?
                        <TextoSelected>Actual</TextoSelected>
                        :
                        <TextoNotSelected>Actual</TextoNotSelected>
                }
            </ContainerButton>

            {/* Boton Historico */}
            <ContainerButton
                onClick={() => setActualOption(TipoOpcion.Historico)}
            >
                {
                    actualOption === TipoOpcion.Historico ?
                        <TextoSelected>Histórico</TextoSelected>
                        :
                        <TextoNotSelected>Histórico</TextoNotSelected>
                }
            </ContainerButton>
        </Container>
    )
}


