import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    CompraVenta,
    DataEspecie,
    DataInterdiarios,
    OfertasInterface,
} from '../../interfaces/mercadoInferfaces';
import { CotizacionEspecie } from '../../interfaces/cotizacionesEspecies';
import { AuthContext } from '../authContext/AuthContext';
import { getHistoricoEspecie, getIntradiarios, getOfertas } from '../../api/mercadoApi';

interface DetalleContextProps {
    especie: DataEspecie | undefined;
    setEspecie: React.Dispatch<React.SetStateAction<DataEspecie | undefined>>;
    intradiarios: DataInterdiarios[] | undefined;
    setIntradiarios: React.Dispatch<
        React.SetStateAction<DataInterdiarios[] | undefined>
    >;
    ofertas: OfertasInterface[] | undefined;
    setOfertas: React.Dispatch<React.SetStateAction<OfertasInterface[] | undefined>>;
    limpiarDatos: () => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loadingOfertas: boolean;
    loadingGrafico: boolean;
    setLoadingGrafico: React.Dispatch<React.SetStateAction<boolean>>;
    dataGrafico: CotizacionEspecie[] | undefined;
    setDataGrafico: React.Dispatch<React.SetStateAction<CotizacionEspecie[] | undefined>>;
    getHistorico: (esp: string) => Promise<void>;
    getIntradiariosEspecie: (esp: string) => Promise<void>;
    getOfertasEspecie: (esp: string) => Promise<void>;
    noHayOfertas: boolean;
}

export const DetalleContext = createContext({} as DetalleContextProps);

export const DetalleProvider = ({ children }: any) => {
    const [especie, setEspecie] = useState<DataEspecie | undefined>();
    const [intradiarios, setIntradiarios] = useState<
        DataInterdiarios[] | undefined
    >();
    const [dataGrafico, setDataGrafico] = useState<CotizacionEspecie[] | undefined>();
    const [ofertas, setOfertas] = useState<OfertasInterface[] | undefined>();
    const [loadingOfertas, setLoadingOfertas] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingGrafico, setLoadingGrafico] = useState(true);

    const [noHayOfertas, setNoHayOfertas] = useState<boolean>(false)

    const { tokenPublico } = useContext(AuthContext);


    const limpiarDatos = () => {
        setEspecie(undefined);
        setIntradiarios(undefined);
        setOfertas(undefined);
    };

    async function getHistorico(esp: string) {
        if (tokenPublico) {
            let resp = await getHistoricoEspecie(tokenPublico, esp);
            setDataGrafico(resp.data.body);
        }
    }

    async function getIntradiariosEspecie(esp: string) {
        if (tokenPublico) {
            let resp = await getIntradiarios(tokenPublico, esp);
            setIntradiarios(resp.data.body);
        }
    }

    async function getOfertasEspecie(esp: string) {
        setLoadingOfertas(true);
        setNoHayOfertas(false);
        const tokenPublicoStorage = localStorage.getItem('tokenPublico');
        if (tokenPublicoStorage) {
            let resp = await getOfertas(tokenPublicoStorage, esp);
            if (resp?.data?.body?.ventas === null && resp?.data?.body?.compras === null) {
                setNoHayOfertas(true);
            }
            const ventas = resp?.data.body?.ventas?.map((e: CompraVenta) => ({
                cantidadVenta: e.cantidad,
                precioVenta: e.precio,
            }));
            const compras = resp?.data.body?.compras?.map((e: CompraVenta) => ({
                cantidadCompra: e.cantidad,
                precioCompra: e.precio,
            }));
            let compraVenta: OfertasInterface[] = [];
            const maxLength = Math.max(ventas?.length || 0, compras?.length || 0)
            for (let i = 0; i < maxLength; i++) {
                compraVenta = [
                    ...compraVenta,
                    {
                        cantidadVenta: ventas?.[i]?.cantidadVenta || 0,
                        precioVenta: ventas?.[i]?.precioVenta || 0,
                        cantidadCompra: compras?.[i]?.cantidadCompra || 0,
                        precioCompra: compras?.[i]?.precioCompra || 0,
                    }
                ]
            }
            setOfertas(compraVenta);
        }
        setLoadingOfertas(false)
    }


    useEffect(() => {
        if (
            especie === undefined ||
            ofertas === undefined ||
            intradiarios === undefined
        ) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [especie, ofertas, intradiarios]);

    return (
        <DetalleContext.Provider
            value={{
                especie,
                setEspecie,
                intradiarios,
                setIntradiarios,
                ofertas,
                setOfertas,
                limpiarDatos,
                loading,
                setLoading,
                loadingOfertas,
                loadingGrafico,
                setLoadingGrafico,
                dataGrafico,
                setDataGrafico,
                getHistorico,
                getIntradiariosEspecie,
                getOfertasEspecie,
                noHayOfertas,
            }}>
            {children}
        </DetalleContext.Provider>
    );
};
