import { useContext } from "react"
import { MercadoContext } from "../../../context/mercadoContext/MercadoContext"
import { UserContext } from "../../../context/userContext/UserContext"
import { TipoMercado } from "../../../enums/enums"
import { formatCurrency } from "../../../helpers/formatCurrency"
import { Container, Dolares, DolaresExterior, Pesos, Titulo } from "./elements"


export const SaldoCartera = () => {

    const { tenencia } = useContext(UserContext);
    const { tipoMercado } = useContext(MercadoContext);


    return tipoMercado === TipoMercado.Local ?
        (
            <Container>
                <div>
                    <Titulo>Saldo disponible</Titulo>
                </div>
                <div>
                    <Pesos>$ {tenencia ? formatCurrency(tenencia?.saldo.pesos) : 0}</Pesos>
                    <Dolares>USD {tenencia ? formatCurrency(tenencia?.saldo.dolarBillete) : 0}</Dolares>
                </div>
            </Container>
        ) :
        (
            <Container>
                <div>
                    <Titulo>Saldo disponible</Titulo>
                </div>
                <div>
                    <DolaresExterior>USD {tenencia ? formatCurrency(tenencia?.saldo.dolarExterior) : 0}</DolaresExterior>
                </div>
            </Container>
        )
}
