import { Box } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { TipoMercado } from "../../../enums/enums"
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useContext } from "react";
import { UserContext } from "../../../context/userContext/UserContext";
import { CenterContainer } from "../../../elements/centerContainer";
import { ContainerMercados, ContainerTitulos, Img, ImgArg, Mercado, Porcentaje } from "./elements";
import { ThemeContext } from "styled-components";

interface Props {
    tipoMercado: TipoMercado;
    handleChangeSwitch: (e: boolean) => void;
    loadingTenencia: boolean,
    errorTenencia: boolean,
}

export const SwitchMercadoReducido = ({ tipoMercado, handleChangeSwitch, loadingTenencia, errorTenencia }: Props) => {

    const { porcentajeExterior, porcentajeLocal } = useContext(UserContext);

    const { loadingColor, primaryColor } = useContext(ThemeContext);

    const styles = {
        arrow: {
            fontSize: 'small',
            color: primaryColor,
            marginRight: '0.4em'
        },
        arrowContainer: {
            display: 'flex',
            padding: '0.5em',
            alignSelf: 'flex-start',
            // marginTop: '0.5em',
            paddingTop: '0.7em',
        }
    }

    return (
        <CenterContainer>
            <ContainerMercados onClick={() => handleChangeSwitch(true)}>
                {tipoMercado === 'LOCAL' ? (
                    <div style={{ display: 'flex', height: '2.2em', alignItems: 'center' }}>
                        <ImgArg
                            alt="Bandera"
                            src={require('../../../images/bandera-argentina-cuenta.png')}
                        />
                        <ContainerTitulos>
                            <Porcentaje>{
                                loadingTenencia
                                    ? <CircularProgress style={{ color: loadingColor, width: '1em', height: '1em' }} />
                                    : errorTenencia
                                        ? '-'
                                        : porcentajeLocal || porcentajeLocal === 0
                                            ? porcentajeLocal
                                            : '-'
                            }%</Porcentaje>
                            <Mercado>MERCADO LOCAL</Mercado>
                        </ContainerTitulos>
                    </div>
                ) : (
                    <div style={{ display: 'flex', height: '2.2em', alignItems: 'center' }}>
                        <Img
                            alt="Bandera"
                            src={require('../../../images/bandera-usa.png')}
                        />
                        <ContainerTitulos>
                            <Porcentaje>{
                                loadingTenencia
                                    ? <CircularProgress style={{ color: "#004B6F", width: '1em', height: '1em' }} />
                                    : errorTenencia
                                        ? '-'
                                        : porcentajeExterior || porcentajeExterior === 0
                                            ? porcentajeExterior
                                            : '-'
                            }%</Porcentaje>
                            <Mercado>MERCADO EXTERIOR</Mercado>
                        </ContainerTitulos>
                    </div>
                )
                }
                <Box sx={styles.arrowContainer}>
                    <ArrowForwardIosOutlinedIcon sx={styles.arrow} />
                </Box>
            </ContainerMercados>
        </CenterContainer>
    )
}


