import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 0em 1em 0;
`;

export const ContainerExitoError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TituloExitoError = styled.p`
  margin: 0px;
  padding: 0.1em;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
  line-height: 33px;
`;

export const MensajeExito = styled.p`
  margin: 0px;
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  line-height: 33px;
`;

export const MensajeError = styled.p`
  margin: 0px;
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.rojoPorcentaje};
  line-height: 33px;
`;

export const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
