import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { TipoMercado, TipoSwitcher } from "../../../enums/enums";
import { useSocketConnection } from "../../../hooks/useSocketHook";
import { SwitchMercado } from "../../SwitchMercado/SwitchMercado";
import { Container, ContainerAltaCbu, ContainerSecundario, ContainerSwitcher, ContainerTitulo, Img, TextoAltaCbu, TitleText } from "./elements";
import { NotificacionContext } from '../../../context/notificacionContext/NotificacionContext';
import { ThemeContext } from 'styled-components';
import SwitchTheme from '../../SwitchTheme/SwitchTheme';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

type Props = {
    titulo?: string;
    showSwitch: boolean;
    esDetalleEspecie?: boolean;
    esMiBilletera?: boolean;
}

export const HeaderDesk = ({ titulo, showSwitch, esDetalleEspecie, esMiBilletera }: Props) => {

    const navigate = useNavigate();

    const { logoOn } = useContext(NotificacionContext);

    const { primaryColor, rojoPorcentaje } = useContext(ThemeContext);

    const { tipoMercado } = useContext(MercadoContext);

    useSocketConnection();

    const styles = {
        notification: {
            color: primaryColor,
            padding: "0.25em"
        },
        user: {
            color: primaryColor,
            padding: "0.25em",
        },
    };

    const banderas = {
        local: require('../../../images/bandera-argentina-cuenta.png'),
        exterior: require('../../../images/bandera-usa.png'),
    }


    return (
        <Container>
            <ContainerTitulo>
                {
                    titulo ?
                        <TitleText>{titulo}</TitleText>
                        : null
                }
            </ContainerTitulo>

            <ContainerSecundario>
                {showSwitch ?
                    <ContainerSwitcher>
                        <SwitchMercado type={TipoSwitcher.Home} />
                    </ContainerSwitcher>
                    :
                    null
                }

                {
                    esDetalleEspecie &&
                    <Img
                        src={tipoMercado === TipoMercado.Local ? banderas.local : banderas.exterior}
                        alt='bandera'
                    />
                }

                {
                    esMiBilletera &&
                    <ContainerAltaCbu
                        onClick={() => {
                            navigate("/agregar-cuenta")
                        }}
                    >
                        <AccountBalanceOutlinedIcon 
                            style={{
                                margin:0,
                                color: primaryColor,
                                padding: '0.2em',
                            }}
                        />
                        <TextoAltaCbu>SOLICITAR EL ALTA DE CBU</TextoAltaCbu>

                        </ContainerAltaCbu>
                }


                <SwitchTheme />


                <IconButton
                    onClick={() => {
                        navigate("/notificaciones")
                    }}
                    aria-label='notificaciones'
                    sx={styles.notification}
                >
                    {logoOn ?
                        <NotificationsActiveOutlinedIcon
                            style={{ color: rojoPorcentaje }}
                        />
                        :
                        <NotificationsNoneIcon />
                    }

                </IconButton>
                <IconButton
                    onClick={() => {
                        navigate("/perfil")
                    }}
                    aria-label='perfil'
                    sx={styles.user}
                >
                    <PersonOutlineIcon ></PersonOutlineIcon>
                </IconButton>
            </ContainerSecundario>

        </Container>
    )
}


