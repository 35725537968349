import { useContext } from 'react';
import { DatoIngresarDinero } from "../../interfaces/cardIngresarDineroInterface";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useAlertCopyToClipboardHook } from "../../hooks/useAlertCopyToClipboardHook";
import AlertCopyToClipboard from "../../alerts/AlertCopyToClipboard";
import { CardContainer, DatosContainer, InfoContainer, Texto, Titulo, TituloContainer } from "./elements";
import { ThemeContext } from "styled-components";

interface Props {
  datos: DatoIngresarDinero[];
  moneda: 'PESOS' | 'DÓLARES';
}

export const CardIngresarDinero = ({ datos, moneda }: Props) => {

  const { open, handleClickOpen } = useAlertCopyToClipboardHook(datos);

  const themeContext = useContext(ThemeContext)

    const { primaryColor } = themeContext;

  return (
    <CardContainer>
      <TituloContainer>
        <Titulo>
          Datos para transferir en {moneda}
        </Titulo>
        <div onClick={handleClickOpen}>
          <ContentCopyOutlinedIcon sx={{ color: primaryColor, cursor: 'pointer', }} />
        </div>
        <AlertCopyToClipboard
          open={open}
          texto='¡Datos copiados!'
        />
      </TituloContainer>
      <InfoContainer>
        {datos.map((dato, index) => (
          <DatosContainer key={index}>
            <Texto>{dato.titulo}</Texto>
            <Texto>{dato.data}</Texto>
          </DatosContainer>
        ))}
      </InfoContainer>
    </CardContainer>
  )
}


