import React, { useContext } from 'react'
import { BotonComprarVender, Container, ContainerButtons } from './elements'
import CardConfirmar from '../../operarComponents/CardConfirmar/CardConfirmar'
import { LimiteOperacion, TipoOperacion } from '../../../enums/enums'
import { formatCurrency } from '../../../helpers/formatCurrency';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import { ThemeContext } from 'styled-components';

interface Props {
    tipoOperacion: TipoOperacion | null;
    especie: string | undefined;
    tipoOrden: LimiteOperacion;
    precio: string;
    cantidad: string;
    importe: string;
    setConfirmo: (value: React.SetStateAction<boolean>) => void;
    setCancelo: (value: React.SetStateAction<boolean>) => void;
    getSymbol: () => string;
}

const ConfirmarDatosInfo = ({ tipoOperacion, especie, tipoOrden, precio, cantidad, importe, setConfirmo, setCancelo, getSymbol }: Props) => {

    const { rojoPorcentaje, buyColor } = useContext(ThemeContext);

    return (
        <Container>
            <CardConfirmar titulo='Especie' texto={formatEspeciesExterior(especie)} />
            <CardConfirmar titulo='Tipo de orden' texto={tipoOrden} />
            <CardConfirmar titulo='Duración' texto={'24 hs.'} />
            <CardConfirmar
                titulo='Precio'
                texto={
                    precio === '' || precio === '0'
                        ? `${getSymbol()} -`
                        : getSymbol() + formatCurrency(precio.replace(',', '.'))
                }
            />
            <CardConfirmar titulo='Cantidad' texto={separacionDecimal(Number(cantidad))} />
            <CardConfirmar
                titulo='Importe'
                texto={
                    importe === '' || importe === '0'
                        ? `${getSymbol()} -`
                        : getSymbol() + formatCurrency(importe)
                }
            />
            <ContainerButtons>
                <BotonComprarVender
                    style={{
                        color:
                            tipoOperacion === TipoOperacion.Compra
                                ? buyColor
                                : rojoPorcentaje,
                        border:
                            tipoOperacion === TipoOperacion.Compra
                                ? `2px solid ${buyColor}`
                                : `2px solid ${rojoPorcentaje}`
                    }}
                    onClick={() => setConfirmo(true)}
                >{
                        tipoOperacion === TipoOperacion.Compra
                            ? 'COMPRAR'
                            : 'VENDER'
                    }</BotonComprarVender>
                <BotonComprarVender
                    onClick={() => setCancelo(true)}
                >Cancelar</BotonComprarVender>
            </ContainerButtons>
        </Container>
    )
}

export default ConfirmarDatosInfo