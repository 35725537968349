import styled from "styled-components";


export const ContainerMovimientos = styled.div`
display: flex;
flex-direction: column;
height: 33vh;
max-height: inherit;
-webkit-box-flex: 1;
flex-grow: 1;
overflow-y: scroll;
`

export const ContainerElements = styled.div`
display: grid;
grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 2fr;
font-size: 14px;
text-align: center;
align-items: center;
@media(min-width: 992px){
    grid-template-columns: repeat(2, 1fr) repeat(2, 1.5fr) repeat(3, 1fr) repeat(2, 1.5fr) 0.5fr;
    font-size: 14px;
}
`

export const TextoDatos = styled.p`
color: ${({theme}) => theme.primaryColor}; 
margin 0;
padding 0.3em 0em;
font-size: 13px;
text-align: center;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
@media(min-width: 992px) {
    font-size: 14px;
}
`

export const TextoDatosNumeros = styled.p`
color: ${({theme}) => theme.primaryColor}; 
margin 0;
padding 0.3em 0em;
font-size: 13px;
text-align: end;
@media(min-width: 992px) {
    font-size: 14px;
}
`