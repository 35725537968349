import styled from "styled-components";


export const Container = styled.div`
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    width: 90%;
    margin-top: 0.1em;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        width: 100%;
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
    }
`

export const Titulo = styled.p`
    margin: 0px;
    padding: 0.7em 1em 0.7em;
    color: ${({theme}) => theme.primaryColor};
    font-weight: 400;
    font-size: 16px;
    @media (min-width: 992px) {
        font-size: 15px;
        padding: 0.1em 0em 0.1em 1em;
    }
`

export const Texto = styled.p`
    margin: 0px;
    padding: 0.7em 1em 0.7em;
    color: ${({theme}) => theme.primaryColor};
    font-weight: 400;
    font-size: 16px;
    @media (min-width: 992px) {
        font-size: 15px;
        padding: 0.1em 1em 0.1em 0em;
        font-weight: bold;
    }
`