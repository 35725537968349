import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";

export const Container = styled.div`
    height: 100vh;
	background-color:  ${({theme}) => theme.alternativeBackgroundColor};
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-top: 5em;
    @media (min-width: 992px) {
        padding-top: 6em; 
	}
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
    padding-top: 32px;
    color: ${({theme}) => theme.primaryColor};
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 100%;
    padding-top: 15em;
    @media (min-width: 992px) {
        padding-top: 5em;
	}
`;