import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.1em;
`

export const ContainerButton = styled.div`
    padding: 0.5em 0.2em 0.5em;
    @media (min-width: 992px) {
        cursor: pointer;
	}
`

export const TextoSelected = styled.p`
    font-size: 15px;
    font-weight: 600;
    border-bottom: 2px solid #F6B40E;
    color: ${({theme}) => theme.primaryColor};;
    margin: 0px;
`

export const TextoNotSelected = styled.p`
    font-size: 15px;
    font-weight: 600;
    color: ${({theme}) => theme.alternativeAccentColor};;
    margin: 0px;
`