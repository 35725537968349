import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components';
import { Container, TextoLabel } from './elements';

interface Props {
    setCheckedOperadas: React.Dispatch<React.SetStateAction<boolean>>;
    checkedOperadas: boolean;
}

const CheckboxOperadas = ({ setCheckedOperadas, checkedOperadas }: Props) => {

    const { primaryColor, colorNotSelectedAside } = useContext(ThemeContext)

    return (
        <Container>
            <FormGroup>
                <FormControlLabel
                    style={{
                        margin: 0,
                    }}
                    control={<Checkbox
                        sx={{
                            color: colorNotSelectedAside,
                            padding: '0.3em',
                            '&.Mui-checked': {
                                color: primaryColor,
                            },
                        }}
                        size='small'
                        defaultChecked
                        value={checkedOperadas}
                        onChange={() => setCheckedOperadas(!checkedOperadas)}
                    />}

                    label={<TextoLabel>Operadas</TextoLabel>} />
            </FormGroup>
        </Container>
    )
}

export default CheckboxOperadas