import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";
import List from '@mui/material/List/List';

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    color: ${({theme}) => theme.primaryColor};
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 91.5vh;
		width: 87vw;
        padding: 0.1vh 0.5vw 0 0.5vw;
        background-color:  ${({theme}) => theme.secondaryBackgroundColor};
	}
`;

export const Label = styled.p`
    margin: 0 1em;
    font-weight: 400;
    @media (min-width: 992px) {
        margin: 1.5em 1em 0 1.3em;
        font-size: 17px;
    }
`

export const Input = styled.input`
    height: 2em;
    border-width: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    outline: none;
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    color: ${({theme}) => theme.primaryColor};
`

export const ContainerInput = styled.div`
    border: none;
    padding: 5px 9px;
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    border-radius: 10px;
    margin: 0.5em 1em 1em 1em;
`

export const Text = styled.p`
    font-size: 0.9em;
    margin: 0 1em;
    font-weight: 400;
    color: #8A8A8A;
`

export const ErrorText = styled.p`
    font-size: 0.9em;
    margin: 1.5em 0 0 1em;
    font-weight: 400;
    color: ${colors.error};
`

export const ListMenu = styled(List)`
    height: 2.5em;
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    border-radius: 4px;
    padding: 0 !important;
    margin: 1.5em 1em 1em 1em !important;
    cursor: pointer;
    @media (min-width: 992px) {
        border-radius: 10px;
        height: 3em;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
`

export const ListMenuEspecies = styled(List)`
    height: 2.1em;
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    border-radius: 4px;
    padding: 0 !important;
    margin: 0.5em 1em 1.5em 1em !important;
    cursor: pointer;
    @media (min-width: 992px) {
        border-radius: 10px;
        height: 2.5em;
        display: flex;
        align-items: center;
    }
`

export const ContainerButton = styled.div`
    border-radius: 10px;
    margin: 3.5em 1em 1em 1em;
`