import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/userContext/UserContext';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import axios, { CancelTokenSource } from 'axios';
import { getPrecioEspecieCorta } from '../api/mercadoApi';
import { AuthContext } from '../context/authContext/AuthContext';
import { Body } from '../interfaces/interfaceEspeciePrecio';
import { TipoMercado } from '../enums/enums';

export const cutName = (name: string) => {
  const searchTerm = '-';
  const indexOf = name.indexOf(searchTerm);
  return name.slice(0, indexOf);
};

export const useListaAlertasHook = () => {
  const { settedAlerts } = useContext(UserContext);
  const { tipoMercado } = useContext(MercadoContext);
  const { deleteAlert } = useContext(UserContext);
  const { tokenPublico } = useContext(AuthContext);
  const [alertasLocales, setAlertasLocales] = useState(
    settedAlerts.filter(a => a.tipoMercado === 'LOCAL'),
  );
  const [alertasExterior, setAlertasExterior] = useState(
    settedAlerts.filter(a => a.tipoMercado === 'EXTERIOR'),
  );
  const [preciosActualesAlertasExterior, setPreciosActualesAlertasExterior] =
    useState<number[]>([]);
  const [preciosActualesAlertasLocal, setPreciosActualesAlertasLocal] =
    useState<number[]>([]);


  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    setAlertasLocales(settedAlerts.filter(a => a.tipoMercado === 'LOCAL'));
    setAlertasExterior(settedAlerts.filter(a => a.tipoMercado === 'EXTERIOR'));

    return () => {
      cancelToken.cancel();
    };
  }, [settedAlerts]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getDataEspecies(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, [alertasLocales, alertasExterior]);


  //para pedir precios apenas se monta
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getDataEspecies(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, []);

  async function getDataEspecies(cancelToken: CancelTokenSource) {
    try {
      if (tokenPublico) {
        const resp = await getPrecioEspecieCorta(
          tokenPublico,
          mapEspecies(),
          cancelToken,
        );
        mapPrecios(resp.data.body);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function mapPrecios(arr: Body[]) {
    let preciosLocales: number[] = [];
    let preciosExterior: number[] = [];

    alertasLocales.forEach(element => {
      let filter = arr.filter(
        f => f.especie === element.especie || f.simbolo === element.especie,
      );
      filter[0]
        ? preciosLocales.push(filter[0].ultimo)
        : preciosLocales.push(0);
    });
    alertasExterior.forEach(element => {
      let filter = arr.filter(
        f =>
          f.especie === element.especie ||
          f.simbolo === element.especie,
      );
      filter[0]
        ? preciosExterior.push(filter[0].ultimo)
        : preciosExterior.push(0);
    });
    setPreciosActualesAlertasLocal(preciosLocales);
    setPreciosActualesAlertasExterior(preciosExterior);
  }
  function mapEspecies() {
    let arr: string[] = [];
    alertasLocales.forEach(element => {
      arr.push(element.especie);
    });
    alertasExterior.forEach(element => {
      arr.push(element.especie);
    });
    return arr;
  }
  function eliminarPrecio(indexDelete: number, tipoMercado: TipoMercado) {
    if (tipoMercado === TipoMercado.Exterior) {
      setPreciosActualesAlertasExterior(
        preciosActualesAlertasExterior.filter(
          (_, index) => index !== indexDelete,
        ),
      );
    } else {
      setPreciosActualesAlertasLocal(
        preciosActualesAlertasLocal.filter((_, index) => index !== indexDelete),
      );
    }
  }

  return {
    deleteAlert,
    eliminarPrecio,
    alertasExterior,
    alertasLocales,
    preciosActualesAlertasLocal,
    preciosActualesAlertasExterior,
    tipoMercado,
  };
};
