import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Themes, colors } from '../../theme/Themes'
import { Form, Img, LabelForm, OptionContainer, Title, ContainerOptions, ErrorText } from './elements'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { useValidarCuentaHook } from '../../hooks/useValidarCuentaHook'
import { ContainerSwitcher } from '../../elements/containerSwitcher'
import SwitchTheme from '../../components/SwitchTheme/SwitchTheme'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

export const ValidarCuenta = () => {

    let url = window.location.href;
    let instancia = url.includes("validar-cuenta") ? "validar" : "restablecer";

    const themeContext = useContext(ThemeContext)

    const { primaryColor } = themeContext;

    const initialForm = {
        nroCuenta: "",
        dni: "",
        mail: "",
    };

    const { error, validate, handleChange, form } =
        useValidarCuentaHook({ initialForm, instancia });

    const [showDni, setShowDni] = React.useState(true);
    const [showEmail, setShowEmail] = React.useState(true);

    const customInputProps = {
        '& label.Mui-focused': {
            color: primaryColor,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: primaryColor,
            },
            '&:hover fieldset': {
                borderColor: primaryColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor,
            },
        },
    }


    return (
        <div style={{ width: '100%' }}>
            <ContainerSwitcher>
                <SwitchTheme />
            </ContainerSwitcher>
            <Form
                autoComplete={"off"}
            >
                {
                    themeContext === Themes.light ?
                        <Img
                            src={require('../../images/validar-cuenta.png')}
                        />
                        :
                        <Img
                            src={require('../../images/validar-cuenta-dark.png')}
                        />
                }
                <Title>
                    {instancia === "validar"
                        ? 'Ingresá tu número de cuenta y mail para generar tu PIN'
                        : 'Ingresá tu número de cuenta y mail para restablecer tu PIN'}
                </Title>
                <ContainerOptions>
                    <OptionContainer>
                        <LabelForm>Nº de cuenta</LabelForm>
                        <TextField
                            type='text'
                            name="nroCuenta"
                            value={form.nroCuenta}
                            aria-label="controlled"
                            onChange={handleChange}
                            sx={customInputProps}
                            inputProps={{
                                sx: {
                                    padding: 0,
                                },
                            }}
                            InputProps={{
                                sx: {
                                    color: primaryColor,
                                    padding: '0.5em 0.8em',
                                    borderRadius: '5px',
                                    fontSize: '1em',

                                }
                            }}
                        />
                    </OptionContainer>
                    <OptionContainer>
                        <LabelForm>Documento de identidad</LabelForm>
                        <TextField
                            type={showDni ? "text" : "password"}
                            name="dni"
                            value={form.dni}
                            aria-label="controlled"
                            onChange={handleChange}
                            sx={customInputProps}
                            inputProps={{
                                sx: {
                                    padding: 0,
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowDni(!showDni)}
                                                edge="end"
                                                tabIndex={-1}
                                            >
                                                {!showDni ? (
                                                    <VisibilityOffOutlined sx={{ color: primaryColor }} />
                                                ) : (
                                                    <VisibilityOutlined sx={{ color: primaryColor }} />
                                                )}
                                            </IconButton>
                                        </div>
                                    </InputAdornment>
                                ),
                                sx: {
                                    color: primaryColor,
                                    padding: '0.5em 0.8em',
                                    borderRadius: '5px',
                                }
                            }}
                        />
                    </OptionContainer>
                    <OptionContainer>
                        <LabelForm>E-mail</LabelForm>
                        <TextField
                            type={showEmail ? "text" : "password"}
                            name="mail"
                            value={form.mail}
                            aria-label="controlled"
                            onChange={handleChange}
                            sx={customInputProps}
                            inputProps={{
                                sx: {
                                    padding: 0,
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowEmail(!showEmail)}
                                                edge="end"
                                                tabIndex={-1}
                                            >
                                                {!showEmail ? (
                                                    <VisibilityOffOutlined sx={{ color: primaryColor }} />
                                                ) : (
                                                    <VisibilityOutlined sx={{ color: primaryColor }} />
                                                )}
                                            </IconButton>
                                        </div>
                                    </InputAdornment>
                                ),
                                sx: {
                                    color: primaryColor,
                                    padding: '0.5em 0.8em',
                                    borderRadius: '5px',
                                }
                            }}
                        />
                    </OptionContainer>
                    {error &&
                        <ErrorText>{error}</ErrorText>}
                </ContainerOptions>
                <Button
                    variant="contained"
                    disabled={false}
                    sx={{
                        width: "90%",
                        backgroundColor: colors.blueRava,
                        marginTop: "2.5em",
                        '@media (min-width: 992px)': {
                            width: '20%',
                        }
                    }}
                    onClick={validate}
                >
                    ACEPTAR
                </Button>
            </Form>
        </div>
    )
}

