import { DataEspecieReducida } from '../../../interfaces/dataCardsHome'
import { formatCurrency } from '../../../helpers/formatCurrency'
import {
    Container,
    ContainerData,
    ContainerEspecieNombre,
    ContainerImagenes,
    ContainerMercadoLocal,
    ContainerSinInfo,
    ContainerTitulo,
    ContainerUltimoVariacion,
    Dia,
    Especie,
    ImgArg,
    ImgDolar,
    ImgEEUU,
    Nombre,
    TextoSinInfo,
    Titulo,
    UltimoLocal,
    Variacion
} from './elements'
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext'
import React, { useContext } from 'react'
import Lottie from 'lottie-react';
import { useCardMercadoHook } from '../../../hooks/useCardMercadoHook'
import { ThemeContext } from 'styled-components'
import { Theme } from '../../../enums/theme'
import { ThemeContext as ContextTheme } from '../../../context/themeContext/ThemeContext'
import { TipoMercado } from '../../../enums/enums'


type Props = {
    title: string,
    data: DataEspecieReducida[] | null,
    img: string,
}

const CardMercado = ({ title, data, img }: Props) => {

    const { loading, tipoMercado } = useContext(MercadoContext);

    const { cutName, formatName, formatVariacion } = useCardMercadoHook();

    const { accentColor, buyColor, rojoPorcentaje } = useContext(ThemeContext)

    const { theme } = useContext(ContextTheme);


    return (
        // Renderizado condicional basado en el título del mercado.
        <ContainerMercadoLocal>
            {/* Contenido de la tarjeta de mercado local o de dólar. */}
            {
                loading ?
                    // Animación de carga mientras se obtienen los datos.
                    <Lottie
                        animationData={
                            theme === Theme.Light
                                ? require('../../../jsons-animation/loading-pagina-completa.json')
                                : require('../../../jsons-animation/loading-pagina-completa-dark.json')}
                        autoPlay={true}
                        loop={true}
                        style={{ height: '100%', width: '100%' }}
                    />
                    :
                    data
                        ? <Container>
                            {/* Títulos */}
                            <ContainerTitulo>
                                <Titulo>{title}</Titulo>
                                <Dia>%dia</Dia>
                            </ContainerTitulo>
                            {/* Mapeo de datos de especies para mostrar en la tarjeta. */}
                            {data.map((d) => (
                                <ContainerData key={d.especie}>
                                    {/* Imágenes y nombres de las especies. */}
                                    <ContainerImagenes>
                                        {
                                            tipoMercado === TipoMercado.Local
                                                ? title === 'Dólar'
                                                    ? <ImgDolar
                                                        alt="icono-dolar"
                                                        src={img}
                                                    />
                                                    : <ImgArg
                                                        alt="bandera-arg-mercado"
                                                        src={img}
                                                    />
                                                : <ImgEEUU
                                                    alt="bandera-usa"
                                                    src={img}
                                                />
                                        }
                                        <ContainerEspecieNombre>
                                            <Especie>
                                                {
                                                    tipoMercado === TipoMercado.Local
                                                        ? title === 'Dólar'
                                                            ? cutName(d.especie)
                                                            : title === "Lo que mas subió"
                                                                ? d.simbolo
                                                                : formatName(d.especie)
                                                        : formatName(d.especie)
                                                }
                                            </Especie>
                                            <Nombre>{d.nombre}</Nombre>
                                        </ContainerEspecieNombre>
                                    </ContainerImagenes>
                                    {/* variación de la especie. */}
                                    <ContainerUltimoVariacion>
                                        <UltimoLocal>{formatCurrency(d.ultimo)}</UltimoLocal>
                                        <Variacion
                                            style={{
                                                color:
                                                    d.variacion > 0
                                                        ? buyColor
                                                        : d.variacion < 0
                                                            ? rojoPorcentaje
                                                            : accentColor
                                            }}
                                        >
                                            {formatVariacion((Math.round(d.variacion * 10) / 10))}%
                                        </Variacion>
                                    </ContainerUltimoVariacion>
                                </ContainerData>
                            ))}
                        </Container>
                        :
                        <ContainerSinInfo>
                            <TextoSinInfo>Información no disponible.</TextoSinInfo>
                        </ContainerSinInfo>
            }
        </ContainerMercadoLocal>
    )
}

export default React.memo(CardMercado);