import styled from "styled-components";


export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
        width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
    }
`;

export const StyledReactInputVerificationCode = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 0.5rem;
    

	--ReactInputVerificationCode-itemWidth: 40px;
	--ReactInputVerificationCode-itemHeight: 38px;
	--ReactInputVerificationCode-itemSpacing: 8px;

	.ReactInputVerificationCode__item {
		font-size: 20px;
		font-weight: bold;
		color: ${({theme}) => theme.primaryColor};
		box-shadow: none;
        border-bottom: ${({theme}) => `1px solid ${theme.primaryColor}`};
        border-radius: 0;
	}

	.ReactInputVerificationCode__item.is-active {
		box-shadow: none;
		border-bottom: 1px solid #36c6d9;
	}

    @media (min-width: 992px) {
        --ReactInputVerificationCode-itemWidth: 50px;
        --ReactInputVerificationCode-itemHeight: 45px;
        --ReactInputVerificationCode-itemSpacing: 25px;
    }
`;

export const ContainerTitulo = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
`

export const Titulo = styled.h2`
    color: ${({theme}) => theme.primaryColor};
    
`

export const SecondaryContainer = styled.div`
    margin-top: 2em;
`


export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center; 
width: 100%;
padding-top: 15em;
@media (min-width: 992px) {
    padding-top: 5em;
}
`;