import { CardIngresarDinero } from '../../components/ingresarDineroComponents/CardIngresarDinero';
import { DatoIngresarDinero } from '../../interfaces/cardIngresarDineroInterface';
import { CardsContainer, Container } from './elements';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';

const datosCardPesos: DatoIngresarDinero[] = [
    { titulo: 'Rava Bursátil', data: 'CUIT: 30-59502502-4' },
    { titulo: 'Nro. de cuenta', data: '2758-4' },
    { titulo: 'CBU', data: '1980001730000000275847' },
    { titulo: 'Alias', data: 'RAVAPESOS' },
];

const datosCardDolares: DatoIngresarDinero[] = [
    { titulo: 'Rava Bursátil', data: 'CUIT: 30-59502502-4' },
    { titulo: 'Nro. de cuenta', data: '9001-0000010192/5' },
    { titulo: 'CBU', data: '1980001790000001019253' },
    { titulo: 'Alias', data: 'RAVADOLARES' },
];

export const IngresarDinero = () => {

    return (
        <Container>
        
            <ResponsiveHeader title="Ingresar dinero" showSwitch={false} />

            <CardsContainer>
                <CardIngresarDinero datos={datosCardPesos} moneda='PESOS' />
                <CardIngresarDinero datos={datosCardDolares} moneda='DÓLARES' />
            </CardsContainer>

        </Container>
    )
}
