import { MenuItem, Popover } from '@mui/material'
import { ThemeContext } from 'styled-components'
import { useContext } from 'react';

interface Props {
    open: boolean,
    anchorEl: HTMLElement | null,
    handleClose: (especie?: string) => void,
    listaMenuEspecies: string[],
}

const MenuEspeciesOpcionesSearchBar = ({ open, anchorEl, handleClose, listaMenuEspecies }: Props) => {

    const { primaryColor, grayToDarkGrayBackground, hoverMenuBackrgound } = useContext(ThemeContext);

    const styles = {
        menuItems: {
            backgroundColor: grayToDarkGrayBackground,
            borderBottom: `0.2px solid ${grayToDarkGrayBackground}`,
            padding: '0.9em',
            width: '11em',
            color: primaryColor,
            '&:hover':{
                backgroundColor: `${hoverMenuBackrgound} !important`,
            }
        }
    }

    return (
        <Popover
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open && listaMenuEspecies?.length > 0}
            onClose={() => handleClose()}
            disableAutoFocus  
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    marginTop: '1.8em !important',
                    maxHeight: '14em',
                    backgroundColor: 'transparent',
                    '& ul': {
                        padding: '0px',
                    },
                }
            }}
        >
            {
                listaMenuEspecies?.map((lista: string) =>
                    <MenuItem key={lista} sx={styles.menuItems} onClick={() => handleClose(lista)}>{lista}</MenuItem>)
            }
        </Popover>
    )
}

export default MenuEspeciesOpcionesSearchBar


