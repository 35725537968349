import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NoFavsInfo = styled.h3`
  color: ${({ theme }) => theme.accentColor};
`;

export const ContainerSinInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
