import React, { useState, useContext, useEffect } from 'react'
import moment from "moment";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material"
import { ArrowIcon, Container, EmptyText, ExtraSpace, ListContainer, MisAlertasButton, MisAlertasText } from './elements';
import { UserContext } from '../../context/userContext/UserContext';
import { AuthContext } from '../../context/authContext/AuthContext';
import { Sent } from '../../interfaces/notificacionInterface';
import { Alert } from '../../interfaces/alertaInterface';
import { alertsSeen } from '../../api/alertasApi';
import { getSentNotifications, notificationsSeen } from '../../api/notificacionesApi';
import { Notificacion } from '../../components/notificacionesComponents/Notificacion/Notificacion';
import { useNavigate } from 'react-router-dom';
import { NotificacionContext } from '../../context/notificacionContext/NotificacionContext';
import { getAlerts } from '../../api/userApi';
import BotonesAlertasNotificaciones from '../../components/notificacionesComponents/BotonesAlertasNotificaciones/BotonesAlertasNotificaciones';
import { ThemeContext } from 'styled-components';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';

export const Notificaciones = () => {

    const { notifications, sentAlerts } = useContext(UserContext);
    const { token, setToken } = useContext(AuthContext)
    const { setLogoOn, screen } = useContext(NotificacionContext);
    const [noticiasFilt, setNoticiasFilt] = React.useState<Sent[]>([]);
    const [alertasFilt, setAlertasFilt] = React.useState<Alert[]>([]);
    const navigate = useNavigate();


    const [loading, setLoading] = useState(false);
    const { setSentAlerts, setSettedAlerts, setNotifications } = useContext(UserContext);

    const themeContext = useContext(ThemeContext)

    const { loadingColor } = themeContext;

    const refresh = async () => {
        try {
            setLoading(true);
            const tokenStorage = localStorage.getItem("token");
            tokenStorage && setToken(tokenStorage);
            if (tokenStorage) {
                const resp = await Promise.all([
                    getAlerts(tokenStorage),
                    getSentNotifications(tokenStorage),
                ]);
                setSettedAlerts(resp[0].data.body.alertsSettedList);
                setSentAlerts(resp[0].data.body.alertsSentList);
                setNotifications(resp[1].data.body.sent);

                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        refresh();
    }, [!notifications])

    useEffect(() => {
        filtNoticias();
    }, [notifications])

    useEffect(() => {
        filtAertas();
    }, [sentAlerts])

    useEffect(() => {
        changeLogo();
    }, [])

    const changeLogo = async () => {
        await setSeen();
        setLogoOn(false);
    }

    const setSeen = async () => {
        if (token) {
            try {
                await alertsSeen(token);
                await notificationsSeen(token);
            } catch (error) {
                console.log(error); 
            }
            refresh();
        }
    }

    const filtNoticias = () => {
        const today = moment().format();

        let filt = notifications?.filter(e =>
            e.duration === "24 hs" ?
                moment(today).diff(e.date, 'hours') <= 24
                :
                e.duration === "7 días" ?
                    moment(today).diff(e.date, 'hours') <= 168
                    :
                    e.duration === "14 días" ?
                        moment(today).diff(e.date, 'hours') <= 336
                        :
                        moment(today).diff(e.date, 'hours') <= 672
        )
        setNoticiasFilt(filt)
    }

    const filtAertas = () => {
        const today = moment().format();

        let filt = sentAlerts?.filter(e =>
            moment(today).diff(e.date, 'hours') <= 24
        )

        setAlertasFilt(filt);
    }


    return (
        <Container>

            <ResponsiveHeader title={screen} showSwitch={false} />

            <BotonesAlertasNotificaciones />

            {
                loading ?
                    <Box sx={{ display: "flex", justifyContent: "center", margin: "0.5em", alignItems: 'center', height: '90%' }}>
                        <CircularProgress style={{ color: loadingColor }} />
                    </Box>
                    :
                    <ListContainer>
                        {
                            screen === "Alertas" ?
                                <MisAlertasButton
                                    onClick={() => navigate("/mis-alertas")}
                                >
                                    <MisAlertasText>Mis alertas</MisAlertasText>
                                    <ArrowIcon icon="material-symbols:arrow-forward-ios-rounded" />
                                </MisAlertasButton>
                                :
                                null
                        }
                        {screen === 'Alertas' ?
                            (
                                alertasFilt.length === 0 ?
                                    <EmptyText>Todavía no recibiste alertas de precios</EmptyText> :
                                    alertasFilt?.map((e, index) => (
                                        <div key={index}>
                                            <Notificacion
                                                tipo="Alerta"
                                                titulo={e.especie}
                                                fecha={e.date ? `${e?.date.slice(8, 10)}/${e.date.slice(5, 7)}/${e.date.slice(0, 4)}` : ""}
                                                precio={e.precio}
                                                mercado={e.tipoMercado}
                                            />
                                        </div>
                                    )))
                            :
                            (
                                noticiasFilt.length === 0 ?
                                    <EmptyText>¡Estás al día! No recibiste nuevas noticias</EmptyText> :
                                    noticiasFilt?.map(((e: Sent) => (
                                        <div key={e?._id}>
                                            <Notificacion
                                                titulo={e?.title}
                                                fecha={`${e?.date.slice(8, 10)}/${e.date.slice(5, 7)}/${e.date.slice(0, 4)}`}
                                                desc={e?.description}
                                                img={e.image === null ? "" : e.image}
                                            />
                                        </div>
                                    )))
                            )
                        }
                        <ExtraSpace />
                    </ListContainer>
            }

        </Container>
    )
}
