import React, { ChangeEvent, useState } from "react";



const useAgregarCuentaMenusHook = (setForm: (e: ChangeEvent<HTMLInputElement> | string, tipo?:string) => void) => {
    const listaTipoMoneda = ["PESOS", "DÓLARES"];
    const listaTipoCuenta = ["CAJA DE AHORROS", "CUENTA CORRIENTE"];

    const [anchorElMoneda, setAnchorElMoneda] = useState<null | HTMLElement>(null);
    const [anchorElCuenta, setAnchorElCuenta] = useState<null | HTMLElement>(null);
    const openMoneda = Boolean(anchorElMoneda);
    const openCuenta = Boolean(anchorElCuenta);

    const handleOpenMenuMoneda = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMoneda(event.currentTarget);
    };

    const handleOpenMenuCuenta = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElCuenta(event.currentTarget);
    };

    const handleCloseTipoMoneda = (op?: string) => {
        setAnchorElMoneda(null);
        if (op) {
            setForm(op, 'tipoMoneda');
        }
    };

    const handleCloseTipoCuenta = (op?: string) => {
        setAnchorElCuenta(null);
        if (op) {
            setForm(op, 'tipoCuenta');
        }
    };

    return {
        handleOpenMenuMoneda,
        handleOpenMenuCuenta,
        openCuenta,
        openMoneda,
        anchorElCuenta,
        anchorElMoneda,
        listaTipoMoneda,
        listaTipoCuenta,
        handleCloseTipoMoneda,
        handleCloseTipoCuenta,
    };
};

export default useAgregarCuentaMenusHook;
