import styled from "styled-components";
import { colors } from "../../theme/Themes";


export const Container = styled.div`
        flex: 1;
        height: 100vh;
        background-color: ${({ theme }) => theme.secondaryBackgroundColor};
        @media (min-width: 992px) {
            display: flex;
            flex-direction: column;
            height: auto;
            background-color: ${({ theme }) => theme.primaryBackgroundColor};
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            margin-top: 1.25em;
            flex-grow: 1;
        }
`

export const ContainerPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const OperarTitle = styled.p`
    margin: 1em 0 0 1.5em;
    font-size: 1.3em;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: bold;
`

export const ContainerDDJJ = styled.div`
    margin: 3em 1.5em 0 1.5em;
    height: 20em;
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 10px;
    @media (min-width: 992px) {
        margin: 1em 1.5em 0 1.5em;
        height: 10em;
        display: flex;
        flex-grow: 1;
        background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    }
`

export const ContainerCards = styled.div`
    margin: 3em 0.2em 0 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 992px) {
        margin: 0.6em 1.5em 0 1.5em;
        align-items: center;
    }
`

export const TextoDDJJ = styled.p`
    margin: 0.5em 1em 0.5em 1em;
    font-size: 15px;
    text-align: justify;
    color: ${({ theme }) => theme.fontColorWhiteToBlack}
`

export const ContainerButtons = styled.div`
    display: flex;
    margin-top: 1em;
    justify-content: center;
    @media (min-width: 992px) {
        margin-top: 1em;
        margin-bottom: 1em;
    }
`

export const ButtonAceptarConfirmar = styled.button`
    background-color: ${({ theme }) => theme.fontColorWhiteToBlue};
    letter-spacing: 1.25px;
    margin-right: 0.3em;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    font-weight: 600;
    border: 2px solid ${colors.blueRava};
    border-radius: 5px;
    width: 39%;
    height: 2.5em;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 15px;
        letter-spacing: 0px;
        height: 3em;
    }
    @media (min-width: 1490px) {
        font-size: 18px;
        letter-spacing: 1.25px;
        height: 2.5em;
        white-space:normal !important;
        word-wrap:break-word;
    }
    `

export const ButtonCancelar = styled.button`
    background-color: ${({ theme }) => theme.backgroundWhiteToGray};
    letter-spacing: 1.25px;
    margin-left: 0.3em;
    color: ${({ theme }) => theme.accentColor};
    font-size: 18px;
    font-weight: 500;
    border: 2px solid ${colors.grayRava};
    border-radius: 5px;
    width: 39%;
    height: 2.5em;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 15px;
        letter-spacing: 0px;
        height: 3em;
    }
    @media (min-width: 1490px) {
        font-size: 18px;
        letter-spacing: 1.25px;
        height: 2.5em;
        white-space:normal !important;
        word-wrap:break-word;
    }
`

export const ContainerLoader = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 17em;
    @media (min-width: 992px) {
        margin-top: 10em;
    }
`

export const ContainerExitoError = styled.div`
    margin: 10em 2em 0 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 992px) {
        margin: 5em 1.5em 0 1.5em;
    }
`

export const TituloExito = styled.p`
    margin: 0px;
    padding: 0.5em;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.primaryColor};
    line-height: 33px;
`

export const TituloError = styled.p`
    margin: 0px;
    padding: 0.5em;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.rojoPorcentaje};
    line-height: 33px;
`


export const MensajeExito = styled.p`
    margin: 0px;
    padding: 0.2em 2em;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.primaryColor};
    line-height: 33px;
`

export const MensajeError = styled.p`
    margin: 0px;
    padding: 0.2em 3em;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.rojoPorcentaje};
    line-height: 33px;
`