import styled from "styled-components";


export const ContainerLabels = styled.div`
    display: grid;
    padding: 0em 0.5em 1em;
    width: fit-content;
`

export const Container = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    margin: 1em;
    margin-top: 2em;
    border-radius: 10px;
    @media (min-width: 992px) {
        margin-top: 1.2em;
    }
`
export const ContainerTitulos = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TextoSolicitarAltaCbu = styled.p`
    color: ${({theme}) => theme.primaryColor};
    font-size: 18px;
    margin: 1em; 
    cursor: pointer;
`

export const TextoSinCuentas = styled.p`
    color: ${({theme}) => theme.primaryColor};
    font-size: 18px;
    margin: 0 0 0 1em;
`
export const TextoSeleccionarCuenta = styled.p`
    color: ${({theme}) => theme.primaryColor};
    font-size: 18px;
    margin-left: 1em;
`