import styled from "styled-components";

export const BotonBuscar = styled.button`
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    color: ${({theme}) => theme.primaryColor};
    border: 2px solid ${({theme}) => theme.primaryColor};
    font-size: 15px;
    font-weight: 700;
    border-radius: 5px;
    width: 6em;
    cursor: pointer;
`