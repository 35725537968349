import React, { useContext } from 'react'
import { CircularProgress } from "@mui/material"
import { TipoMercado } from '../../../enums/enums';
import { UserContext } from '../../../context/userContext/UserContext';
import { Box } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ArrowContainer, Container, ContainerMercados, ContainerPorcentaje, Img, Porcentaje } from './elements';
import { ThemeContext } from 'styled-components';


interface Props {
    handleChangeSwitch: (e: boolean) => void;
    tipoMercado: TipoMercado;
    setTipoMercado: React.Dispatch<React.SetStateAction<TipoMercado>>;
    loadingTenencia: boolean,
    errorTenencia: boolean,
}

export const SwitchMercadoNavAmpliado = ({ handleChangeSwitch, tipoMercado, setTipoMercado, loadingTenencia, errorTenencia }: Props) => {

    const { porcentajeExterior, porcentajeLocal } = useContext(UserContext)

    const handlerTipoMercado = (tipoMercado: TipoMercado) => {
        localStorage.setItem('tipoMercado', tipoMercado);
        setTipoMercado(tipoMercado);
        handleChangeSwitch(false);
    }

    const { primaryColor } = useContext(ThemeContext);

    const styles = {
        arrow: {
            fontSize: 'small',
            color: primaryColor,
            height: '10px',
            width: '10px'
        }
    }


    const ItemLocal = () => {
        return (
            <div onClick={() => handlerTipoMercado(TipoMercado.Local)}>
                <Box sx={{ display: 'flex' }}>
                    <Img
                        alt="Bandera"
                        src={require('../../../images/bandera-argentina-cuenta.png')}
                    />
                    <ContainerPorcentaje>
                        <Porcentaje>{
                            loadingTenencia
                                ? <CircularProgress style={{ color: "#004B6F", width: '1em', height: '1em' }} />
                                : errorTenencia
                                    ? '-'
                                    : porcentajeLocal || porcentajeLocal === 0
                                        ? porcentajeLocal
                                        : 0
                        }%</Porcentaje>
                    </ContainerPorcentaje>
                </Box>
            </div>
        )
    }

    const ItemExt = () => {
        return (
            <div onClick={() => handlerTipoMercado(TipoMercado.Exterior)}>
                <Box sx={{ display: 'flex' }}>
                    <Img
                        alt="Bandera"
                        src={require('../../../images/bandera-usa.png')}
                    />
                    <ContainerPorcentaje>
                        <Porcentaje>{
                            loadingTenencia
                                ? <CircularProgress style={{ color: "#004B6F", width: '1em', height: '1em' }} />
                                : errorTenencia
                                    ? '-'
                                    : porcentajeExterior || porcentajeExterior === 0
                                        ? porcentajeExterior
                                        : 0
                        }%</Porcentaje>
                    </ContainerPorcentaje>
                </Box>
            </div>
        )
    }

    return (
        <Container>
            <ContainerMercados>
                <Box>
                    {tipoMercado === TipoMercado.Local ? <ItemLocal /> : <ItemExt />}
                    <Box
                        sx={{
                            height: 1,
                            backgroundColor: '#EDE9E9',
                            marginLeft: 0,
                            marginBottom: 0.6
                        }}
                    />
                    {tipoMercado !== TipoMercado.Local ? <ItemLocal /> : <ItemExt />}
                </Box>
                <ArrowContainer>
                    <ArrowForwardIosOutlinedIcon sx={styles.arrow} />
                </ArrowContainer>
            </ContainerMercados>
        </Container>
    )
}


