import React, { useContext } from 'react'
import { FormAgregarCuentaProps } from '../../../interfaces/agregarCuentaInterface'
import { BotonCancelar, BotonContinuar, Container, ContainerExitoError, ContainerLoader, ContainerMensajes, ContenedorBotones, ContenedorDatos, Datos, MensajeError, MensajeExito, Titulo, TituloExitoError } from './elements'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ThemeContext } from 'styled-components';
import { CircularProgress } from '@mui/material';

interface Props {
    form: FormAgregarCuentaProps;
    cancelar: () => void;
    confirmarCuenta: React.Dispatch<React.SetStateAction<boolean>>;
    confirmoCuenta: boolean;
    loading: boolean;
    error: boolean;
}

const ConfirmarDatosCuenta = ({ form, cancelar, confirmarCuenta, confirmoCuenta, loading, error }: Props) => {

    const { rojoPorcentaje, primaryColor, loadingColor } = useContext(ThemeContext);

    return (
        <Container>
            {!confirmoCuenta
                ? <div>
                    <div>
                        <Titulo>Datos de la cuenta</Titulo>
                        <ContenedorDatos>
                            <Datos>{`Titular: ${form.titular}`}</Datos>
                            <Datos>{`Tipo de moneda: ${form.tipoMoneda}`}</Datos>
                            <Datos>{`CBU: ${form.cbu}`}</Datos>
                            <Datos>{`Tipo de cuenta: ${form.tipoCuenta}`}</Datos>
                            <Datos>{`Número de cuenta: ${form.nroCuenta}`}</Datos>
                            <Datos>{`CUIT: ${form.cuit}`}</Datos>
                            <Datos>{`Alias: ${form.alias}`}</Datos>
                        </ContenedorDatos>
                    </div>
                    <ContenedorBotones>
                        <BotonContinuar
                            onClick={() => confirmarCuenta(true)}
                        >CONFIRMAR</BotonContinuar>
                        <BotonCancelar
                            onClick={cancelar}
                        >CANCELAR</BotonCancelar>
                    </ContenedorBotones>
                </div>
                : !loading
                    ? <ContainerMensajes>
                        {
                            !error
                                ? <ContainerExitoError>
                                    <TaskAltIcon sx={{ fontSize: '50px', color: primaryColor }} />
                                    <TituloExitoError
                                        style={{
                                            color: primaryColor
                                        }}
                                    >
                                        ¡Listo!
                                    </TituloExitoError>
                                    <MensajeExito>Tu solicitud de alta de CBU se envió correctamente.</MensajeExito>
                                    <MensajeExito>En el transcurso del día lo verás actualizado al ingresar/retirar dinero.</MensajeExito>
                                </ContainerExitoError>
                                :
                                <ContainerExitoError>
                                    <CancelOutlinedIcon sx={{ fontSize: '50px', color: rojoPorcentaje }} />
                                    <TituloExitoError
                                        style={{
                                            color: rojoPorcentaje
                                        }}
                                    >
                                        {/* {tituloError} */}
                                        ¡Lo siento!
                                    </TituloExitoError>
                                    <MensajeError>Ocurrió un error al intentar procesar tu cuenta,
                                        por favor comunicate con tu asesor/a.
                                    </MensajeError>
                                </ContainerExitoError>
                        }
                    </ContainerMensajes>
                    : <ContainerLoader>
                        <CircularProgress size={40} thickness={4} style={{ color: loadingColor }} />
                    </ContainerLoader>
            }
        </Container>
    )
}

export default ConfirmarDatosCuenta