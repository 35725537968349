import styled from "styled-components";

export const Container = styled.div`
    height: 80vh;
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    padding: 1.2em 1.8em;
    color: ${({theme}) => theme.accentColor};
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 91.5vh;
		width: 85vw;
        padding: 0vh 1.5vw 0 1.5vw;
	}
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
`;

export const Title = styled.p`
    color: ${({theme}) => theme.redToWhiteColor};
    font-size: 22px;
    font-weight: bold;
    margin: 0;
`;

export const ImgContainer = styled.div`
    display: flex; 
    justify-content: center;
`

export const Img = styled.img`
    margin-top: 24px; 
    width: 300px; 
    height: 300px;
`;
