import styled from "styled-components";


export const ContainerElements = styled.div`
display: grid;
grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 2fr;
font-size: 14px;
text-align: center;
align-items: center;
margin-right: 1em;
@media(min-width: 992px){
    grid-template-columns: repeat(2, 1fr) repeat(2, 1.5fr) repeat(3, 1fr) repeat(2, 1.5fr) 0.5fr;
    font-size: 16px;
    margin-right: 0px;
}
`

export const Titulos = styled.p`
font-weight: 600;
color: ${({ theme }) => theme.primaryColor}; 
margin: 0.5em 0 0.3em 0;
`