import styled, { css } from "styled-components";

export const ContainerSwitcher = styled.div`
    position: absolute;
    right: 1em;
    top: 0.3em;
    @media (min-width: 992px) {
        right: 5.5em;
        top: 0.7em;
    }
`