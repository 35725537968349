import { useContext } from 'react'
import {
    ContainerInsideSaldos,
    ContainerPorcentajes,
    ContainerSaldos,
    Dolares,
    DolaresLocal,
    ErrorText,
    ImgDesk,
    Pesos,
    PorcentajesCero,
    PorcentajesNegativos,
    PorcentajesPositivos,
    Saldos,
    SaldosDolares,
    SecondContainer,
    TextoPorcentajeNegativo,
    TextoPorcentajePositivo,
    TipoMercadoText,
    Visibility
} from './elements';
import { IconButton, Typography } from '@mui/material';
import { ThemeContext } from 'styled-components';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

interface Props {
    mercado: string;
    variacion: number | null;
    errorTenencia: boolean;
    imgBandera: string;
    showPassword: boolean;
    handleClickShowPassword: () => void;
    mercadoAbierto: () => boolean;
    moneda: string;
    pesosEnCuenta?: string;
    dolaresMercadoLocal?: string;
    dolaresEnCuenta?: string;
    diferenciaMercado: string;
}

const InfoMercadoDesk = ({
    mercado,
    variacion,
    errorTenencia,
    imgBandera,
    showPassword,
    handleClickShowPassword,
    mercadoAbierto,
    moneda,
    pesosEnCuenta,
    dolaresMercadoLocal,
    dolaresEnCuenta,
    diferenciaMercado,
}: Props) => {

    const { primaryColor, rojoPorcentaje, buyColor } = useContext(ThemeContext);


    return (
        <ContainerSaldos>
            <ContainerInsideSaldos>
                {
                    mercado === "LOCAL"
                        ? <Saldos>
                            <TipoMercadoText>{`MERCADO ${mercado}`}</TipoMercadoText>
                            {
                                errorTenencia ?
                                    <ErrorText>No podemos mostrar tu tenencia en este momento.
                                        Comunicate con tu asesor/a.</ErrorText>
                                    :
                                    <>
                                        <Pesos>${pesosEnCuenta}</Pesos>
                                        <DolaresLocal>USD {dolaresMercadoLocal}</DolaresLocal>
                                    </>
                            }
                        </Saldos>
                        : <SaldosDolares>
                            <TipoMercadoText style={{ marginBottom: '1em' }}>{`MERCADO ${mercado}`}</TipoMercadoText>
                            {
                                errorTenencia ?
                                    <ErrorText>No podemos mostrar tu tenencia en este momento.
                                        Comunicate con tu asesor/a.</ErrorText>
                                    :
                                    <Dolares>USD {dolaresEnCuenta}</Dolares>
                            }
                        </SaldosDolares>
                }
            </ContainerInsideSaldos>
            <SecondContainer>
                <ContainerPorcentajes>
                    {
                        variacion !== null && !isNaN(variacion) ?
                            // Cuando la variacion es 0
                            variacion === 0 ? (
                                <PorcentajesCero>
                                    <Typography color={primaryColor}>-%</Typography>
                                </PorcentajesCero>
                            )
                                :
                                !mercadoAbierto() ?
                                    (
                                        // Cuando el mercado NO esta abierto
                                        <PorcentajesCero>
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <Typography color={primaryColor}>{`${moneda} ${diferenciaMercado}`}</Typography>
                                            </div>
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                {
                                                    variacion > 0 ?
                                                        <ArrowDropUpSharpIcon sx={{ color: primaryColor }} />
                                                        :
                                                        <ArrowDropDownSharpIcon sx={{ color: primaryColor }} />
                                                }
                                                <Typography color={primaryColor}>{formatPorcentaje(variacion)}%</Typography>
                                            </div>
                                        </PorcentajesCero>
                                    ) : (
                                        // Cuando el mercado esta abierto y la variacion mayor a 0
                                        variacion > 0 ?
                                            (
                                                <PorcentajesPositivos>
                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <TextoPorcentajePositivo>{`${moneda} ${diferenciaMercado}`}</TextoPorcentajePositivo>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <ArrowDropUpSharpIcon sx={{ color: buyColor }} />
                                                        <TextoPorcentajePositivo> {formatPorcentaje(variacion)}%</TextoPorcentajePositivo>
                                                    </div>
                                                </PorcentajesPositivos>) :
                                            // Cuando el mercado esta abierto y la variacion menor a 0
                                            (
                                                <PorcentajesNegativos>
                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <TextoPorcentajeNegativo>{`${moneda} ${diferenciaMercado}`}</TextoPorcentajeNegativo>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <ArrowDropDownSharpIcon sx={{ color: rojoPorcentaje }} />
                                                        <TextoPorcentajeNegativo> {formatPorcentaje(variacion)}%</TextoPorcentajeNegativo>
                                                    </div>
                                                </PorcentajesNegativos>)
                                    )
                            : null
                    }
                </ContainerPorcentajes>
                <div style={{ display: "flex", flexDirection: 'column', marginRight: '1em' }}>
                    {
                        (
                            !errorTenencia ?
                                // Si no hay error en la tenencia se muestra el icono para ocultar la variacion/saldo
                                <Visibility>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        size="small"
                                        sx={{ padding: '0 0em 0 0' }}
                                    >
                                        {showPassword ? (
                                            <VisibilityOffOutlined sx={{ color: primaryColor }} />
                                        ) : (
                                            <VisibilityOutlined sx={{ color: primaryColor }} />
                                        )}
                                    </IconButton>
                                </Visibility>
                                :
                                null
                        )
                    }
                    <ImgDesk
                        alt="Bandera"
                        src={imgBandera}
                    />
                </div>
            </SecondContainer>
        </ContainerSaldos>
    )
}

export default InfoMercadoDesk