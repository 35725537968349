import styled from "styled-components";


export const Container = styled.div`
display: flex;
border-bottom: 1px solid ${({theme}) => theme.bordesMercado};
align-items: center;
`

export const ContainerInfo = styled.div`
width: 100%;
margin-top: 0.3em;
margin-left: 0.5em;
margin-right: 0.6em;
@media (min-width: 992px){
    margin-right: 3.5em;
    margin-left: 3.5em;
}
`

export const InfoTopContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: end;
`

export const Simbolo = styled.p`
margin: 0px;
color: ${({theme}) => theme.primaryColor};
font-weight: 600;
font-size: 18px;
letter-spacing: 0.44px;
width: 3em;
cursor: pointer;
`

export const SimboloOpciones = styled.p`
margin: 0;
color: ${({theme}) => theme.primaryColor};
font-weight: 600;
font-size: 15px;
letter-spacing: 0px;
width: 3em;
cursor: pointer;
@media (min-width: 992px) {
    font-size: 18px;
    letter-spacing: 0.44px;    
}
`

export const Ultimo = styled.p`
margin: 0px;
color: ${({theme}) => theme.primaryColor};
font-weight: 700;
font-size: 15px;
text-align: right;
margin-bottom: 0.3em;
margin-right: 0.8em;
width: 5em;
`

export const Variacion = styled.p`
margin: 0px;
font-weight: 700;
font-size: 15px;
text-align: left;
width: 5em;
margin-left: 0.3em;
margin-bottom: 0.3em;
@media (min-width: 992px){
    margin-right: 0.8em;
}
`

export const Hora = styled.p`
margin: 0px;
width: 3.7em;
text-align: center;
font-size: 13px;
margin-bottom: 0.3em;
color: ${({theme}) => theme.fontColorWhiteToBlack};
`

export const InfoButtomContainer = styled.div`
display: flex;
justify-content: space-between;
background-color: ${({theme}) => theme.colorBottomContainerMercado};
border-radius: 7px;
align-items: flex-end;
margin-bottom: 0.5em;
@media (min-width: 992px) {
    justify-content: center;
}
`

export const ContainerButtomIzquierda = styled.div`
display: flex;
align-items: end;
width: 4em;
flex-wrap: wrap-reverse;
@media (min-width: 992px) {
    width: 8em;
    justify-content: flex-end;
}
`
export const ContainerButtomDerecha = styled.div`
display: flex;
align-items: end;
width: 4em;
justify-content: flex-end;
flex-wrap: wrap;
@media (min-width: 992px) {
    display: flex;
    width: 8em;
    justify-content: flex-start;
}
`

export const NumeroCantidadIzquierda = styled.p`
margin: 0px;
color: ${({theme}) => theme.accentColor};
font-weight: 700;
font-size: 13px;
margin-bottom: 0.1em;
margin-left: 0.3em;
@media (min-width: 992px) {
    font-size: 14px;
}
`

export const NumeroCantidadDerecha = styled.p`
margin: 0px;
color: ${({theme}) => theme.accentColor};
font-weight: 700;
font-size: 13px;
margin-right: 0.3em;
margin-bottom: 0.1em;
@media (min-width: 992px) {
    font-size: 14px;
}
`

export const TextoCantidadIzquierda = styled.p`
margin: 0px;
margin-left: 0.3em;
color: ${({theme}) => theme.accentColor};
font-size: 10px;
margin-bottom: 0.2em;
@media (min-width: 992px) {
    margin-right: 1.5em;
    font-size: 11px;
}
`

export const TextoCantidadDerecha = styled.p`
margin: 0px;
margin-right: 0.3em;
color: ${({theme}) => theme.accentColor};
font-size: 10px;
margin-bottom: 0.2em;
@media (min-width: 992px) {
    margin-left: 1.5em;
    font-size: 11px;

}
`

export const ContainerCompra = styled.div`
display: flex;
flex-direction: column;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.3);
border-radius: 5px;
align-items: flex-end;
width: 4.7em;
background-color: ${({theme}) => theme.grayToDarkGrayBackground};
margin-right: 0.3em;
cursor: pointer;
`
export const ContainerVenta = styled.div`
display: flex;
flex-direction: column;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.3);
border-radius: 5px;
align-items: flex-end;
width: 4.7em;
background-color: ${({theme}) => theme.grayToDarkGrayBackground};
align-items: flex-start;
margin-left: 0.3em;
cursor: pointer;
`

export const PrecioCompra = styled.p`
margin: 0px;
font-weight: 750;
font-size: 13px;
margin-right: 0.3em;
color:  ${({theme}) => theme.fontColorWhiteToBlack};
`

export const PrecioVenta = styled.p`
margin: 0px;
font-weight: 750;
font-size: 13px;
margin-left: 0.3em;
color:  ${({theme}) => theme.fontColorWhiteToBlack};

`

export const TituloCompraVenta = styled.p`
margin: 0px;
font-size: 12px;
margin-left: 0.3em;
margin-right: 0.3em;
color:  ${({theme}) => theme.fontColorWhiteToBlack};

`