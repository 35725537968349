import React from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { ContainerElements, Titulos } from './elements'

const TitulosMovimientos = () => {

    const { width } = useWindowDimensions()

    return width < 992
        ? (<ContainerElements>
            <Titulos>Fecha Op.</Titulos>
            <Titulos>Fecha Liq.</Titulos>
            <Titulos>Oper.</Titulos>
            <Titulos>Especie</Titulos>
            <Titulos>Importe</Titulos>
        </ContainerElements>)
        : (<ContainerElements>
            <Titulos>Fecha Op.</Titulos>
            <Titulos>Fecha Liq.</Titulos>
            <Titulos>Oper.</Titulos>
            <Titulos>Referencia</Titulos>
            <Titulos>Cantidad</Titulos>
            <Titulos>Especie</Titulos>
            <Titulos>Precio</Titulos>
            <Titulos style={{
                paddingLeft: '0.5em'
            }}>Importe</Titulos>
            <Titulos style={{
                paddingLeft: '0.5em'
            }}>Saldo</Titulos>
            <Titulos></Titulos>
        </ContainerElements>)
}

export default TitulosMovimientos