import styled from "styled-components";

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
        width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
    }
`;

export const AcordionContainer = styled.div`
    @media (min-width: 992px) {
        padding: 2em 0em 0em 1em;
    }
`

export const PreguntaText = styled.p`
    font-size: 1em;
    color: ${({theme}) => theme.primaryColor};
    margin: 0;
    font-weight: 400;
`

export const RespuestaText = styled.p`
    font-size: 1em;
    color: ${({theme}) => theme.fontColorWhiteToBlack};
    margin: 0;
    line-height: 18px;
`