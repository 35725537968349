import React, { useContext } from 'react'
import {
    ContainerInsideSaldos,
    ContainerPorcentajes,
    ContainerSaldos,
    Dolares,
    DolaresLocal,
    ErrorText,
    Img,
    Pesos,
    PorcentajesCero,
    PorcentajesNegativos,
    PorcentajesPositivos,
    Saldos,
    SaldosDolares,
    SecondContainer,
    TextoPorcentajeNegativo,
    TextoPorcentajePositivo,
    Visibility
} from './elements'
import { IconButton, Typography } from '@mui/material'
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { ThemeContext } from 'styled-components';

interface Props {
    mercado: string;
    variacion: number | null;
    errorTenencia: boolean;
    imgBandera: string;
    showPassword: boolean;
    handleClickShowPassword: () => void;
    mercadoAbierto: () => boolean;
    moneda: string;
    pesosEnCuenta?: string;
    dolaresMercadoLocal?: string;
    dolaresEnCuenta?: string;
    diferenciaMercado: string;
}

const InfoMercadoPhone = ({
    mercado,
    variacion,
    errorTenencia,
    imgBandera,
    showPassword,
    handleClickShowPassword,
    mercadoAbierto,
    moneda,
    pesosEnCuenta,
    dolaresMercadoLocal,
    dolaresEnCuenta,
    diferenciaMercado,
}: Props) => {

    const { primaryColor, rojoPorcentaje, buyColor } = useContext(ThemeContext);

    return (
        <ContainerSaldos>
            <ContainerInsideSaldos>
                <Img
                    alt="Bandera"
                    src={imgBandera}
                />
                {
                    mercado === 'LOCAL'
                        ? <Saldos>
                            {
                                errorTenencia ?
                                    <ErrorText>No podemos mostrar tu tenencia en este momento.
                                        Comunicate con tu asesor/a.</ErrorText>
                                    :
                                    <>
                                        <Pesos>${pesosEnCuenta}</Pesos>
                                        <DolaresLocal>USD {dolaresMercadoLocal}</DolaresLocal>
                                    </>
                            }
                        </Saldos>
                        : <SaldosDolares>
                            {
                                errorTenencia ?
                                    <ErrorText>No podemos mostrar tu tenencia en este momento.
                                        Comunicate con tu asesor/a.</ErrorText>
                                    :
                                    <Dolares>USD {dolaresEnCuenta}</Dolares>
                            }
                        </SaldosDolares>
                }

            </ContainerInsideSaldos>
            <SecondContainer>
                <ContainerPorcentajes>
                    {
                        variacion !== null && !isNaN(variacion) ?
                            // Cuando la variacion es 0
                            variacion === 0 ? (
                                <PorcentajesCero>
                                    <Typography color={primaryColor}>-%</Typography>
                                </PorcentajesCero>
                            ) :
                                // Cuando el mercado NO esta abierto
                                !mercadoAbierto() ?
                                    <PorcentajesCero>
                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                            {variacion > 0 ?
                                                <ArrowDropUpSharpIcon sx={{ color: primaryColor }} />
                                                :
                                                <ArrowDropDownSharpIcon sx={{ color: primaryColor }} />
                                            }
                                            <Typography color={primaryColor}>{`${moneda} ${diferenciaMercado}`}</Typography>
                                        </div>
                                    </PorcentajesCero>
                                    : (
                                        // Cuando el mercado esta abierto y la variacion mayor a 0
                                        variacion > 0 ?
                                            (
                                                <PorcentajesPositivos>
                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <ArrowDropUpSharpIcon sx={{ color: buyColor }} />
                                                        <TextoPorcentajePositivo>{`${moneda} ${diferenciaMercado}`}</TextoPorcentajePositivo>
                                                    </div>
                                                </PorcentajesPositivos>) :
                                            (
                                                // Cuando el mercado esta abierto y la variacion menor a 0
                                                <PorcentajesNegativos>
                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <ArrowDropDownSharpIcon sx={{ color: rojoPorcentaje }} />
                                                        <TextoPorcentajeNegativo>{`${moneda} ${diferenciaMercado}`}</TextoPorcentajeNegativo>
                                                    </div>
                                                </PorcentajesNegativos>)
                                    ) : null
                    }
                </ContainerPorcentajes>
                {
                    (
                        !errorTenencia ?
                            // Si no hay error en la tenencia se muestra el icono para ocultar la variacion/saldo
                            <Visibility>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    size="small"
                                    sx={{ padding: '0 0.3em 0 0' }}
                                >
                                    {showPassword ? (
                                        <VisibilityOffOutlined sx={{ color: primaryColor }} />
                                    ) : (
                                        <VisibilityOutlined sx={{ color: primaryColor }} />
                                    )}
                                </IconButton>
                            </Visibility>
                            :
                            null
                    )
                }
            </SecondContainer>
        </ContainerSaldos>
    )
}

export default InfoMercadoPhone