import React, { useContext } from 'react'
import { DetalleContext } from '../context/detalleEspecieContext/DetalleContext';
import { AuthContext } from '../context/authContext/AuthContext';
import { NavigationContext } from '../context/navigationContext/NavigationContext';
import { useNavigate } from 'react-router-dom';
import { getDataEspecie } from '../api/mercadoApi';
import { TipoMercado, TipoNavigate } from '../enums/enums';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { OperarContext } from '../context/operarContext/OperarContext';
import { formatCurrency } from '../helpers/formatCurrency';
import { formatEspeciesExterior } from '../helpers/formatEspeciesExterior';

const useDetalleEspecieTenencias = () => {

    const { limpiarDatos, setEspecie, getHistorico, getIntradiariosEspecie, getOfertasEspecie } = useContext(DetalleContext);
    const { tokenPublico } = useContext(AuthContext);
    const { setSelectedNavigate } = useContext(NavigationContext);
    const navigate = useNavigate();
    const { tipoMercado } = useContext(MercadoContext);
    const { onChangePrecio, setTextoTitulos } = useContext(OperarContext);


    const onClickEspecie = async (especie: string) => {
        if (tokenPublico) {
            const resp = await getDataEspecie(tokenPublico, [especie])
            const dataEspecie = resp.data.body ? resp.data.body[0] : null
            if (dataEspecie !== null) {
                limpiarDatos();
                setEspecie(dataEspecie);
                getHistorico(dataEspecie?.especie);
                getIntradiariosEspecie(dataEspecie?.especie);
                getOfertasEspecie(dataEspecie?.especie);
                setTextoTitulos(tipoMercado === TipoMercado.Local
                    ? dataEspecie?.simbolo
                    : formatEspeciesExterior(dataEspecie?.especie))
                onChangePrecio(formatCurrency(dataEspecie?.ultimo));
                setSelectedNavigate(TipoNavigate.Mercado)
                navigate(`/mercado/detalleEspecie/${dataEspecie.especie}/${tipoMercado}`);
            }
        }
    }


    return {
        onClickEspecie,
    }
}

export default useDetalleEspecieTenencias