import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
`

export const ContainerTextoInput = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 2.5fr;
    gap: 0.5em;
    margin: 0 0.5em 0.2em 0.5em;
    padding-right: 0.5em;
`;

export const ContainerTenencia = styled.div`
    margin-left: 1em;
`


export const Texto = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0;
    margin-left: 0.5em;
`;


export const Input = styled.input`
    width: 90%;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.primaryColor};
    background-color: transparent;
    margin-left: 1em;
    }
`;

export const TextoDiaria = styled.p`
    margin: 0;
    margin-left: 1em;
    color: ${({ theme }) => theme.primaryColor};
    theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({ theme }) =>
    theme.secondaryBackgroundColor};
`;

export const ContainerDiaria = styled.div`
    box-shadow: inset -4px -4px 4px ${({ theme }) => theme.secondaryBackgroundColor},
    inset 4px 2px 14px ${({ theme }) => theme.secondaryBackgroundColor};
    border: 1px solid rgba(74, 69, 85, 0.2);
    background-color: ${({ theme }) => theme.alternativeBackgroundColor};
    border-radius: 5px;
    display: flex;
    align-items: center;
`;

export const ContainerButtonsPlazo = styled.div`
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    gap: 10px;
`

export const ButtonsPlazo = styled.button`
    font-size: 15px;
    color: ${({ theme }) => theme.primaryColor};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    cursor: pointer;
`

export const TenenciaTotalText = styled.p`
    color: ${({theme}) => theme.primaryColor};
    width: 75%;
    font-size: 0.9em;
    margin: 0.1em 0 0 0;
`
