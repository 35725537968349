import { Box, ListItem, ListItemText } from '@mui/material'
import { useCallback, useContext } from 'react'
import { DetalleContext } from '../../../context/detalleEspecieContext/DetalleContext';
import { DataInterdiarios } from '../../../interfaces/mercadoInferfaces';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { formatCurrency } from '../../../helpers/formatCurrency';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import { Container, ContainerTitulos, Hora, Precio, SinDatos, Volumen } from './elements';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';


const OperacionesEspecie = () => {

    const { intradiarios } = useContext(DetalleContext)
    const reversedIntradiarios: DataInterdiarios[] = intradiarios ? [...intradiarios].reverse() : [];

    const { width, height } = useWindowDimensions();

    const { primaryColor, rojoPorcentaje, buyColor } = useContext(ThemeContext);


    const diferenciasUltimo = (index: number) => {
        if (reversedIntradiarios[index]?.ultimo === reversedIntradiarios[index + 1]?.ultimo) {
            return 0;
        } else if (reversedIntradiarios[index]?.ultimo < reversedIntradiarios[index + 1]?.ultimo) {
            return -1;
        } else if (reversedIntradiarios[index]?.ultimo > reversedIntradiarios[index + 1]?.ultimo) {
            return 1;
        }
    }


    const styles = {
        containerTitulos: {
            display: "flex",
            justifyContent: "space-around",
            textAlign: 'center',
        },
        precio: {
            color: primaryColor,
            display: 'flex',
            justifyContent: 'end',
            width: '3.7em',
            flex: 'inherit',
        },
    }

    const renderItem = useCallback(({ index, style }: ListChildComponentProps) => {
        const item = reversedIntradiarios[index];
        const diferencia = diferenciasUltimo(index);
        return (
            <ListItem sx={{ ...styles.containerTitulos, borderBottom: "1px solid rgba(0, 0, 0, 0.1);", marginTop: '0.5em', padding: '0px' }} style={{ ...style }}>
                {
                    diferencia === 0 ?
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '12px' }}
                            sx={{ ...styles.precio, justifyContent: 'flex-end' }}>
                            {item ? formatCurrency(item.ultimo) : ''}
                        </ListItemText >
                        : diferencia === 1 ?
                            <Box sx={{ ...styles.precio, color: buyColor, justifyContent: 'flex-end' }}>
                                <ArrowDropUpSharpIcon />
                                <ListItemText
                                    sx={{ minWidth: 'unset' }}
                                    primaryTypographyProps={{ fontSize: '12px' }}>
                                    {item ? formatCurrency(item.ultimo) : ''}
                                </ListItemText >
                            </Box>
                            :
                            <Box sx={{ ...styles.precio, color: rojoPorcentaje, justifyContent: 'flex-end', }}>
                                <ArrowDropDownSharpIcon />
                                <ListItemText
                                    sx={{ minWidth: 'unset' }}
                                    primaryTypographyProps={{ fontSize: '12px' }}>
                                    {item ? formatCurrency(item.ultimo) : ''}
                                </ListItemText >
                            </Box>
                }
                <ListItemText primaryTypographyProps={{ fontSize: '12px' }} sx={{ ...styles.precio, width: '2.5em', }}>{item ? item.volnominal : ''}</ListItemText >
                <ListItemText primaryTypographyProps={{ fontSize: '12px' }} sx={{ ...styles.precio, width: '4em', }}>{item ? item.hora : ''}</ListItemText >
            </ListItem >
        )
    }, [reversedIntradiarios]);


    const heightFixedList = () => {
        if (height > 950 && width < 992) return 290;
        if (height > 760 && width < 992) return 240;
        if (height < 761 && width > 992) return 240;
        if (height > 761 && width > 992) return 330;
        return 175
    }


    return (
        <Container>
            <ContainerTitulos>
                <Precio>Precio</Precio>
                <Volumen>Volumen</Volumen>
                <Hora>Hora</Hora>
            </ContainerTitulos>
            {reversedIntradiarios.length ?
                    <FixedSizeList
                        height={heightFixedList()}
                        width={'100%'}
                        itemSize={25}
                        itemCount={reversedIntradiarios ? reversedIntradiarios?.length : 10}
                        overscanCount={5}
                        style={{ 
                            paddingLeft: '0px', 
                            paddingRight: '0px', 
                            flexGrow: 1 
                        }}
                    >
                        {renderItem}
                    </FixedSizeList>
                :
                <SinDatos>
                    No hay datos
                </SinDatos>
            }
        </Container>
    )
}

export default OperacionesEspecie



