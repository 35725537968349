import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0 0.5vw;
    height: 8vh;
    width: 87vw;
    display: flex;
    justify-content: space-between;
    background-color: ${({theme}) => theme.secondaryBackgroundColor};
    z-index: 1;
`;

export const ContainerSecundario = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1em;

`

export const ContainerSwitcher = styled.div`
    width: 23em; 
    height: 2.2em;
`

export const Img = styled.img`
    height: 1.8em; 
    width: 1.8em;
    margin-right: 1em; 
    margin-bottom: 0.1em;
`

export const ContainerTitulo = styled.div`
    display: flex;
    align-items: center;
    margin-left: 1em;
`

export const TitleText = styled.p`
    font-size: 1.8em;
    font-weight: 700;
    color: ${({theme}) => theme.primaryColor};
    margin: 0;
`

export const ContainerAltaCbu = styled.div`
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    border: 2px solid ${({theme}) => theme.primaryColor};
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 0.5em;
`

export const TextoAltaCbu = styled.p`
    margin: 0;
    color: ${({theme}) => theme.primaryColor};
    padding: 0.2em;
`