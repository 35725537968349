import { useContext } from 'react'
import { Button, ButtonsContainer, Linea } from './elements'
import { NotificacionContext, OpcionScreen } from '../../../context/notificacionContext/NotificacionContext';
import { useNavigate } from 'react-router-dom';

const BotonesAlertasNotificaciones = () => {

    const { screen, setScreen } = useContext(NotificacionContext);

    const navigate = useNavigate();


    return (
        <ButtonsContainer>
            <Button
                onClick={
                    () => {
                        setScreen(OpcionScreen.Notificaciones)
                        navigate('/notificaciones');
                    }}
            >
                Noticias
                {
                    screen === "Notificaciones" &&
                    <Linea></Linea>
                }
            </Button>
            <Button
                onClick={() => screen === "Notificaciones" && setScreen(OpcionScreen.Alertas)}
            >
                Alertas
                {
                    screen === "Alertas" &&
                    <Linea></Linea>
                }
            </Button>
        </ButtonsContainer>
    )
}

export default BotonesAlertasNotificaciones