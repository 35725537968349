import { useContext, useEffect } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { Aside } from './Aside/Aside';
import { Tabs } from './Tabs';
import { AuthContext } from '../../context/authContext/AuthContext';
import { useNavigate } from 'react-router-dom';

export const Navigation = () => {

    const { width } = useWindowDimensions();

    const navigate = useNavigate();
    const { status, logOut } = useContext(AuthContext);

    useEffect(() => {
        if (status === "authenticated") {
            let timeout = setTimeout(() => {
                // Ejecutar la acción de cierre de sesión
                logOut();
                navigate('/login');
                window.location.reload();
            }, 3600000); // 1 hora en milisegundos

            // Reiniciar el temporizador en cada interacción del usuario
            const resetTimeout = () => {
                clearTimeout(timeout);
                // Vuelve a iniciar el temporizador
                // Esto asegura que se reinicie el conteo en cada interacción del usuario
                timeout = setTimeout(() => {
                    // Ejecutar la acción de cierre de sesión
                    logOut();
                    navigate('/login');
                    window.location.reload();
                }, 3600000); // 
            };

            // Agrega los eventos de interacción del usuario
            document.addEventListener("mousemove", resetTimeout);
            document.addEventListener("mousedown", resetTimeout);
            document.addEventListener("keypress", resetTimeout);
            document.addEventListener("touchmove", resetTimeout);

            return () => {
                // Limpia los eventos al desmontar el componente
                document.removeEventListener("mousemove", resetTimeout);
                document.removeEventListener("mousedown", resetTimeout);
                document.removeEventListener("keypress", resetTimeout);
                document.removeEventListener("touchmove", resetTimeout);
                clearTimeout(timeout);
            };
        }
    }, [status]);


    return (
        <div>
            {
                width > 992 ?
                    <Aside />
                    :
                    <Tabs />
            }
        </div>
    )
}
