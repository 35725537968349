import styled from "styled-components"


export const Container = styled.div`
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    height: 14em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 10px;
    margin-top: 0.5em;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    color: black;
    @media (min-height: 780px) {
        height: 18em;
        margin-top: 1em;
    }
    @media (min-width: 992px) {
        width: 100%;
        margin-top: 1.6em;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
`

export const ContainerGrafico = styled.div`
width: 100%;
margin-right: 1em;
overflow: inherit;
display: grid;
flex-grow: 1;
`

export const ContainerMargen = styled.div`
display: flex;
justify-content: space-around;
margin-left: 2.4em;
`

export const Margen = styled.p`
margin: 0px;
display: flex;
font-weight: 400;
font-size: 13px;
width: 3.5em;
height: 2em;
justify-content: center;
align-items: center
`