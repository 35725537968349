import apiInstance from '../utils/AxiosApiRava';
import {dataToApi} from '../helpers/dataToApi';
import {RespDDJJ} from '../interfaces/ddjjInterface';

export async function getDDJJOperar(access_token: string) {
  const data = dataToApi({
    access_token,
  });
  return await apiInstance.post<RespDDJJ>('/publico/ddjj/read', data);
}
