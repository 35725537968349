import React, { useState } from 'react'

export const useMenuEspeciesHook = (setActualMenuEspecies: React.Dispatch<React.SetStateAction<string>>) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (op?: string) => {
        setAnchorEl(null);       
        if (op) setActualMenuEspecies(op)
    };

    return {
        open,
        handleClick,
        handleClose,
        anchorEl
    }
}
