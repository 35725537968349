import { useContext } from "react";
import { Routes, Route, BrowserRouter, Navigate, } from "react-router-dom";
import { Login } from "../views/Login/Login";
import { ValidarCuenta } from "../views/ValidarCuenta/ValidarCuenta";
import { Home } from "../views/Home/Home";
import { AuthContext } from "../context/authContext/AuthContext";
import { Pin } from "../views/Pin/Pin";
import { IngresaPin } from "../views/IngresaPin/IngresaPin";
import { Ordenes } from "../views/Ordenes/Ordenes";
import { IngresarDinero } from '../views/IngresarDinero/IngresarDinero';
import { Notificaciones } from "../views/Notificaciones/Notificaciones";
import { DetalleNotificacion } from "../views/DetalleNotificacion/DetalleNotificacion";
import { MisAlertas } from "../views/MisAlertas/MisAlertas";
import { AgregarAlerta } from "../views/AgregarAlerta/AgregarAlerta";
import { Mercado } from "../views/Mercado/Mercado";
import { SearchEspecie } from "../views/SearchEspecie/SearchEspecie";
import { DetalleEspecie } from "../views/DetalleEspecie/DetalleEspecie";
import { Perfil } from "../views/Perfil/Perfil";
import { PreguntasFrecuentes } from "../views/PreguntasFrecuentes/PreguntasFrecuentes";
import { MiBilletera } from "../views/MiBilletera/MiBilletera";
import { RetirarDinero } from "../views/RetirarDinero/RetirarDinero";
import Operar from "../views/Operar/Operar";
import ComprarScreen from "../views/ComprarScreen/ComprarScreen";
import { Navigation } from "../components/NavBar/Navigation";
import { NotFound } from "../views/NotFound/NotFound";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ConfirmarScreenRetirar } from "../components/retirarDineroComponents/ConfirmarScreen/ConfirmarScreenRetirar";
import { Movimientos } from "../views/Movimientos/Movimientos";
import MercadoNuevo from "../views/MercadoNuevo/MercadoNuevo";
import AgregarCuenta from "../views/AgregarCuenta/AgregarCuenta";
import CambiarPin from "../views/CambiarPin/CambiarPin";
import MovimientosTitulos from "../views/MovimientosTitulos/MovimientosTitulos";

export const AppRouter = () => {

	const { status } = useContext(AuthContext);
	const { width } = useWindowDimensions();

	return (
		<BrowserRouter>
			{status === "not-authenticated" ?
				<Routes>
					<Route exact path="/" element={<Navigate to="/login" replace />} />
					<Route path="/login" element={<Login />} />
					<Route path="/validar-cuenta" element={<ValidarCuenta />} />
					<Route path="/restablecer-pin" element={<ValidarCuenta />} />
					<Route path="/genera-pin" element={<Pin />} />
					<Route path="/cambia-pin" element={<Pin />} />
					<Route path="/ingresa-pin" element={<IngresaPin />} />
					<Route path="/cambio-de-pin" element={<IngresaPin />} />
					<Route path="*" element={<Navigate to="/login" replace />} />
				</Routes>
				:
				status === "authenticated" ?
					<>
						<Routes>
							<Route path="/home" element={<Home />} />
							<Route path="/ordenes" element={<Ordenes />} />
							<Route path="/ingresar-dinero" element={<IngresarDinero />} />
							<Route path="/retirar-dinero" element={<RetirarDinero />} />
							<Route path="/confirmar-retirar-dinero" element={<ConfirmarScreenRetirar />} />
							<Route path="/perfil/movimientos" element={<Movimientos />} />
							<Route path="/perfil/movimientos/titulos" element={<MovimientosTitulos />} />
							<Route path="/notificaciones" element={<Notificaciones />} />
							<Route path="/detalle-notificacion" element={<DetalleNotificacion />} />
							<Route path="/mis-alertas" element={<MisAlertas />} />
							<Route path="/agregar-alerta" element={<AgregarAlerta />} />
							{width < 992 ?
								<>
									<Route path="/operar" element={<Operar />} />
									<Route path="/operar/comprarScreen" element={<ComprarScreen />} />
								</>
								: null
							}
							<Route path="/mercado" element={width < 992 ? <Mercado /> : <MercadoNuevo />} />
							<Route path="/mercado/searchEspecie" element={<SearchEspecie />} />
							<Route path="/mercado/detalleEspecie/:especieParams/:mercadoParams" element={<DetalleEspecie />} />
							<Route path="/perfil" element={<Perfil />} />
							<Route path="/perfil/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
							<Route path="/perfil/mi-billetera" element={<MiBilletera />} />
							<Route path="/agregar-cuenta" element={<AgregarCuenta />} />
							<Route path="/cambiar-pin" element={<CambiarPin />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
						<Navigation />
					</>
					: null
			}
		</BrowserRouter>
	);
};
