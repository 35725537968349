import styled from "styled-components";

export const Container = styled.div`
background-color: ${({theme}) => theme.primaryBackgroundColor};
display: flex;
border-radius: 15px;
margin-top: 0.5em;
margin-right: 1em;
margin-left: 1em;
justify-content: space-between;
height: 5em;    
@media (min-height: 780px) {
    margin-top: 1.5em;
}
@media (min-width: 992px) {
    margin-top: 0em;
    height: 6.5em;
    padding: 0.2em 0em 0.3em;
}
`

export const ContainerEspeciePrecio = styled.div`
margin: 0.5em 0em 0.5em 1.5em;
`

export const ConatinerTitlesNombre =  styled.div`
display: flex;
align-items: baseline;
`

export const Titles = styled.p`
margin: 0px;
font-weight: 600;
font-size: 17px;
color: ${({theme}) => theme.primaryColor};
letter-spacing: 0.44px;
@media (min-height: 780px) {
    font-size: 21px;
}
@media (min-width: 992px) {
    font-weight: 700;
    font-size: 24px;
}
`
export const Precio = styled.p`
margin: 0px;
font-weight: 600;
font-size: 17px;
color: ${({theme}) => theme.primaryColor};;
letter-spacing: 0.44px;
@media (min-height: 780px) {
    font-size: 21px;
}
@media (min-width: 992px) {
    font-size: 24px;
}
`


export const Nombre = styled.p`
margin: 0px;
font-weight: 400;
font-size: 19px;
color: ${({theme}) => theme.primaryColor};;
letter-spacing: 0.44px;
margin-left: 0.7em;
`

export const EspeciePrecio = styled.p`
margin: 0px;
font-weight: 600;
font-size: 12px;
color: ${({theme}) => theme.primaryColor};;
letter-spacing: 0.44px;
line-height: 10px;
@media (min-height: 780px) {
    font-size: 14px;
}
@media (min-width: 992px) {
    font-size: 16px
}
`

export const ContainerPorcentajes = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
width: 13em;
margin-bottom: 0.6em;
margin-right: 0.7em;
`

export const ContainerVariaciones = styled.div`
display: flex;
justify-content: space-between;
text-align: center;
`

export const  Variaciones = styled.p`
margin: 0px;
font-weight: 700;
font-size: 15px;
letter-spacing: 0.44px;
width: 4.3em;
@media (min-height: 780px) {
    font-size: 18px;
}
`

export const Porcentajes = styled.p`
margin: 0px;
font-weight: 600;
font-size: 13px;
color: ${({theme}) => theme.accentColor};
letter-spacing: 0.44px;
width: 5em;
@media (min-height: 780px) {
    font-size: 15px;
}
`

export const ContenedorFechaHora = styled.div`
display: flex;
justify-content: flex-end;
`
export const FechaHora = styled.p`
margin: 0px;
font-weight: 400;
font-size: 16px;
color: ${({theme}) => theme.accentColor};
letter-spacing: 0.44px;
margin-bottom: 1em;
margin-right: 0.6em;
`