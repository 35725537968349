import { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { NotificationResp } from "../interfaces/notificacionInterface";
import apiBackInstance from "../utils/AxiosApiBack";

function setToken(token: string) {
	apiBackInstance.interceptors.request.use(async function (
		config: AxiosRequestConfig<any>
	): Promise<InternalAxiosRequestConfig<any>> {
		config.headers = {
			...config.headers,
			authorization: token,
		};
		return config as InternalAxiosRequestConfig<any>;
	});
}
export async function getSentNotifications(token: string) {
	setToken(token);
	return await apiBackInstance.get<NotificationResp>("sentNotifications");
}

export async function notificationsSeen(token: string) {
	setToken(token);
	return await apiBackInstance.put("notificationsSeen");
}
