import { TipoMercado, TipoNavigate } from "../../../enums/enums";
import { useNavigationHook } from "../../../hooks/useNavigationHook";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Button, Container, ContainerTitles, Title } from "./elements"

interface Props {
    mercado: TipoMercado;
}

export const CardSinSaldo = ({mercado}: Props) => {

    const { handleNavigate } = useNavigationHook();
    const { width } = useWindowDimensions();

    return (
        <Container>
            <ContainerTitles>
                {width < 992
                    ? <>
                        <Title>¿Todavía no operaste?</Title>
                        <Button
                            onClick={() => handleNavigate(TipoNavigate.Operar)}>OPERAR</Button>
                    </>
                    : <Title>
                        {`No hay operaciones en el mercado ${mercado === TipoMercado.Local ? 'local' : 'internacional'}`}
                    </Title>
                }
            </ContainerTitles>
        </Container>
    )
}
