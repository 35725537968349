import { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import apiBackInstance from "../utils/AxiosApiBack";

function setToken(token: string) {
	apiBackInstance.interceptors.request.use(async function (
		config: AxiosRequestConfig<any>
	): Promise<InternalAxiosRequestConfig<any>> {
		config.headers = {
			...config.headers,
			authorization: token,
		};
		return config as InternalAxiosRequestConfig<any>;
	});
}
export async function saveDeviceToken(token: string, deviceToken: string) {
	setToken(token);
	return await apiBackInstance.put("saveDeviceToken", {
		deviceToken,
	});
}

export async function alertsSeen(token: string) {
	setToken(token);
	return await apiBackInstance.put("alertsSeen");
}

export async function getUserId(token: string) {
	setToken(token);
	return await apiBackInstance.get("userId");
}
