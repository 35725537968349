import { createContext, useState } from "react";

export enum OpcionScreen {
    Notificaciones = 'Notificaciones',
    Alertas = 'Alertas',
}

type notificacionContextProps = {
    logoOn: boolean,
    setLogoOn: React.Dispatch<React.SetStateAction<boolean>>,
    screen: OpcionScreen,
    setScreen: React.Dispatch<React.SetStateAction<OpcionScreen>>,
}

export const NotificacionContext = createContext({} as notificacionContextProps);


export const NotificacionProvider = ({ children }: any) => {

    const [logoOn, setLogoOn] = useState<boolean>(false);
    const [screen, setScreen] = useState<OpcionScreen>(OpcionScreen.Notificaciones);

    return (
        <NotificacionContext.Provider
            value={{
                logoOn,
                setLogoOn,
                screen,
                setScreen,
            }}>
            {children}
        </NotificacionContext.Provider>
    );
}