import { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { resetPin, signUp } from '../api/userApi';
import { AuthContext } from '../context/authContext/AuthContext';
import { UserContext } from '../context/userContext/UserContext';
import axios, { AxiosError } from 'axios';

interface Props {
  initialForm: formInterface,
  instancia: String,
}

interface formInterface {
  nroCuenta: string,
  dni: string,
  mail: string,
}

export const useValidarCuentaHook = ({ initialForm, instancia }: Props) => {
  const [form, setForm] = useState(initialForm);
  const [error, setError] = useState('');
  const { setDniContext, setNroCuentaContext } = useContext(UserContext);
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  }

  const validate = async () => {
      setError('');
      if (form.nroCuenta === '' || form.mail === '' || form.dni === '') {
      setError('Error. Debe completar todos los campos');
    } else if (!form.mail.includes('@')) {
      setError('Error. El mail es incorrecto');
    } else {
      try {
        const resp = instancia === "validar" ? await signUp(form.mail, parseInt(form.nroCuenta), form.dni)
          : await resetPin(form.mail, parseInt(form.nroCuenta), form.dni);
        if (resp.status === 200) {
          setToken(resp.data.body.token);
          setDniContext(form.dni)
          setNroCuentaContext(form.nroCuenta)
          navigate("/genera-pin");
        } else {
          setError('Error.');
        }
      } catch (error: any | AxiosError) {
        let errorYaCreado = false
        // setLoadingValidate(false);
        if (axios.isAxiosError(error)) {
          let body: string | undefined;
          const responseData = error.response?.data;
          if (responseData && typeof responseData === 'object' && 'body' in responseData) {
            body = typeof responseData.body === "string" ? responseData.body : undefined;
            if (body === "existing user") {
              errorYaCreado = true
              setError("El usuario ya fue creado con anterioridad. Vuelva a la pantalla anterior y restablezca su PIN si no lo recuerda.")
            }
          }
          if (!errorYaCreado) {
            setError('Error en alguno de los campos.');
          }
        }
      }
    }
  };

  return {
    error,
    validate,
    handleChange,
    form
  };
};
