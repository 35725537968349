import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 0em 1em 0;
`;

export const ContainerDDJJ = styled.div`
  height: 10em;
  overflow-y: scroll;
  border-radius: 10px;
  margin: 1em 1.5em 0 1.5em;
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContainerButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5em 1em 0em 1em;
  gap: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
`;

export const BotonComprarVender = styled.button`
  font-weight: 600;
  font-size: 16px;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  border-radius: 5px;
  color: ${({ theme }) => theme.primaryColor};
  height: 1.8em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextoDDJJ = styled.p`
  margin: 0.5em 1em 0.5em 1em;
  font-size: 15px;
  text-align: justify;
  color: ${({ theme }) => theme.fontColorWhiteToBlack};
`;