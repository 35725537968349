import styled from "styled-components";
import { colors } from "../../theme/Themes";
import Button from '@mui/material/Button';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    /* align-items: center; */
    background-color:  ${({theme}) => theme.alternativeBackgroundColor};
    justify-content: center;
`;

export const SecondContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 3em;
    @media (min-width: 992px) {
		width: 40%;
        margin-top: 4em;
	}
`;


export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
    width: 100%;
`;

export const LabelForm = styled.label`
    font-size: 1em;
    color: ${({theme}) => theme.primaryColor};
    margin-bottom: 0.3em;
    margin-top: 1em;
`

export const Img = styled.img`
    margin-bottom: 1em;  
    @media (min-width: 992px) {
        margin-bottom: 4em;
	} 
`;

export const Error = styled.p`
margin: 0;
color: ${({theme}) => theme.errorColor};
width: 100%; 
text-align: center;
@media (min-width: 992px) {
} 
`

export const LinksContainer = styled.div`
    margin-top: 1em;
    @media (min-width: 992px) {
        margin-top: 2em;
	}
`;


export const ButtonContainer = styled.div`
    width: 10%;
    position: relative;
    bottom: 2.4em;
    left: 82%;
    margin: 0;
    height: 0.5em;
    /* tab-index: -1; */
    @media (min-width: 992px){
        left: 91%; 
    }
`

export const TextoPrimeraVez = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: ${({theme}) => theme.primaryColor};
    text-align: center;
    @media (min-width: 992px) {
        font-size: 1rem;
    }
`
export const PrimeraVezContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
	}
`;

export const TextoPin = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: ${({theme}) => theme.primaryColor};
    text-align: center;
    @media (min-width: 992px) {
        font-size: 1rem;
        margin-left: 0.5em;
    }
`

export const TextoOlvidastePin = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: ${({theme}) => theme.primaryColor};
    text-align: center;
    @media (min-width: 992px) {
        font-size: 1rem;
    }
`



export const BotonAceptar = () => (
    <Button type="submit"
        variant="contained"
        sx={{
            width: "100%",
            bgcolor: colors.blueRava,
            '&:hover': {
                bgcolor: colors.blueRava,
            },
            '&:active': {
                bgcolor: colors.blueRava,
            },
            marginTop: '1em',
            fontSize: '14px',
            fontWeight: 600,
            '@media (min-width: 992px)': {
                width: '50%'
            }
        }} > ACEPTAR </ Button>
);