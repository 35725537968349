import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    background-color: ${({theme}) => theme.contextMenuBackgroundColor};
    border-radius: 10px;
    margin: 0.5em 1em 0.5em;
    height: 2em;
    align-items: center;
    @media (min-width: 992px){
        // margin-top: 1em;
        height: 33px;
        margin-left: 1em;
        margin-right: 1em;
        width: 30%;
`   