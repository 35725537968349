import { useContext } from 'react'
import { CircularProgress } from '@mui/material';
import { Container, LoadingContainer, ContainerInfo, ContainerSinMovimientos, SecondContainer, TextoSinMovimientos, BotonTitulos, TextoBotonTitulos, ContainerHeader, ContainerTexto, Texto } from './elements';
import { ThemeContext } from 'styled-components';
import { useMovimientosHook } from '../../hooks/useMovimientosHook';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';
import HeaderMovimientos from '../../components/MovimientosComponents/HeaderMovmientos/HeaderMovimientos';
import { useNavigate } from 'react-router-dom';
import TablaMovimientos from '../../components/MovimientosComponents/TablaMovimientos/TablaMovimientos';
import TitulosMovimientos from '../../components/MovimientosComponents/TitulosMovimientos/TitulosMovimientos';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import MenuPopUp from '../../components/MenuPopUp/MenuPopUp';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';


export const Movimientos = () => {

    const navigate = useNavigate();

    const { width } = useWindowDimensions();

    const {
        primaryColor,
        loadingColor,
    } = useContext(ThemeContext);

    const {
        loadingMovimientos,
        formatearCadena,
        traerDocumentos,
        loadingDescarga,
        fechaDesde,
        setFechaDesde,
        fechaHasta,
        setFechaHasta,
        buscarMovimientos,
        monedas,
        handleMenus,
        dataEspecies,
        setEspecieSeleccionada,
        movimientosFiltrados,
        tiposObjeto,
        handleOpenMenuMovimientos,
        handleCloseMenuMovimientos,
        movimientoSeleccionado,
        open,
        anchorEl,
        listaTipoMovimientos,
    } = useMovimientosHook('Movimientos cuenta corriente');


    return (
        <Container>

            <ResponsiveHeader title={width < 992 ? 'Movimientos' : 'Movimientos de cuenta corriente'} showSwitch={false} />

            <SecondContainer>
                {width < 992
                    ? <>
                        <ContainerTexto onClick={handleOpenMenuMovimientos}>
                            <Texto>{movimientoSeleccionado}</Texto>
                            <KeyboardArrowRightRoundedIcon
                                sx={{
                                    color: primaryColor,
                                    marginRight: '0.4em',
                                    marginTop: '0.2em',
                                    cursor: 'pointer',
                                    fontSize: '32px',
                                }} />
                        </ContainerTexto>
                        <MenuPopUp
                            open={open}
                            anchorEl={anchorEl}
                            handleCloseMenu={handleCloseMenuMovimientos}
                            listaMenu={listaTipoMovimientos}
                            actualMenu={movimientoSeleccionado}
                        />
                    </>
                    : <ContainerHeader>
                        <HeaderMovimientos
                            fechaDesde={fechaDesde}
                            fechaHasta={fechaHasta}
                            setFechaDesde={setFechaDesde}
                            setFechaHasta={setFechaHasta}
                            buscarMovimientos={buscarMovimientos}
                            monedas={monedas}
                            tipos={tiposObjeto}
                            handleMenus={handleMenus}
                            especies={dataEspecies}
                            setEspecieSeleccionada={setEspecieSeleccionada}
                        />
                        <BotonTitulos
                            onClick={() => navigate('/perfil/movimientos/titulos')}
                        >
                            <TextoBotonTitulos>MOVIMIENTOS DE TÍTULOS</TextoBotonTitulos>
                        </BotonTitulos>
                    </ContainerHeader>
                }
                <ContainerInfo>
                    <TitulosMovimientos />
                    {
                        loadingMovimientos ?
                            <LoadingContainer>
                                <CircularProgress style={{ color: loadingColor }} />
                            </LoadingContainer>
                            :
                            movimientosFiltrados.length ?
                                <TablaMovimientos
                                    movimientosFiltrados={movimientosFiltrados}
                                    formatearCadena={formatearCadena}
                                    traerDocumentos={traerDocumentos}
                                    loadingDescarga={loadingDescarga}
                                />
                                :
                                <ContainerSinMovimientos>
                                    <TextoSinMovimientos>
                                        No hay movimientos para mostrar
                                    </TextoSinMovimientos>
                                </ContainerSinMovimientos>
                    }
                </ContainerInfo>
            </SecondContainer>
        </Container>
    )
}
