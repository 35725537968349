import { DataEspecie } from '../../../interfaces/mercadoInferfaces';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import { useCardEspecieHook } from '../../../hooks/useCardEspecieHook';
import { useModalHook } from '../../../hooks/useModalHook';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import { Container, ContainerSecundario, ContainerUltimoVariacion, Hora, NombreEspecie, UltimoVariacion } from './elements';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';

interface Props {
    especie: DataEspecie;
}

const CardEspecieExterior = ({ especie }: Props) => {

    const { esFav, addFav, deleteFav, cutHour, onClickEspecies } = useCardEspecieHook(especie);
    const { open, handleOpen, handleClose, handleAceptarModal } = useModalHook(deleteFav);

    const { primaryColor, secundaryBorderColor, buyColor, rojoPorcentaje } = useContext(ThemeContext);


    return (
        <Container>
            <div style={{ display: "flex" }}>
                {esFav ?
                    <div style={{ display: 'flex' }}>
                        <StarRoundedIcon
                            onClick={handleOpen}
                            sx={{
                                color: primaryColor,
                                border: `0.1px solid ${secundaryBorderColor}`,
                                borderRadius: "20px",
                                padding: "3px",
                                marginLeft: "0.3em",
                                cursor: 'pointer',
                            }}
                        />
                        <ModalConfirmacion
                            open={open}
                            handleClose={handleClose}
                            handleConfirmarModal={handleAceptarModal}
                            tituloModal={'Eliminar'}
                            textoModal={`¿Quieres eliminar ${especie.nombre} de tus favoritos?`}
                            textoConfirmarModal={'Eliminar'}
                        />
                    </div>
                    :
                    <StarBorderRoundedIcon
                        onClick={() => addFav()}
                        sx={{
                            color: primaryColor,
                            border: `0.1px solid ${secundaryBorderColor}`,
                            borderRadius: "20px",
                            padding: "3px",
                            marginLeft: "0.3em",
                            cursor: 'pointer',
                        }}
                    />
                }
            </div>
            <ContainerSecundario>
                <div>
                    <NombreEspecie onClick={(e) => onClickEspecies(e)}>{formatEspeciesExterior(especie.especie)}</NombreEspecie>
                </div>
                <div style={{ display: "flex" }}>
                    <ContainerUltimoVariacion>
                        <UltimoVariacion>{formatCurrency(especie.ultimo)}</UltimoVariacion>
                        <UltimoVariacion
                            style={{
                                color: especie.variacion > 0 ? buyColor : especie.variacion < 0 ? rojoPorcentaje : primaryColor
                            }}
                        >
                            {especie.variacion ? formatPorcentaje(especie.variacion) + '%' : '- %'}
                        </UltimoVariacion>
                    </ContainerUltimoVariacion>
                    <div>
                        <Hora>{cutHour(especie.hora)}</Hora>
                    </div>
                </div>
            </ContainerSecundario>
        </Container>
    )
}

export default CardEspecieExterior
