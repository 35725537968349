import styled from "styled-components";
import { colors } from "../../../theme/Themes";

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1.2em 0;
    @media (min-width: 992px) { 
        background-color: ${colors.blueRava};
        padding: 1em 0 1em 0;
        border-radius: 7px;
        margin: 0 1em 0 1em;
        height: 2.5em;
    }
`;

export const Button = styled.button`
    width: 50%;
    background: none;
    border: none;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
    @media (min-width: 992px){
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const Linea = styled.div`
    width: 6em;
    height: 0.1em;
    margin-left: 2.2em;
    margin-top: 0.5em;
    background-color: white;
    @media (min-width: 992px){
        display: flex;
        justify-content: center;
        width: 25%;
        margin-left: 0;
        height: 0.2em;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-top: 0.2em;
    }
`;