export function formatCurrency(x: number | null | string) {
    if (x === null) {
        return '';
    } else {
        
        let numberSeparado = Number(x).toFixed(2).toString().split('.');
        //let numberSeparado = x.toString().split('.');
        
        if (numberSeparado.length > 1) {
            numberSeparado[1] = numberSeparado[1].substring(0, 2);
        }
        let numeroDosDecimales = numberSeparado.join(',');
        
        
        return numeroDosDecimales.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}