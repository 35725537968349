import { useContext } from 'react'
import { DataEspecie } from '../interfaces/mercadoInferfaces';
import { TipoNavigate, TipoOperacion } from '../enums/enums';
import { useNavigationHook } from './useNavigationHook';
import { OperarContext } from '../context/operarContext/OperarContext';
import { useSearchEspecieOperarHook } from './useSearchEspecieOperarHook';
import useWindowDimensions from './useWindowDimensions';

export const useOperarHook = () => {

    const { handleNavigate } = useNavigationHook();
    const { setTextoTitulos, setActualOption, setDisabled, setChoiced, setPrecio, setCantidad } = useContext(OperarContext);
    const { searchEspecieFromName } = useSearchEspecieOperarHook();

    const { width } = useWindowDimensions();

    const onClickCompraVenta = (
        especie: DataEspecie | undefined,
        operacion: TipoOperacion,
        precio: string | number,
        cantidad: string | number,
    ) => {
        if (especie) {

            setTextoTitulos(especie.simbolo);
            setChoiced(
                searchEspecieFromName(
                    (especie.simbolo)
                ))
        }
        setDisabled(false);
        setPrecio(String(precio));
        setCantidad(String(cantidad));
        setActualOption(operacion);
        if (width < 922) {
            handleNavigate(TipoNavigate.Operar);
        }
    }


    return {
        onClickCompraVenta
    }
}
