import React, { useContext, useEffect } from 'react'
import { AcordionContainer, Container, PreguntaText, RespuestaText } from './elements'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material"
import { UserContext } from '../../context/userContext/UserContext';
import { AuthContext } from '../../context/authContext/AuthContext';
import { getPregFrecuentes } from '../../api/userApi';
import { ThemeContext } from 'styled-components';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    padding: '4px 8px 4px 12px',
    backgroundColor: 'unset',
    [theme.breakpoints.up('lg')]: {
        padding: '0.5em',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '16px 20px',
    backgroundColor: 'unset;',
}));

export const PreguntasFrecuentes = () => {

    const { pregFrecuentes, setPregFrecuentes } = useContext(UserContext);

    const [expanded, setExpanded] = React.useState<string | false>('');

    const { setToken } = useContext(AuthContext);

    const [loading, setLoading] = React.useState<boolean>(false);

    const themeContext = useContext(ThemeContext)

    const { secondaryBackgroundColor, loadingColor, primaryBackgroundColor } = themeContext;

    const refresh = async () => {
        setLoading(true);
        const tokenStorage = localStorage.getItem("token");
        tokenStorage && setToken(tokenStorage);
        if (tokenStorage) {
            try {
                const resp = await getPregFrecuentes(tokenStorage);
                setPregFrecuentes(resp.data.body.preguntasFrecuentes);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        refresh();
    }, [])


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <Container>

            <ResponsiveHeader title='Preguntas frecuentes' showSwitch={false} />

            <AcordionContainer>
                {
                    loading ?
                        <Box sx={{ display: "flex", justifyContent: "center", margin: "0.5em", alignItems: 'center', height: '90%' }}>
                            <CircularProgress style={{ color: loadingColor }} />
                        </Box>
                        :
                        pregFrecuentes?.map((e) =>
                            <Accordion expanded={expanded === e.preg} onChange={handleChange(e.preg)} key={e.preg}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: secondaryBackgroundColor, borderBottom: `2px solid #CACACA`, }}>
                                    <PreguntaText>{e.preg}</PreguntaText>
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: primaryBackgroundColor, borderBottom: `2px solid #CACACA`, }}>
                                    <RespuestaText>{e.resp}</RespuestaText>
                                </AccordionDetails>
                            </Accordion>
                        )
                }
            </AcordionContainer>
        </Container>
    )
}