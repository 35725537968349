import styled from "styled-components";
import { colors } from "../../theme/Themes";


export const Container = styled.div`
flex: 1;
height: 100vh;
background-color: ${({theme}) => theme.secondaryBackgroundColor};
@media (min-width: 992px) {
    margin-top: 8vh;
    margin-left: 12vw;
    height: 92vh;
    width: 87vw;
    padding: 0vh 0.5vw 0 0.5vw;
}
`

export const ContainerEspecie = styled.div`
margin: 1.5em 1em 0;
background-color: ${({theme}) => theme.primaryBackgroundColor};
border-radius: 7px;
height: 4.5em;
@media (min-width: 992px) {
    margin: 1em 1em 0;
    height: 100%;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);

}
`

export const ContenedorPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    height: 99%;
`

export const TextoError = styled.p`
padding: 0.8em;
text-align: center;
color: ${({theme}) => theme.rojoPorcentaje};
`