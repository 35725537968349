import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle'
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

interface Props {
    open: boolean;
    texto: string;
    handleClose: () => void;
}

const AlertErrorDatosOperar = ({ open, texto, handleClose }: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '20px',
                    backgroundColor: primaryBackgroundColor,
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: primaryBackgroundColor,
                    color: primaryColor,
                    padding: '1em 1em 0.5em 1em',
                    fontSize: '18px',
                    display: 'flex',
                    textAlign: 'center',
                }}
            >
                {texto}
            </DialogTitle>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    style={{
                        color: primaryColor,
                        backgroundColor: primaryBackgroundColor,
                    }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default AlertErrorDatosOperar