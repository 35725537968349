import React from 'react'
import { TipoOpcionGrafico } from '../../../enums/enums'
import { Container, ContainerButton, FontStylesNotSelected, FontStylesSelected } from './elements'

interface Props {
    actualOption: TipoOpcionGrafico,
    setActualOption: React.Dispatch<React.SetStateAction<TipoOpcionGrafico>>
}

const BotonesGraficOperaciones = ({ actualOption, setActualOption }: Props) => {
    return (
        <div>
            <Container>
                <ContainerButton onClick={() => setActualOption(TipoOpcionGrafico.Grafico)}>
                    {actualOption === TipoOpcionGrafico.Grafico ? 
                    <FontStylesSelected>Gráfico</FontStylesSelected> 
                    : 
                    <FontStylesNotSelected>Gráfico</FontStylesNotSelected>
                    }
                </ContainerButton>
                <ContainerButton onClick={() => setActualOption(TipoOpcionGrafico.Operaciones)}>
                    {actualOption === TipoOpcionGrafico.Operaciones ? 
                    <FontStylesSelected>Operaciones</FontStylesSelected> 
                    : 
                    <FontStylesNotSelected>Operaciones</FontStylesNotSelected>
                    }
                </ContainerButton>
            </Container>
        </div>
    )
}

export default BotonesGraficOperaciones