import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Icon } from "@iconify/react";
import { AgregarAlertaButton, AgregarAlertaText, BoxContainer, Container, MisAlertasContainer, MisAlertasText } from './elements'
import { useListaAlertasHook } from '../../hooks/useAlertasHook';
import { AlertaCard } from '../../components/notificacionesComponents/AlertaCard/AlertaCard';
import { TipoAlerta, TipoMercado } from '../../enums/enums';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import BotonesAlertasNotificaciones from '../../components/notificacionesComponents/BotonesAlertasNotificaciones/BotonesAlertasNotificaciones';
import { ThemeContext } from 'styled-components';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';


export const MisAlertas = () => {

  const themeContext = useContext(ThemeContext);

  const { primaryColor } = themeContext;

  const navigate = useNavigate();

  const { 
    deleteAlert,
    eliminarPrecio,
    alertasExterior,
    alertasLocales,
    preciosActualesAlertasLocal,
    preciosActualesAlertasExterior, 
    tipoMercado,
  } = useListaAlertasHook();

  const { width } = useWindowDimensions();

  return (
    <Container>

      <ResponsiveHeader title='Configuración' showSwitch={true} />

      {
        width > 992 ?
          <BotonesAlertasNotificaciones />
          :
          null
      }


      <BoxContainer>
        <MisAlertasContainer>
          <MisAlertasText>Mis alertas</MisAlertasText>
        </MisAlertasContainer>
        <AgregarAlertaButton
          onClick={() => navigate("/agregar-alerta", {
            state: {
              editarAlerta: false,
              alerta: null,
            }
          })}
        >
          <AgregarAlertaText>Agregar alerta</AgregarAlertaText>
          <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '1.2em', height: '1.2em', color: primaryColor }} />
        </AgregarAlertaButton>

        {tipoMercado === TipoMercado.Local
          ? alertasLocales.map((alerta, idx) => (
          <AlertaCard
            deleteAlert={deleteAlert}
            eliminarPrecio={eliminarPrecio}
            idx={idx}
            key={alerta._id}
            alerta={alerta}
            tipoAlerta={
              alerta.tipoAlerta === 'SOPORTE'
                ? TipoAlerta.Soporte
                : TipoAlerta.Resistencia
            }
            especie={alerta.especie}
            precio={alerta.precio}
            precioActual={preciosActualesAlertasLocal[idx]}
            panel={alerta.panel}
            opciones={alerta.opciones}
            id={alerta._id}
          />
        ))
        :
        alertasExterior.map((alerta, idx) => (
          <AlertaCard
            deleteAlert={deleteAlert}
            eliminarPrecio={eliminarPrecio}
            idx={idx}
            key={alerta._id}
            alerta={alerta}
            tipoAlerta={
              alerta.tipoAlerta === 'SOPORTE'
                ? TipoAlerta.Soporte
                : TipoAlerta.Resistencia
            }
            especie={alerta.especie}
            precio={alerta.precio}
            precioActual={preciosActualesAlertasExterior[idx]}
            panel={alerta.panel}
            opciones={alerta.opciones}
            id={alerta._id}
            />
        ))
      }
      </BoxContainer>
    </Container>
  )
}
