import { BottomNavigation, BottomNavigationAction, Box, Button } from "@mui/material";
import { Icon } from '@iconify/react';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { TipoNavigate } from "../../enums/enums";
import { useNavigationHook } from "../../hooks/useNavigationHook";
import { useContext } from "react";
import { NavigationContext } from "../../context/navigationContext/NavigationContext";
import { ThemeContext } from "styled-components";


export const Tabs = () => {

    const { selectedNavigate } = useContext(NavigationContext);
    const { handleNavigate } = useNavigationHook();

    const { primaryBackgroundColor, primaryColor, textColorOperarButton } = useContext(ThemeContext);

    const styles = {
        container: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '8vh',
            backgroundColor: primaryBackgroundColor,
        },
        buttomContainer: {
            backgroundColor: primaryColor,
            padding: '7px',
            width: '114px',
            borderRadius: '50px',
            position: 'absolute',
            left: 'calc(50% - 120px/2 + 117.5px)',
            top: 'calc(50% - 60px/2 - 20.5px)'
        },
        buttom: {
            color: textColorOperarButton,
        },
        colorSelected: {
            color: primaryColor,
        },
        colorNotSelected: {
            color: 'grey'
        }
    }

    return (
        <BottomNavigation sx={styles.container}>
            <Box>
                <BottomNavigationAction
                    label="Inicio"
                    sx={{ color: selectedNavigate === TipoNavigate.Home ? styles.colorSelected.color : styles.colorNotSelected.color }}
                    showLabel
                    onClick={() => handleNavigate(TipoNavigate.Home)}
                    disableRipple
                    icon={
                        <Icon
                            icon="tabler:home"
                            fontSize={25}
                            color={selectedNavigate === TipoNavigate.Home ? styles.colorSelected.color : styles.colorNotSelected.color}
                        />}
                />
                <BottomNavigationAction
                    label='Mercado'
                    sx={{ color: selectedNavigate === TipoNavigate.Mercado ? styles.colorSelected.color : styles.colorNotSelected.color }}
                    showLabel
                    onClick={() => handleNavigate(TipoNavigate.Mercado)}
                    disableRipple
                    icon={
                        <Icon
                            icon="mdi:finance"
                            fontSize={25}
                            color={selectedNavigate === TipoNavigate.Mercado ? styles.colorSelected.color : styles.colorNotSelected.color}
                        />}
                />
                <BottomNavigationAction
                    label='Órdenes'
                    sx={{ color: selectedNavigate === TipoNavigate.Ordenes ? styles.colorSelected.color : styles.colorNotSelected.color }}
                    showLabel
                    onClick={() => handleNavigate(TipoNavigate.Ordenes)}
                    disableRipple
                    icon={
                        <Icon
                            icon="mdi:monitor-dashboard"
                            fontSize={25}
                            color={selectedNavigate === TipoNavigate.Ordenes ? styles.colorSelected.color : styles.colorNotSelected.color}
                        />}
                />
            </Box>
            <Box sx={styles.buttomContainer}>
                <Button
                    sx={styles.buttom}
                    disableRipple
                    onClick={() => handleNavigate(TipoNavigate.Operar)}
                >
                    <KeyboardArrowUpRoundedIcon sx={{ marginRight: '10px' }} /> Operar
                </Button>
            </Box>
        </BottomNavigation>
    )
}

