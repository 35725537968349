import React from 'react'
import { BotonComprarVender, Container, ContainerButtons, ContainerDDJJ, TextoDDJJ } from './elements'

interface Props {
    getTextoDDJJ: () => string[]
    setAceptoDDJJ: (value: React.SetStateAction<boolean>) => void
    setCancelo: (value: React.SetStateAction<boolean>) => void
}

const ConfirmarDDJJ = ({ getTextoDDJJ, setAceptoDDJJ, setCancelo }: Props) => {
    return (
        <Container>
            <ContainerDDJJ>
                <div style={{ borderRadius: '10px' }}>
                    {getTextoDDJJ().map((texto, i) => (
                        <TextoDDJJ key={i}>{texto}</TextoDDJJ>
                    ))
                    }
                </div>
            </ContainerDDJJ>
            <ContainerButtons>
                <BotonComprarVender
                    onClick={() => setAceptoDDJJ(true)}
                >ACEPTO</BotonComprarVender>
                <BotonComprarVender
                    onClick={() => setCancelo(true)}
                >CANCELAR</BotonComprarVender>
            </ContainerButtons>
        </Container>
    )
}

export default ConfirmarDDJJ