import { useContext } from 'react'
import { Container, ContainerLabels, ContainerTitulos, TextoSinCuentas, TextoSolicitarAltaCbu } from './elements'
import { Titulo } from '../../../views/RetirarDinero/elements'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ThemeContext } from 'styled-components'
import { RetirarDineroContext } from '../../../context/retirarDineroContext/RetirarDineroContext'
import { useRetirarDinero } from '../../../hooks/useRetirarDinero'
import { Cuenta } from '../../../interfaces/getCuentasInterface'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import AddIcon from '@mui/icons-material/Add';

interface Props {
    cuentas: Cuenta[];
}

export const CustomRadioButtons = ({ cuentas }: Props) => {

    const { primaryColor } = useContext(ThemeContext);
    const { handleChangeRadioButton, cuentaBancariaSeleccionada, moneda, loadingCuentas } = useContext(RetirarDineroContext);
    const { cutBankName } = useRetirarDinero();
    const navigate = useNavigate();

    const { width } = useWindowDimensions();

    return (
        moneda !== '' ?
            <Container>
                <ContainerTitulos>
                    <Titulo>Seleccionar cuenta</Titulo>
                    {
                        width > 992
                            ? <TextoSolicitarAltaCbu
                                onClick={
                                    () => navigate("/agregar-cuenta")
                                }
                            >
                                Solicitar el alta de CBU
                            </TextoSolicitarAltaCbu>
                            : <AddIcon
                                onClick={() => navigate('/agregar-cuenta')}
                                style={{
                                    color: primaryColor,
                                    padding: '0.4em',
                                    fontSize: '1.8em',
                                }}
                            />
                    }

                </ContainerTitulos>
                {
                    !loadingCuentas ?
                        <ContainerLabels>
                            {!cuentas.length ?
                                <TextoSinCuentas>No tenes ninguna cuenta asociada. <br></br> Comunicate con tu asesor</TextoSinCuentas>
                                :
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={cuentaBancariaSeleccionada?.['CBU-CVU']}
                                        onChange={handleChangeRadioButton}
                                    >
                                        {cuentas.map((c) => (
                                            moneda === c.Moneda || (moneda === 'DÓLAR LOCAL' && c.Moneda === 'DOLARES') ?
                                                <FormControlLabel
                                                    sx={{ color: primaryColor, marginLeft: '0.1em' }}
                                                    key={c['CBU-CVU']}
                                                    value={c['CBU-CVU']}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: primaryColor,
                                                                '&.Mui-checked': {
                                                                    color: primaryColor,
                                                                }
                                                            }} />}
                                                    label={`BANCO ${cutBankName(c.Banco)} ****${c['CBU-CVU'].slice(-4)}`} />
                                                :
                                                null
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            }
                        </ContainerLabels>
                        :
                        null
                }
            </Container >
            : null
    )
}
