import { Box } from '@mui/material'
import { TipoMercado, TipoSwitcher } from '../../../enums/enums';
import { SwitchMercado } from '../../SwitchMercado/SwitchMercado';
import { Container, ContainerSwitcher, Titulo } from './elements';
import { useSocketConnection } from "../../../hooks/useSocketHook";
import SwitchTheme from '../../SwitchTheme/SwitchTheme';
import { useContext } from 'react';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';

type Props = {
    titulo: string;
    showSwitch: boolean;
    esDetalleEspecie?: boolean;
}

export const Headers = ({ titulo, showSwitch, esDetalleEspecie }: Props) => {

    useSocketConnection();

    const { tipoMercado } = useContext(MercadoContext);

    const banderas = {
        local: require('../../../images/bandera-argentina-cuenta.png'),
        exterior: require('../../../images/bandera-usa.png'),
    }

    return (
        <Container>
            <ContainerSwitcher>
                <SwitchTheme />
            </ContainerSwitcher>
            <Box>
                <Titulo>{titulo}</Titulo>
            </Box>
            {
                showSwitch ?
                    <Box>
                        <SwitchMercado type={TipoSwitcher.NavBar} />
                    </Box> :
                    null
            }


            {esDetalleEspecie ?
                <img
                    style={{ height: '1.8em', position: 'absolute', right: '1.5em' }}
                    src={tipoMercado === TipoMercado.Local ? banderas.local : banderas.exterior}
                    alt='bandera'
                />
                : null
            }

        </Container>
    )
}
