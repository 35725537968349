import { useContext } from 'react'
import { DetalleContext } from '../../../context/detalleEspecieContext/DetalleContext';
import { formatCurrency } from '../../../helpers/formatCurrency';
import Lottie from 'lottie-react';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import { TipoMercado } from '../../../enums/enums';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import { ConatinerTitlesNombre, Container, ContainerEspeciePrecio, ContainerPorcentajes, ContainerVariaciones, ContenedorFechaHora, EspeciePrecio, FechaHora, Nombre, Porcentajes, Precio, Titles, Variaciones } from './elements';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import { ThemeContext } from 'styled-components';
import { ThemeContext as ContextTheme } from '../../../context/themeContext/ThemeContext';
import { Theme } from '../../../enums/theme';

const CardDetalleEspecie = () => {

    const { especie, loading } = useContext(DetalleContext);
    const { tipoMercado } = useContext(MercadoContext);
    const { width } = useWindowDimensions();

    const { rojoPorcentaje, primaryColor, buyColor } = useContext(ThemeContext);

    const { theme } = useContext(ContextTheme);

    const date = () => {
        let date = new Date();

        let correctMonth;

        let day = date.getDate();
        let month = date.getMonth() + 1;

        if (month < 10) {
            correctMonth = `0${month}`;
        } else {
            correctMonth = `${month}`;
        }
        if (day < 10) {
            return `0${day}/${correctMonth}`;
        } else {
            return `${day}/${correctMonth}`;
        }
    };


    return (
        <Container>
            {
                loading ?
                    <Lottie
                        animationData={
                            theme === Theme.Light
                                ? require('../../../jsons-animation/loading-pagina-completa.json')
                                : require('../../../jsons-animation/loading-pagina-completa-dark.json')}
                        autoPlay={true}
                        loop={true}
                        style={{
                            display: 'flex',
                            height: '5em',
                        }}
                    /> :
                    (
                        <>
                            <ContainerEspeciePrecio>
                                <ConatinerTitlesNombre>
                                    {tipoMercado === TipoMercado.Local ?
                                        <Titles>
                                            {especie?.simbolo}
                                        </Titles>
                                        :
                                        <Titles>
                                            {formatEspeciesExterior(especie ? especie?.especie : '')}
                                        </Titles>
                                    }

                                    {width > 992 ?
                                        <Nombre>
                                            {especie?.nombre}
                                        </Nombre>
                                        : null
                                    }
                                </ConatinerTitlesNombre>

                                {width < 992 ?
                                    <EspeciePrecio>
                                        Especie
                                    </EspeciePrecio>
                                    : null
                                }
                                <Precio>
                                    {especie?.ultimo ? formatCurrency(especie.ultimo) : '-'}
                                </Precio>
                                <EspeciePrecio>
                                    Precio
                                </EspeciePrecio>
                            </ContainerEspeciePrecio>
                            <ContainerPorcentajes>
                                {width > 992 ?
                                    <ContenedorFechaHora>
                                        <FechaHora>
                                            {date()}
                                        </FechaHora>
                                        <FechaHora>
                                            {especie?.hora.slice(0, 5)}
                                        </FechaHora>
                                    </ContenedorFechaHora>
                                    : null
                                }
                                <ContainerVariaciones>
                                    <Porcentajes>
                                        %día
                                    </Porcentajes>
                                    <Porcentajes>
                                        %mes
                                    </Porcentajes>
                                    <Porcentajes>
                                        %año
                                    </Porcentajes>
                                </ContainerVariaciones>
                                <ContainerVariaciones>
                                    <Variaciones
                                        style={{
                                            color: especie?.variacion ? especie?.variacion > 0 ? buyColor : especie.variacion < 0 ? rojoPorcentaje : primaryColor : primaryColor
                                        }}>
                                        {especie?.variacion ? formatPorcentaje(especie.variacion) : '-'}
                                    </Variaciones>
                                    <Variaciones
                                        style={{
                                            color: especie?.varMTD ? especie?.varMTD > 0 ? buyColor : especie.varMTD < 0 ? rojoPorcentaje : primaryColor : primaryColor
                                        }}>
                                        {especie?.varMTD ? formatPorcentaje(especie.varMTD) : '-'}
                                    </Variaciones>
                                    <Variaciones
                                        style={{
                                            color: especie?.varYTD ? especie?.varYTD > 0 ? buyColor : especie.varYTD < 0 ? rojoPorcentaje : primaryColor : primaryColor
                                        }}>
                                        {especie?.varYTD ? formatPorcentaje(especie.varYTD) : '-'}
                                    </Variaciones>
                                </ContainerVariaciones>
                            </ContainerPorcentajes>
                        </>
                    )}
        </Container>
    )
}

export default CardDetalleEspecie
