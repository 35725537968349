import React from 'react'
import { DatoIngresarDinero } from '../interfaces/cardIngresarDineroInterface';

export const useAlertCopyToClipboardHook = (datos: DatoIngresarDinero[]) => {

    const [open, setOpen] = React.useState(false);

    // Abre el popUp
    const handleClickOpen = () => {
        setOpen(true);
        copyToClipboard();
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    // copia los datos en el portaPapeles
    const copyToClipboard = async () => {
        let returnText = '';
        datos.forEach(element => {
            returnText = returnText + element.titulo + ': ' + element.data + '\n';
        });
        await navigator.clipboard.writeText(returnText);
    };

    return {
        open,
        handleClickOpen,
    }
}

