import styled from "styled-components";

export const CardContainer = styled.div`
background-color:  ${({theme}) => theme.primaryBackgroundColor};
margin-bottom: 1.5em;
padding: 0.7em;
border-radius: 5px;
@media (min-width: 992px) {
    width: 47%;
}
`

export const TituloContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 2em;
@media (min-width: 992px) {
    margin: 1em 1em 1em 1em;
}
`

export const Texto = styled.p`
margin: 0px;
color: ${({theme}) => theme.primaryColor};
font-size: 15px;
@media (min-width: 992px) {
    font-size: 17px;
}
`

export const Titulo = styled.p`
margin: 0px;
color: ${({theme}) => theme.primaryColor};
font-size: 15px;
font-weight: 700;
@media (min-width: 992px) {
    font-size: 22px;
}
`

export const InfoContainer = styled.div`
margin-bottom: 1em;
@media (min-width: 992px) {
    margin: 0 1em 1em 1em;
}
`

export const DatosContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`