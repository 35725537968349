import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justifyContent: center;
    marginRight: 0.8em;
`;

export const ContainerMercados = styled.div`
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: space-between;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 0.3em;
    position: absolute;
    right: 15px;
    top: 13px;
    width: 4em;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
`;

export const ContainerPorcentaje = styled.div`
    display: flex;
    align-items: center;    
`

export const Img = styled.img`
    width: 30%;
    height: 30%;
    margin-left: 0.3em;
`;


export const Porcentaje = styled.p`
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({theme}) => theme.primaryColor};
    margin: 0px;
    margin-right: 0.3em;
    margin-left: 0.6em;
`

export const ArrowContainer = styled.div`
    display: flex;
    align-self: flex-start;
    margin-top: 0.2em;
`