import React, { useContext } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { ContainerInput, Input } from '../../../views/AgregarAlerta/elements';
import { Box } from '@mui/material';
import { ThemeContext } from 'styled-components';

interface Props {
    listaEspeciesTitulos: string[] | undefined,
    handleClickEspecie: (event: React.MouseEvent<HTMLElement>) => void,
    especieSeleccionada: string | undefined,
    handleButtonClickEspecie: (opcion: string | null) => void,
}

export const TercerMenu = ({ listaEspeciesTitulos, handleClickEspecie, especieSeleccionada, handleButtonClickEspecie}: Props) => {

     
    const themeContext = useContext(ThemeContext)
    const { primaryColor, grayToDarkGrayBackground, hoverMenuBackrgound } = themeContext;

    const styles = {
        MenuItem: {
            backgroundColor: grayToDarkGrayBackground,
            borderBottom: `0.2px solid ${grayToDarkGrayBackground}`,
            padding: '0.9em',
            width: '11em',
            color: primaryColor,
            
            '&:hover': {
                backgroundColor: `${hoverMenuBackrgound} !important`,
            }
        },
    }

    return (
        <div>
            <Autocomplete
                sx={{ width: "100%", }}
                componentsProps={{ popper: { style: { width: 'fit-content', },  placement: 'bottom-start'  } }}
                ListboxProps={{ style: { padding: 0, borderRadius: "4px",} }}
                options={listaEspeciesTitulos ? listaEspeciesTitulos.map((option, index) => ({
                    label: option,
                    value: option,
                    key: index,
                })) : []}
                filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                        option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                    )
                }
                isOptionEqualToValue={(option, value) => option.label === value.label}
                noOptionsText="No hay coincidencias"
                onChange={(e, newValue) =>
                    newValue && handleButtonClickEspecie(newValue.value)
                }
                disableListWrap
                renderOption={(props, option) => (
                    <Box component="li" {...props} sx={styles.MenuItem} key={option.key}>
                        {option.label}
                    </Box>
                  )}
                renderInput={(params) => (
                    <ContainerInput
                        ref={params.InputProps.ref}
                    >
                        <Input
                            type="text"
                            {...params.inputProps}
                            style={{ width: "100%"}}
                        />
                    </ContainerInput>
                )}
            />
        </div>
    )
}

