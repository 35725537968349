import ReactInputVerificationCode from "react-input-verification-code";
import { Button } from '@mui/material';
import { colors } from '../../theme/Themes';
import { useIngresarPinHook } from '../../hooks/useIngresarPinHook';
import { ButtonContainer, ContentContainer, ErrorContainer, StyledReactInputVerificationCode, TextContainer, Text, Container } from './elements';
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { ContainerSwitcher } from '../../elements/containerSwitcher';
import SwitchTheme from '../../components/SwitchTheme/SwitchTheme';
import { useContext } from "react";
import { AuthContext } from "../../context/authContext/AuthContext";

export const IngresaPin = () => {
    let url = window.location.href;
    let instancia = url.includes("ingresa-pin") ? "ingresa" : "cambio";

    const { width } = useWindowDimensions();
    const { setPinGeneradoRava } = useContext(AuthContext);
    const {
        error,
        pinValue,
        setPinValue,
        continuar,
        primerPin
    } =
        useIngresarPinHook(instancia);


    return (
        <Container>
            <ContainerSwitcher>
                <SwitchTheme />
            </ContainerSwitcher>
            <ContentContainer>
                <TextContainer>

                    {width < 992 ?
                        <h4>
                            {instancia === 'ingresa' ? 'GENERACIÓN DE PIN'
                                : primerPin === "" ? 'CAMBIO DE PIN' : 'REPETIR PIN'}
                        </h4>
                        :
                        <h2>
                            {instancia === 'ingresa' ? 'Generación de PIN'
                                : primerPin === "" ? 'Cambio de PIN' : 'Repetir PIN'}
                        </h2>
                    }

                    <Text>
                        {instancia === 'ingresa' ? 'Ingresá el PIN que recibiste.' :
                            primerPin === "" ? 'Escribí el nuevo PIN' : 'Introducí nuevamente el PIN que elegiste'}
                    </Text>

                </TextContainer>
                <StyledReactInputVerificationCode>
                    <ReactInputVerificationCode
                        length={6}
                        onCompleted={(newValue: any) => {
                            setPinValue(newValue);
                            setPinGeneradoRava(newValue);
                        }}
                        value={pinValue}
                    />
                </StyledReactInputVerificationCode>
            </ContentContainer>

            {error ? (
                <ErrorContainer>
                    <p>{error}</p>
                </ErrorContainer>
            ) : null}

            <ButtonContainer>
                <Button
                    variant="contained"
                    sx={{
                        width: "60%",
                        backgroundColor: !pinValue || pinValue.includes("·") ? 'gray' : colors.blueRava,
                        marginTop: "1em",
                        color: 'white',
                        '@media (min-width: 992px)': {
                            width: "15%",
                        }
                    }}
                    disabled={!pinValue || pinValue.includes("·") ? true : false}
                    onClick={continuar}
                >

                    SIGUIENTE
                </Button>
            </ButtonContainer>

        </Container>

    )
}