import { Dialog, DialogTitle } from '@mui/material';
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components';

interface Props {
    open: boolean,
    texto: string
}

const AlertCopyToClipboard = ({ open, texto }: Props) => {

    const themeContext = useContext(ThemeContext)

    const { primaryBackgroundColor, primaryColor } = themeContext;

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '20px',
                    backgroundColor: primaryBackgroundColor,
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor:  primaryBackgroundColor, color: primaryColor, padding: '1.2em' }}>
                {texto}
            </DialogTitle>
        </Dialog >
    )
}

export default AlertCopyToClipboard