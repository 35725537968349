import React, { useContext, useEffect, useState } from 'react'
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { TipoMercado, TipoNavigate } from '../enums/enums';
import { DatosClaveEspecie } from '../interfaces/nombreEspeciesInterfaces';
import { DataEspecieOpciones } from '../interfaces/mercadoInferfaces';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '../context/navigationContext/NavigationContext';


export const useSearchMercadoHook = (dataOpciones?: DataEspecieOpciones[] | null) => {

    const { dataLocal, dataExterior, tipoMercado, setEspecieSearch, especieOpciones } = useContext(MercadoContext);
    const [text, setText] = useState('');
    const [filterData, setFilterData] = useState<DatosClaveEspecie[]>([])
    const [filterDataOpciones, setFilterDataOpciones] = useState<DataEspecieOpciones[]>([])
    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
    const [anchorElMenuOpciones, setAnchorElMenuOpciones] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorElMenu);
    const openMenuOpciones = Boolean(anchorElMenuOpciones);
    const [actualMenuOpciones, setActualMenuOpciones] = useState(
        especieOpciones ? especieOpciones[0].especie : '',
    );
    
    const navigate = useNavigate();
    const { setSelectedNavigate } = useContext(NavigationContext);


    const handleChangeInputPhone = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value.length) {
            if (!dataOpciones) {
                setAnchorElMenu(e.currentTarget)
            } else {
                setAnchorElMenuOpciones(e.currentTarget)
            }
        } else {
            setAnchorElMenu(e.currentTarget)
            setAnchorElMenuOpciones(e.currentTarget)
        }
        setText(e.target.value.toLocaleUpperCase());
    }

    const handleOpenMenuOpciones = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorElMenuOpciones(e.currentTarget)
    }

    const handleCloseMenu = (especie?: DatosClaveEspecie) => {
        if (especie) {
            setEspecieSearch(especie)
            setText(especie.especie)
            setSelectedNavigate(TipoNavigate.Mercado)
            navigate('/mercado/searchEspecie');
        }
        setAnchorElMenu(null);
    };

    const handleCloseMenuOpciones = (especie?: string) => {
        if (especie) setActualMenuOpciones(especie)
        setAnchorElMenuOpciones(null);
    };

    useEffect(() => {
        const filter = (tipoMercado === TipoMercado.Local) ?
            dataLocal?.filter(d => d.especie.toLocaleLowerCase().startsWith(text.toLocaleLowerCase()))
            :
            dataExterior?.filter(d => d.especie.toLocaleLowerCase().startsWith(text.toLocaleLowerCase()));
        if (filter) setFilterData(filter.slice(0, 50));
    }, [text])

    useEffect(() => {
        filterOpciones();
    }, [actualMenuOpciones, dataOpciones]);

    function filterOpciones() {
        let newArr: DataEspecieOpciones[] = [];
        if (dataOpciones) {
            dataOpciones.forEach(element => {
                if (element.especieopciones === actualMenuOpciones) {
                    newArr.push(element);
                }
            });
        }
        setFilterDataOpciones(newArr);
    }


    return {
        text,
        setText,
        filterData,
        filterDataOpciones,
        handleChangeInputPhone,
        openMenu,
        anchorElMenu,
        handleCloseMenu,
        openMenuOpciones,
        anchorElMenuOpciones,
        handleCloseMenuOpciones,
        handleOpenMenuOpciones,
        actualMenuOpciones
    }
}