
export interface CustomTheme {
	primaryBackgroundColor: string;
	secondaryBackgroundColor: string;
	alternativeBackgroundColor: string;
	alterHomeBackgrooundColor: string;
	primaryColor: string;
	secondaryColor: string;
	buyColor: string;
	sellColor: string;
	accentColor: string;
	alternativeAccentColor: string
	errorColor: string;
	fontColorWhiteToBlack: string;
	fontColorWhiteToBlue: string;
	primaryBorderColor: string;
	secundaryBorderColor: string;
	tabBarColor: string;
	backgroundColorOperarButton: string
	textColorOperarButton: string
	circuloVenderBackground: string
	arrowUpColor: string
	arrowDownColor: string
	contextMenuBackgroundColor: string
	alternativeBorderColor: string
	redToWhiteColor: string
	grayToDarkGrayBackground: string
	tipoAlertaColor: string
	bordesMercado: string
	containerCardExterior: string
	rojoPorcentaje: string
	colorBottomContainerMercado: string
	loadingColor: string;
	backgroundColorButtonsHome: string;
	hoverMenuBackrgound: string;
	backgroundColorButtonsGrafico: string;
	backgroundWhiteToGray: string;
	selectedAside: string;
	alterBackgroundCardsHome: string;
	amarilloCirculoMercado: string;
	verdeCambioPrecios: string;
	rojoCambioPrecios: string;
	azulCambioCantidades: string;
	colorSelectedAside: string;
	colorNotSelectedAside: string;
	backgroundMercadoNuevo: string;
}

const LightTheme: CustomTheme = {
	primaryBackgroundColor: 'white',
	secondaryBackgroundColor: '#F3F1F1',
	alternativeBackgroundColor: 'white',
	alterHomeBackgrooundColor: '#00386E0D',
	grayToDarkGrayBackground: '#F3F1F1',
	primaryColor: '#00386E',
	secondaryColor: 'white',
	buyColor: '#03DAC5',
	sellColor: '#B00020',
	accentColor: '#8A8A8A',
	alternativeAccentColor: "#3C3C4399",
	errorColor: '#B00020',
	fontColorWhiteToBlack: 'black',
	fontColorWhiteToBlue: 'white',
	primaryBorderColor: '#00386E7D',
	secundaryBorderColor: '#E7E7E7',
	alternativeBorderColor: '#c9c9c9',
	tabBarColor: "white",
	backgroundColorOperarButton: '#00386E',
	textColorOperarButton: "white",
	circuloVenderBackground: '#DFF9F6',
	arrowUpColor: '#00386E',
	arrowDownColor: '#00386E',
	contextMenuBackgroundColor: "rgba(118, 118, 128, 0.1)",
	redToWhiteColor: '#B00020',
	tipoAlertaColor: "#77777E",
	bordesMercado: "#d3d3d3",
	containerCardExterior: "#F5F5F5",
	rojoPorcentaje: "#B00020",
	colorBottomContainerMercado: "#F5F5F5",
	loadingColor: '#004B6F',
	backgroundColorButtonsHome: '#00386E',
	hoverMenuBackrgound: '#F7F7F7',
	backgroundColorButtonsGrafico: '#F2F2F2',
	backgroundWhiteToGray: 'white',
	selectedAside: 'rgba(180, 215, 238, 0.56)',
	alterBackgroundCardsHome: '#6082A31D',
	amarilloCirculoMercado: '#FBC02D',
	verdeCambioPrecios: '#C2F0EE',
	rojoCambioPrecios: '#D998A6',
	azulCambioCantidades: '#C2D0DF',
	colorSelectedAside: '#00386E',
	colorNotSelectedAside: 'gray',
	backgroundMercadoNuevo: 'rgba(138, 138, 138, 0.2)',
};

const DarkTheme: CustomTheme = {
	primaryBackgroundColor: '#48494D',
	secondaryBackgroundColor: '#282B30',
	alternativeBackgroundColor: '#282B30',
	alterHomeBackgrooundColor: '#48494D0D',
	grayToDarkGrayBackground: '#48494D',
	primaryColor: 'white',
	secondaryColor: 'white',
	buyColor: '#03DAC5',
	sellColor: '#B00020',
	accentColor: 'white',
	alternativeAccentColor: "#D9D9D9",
	errorColor: '#FF375C',
	fontColorWhiteToBlack: 'white',
	fontColorWhiteToBlue: '#00386E',
	primaryBorderColor: '#FFFFFF7D',
	secundaryBorderColor: 'transparent',
	alternativeBorderColor: '#FFFFFF7D',
	tabBarColor: "#48494D",
	backgroundColorOperarButton: "white",
	textColorOperarButton: "black",
	circuloVenderBackground: 'transparent',
	arrowUpColor: '#03DAC5',
	arrowDownColor: "#FF375C",
	contextMenuBackgroundColor: "#48494D",
	redToWhiteColor: 'white',
	tipoAlertaColor: "white",
	bordesMercado: "gray",
	containerCardExterior: "#3F4043",
	rojoPorcentaje: "#FF375C",
	colorBottomContainerMercado: "#3F4043",
	loadingColor: 'white',
	backgroundColorButtonsHome: '#00386E',
	hoverMenuBackrgound: '#262626',
	backgroundColorButtonsGrafico: '#282B30',
	backgroundWhiteToGray: '#8A8A8A',
	selectedAside: 'rgba(247, 247, 247, 0.56)',
	alterBackgroundCardsHome: '#282B30',
	amarilloCirculoMercado: '#FFEA00',
	verdeCambioPrecios: '#19716C',
	rojoCambioPrecios: '#5E1A2A',
	azulCambioCantidades: '#40546D',
	colorSelectedAside: 'rgba(72, 73, 77, 1)',
	colorNotSelectedAside: 'white',
	backgroundMercadoNuevo: 'rgba(215, 214, 214, 0.2)',
};

export const Themes = {
	light: LightTheme,
	dark: DarkTheme,
};

export const colors = {
	blueRava: '#00386E',
	grayRava: '#8A8A8A',
	error: '#B00020',
	success: '#03DAC5',
	secondBlue: '#005785',
}