import styled from "styled-components";


export const Container = styled.div`
flex: 1;
height: 100vh;
background-color:  ${({ theme }) => theme.secondaryBackgroundColor};
@media (min-width: 992px) {
    margin-top: 8vh;
    margin-left: 12vw;
    height: 92vh;
    width: 87vw;
    padding: 0vh 0.5vw 0 0.5vw;
}
`

export const ContainerTexto = styled.div`
display: flex;
margin: 1em;
justify-content: space-between;
background-color: ${({ theme }) => theme.primaryBackgroundColor};
border-radius: 10px;
cursor: pointer;
`

export const Texto = styled.p`
margin: 0;
padding: 0.8em;
font-size: 15px;
font-weight: 700;
color: ${({ theme }) => theme.primaryColor};
`

export const ContainerInfo = styled.div`
display: flex;
flex-direction: column;
height: 100%;

`

export const ContainerSinMovimientos = styled.div`
display: flex;
justify-content: center;
margin-top: 3em;
`

export const TextoSinMovimientos = styled.p`
font-size: 18px;
color: ${({ theme }) => theme.accentColor};
font-weight: 600;
`

export const SecondContainer = styled.div`
display: flex;
flex-direction: column;
height: 99%;
`

export const LoadingContainer = styled.div`
display: flex; 
justify-content: center; 
align-items: center;
height: 100%;
`
export const BotonTitulos = styled.div`
    color: white;
    border-radius: 5px;
    background-color: #00386E;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.primaryColor};
    margin-right: 0.5em;
`

export const TextoBotonTitulos = styled.p`
    padding: 0.4em;
    margin: 0;
    letter-spacing: 1px;
`

export const ContainerHeader = styled.div`
display: flex;
justify-content: space-between; 
align-items: center;
`