import styled from "styled-components";


export const Container = styled.div`
    background-color: ${({theme}) => theme.secondaryBackgroundColor};
    height: 95vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
		width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
	}
`;

export const TituloGrafico = styled.p`
font-size: 15px;
margin: 1.5em 0px  0px 2em;
font-weight: 600;
color: ${({theme}) => theme.primaryColor};
@media (min-width: 992px) {
    font-size: 17px;
    margin: 1.13em 0 0 2em;
}
`

export const RowContainer = styled.div`
    @media (min-width: 992px) {
        display: flex;
        height: 100%;
        width: 100%;
	}
`
export const OperarContainer = styled.div`
    @media (min-width: 992px) {
        display: flex;
        padding: 0em 1.4em 0em 2.5em;
        width: 40%;
	}
`

export const ContainerPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    height: 99%;
`

export const ContainerGraficoCardOfertas = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 0.1em;
`