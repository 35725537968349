import { TipoMercado, TipoNavigate, TipoOpcion, TipoOperacion } from "../../../enums/enums";
import { formatCurrency } from "../../../helpers/formatCurrency";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { formatEspeciesExterior } from "../../../helpers/formatEspeciesExterior";
import { BotonVender, Container, ContainerBotonPrecio, ContainerEspecieCantidad, ContainerPorcentajeActual, ContainerPorcentajeHistorico, ContainerPrecioCantidadTotal, ContainerPrecioPorcentaje, CostoHistorico, PorcentajeCero, PorcentajeCeroHistorico, PorcentajeNegativo, PorcentajePositivo, PrecioCosto, TextoCantidad, TextoEspecie, TotalPrecioCantidad } from "./elements";
import { useContext } from "react";
import { OperarContext } from "../../../context/operarContext/OperarContext";
import { useNavigationHook } from "../../../hooks/useNavigationHook";
import { useSearchEspecieOperarHook } from "../../../hooks/useSearchEspecieOperarHook";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { separacionDecimal } from "../../../helpers/separacionDecimal";
import { formatPorcentaje } from "../../../helpers/formatPorcentaje";
import { Icon } from "@iconify/react";
import { ThemeContext } from "styled-components";
import { ThemeContext as ContextTheme } from "../../../context/themeContext/ThemeContext";
import { Theme } from "../../../enums/theme";
import { MercadoContext } from "../../../context/mercadoContext/MercadoContext";
import useDetalleEspecieTenencias from "../../../hooks/useDetalleEspecieTenencias";


interface Props {
    porcentaje: number | null;
    precio: number | null;
    cantidad: number;
    importe: string | number;
    especie: string;
    tipoOpcion: TipoOpcion;
    costo: number;
    variacionCosto: number | null;
    pintar?: boolean;
}

export const CardEspecieCartera = ({ porcentaje, precio, cantidad, importe, especie, tipoOpcion, costo, variacionCosto, pintar }: Props) => {

    const { handleNavigate } = useNavigationHook();
    const { setTextoTitulos, setActualOption, setDisabled, setChoiced, setCantidad } = useContext(OperarContext);
    const { searchEspecieFromName } = useSearchEspecieOperarHook();
    const { width } = useWindowDimensions();
    const {onClickEspecie} = useDetalleEspecieTenencias();

    const { primaryBackgroundColor, alterHomeBackgrooundColor, rojoPorcentaje } = useContext(ThemeContext);

    const { theme } = useContext(ContextTheme);

    const { tipoMercado } = useContext(MercadoContext)

    const onClickVender = () => {
        setTextoTitulos(formatEspeciesExterior(especie));
        setCantidad(String(cantidad))
        setChoiced(
            searchEspecieFromName(
                formatEspeciesExterior(especie)
            ))
        setDisabled(false);
        setActualOption(TipoOperacion.Venta);
        if (width < 992) handleNavigate(TipoNavigate.Operar);
    }

    return (
        <Container
            style={{
                backgroundColor: width > 992 && pintar ? alterHomeBackgrooundColor : primaryBackgroundColor,
            }}
        >

            {/* Nombre de las especies y Porcentajes de variaciones actuales */}
            <ContainerEspecieCantidad>
                <TextoEspecie
                    onClick={() => onClickEspecie(especie)}
                >
                    {formatEspeciesExterior(especie)}
                </TextoEspecie>
                {
                    tipoOpcion === TipoOpcion.Actual && porcentaje !== null ?
                        (
                            <ContainerPorcentajeActual>
                                {
                                    porcentaje === 0 ?
                                        <PorcentajeCero>-%</PorcentajeCero>
                                        : porcentaje > 0 ?
                                            <PorcentajePositivo>{formatPorcentaje(porcentaje)}%</PorcentajePositivo>
                                            :
                                            <PorcentajeNegativo>{formatPorcentaje(porcentaje)}%</PorcentajeNegativo>
                                }
                            </ContainerPorcentajeActual>
                        )
                        : tipoOpcion === TipoOpcion.Historico && variacionCosto !== null ?
                            (
                                <ContainerPorcentajeHistorico>
                                    {
                                        variacionCosto === 0 ?
                                            <div>
                                                <PorcentajeCeroHistorico>-%</PorcentajeCeroHistorico>
                                            </div>
                                            : variacionCosto > 0 ?
                                                (
                                                    <>
                                                        <PorcentajePositivo>{formatPorcentaje(variacionCosto)}%</PorcentajePositivo>
                                                        <ArrowDropUpIcon sx={{ color: '#03DAC5' }} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <PorcentajeNegativo>{formatPorcentaje(variacionCosto)}%</PorcentajeNegativo>
                                                        <ArrowDropDownIcon sx={{ color: rojoPorcentaje }} />
                                                    </>
                                                )
                                    }
                                </ContainerPorcentajeHistorico>
                            )
                            :
                            null
                }
            </ContainerEspecieCantidad>

            {/* Precios y Cantidades de las especies */}
            <ContainerPrecioCantidadTotal style={{ alignItems: tipoOpcion === TipoOpcion.Actual ? "flex-start" : "center" }}>
                <TextoCantidad>{separacionDecimal(cantidad)}</TextoCantidad>
                <ContainerPrecioPorcentaje>
                    {
                        tipoOpcion === TipoOpcion.Actual ?
                            <PrecioCosto>
                                {
                                    tipoMercado === TipoMercado.Local
                                        ? `$${formatCurrency(precio)}`
                                        : `USD ${formatCurrency(precio)}`
                                }
                            </PrecioCosto>
                            :
                            <CostoHistorico>
                                {
                                    tipoMercado === TipoMercado.Local
                                        ? `$${formatCurrency(costo)}`
                                        : `USD ${formatCurrency(costo)}`
                                }
                            </CostoHistorico>
                    }
                    {
                        tipoOpcion === TipoOpcion.Actual ?
                            <TotalPrecioCantidad>
                                {
                                    tipoMercado === TipoMercado.Local
                                        ? `$${formatCurrency(importe)}`
                                        : `USD ${formatCurrency(importe)}`
                                }
                            </TotalPrecioCantidad>
                            :
                            null
                    }
                </ContainerPrecioPorcentaje>
            </ContainerPrecioCantidadTotal>

            {/* Boton de Vender */}
            <ContainerBotonPrecio>
                <div>
                    {especie !== 'DOLA'
                        ? <BotonVender
                            style={{
                                boxShadow: theme === Theme.Light ? '0px 1px 3px rgba(0, 0, 0, 0.2)' : undefined,
                                gap: '2px',
                            }}
                            onClick={onClickVender}
                        >
                            {
                                width > 992 ?
                                    'VENDER'
                                    :
                                    null
                            }
                            <Icon icon="iconamoon:arrow-right-6-circle" style={{ color: '#01AE9D', fontSize: '2.5em' }} />
                        </BotonVender>
                        : <BotonVender style={{ color: 'transparent', backgroundColor: "transparent", gap: '2px', cursor: 'auto' }}>
                            {
                                width > 992 ?
                                    'VENDER'
                                    :
                                    null
                            }
                            <Icon icon="iconamoon:arrow-right-6-circle" style={{ color: 'transparent', fontSize: '2.5em' }} />
                        </BotonVender>
                    }
                </div>
            </ContainerBotonPrecio>
        </Container>
    )
}
