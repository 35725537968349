import styled from "styled-components";


export const Container = styled.div`
background-color: ${({theme}) => theme.primaryBackgroundColor};
margin-left: 1em;
margin-right: 1em;
border-radius: 10px;
margin-top: 0.5em;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
@media (min-height: 750px) {
    height: 18em;
    margin-top: 1em;
}
@media (min-width: 992px) {
    width: 99%;
    height: 100%;
    margin: 1.8em 0 0 1em;
    display: flex;
    flex-direction: column;
}
`

export const ContainerTitulos = styled.div`
display: flex;
justify-content: space-around;
text-align: center;
@media (min-width: 992px) {
    padding-right: 1em;
}
`

export const Precio = styled.p`
margin: 0px;
width: 4.8em;
font-weight: 600;
font-size: 14px;
color: ${({theme}) => theme.alternativeAccentColor};
margin-top: 1em;
text-align: end; 
@media (min-width: 992px) {
    font-size: 15px;
    width: 4.5em;
}
`


export const Volumen = styled.p`
margin: 0px;
width: 7em;
font-weight: 600;
font-size: 14px;
color: ${({theme}) => theme.alternativeAccentColor};
margin-top: 1em;
text-align: start;
@media (min-width: 992px) {
    font-size: 15px;
    width: 7.2em;
}
`

export const Hora = styled.p`
margin: 0px;
width: 3em;
font-weight: 600;
font-size: 14px;
color: ${({theme}) => theme.alternativeAccentColor};
margin-top: 1em;
text-align: start;
@media (min-width: 992px) {
    font-size: 15px;
    width: 2.7em;
    text-align: start;  
}
`

export const SinDatos = styled.div`
display: flex;
justify-content: center;
margin-top: 3em;
font-weight: 600;
font-size: 16px;
color: ${({theme}) => theme.alternativeAccentColor};
`
