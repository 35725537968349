import { useContext } from 'react'
import { ContainerTitulos, ContainterCirculo, TextoDatos } from './elements'
import { ThemeContext } from 'styled-components';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { Order } from '../../../interfaces/ordersInterface';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
    data: Order[] | undefined;
    formatEstado: (estado: string) => string
}

const OrdenesDelDia = ({ data, formatEstado }: Props) => {

    const { primaryBackgroundColor, alterBackgroundCardsHome, rojoPorcentaje, buyColor, amarilloCirculoMercado} = useContext(ThemeContext);

    return (
        <div>
            {data &&
                data.map((d, i: number) =>
                    <ContainerTitulos
                        key={i}
                        style={{
                            backgroundColor: i % 2 !== 0
                                ? primaryBackgroundColor
                                : alterBackgroundCardsHome
                        }}
                    >
                        <TextoDatos
                            style={{
                                textAlign: 'start',
                                fontWeight: 700,
                            }}
                        >
                            {formatEspeciesExterior(d.ticker)}
                        </TextoDatos>
                        <TextoDatos style={{ textAlign: 'center' }}>{d.tipoOrden}</TextoDatos>
                        <TextoDatos>{separacionDecimal(Number(d.cantidad))}</TextoDatos>
                        <TextoDatos>{formatCurrency(d.precio)}</TextoDatos>

                        <ContainterCirculo>
                            <CircleIcon
                                style={{
                                    color: formatEstado(d.estado) === 'C'
                                        ? rojoPorcentaje
                                        : formatEstado(d.estado) === 'T'
                                            ? buyColor
                                            : amarilloCirculoMercado,
                                    fontSize: '12px',
                                }}
                            />
                        </ContainterCirculo>
                    </ContainerTitulos>
                )}
        </div>
    )
}

export default OrdenesDelDia