import { Box } from '@mui/material'
import { TipoMercado, TipoNavigate, TipoOpcionGrafico } from '../../enums/enums'
import CardDetalleEspecie from '../../components/detalleEspecieComponents/CardDetalleEspecie/CardDetalleEspecie'
import GraficoEspecie from '../../components/detalleEspecieComponents/GraficoEspecie/GraficoEspecie'
import CardOfertas from '../../components/detalleEspecieComponents/CardOfertas/CardOfertas'
import BotonesGraficoOperaciones from '../../components/detalleEspecieComponents/BotonesGraficoOperaciones/BotonesGraficOperaciones'
import { useContext, useEffect, useState } from 'react'
import OperacionesEspecie from '../../components/detalleEspecieComponents/OperacionesEspecie/OperacionesEspecie'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import { Container, ContainerGraficoCardOfertas, ContainerPrincipal, OperarContainer, RowContainer, TituloGrafico } from './elements'
import { DetalleContext } from '../../context/detalleEspecieContext/DetalleContext'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { AuthContext } from '../../context/authContext/AuthContext'
import { getDataEspecie, getHistoricoEspecie, getIntradiarios } from '../../api/mercadoApi'
import { NavigationContext } from '../../context/navigationContext/NavigationContext'
import { useParams } from 'react-router-dom'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import OperarNuevo from '../OperarNuevo/OperarNuevo'


export const DetalleEspecie = () => {

    const [actualOption, setActualOption] = useState<TipoOpcionGrafico>(TipoOpcionGrafico.Grafico);
    const { tipoMercado, setTipoMercado } = useContext(MercadoContext);
    const { setEspecie, setDataGrafico, setIntradiarios, setLoading, getOfertasEspecie } = useContext(DetalleContext);
    const { setTokenPublico } = useContext(AuthContext);
    const { setSelectedNavigate } = useContext(NavigationContext);

    const { width } = useWindowDimensions();

    const { especieParams, mercadoParams } = useParams();

    useEffect(() => {
        const refresh = async () => {
            setSelectedNavigate(TipoNavigate.Mercado)
            setLoading(true);
            mercadoParams === TipoMercado.Local ? setTipoMercado(TipoMercado.Local) : setTipoMercado(TipoMercado.Exterior)
            const tokenStoragePublico = localStorage.getItem("tokenPublico");
            tokenStoragePublico && setTokenPublico(tokenStoragePublico);
            if (especieParams && tokenStoragePublico) {
                try {
                    const resp = await Promise.all([
                        getDataEspecie(tokenStoragePublico, [especieParams]),
                        getHistoricoEspecie(tokenStoragePublico, especieParams),
                        getIntradiarios(tokenStoragePublico, especieParams),
                    ])
                    setEspecie(resp[0].data.body[0]);
                    setDataGrafico(resp[1].data.body);
                    setIntradiarios(resp[2].data.body);
                    getOfertasEspecie(especieParams)
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            }
        }
        refresh()
    }, [])




    return (
        <Container>

            <ResponsiveHeader title='Mercado' showSwitch={false} esDetalleEspecie={true} />

            {
                width > 992 ?
                    <ContainerPrincipal>
                        <div>
                            <CardDetalleEspecie />
                            {tipoMercado === TipoMercado.Local ?
                                <BotonesGraficoOperaciones
                                    actualOption={actualOption}
                                    setActualOption={setActualOption}
                                />
                                :
                                <TituloGrafico>Gráfico</TituloGrafico>
                            }
                        </div>
                        {tipoMercado === TipoMercado.Local ?
                            <RowContainer>
                                <ContainerGraficoCardOfertas>
                                    {actualOption === TipoOpcionGrafico.Grafico ?
                                        <GraficoEspecie />
                                        :
                                        <OperacionesEspecie />
                                    }
                                    <CardOfertas />
                                </ContainerGraficoCardOfertas>
                                <OperarContainer>
                                    <OperarNuevo
                                        esDetalleEspecie={true}
                                    />
                                </OperarContainer>
                            </RowContainer>
                            :
                            <RowContainer>
                                <ContainerGraficoCardOfertas>
                                    <GraficoEspecie />
                                </ContainerGraficoCardOfertas>
                                <OperarContainer>
                                    <OperarNuevo
                                        esDetalleEspecie={true}
                                    />
                                </OperarContainer>
                            </RowContainer>
                        }
                    </ContainerPrincipal>
                    :
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto', width: '100%' }}>
                        <CardDetalleEspecie />
                        {tipoMercado === TipoMercado.Local ?
                            <>
                                <BotonesGraficoOperaciones
                                    actualOption={actualOption}
                                    setActualOption={setActualOption}
                                />
                                {actualOption === TipoOpcionGrafico.Grafico ?
                                    <GraficoEspecie />
                                    :
                                    <OperacionesEspecie />
                                }
                                <CardOfertas />
                            </>
                            :
                            <>
                                <TituloGrafico>Gráfico</TituloGrafico>
                                <GraficoEspecie />
                            </>
                        }
                    </Box>
            }


        </Container>
    )
}

