import React, { ChangeEvent, useContext } from 'react'
import { ContenedorElementosCentrados, ContenedorElementosCentradosCompraVenta, Hora, Numeros, SecondaryContainer, Texto, Variacion } from './elements'
import StarFavoritos from '../StarFavoritos/StarFavoritos'
import { ThemeContext } from 'styled-components'
import { formatCurrency } from '../../../helpers/formatCurrency'
import { formatPorcentaje } from '../../../helpers/formatPorcentaje'
import { separacionDecimal } from '../../../helpers/separacionDecimal'
import { DataEspecie, DataEspecieOpciones } from '../../../interfaces/mercadoInferfaces'
import { useCardEspecieHook } from '../../../hooks/useCardEspecieHook'
import { useModalHook } from '../../../hooks/useModalHook'
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext'
import { TipoMercado } from '../../../enums/enums'
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior'
import QueryStatsIcon from '@mui/icons-material/QueryStats';

interface Props {
    especie: DataEspecie | DataEspecieOpciones;
    index: number;
    cargarDatosOperar: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    style: React.CSSProperties;
    colorUltimo?: string;
    colorCantCompra?: string;
    colorPrecioCompra?: string;
    colorPrecioVenta?: string;
    colorCantVenta?: string;
    arrayLength: number;
}

const CardEspecieLocalNuevo = ({
    especie,
    index,
    cargarDatosOperar,
    style,
    colorUltimo,
    colorCantCompra,
    colorPrecioCompra,
    colorPrecioVenta,
    colorCantVenta,
    arrayLength,
}: Props) => {

    const { primaryBackgroundColor, alterBackgroundCardsHome, primaryColor, rojoPorcentaje, buyColor } = useContext(ThemeContext);

    const { esFav, addFav, deleteFav, onClickEspecies } = useCardEspecieHook(especie);

    const { open, handleOpen, handleClose, handleAceptarModal } = useModalHook(deleteFav);

    const { tipoMercado } = useContext(MercadoContext);

    const esCero = (num: number) => {
        return num === 0 ? true : false;
    }

    // modifica el formato del horario, borra los segundos
    const cutHour = (hour: string | null | undefined) => {
        const searchTerm = ':';
        let hora = '-';
        if (hour !== undefined && hour !== null) {
            const lastIndexOf = hour.lastIndexOf(searchTerm);
            hora = hour.slice(0, lastIndexOf);
        }
        return hora;
    }

    return (
        <SecondaryContainer
            key={especie.simbolo}
            style={{
                backgroundColor: index % 2 !== 0
                    ? primaryBackgroundColor
                    : alterBackgroundCardsHome,
                ...style
            }}
        >
            <ContenedorElementosCentrados style={{ justifyContent: 'start' }}>
                <StarFavoritos
                    esFav={esFav}
                    agregarFav={addFav}
                    openFav={open}
                    handleOpenFav={handleOpen}
                    handleCloseFav={handleClose}
                    handleAceptarModalFav={handleAceptarModal}
                    nombreEspecie={especie.nombre}
                    deleteFav={deleteFav}
                />
                <Hora>
                    {cutHour(especie.hora)}
                </Hora>
                <Texto
                    onClick={(e) => {
                        tipoMercado === TipoMercado.Local
                            ? cargarDatosOperar(especie.simbolo, especie.ultimo, '', especie.especie)
                            : cargarDatosOperar(formatEspeciesExterior(especie.especie), especie.ultimo, '')
                    }}
                >
                    {tipoMercado === TipoMercado.Local
                        ? especie.simbolo
                        : formatEspeciesExterior(especie.especie)}
                </Texto>
            </ContenedorElementosCentrados>
            {tipoMercado === TipoMercado.Local
                ? <>
                    <ContenedorElementosCentrados
                        color={colorUltimo}
                    >
                        <Numeros
                            onClick={(e) => {
                                tipoMercado === TipoMercado.Local
                                    ? cargarDatosOperar(especie.simbolo, especie.ultimo, '')
                                    : cargarDatosOperar(formatEspeciesExterior(especie.especie), especie.ultimo, '');
                            }}
                        >{
                                esCero(Number(especie.ultimo))
                                    ? '-'
                                    : `$${formatCurrency(especie.ultimo)}`
                            }</Numeros>
                    </ContenedorElementosCentrados>
                    <ContenedorElementosCentrados>
                        <Variacion
                            style={{
                                color: especie.variacion > 0
                                    ? buyColor
                                    : especie.variacion < 0
                                        ? rojoPorcentaje
                                        : primaryColor
                            }}
                        >
                            {
                                esCero(Number(especie.variacion))
                                    ? '-'
                                    : `${formatPorcentaje(especie.variacion)}`
                            }
                        </Variacion>
                    </ContenedorElementosCentrados>
                    <ContenedorElementosCentradosCompraVenta
                        color={colorCantCompra}
                        style={{
                            borderRadius: arrayLength - 1 === index ? '0 0 0 10px' : 'undefined'
                        }}
                    >
                        <Numeros
                            onClick={(e) => {
                                cargarDatosOperar(especie.simbolo, especie.preciocompra, especie.cantcompra);
                            }}
                        >
                            {
                                esCero(Number(especie.cantcompra))
                                    ? '-'
                                    : separacionDecimal(Number(especie.cantcompra))
                            }
                        </Numeros>
                    </ContenedorElementosCentradosCompraVenta>
                    <ContenedorElementosCentradosCompraVenta
                        color={colorPrecioCompra}
                    >
                        <Numeros
                            onClick={(e) => {
                                cargarDatosOperar(especie.simbolo, especie.preciocompra, especie.cantcompra);
                            }}
                        >
                            {
                                esCero(Number(especie.preciocompra))
                                    ? '-'
                                    : `$${formatCurrency(especie.preciocompra)}`
                            }
                        </Numeros>
                    </ContenedorElementosCentradosCompraVenta>
                    <ContenedorElementosCentradosCompraVenta
                        color={colorPrecioVenta}
                    >
                        <Numeros
                            onClick={(e) => {
                                cargarDatosOperar(especie.simbolo, especie.precioventa, especie.cantventa);
                            }}
                        >
                            {
                                esCero(Number(especie.precioventa))
                                    ? '-'
                                    : `$${formatCurrency(especie.precioventa)}`
                            }
                        </Numeros>
                    </ContenedorElementosCentradosCompraVenta>
                    <ContenedorElementosCentradosCompraVenta
                        color={colorCantVenta}
                        style={{
                            borderRadius: arrayLength - 1 === index ? '0 0 10px 0' : 'undefined'
                        }}
                    >
                        <Numeros
                            onClick={(e) => {
                                cargarDatosOperar(especie.simbolo, especie.precioventa, especie.cantventa);
                            }}
                        >
                            {
                                esCero(Number(especie.cantventa))
                                    ? '-'
                                    : separacionDecimal(Number(especie.cantventa))
                            }
                        </Numeros>
                    </ContenedorElementosCentradosCompraVenta>

                    <ContenedorElementosCentrados>
                        <Numeros
                            style={{
                                cursor: 'text'
                            }}
                        >
                            {
                                esCero(Number(especie.volefectivo))
                                    ? '-'
                                    : separacionDecimal(Number(especie.volefectivo))
                            }
                        </Numeros>
                        <QueryStatsIcon
                            onClick={(e) => {
                                onClickEspecies(e)
                                tipoMercado === TipoMercado.Local
                                    ? cargarDatosOperar(especie.simbolo, especie.ultimo, '', especie.especie)
                                    : cargarDatosOperar(formatEspeciesExterior(especie.especie), especie.ultimo, '')
                            }}
                            sx={{
                                color: primaryColor,
                                padding: "0px",
                                fontSize: '1.2em',
                                marginRight: "0.5em",
                                cursor: 'pointer'
                            }}
                        />
                    </ContenedorElementosCentrados>
                </>
                :
                <>
                    <ContenedorElementosCentrados
                        color={colorUltimo}
                    >
                        <Numeros
                            onClick={(e) => {
                                cargarDatosOperar(formatEspeciesExterior(especie.especie), especie.ultimo, '');
                            }}
                        >{
                                esCero(Number(especie.ultimo))
                                    ? '-'
                                    : `$${formatCurrency(especie.ultimo)}`
                            }</Numeros>
                    </ContenedorElementosCentrados>
                    <ContenedorElementosCentrados>
                        <Variacion
                            style={{
                                color: especie.variacion > 0
                                    ? buyColor
                                    : especie.variacion < 0
                                        ? rojoPorcentaje
                                        : primaryColor
                            }}
                        >
                            {
                                esCero(Number(especie.variacion))
                                    ? '-'
                                    : `${formatPorcentaje(especie.variacion)}`
                            }
                        </Variacion>
                    </ContenedorElementosCentrados>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <ContenedorElementosCentrados>
                        <QueryStatsIcon
                            onClick={(e) => { onClickEspecies(e) }}
                            sx={{
                                color: primaryColor,
                                padding: "0px",
                                fontSize: '1.2em',
                                marginRight: "0.5em",
                                cursor: 'pointer'
                            }}
                        />
                    </ContenedorElementosCentrados>
                </>
            }
        </SecondaryContainer>
    )
}

export default CardEspecieLocalNuevo