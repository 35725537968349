import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    margin-top: 1em;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
`

export const ContainerTitles = styled.div`
    display: flex;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.p`
    margin: 0px;
    margin-left: 1em;
    font-size: 16px;
    font-weight: 400;
    color: ${({theme}) => theme.primaryColor};;
`

export const Button = styled.button`
    border: none;
    cursor: pointer;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    padding: 0.6em;
    margin-right: 1.1em;
    color: ${({theme}) => theme.primaryColor};;
    font-size: 14px;
    font-weight: 600;
`