import { createContext, useContext, useState } from 'react';
import { MercadoContext } from '../mercadoContext/MercadoContext';
import { addFavourite, deleteFavourite } from '../../api/userApi';
import { Favourite } from '../../interfaces/favouritesInterface';
import { TipoMercado } from '../../enums/enums';

type especiesContextProps = {
    especiesLocalesFavoritas: string[];
    especiesExteriorFavoritas: string[];
    sumarEspecie: (especie: string) => Promise<void>;
    borrarEspecie: (especie: string) => Promise<void>;
    buscarEspecie: (especie: string) => boolean;
    setEspeciesFromApi: (especiesApi: Favourite[]) => void
};

export const EspeciesFavoritasContext = createContext(
    {} as especiesContextProps,
);

export const EspeciesProvider = ({ children }: any) => {
    const [especiesLocalesFavoritas, setEspeciesLocalesFavoritas] = useState<string[]>([]);
    const [especiesExteriorFavoritas, setEspeciesExteriorFavoritas] = useState<string[]>([]);
    const { tipoMercado } = useContext(MercadoContext);


    const setEspeciesFromApi = async (especiesApi: Favourite[]) => {
        let arrLocal: string[] = [];
        let arrExterior: string[] = [];
        especiesApi.forEach(element => {
            if (element.tipoMercado === 'LOCAL') {
                arrLocal.push(element.especie);
            } else {
                arrExterior.push(element.especie);
            }
        });
        setEspeciesLocalesFavoritas(arrLocal);
        setEspeciesExteriorFavoritas(arrExterior);
    };

    const sumarEspecie = async (especie: string) => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const resp = await addFavourite(especie, tipoMercado.toString(), token);
                if (tipoMercado === TipoMercado.Local) {
                    setEspeciesLocalesFavoritas(especiesLocalesFavoritas => [
                        ...especiesLocalesFavoritas,
                        especie,
                    ]);
                } else {
                    setEspeciesExteriorFavoritas(especiesExteriorFavoritas => [
                        ...especiesExteriorFavoritas,
                        especie,
                    ]);
                }
            } catch (error) {
                console.log(error)
            }

        }
    };
    const borrarEspecie = async (especie: string) => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const resp = await deleteFavourite(especie, token);
                if (tipoMercado === TipoMercado.Local) {
                    const indexDelete = especiesLocalesFavoritas
                        .map(function (x) {
                            return x;
                        })
                        .indexOf(especie);
                    setEspeciesLocalesFavoritas(especies =>
                        especies.filter((_, index) => index !== indexDelete),
                    );
                } else {
                    const indexDelete = especiesExteriorFavoritas
                        .map(function (x) {
                            return x;
                        })
                        .indexOf(especie);
                    setEspeciesExteriorFavoritas(especies =>
                        especies.filter((_, index) => index !== indexDelete),
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const buscarEspecie = (especie: string) => {
        let result;
        if (tipoMercado === TipoMercado.Local) {
            result = especiesLocalesFavoritas.find(e => e === especie);
        } else {
            result = especiesExteriorFavoritas.find(e => e === especie);
        }
        return result !== undefined;
    };

    return (
        <EspeciesFavoritasContext.Provider
            value={{
                especiesLocalesFavoritas,
                especiesExteriorFavoritas,
                sumarEspecie,
                borrarEspecie,
                buscarEspecie,
                setEspeciesFromApi
            }}>
            {children}
        </EspeciesFavoritasContext.Provider>
    );
};
