import axios from "axios";

//const baseURL = process.env.REACT_APP_API_HOST_LOCAL;
const baseURL = process.env.REACT_APP_API_HOST;

const apiBackInstance = axios.create({
	baseURL,
});

export default apiBackInstance;
