import { createContext, useEffect, useState } from 'react';
import { getPublicToken } from '../../api/publicTokenProvider';

interface AuthContextProps {
    status:
    | 'authenticated'
    | 'not-authenticated'
    | 'changing-pin'
    | 'setting-pin'
    | 'loading';
    setStatus: React.Dispatch<React.SetStateAction<"authenticated" | "not-authenticated" | "changing-pin" | "setting-pin" | "loading">>,
    token: string,
    setLoading: (token: string) => void,
    tokenPublico: string | undefined,
    setTokenPublico: (tokenPublico: string | undefined) => void;
    setToken: (token: string) => void;
    logOut: () => void;
    cleanStorage: () => void;
    singIn: (token: string, userId: string) => void;
    userId: string;
    setPinGeneradoRava: React.Dispatch<React.SetStateAction<string>>;
    pinGeneradoRava: string;
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {

    const tokenStorage = localStorage.getItem("token");

    const [status, setStatus] =
        useState<| 'authenticated'
            | 'not-authenticated'
            | 'changing-pin'
            | 'setting-pin'
            | 'loading'>(tokenStorage ? "authenticated" : "not-authenticated")

    const [token, setToken] = useState('');
    const [tokenPublico, setTokenPublico] = useState<string | undefined>('')
    const [userId, setUserId] = useState('')
    const [pinGeneradoRava, setPinGeneradoRava] = useState('');

    async function getToken() {
        const resp = await getPublicToken();
        if (resp) {
            setTokenPublico(resp?.data.access_token);
            localStorage.setItem("tokenPublico", resp?.data.access_token)
        }
    }

    useEffect(() => {
        status === 'authenticated' && getToken();
    }, [status]);

    const singIn = (token: string, userId: string) => {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        setUserId(userId);
        setToken(token)
        setStatus("authenticated");
    };

    const setLoading = (token: string) => {
        setStatus('loading')
        setToken(token);
    }

    const cleanStorage = () => {
        localStorage.setItem('token', "")
        localStorage.setItem('tokenPublico', "")
    }

    const logOut = () => {
        cleanStorage();
        setToken('');
        setTokenPublico('');
        setStatus('not-authenticated');
    };

    return (
        <AuthContext.Provider
            value={{
                status,
                setStatus,
                token,
                setLoading,
                tokenPublico,
                setToken,
                logOut,
                cleanStorage,
                setTokenPublico,
                singIn,
                userId,
                setPinGeneradoRava,
                pinGeneradoRava,
            }}>
            {children}
        </AuthContext.Provider>
    )
}