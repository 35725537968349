import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
    width: 100%;
    height: 5em;
	@media (min-width: 992px) {
		width: 100%;
	}
`;

export const LabelForm = styled.label`
    font-size: 1em;
    color: ${({theme}) => theme.primaryColor};
    margin-bottom: 0.3em;
    margin-top: 1em;
`

export const Form = styled.form`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2em;
    background: ${({theme}) => theme.alternativeBackgroundColor};
`

export const Img = styled.img`
    width: 60%;
    margin-bottom: 24px;
    margin-top: 5em;
    @media (min-width: 992px) {
		width: 13%;
        margin-top: 5em
	}
`;

export const Title = styled.h3`
    color: ${({theme}) => theme.primaryColor};
    text-align: center;
    font-weight: 400;
    margin: 0;
`;

export const ContainerOptions = styled.div`
    width: 90%;
    @media (min-width: 992px) {
		width: 40%;
        margin-top: 2em;
	}
`;


export const ButtonContainer = styled.div`
    width: 10%;
    position: relative;
    bottom: 2.2em;
    left: 82%;
    margin: 0;
    @media (min-width: 992px) {
		left: 90%;
	}
`;

export const Button= styled.button`
    border: none;
    background: none;
    margin: 0;
`;

export const ErrorText = styled.p`
    color: ${({theme}) => theme.errorColor};
    margin: 0.5em 0 0 0;
    text-align: center;
`;

