import styled from "styled-components";



export const Container = styled.div`
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    height: 92vh;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
		width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
	}
`;

export const ContainerCardMercadoLocal = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
gap: 0.5em;
margin-left: 1em;
overflow-x: scroll; 
@media (min-width: 992px) {
    overflow: inherit;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    margin-right: 1.4em;
}
`
export const PhoneContainer = styled.div`
    overflow-y: scroll;
    flex-grow: 1;
    margin-bottom: 8vh;
`


export const RowContainer = styled.div`
    @media (min-width: 992px) {
        display: flex;
        width: 100%;
        height: 100%;
    }
`
export const OperarContainer = styled.div`
    @media (min-width: 992px) {
        display: flex;
        // padding: 0em 1.5em 0.5em 2.5em;
        // padding: 0.2em 1.5em 0em 2.5em
        padding: 0.2em 1.4em 0em 0em;
        margin-left: 2.5em;
        width: 40%;
    }
`

export const ContenedorPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    height: 99%;
`

export const ContainerSearchListaEspecies = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`