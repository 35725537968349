import React, { useContext } from 'react'
import { Icon } from '@iconify/react';
import { Button, IconText } from '../Aside/elements';
import { TipoNavigate } from '../../../enums/enums';
import { ThemeContext } from 'styled-components';

interface Props {
    handleNavigate: (type: TipoNavigate) => void;
    navegarHacia: TipoNavigate;
    selectedNavigate: TipoNavigate;
    titulo: string;
    icono: string;
}

const BotonesAside = ({ handleNavigate, selectedNavigate, titulo, icono, navegarHacia }: Props) => {

    const { primaryColor, selectedAside, primaryBackgroundColor, colorSelectedAside, colorNotSelectedAside } = useContext(ThemeContext);

    const styles = {
        colorSelected: {
            color: colorSelectedAside,
            backgroundColor: selectedAside,
        },
        colorNotSelected: {
            color: colorNotSelectedAside
        },
    }

    return (
        <Button
            onClick={() => handleNavigate(navegarHacia)}
            style={{
                backgroundColor: selectedNavigate === navegarHacia ? styles.colorSelected.backgroundColor : primaryBackgroundColor,
                borderRight: selectedNavigate === navegarHacia ? `0.3em solid ${primaryColor}` : 'none',
            }}
        >
            <Icon
                icon={icono}
                // fontSize={25}
                width={25}
                color={selectedNavigate === navegarHacia ? styles.colorSelected.color : styles.colorNotSelected.color}
                style={{
                    minWidth: 25
                }}
            />
            <IconText
                style={{
                    color: selectedNavigate === navegarHacia ? styles.colorSelected.color : styles.colorNotSelected.color,
                }}
            >
                {titulo}
            </IconText>
        </Button>
    )
}

export default BotonesAside