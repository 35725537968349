import styled from "styled-components";

export const Container = styled.div`
background-color: ${({theme}) => theme.primaryBackgroundColor};
display: flex;
height: 3.5em;
align-items: center;
justify-content: center;
position: sticky;
`

export const Titulo = styled.p`
margin: 0px;
font-size: 25px;
font-weight: 700;
color: ${({theme}) => theme.primaryColor};
font-family: "Roboto","Helvetica","Arial",sans-serif;
`

export const ContainerSwitcher = styled.div`
position: absolute;
left: 0.7em;
top: 0.6em;
`
