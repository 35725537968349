import styled from "styled-components";

export const Container = styled.div`
    padding: 0.2em 1.5em;
    border-bottom: 0.1em solid #EBEBEB;
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8em;
`;

export const NoticiaText = styled.p`
    color: ${({theme}) => theme.redToWhiteColor};
    font-size: 1em;
    font-weight: bold;
    margin: 0.5em 0;
    @media (min-width: 992px) {
        font-size: 1.1em;
    }
`

export const AlertaText = styled.p`
    /* color: #03DAC5; */
    color: ${({theme}) => theme.tipoAlertaColor};
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    @media (min-width: 992px) {
        font-size: 1.2em;
    }
`

export const FechaText = styled.p`
    color: ${({theme}) => theme.accentColor};
    font-size: 1em;
    margin: 0;
    @media (min-width: 992px) {
        font-size: 1.1em;
    }
`

export const PrecioText = styled.p`
    color: ${({theme}) => theme.accentColor};
    font-size: 1em;
    margin: 0.5em 0;
    @media (min-width: 992px) {
        font-size: 1.1em;
        margin-left: 2em;
    }
`

export const DescText = styled.p`
    color: ${({theme}) => theme.accentColor};
    font-size: 1em;
    margin: 0;
    @media (min-width: 992px) {
        font-size: 1.1em;
    }
`

export const Button = styled.button`
    background: none;
    border: none;
    color: ${({theme}) => theme.secondaryBackgroundColor};
    font-size: 1.1em;
    
`;

export const ButtonText = styled.p`
    color: ${({theme}) => theme.primaryColor};
    font-size: 0.9em;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 0.8em;
    }
`
export const Linea = styled.div`
    height: 0.1em;
    background-color: #EBEBEB;
`;

export const Img = styled.img`
    height: 15px;
    width: 15px;
    margin-right: 0.5em;
    @media (min-width: 992px) {
        height: 25px;
        width: 25px;
    }
`;

export const AlertaTitleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
