import { useContext, useState } from 'react';
import { signInApi } from '../api/userApi';
import { AuthContext } from '../context/authContext/AuthContext';
import { UserContext } from '../context/userContext/UserContext';
import { useNavigationHook } from './useNavigationHook';
import { TipoNavigate } from '../enums/enums';



export const useLoginHook = () => {
    const [nroCuenta, setNroCuenta] = useState('');
    const [pin, setPin] = useState('');
    const [dni, setDni] = useState('');
    const [error, setError] = useState('');
    const { setDniContext, setNroCuentaContext } = useContext(UserContext);
    const { singIn } = useContext(AuthContext);
    const { handleNavigate } = useNavigationHook();

    // valida que los datos ingresados sean correctos y tengan los caracteres necesarios
    const validate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (nroCuenta === '' || pin === '' || dni === '') {
            setError('Error. Debe completar todos los campos.');
        } else if (pin.length < 6) {
            setError('Error. El pin debe ser de 6 digitos.');
        } else {
            setError('');
            try {
                const resp = await signInApi(parseInt(nroCuenta.trim()), pin.trim(), dni.trim());
                if (resp.status === 200) {
                    const userId = resp.data.body.userId;
                    const token = resp.data.body.token;
                    if (token) {
                        setDniContext(dni);
                        setNroCuentaContext(nroCuenta);
                        singIn(token, userId);
                        handleNavigate(TipoNavigate.Home)
                    }
                }
            } catch (error: any) {
                if (error.response?.status === 400 || error.response?.status === 401) {
                    setError('Datos incorrectos. Intente nuevamente.');
                } else {
                    setError('Ocurrió un error al iniciar sesión. Por favor intentá nuevamente más tarde');
                }
            }
        }
    };


    return {
        nroCuenta,
        setNroCuenta,
        dni,
        setDni,
        pin,
        setPin,
        error,
        validate,
    };
};
