import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    top: 0;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({theme}) => theme.primaryBackgroundColor};
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.14), 0px 0px 10px 0px rgba(0, 0, 0, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1;
`;

export const Img = styled.img`
    max-width: 100%;
    margin-top: 1em;
`

export const ContainerImg = styled.div`
    padding: 0 0.3em;
`

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 2.6em;
    border-top: 0.1em solid #EBEBEB;
`;

export const Button = styled.button`
    height: 4em;
    width: 100%;
    padding-left: 2em;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    background: transparent;
    border-bottom: 0.1em solid #EBEBEB;
    cursor: pointer;
`
export const IconText = styled.p`
    font-size: 1em;
    margin-left: 0.5em;
    margin-top: 1.2em;
    color: ${({theme}) => theme.colorNotSelectedAside};;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
`