import { createContext, useState } from "react";
import { TipoNavigate } from "../../enums/enums";

type navigationContextProps = {
    selectedNavigate: TipoNavigate,
    setSelectedNavigate: React.Dispatch<React.SetStateAction<TipoNavigate>>,
}

export const NavigationContext = createContext({} as navigationContextProps);


export const NavigationProvider = ({ children }: any) => {

    const [selectedNavigate, setSelectedNavigate] = useState<TipoNavigate>(TipoNavigate.Home)

    return (
        <NavigationContext.Provider value={{ selectedNavigate, setSelectedNavigate }}>
            {children}
        </NavigationContext.Provider>
    );
}