import { useContext } from 'react'
import { useMercadoHook } from '../../hooks/useMercadoHook'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import { TipoMercado } from '../../enums/enums'
import CardMercado from '../../components/mercadoComponents/CardMercado/CardMercado'
import { CardListaEspecies } from '../../components/mercadoComponents/CardListaEspecies/CardListaEspecies'
import SearchBar from '../../components/mercadoComponents/SearchBar/SearchBar'
import { Container, ContainerCardMercadoLocal, ContainerSearchListaEspecies, ContenedorPrincipal, OperarContainer, PhoneContainer, RowContainer } from './elements'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Operar from '../Operar/Operar'
import CardMercadoAcordion from '../../components/mercadoComponents/CardMercadoAcordion/CardMercadoAcordion'
import ComprarScreen from '../ComprarScreen/ComprarScreen'
import { OperarContext } from '../../context/operarContext/OperarContext'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'

export const Mercado = () => {

    const { dataMercadoCard, dataDolar, dataMercadoLocalEspecies, dataAvtivasCincoExt, dataTopCincoExt } = useMercadoHook();
    const { tipoMercado } = useContext(MercadoContext);
    const { width } = useWindowDimensions();
    const { deskOperar } = useContext(OperarContext);


    return (
        <Container>
            <ResponsiveHeader title='Mercado' showSwitch={true} />
            {
                width > 992 ?
                    <ContenedorPrincipal>
                        {
                            tipoMercado === TipoMercado.Local ?
                                (
                                    <ContainerCardMercadoLocal>
                                        <CardMercado
                                            title="Mercado local"
                                            data={dataMercadoCard}
                                            img={require('../../images/bandera-mercado-local.png')}
                                        />
                                        <CardMercado
                                            title="Dólar"
                                            data={dataDolar}
                                            img={require('../../images/icono-dolar.png')}
                                        />
                                        <CardMercado
                                            title="Lo que mas subió"
                                            data={dataMercadoLocalEspecies}
                                            img={require('../../images/bandera-mercado-local.png')}
                                        />
                                    </ContainerCardMercadoLocal>
                                ) :
                                (
                                    <ContainerCardMercadoLocal>
                                        <CardMercado
                                            title='Principales índices de EE. UU.'
                                            data={dataMercadoCard}
                                            img={require('../../images/bandera-usa-small.png')}
                                        />
                                        <CardMercado
                                            title='Lo que mas subió'
                                            data={dataTopCincoExt ? dataTopCincoExt.slice(0, 3) : null}
                                            img={require('../../images/bandera-usa-small.png')}
                                        />
                                        <CardMercado
                                            title='Lo mas destacado'
                                            data={dataAvtivasCincoExt ? dataAvtivasCincoExt.slice(0, 3) : null}
                                            img={require('../../images/bandera-usa-small.png')}
                                        />
                                    </ContainerCardMercadoLocal>
                                )
                        }
                        <RowContainer>
                            <ContainerSearchListaEspecies>
                                <SearchBar />
                                <CardListaEspecies />
                            </ContainerSearchListaEspecies>
                            <OperarContainer>
                                {!deskOperar ?
                                    <Operar />
                                    :
                                    <ComprarScreen />
                                }
                            </OperarContainer>
                        </RowContainer>
                    </ContenedorPrincipal>
                    :
                    <>
                        <SearchBar />
                        <PhoneContainer>
                            {
                                tipoMercado === TipoMercado.Local ?
                                    (
                                        <ContainerCardMercadoLocal>
                                            <CardMercado
                                                title="Mercado local"
                                                data={dataMercadoCard}
                                                img={require('../../images/bandera-mercado-local.png')}
                                            />
                                            <CardMercado
                                                title="Dólar"
                                                data={dataDolar}
                                                img={require('../../images/icono-dolar.png')}
                                            />
                                        </ContainerCardMercadoLocal>
                                    ) :
                                    (
                                        <div style={{ margin: '0.5em 1em 0', }}>
                                            <CardMercadoAcordion title='Principales índices de EE. UU.' data={dataMercadoCard} />
                                        </div>
                                    )
                            }
                            <CardListaEspecies />
                        </PhoneContainer>
                    </>
            }
        </Container>
    )
}

