import styled from "styled-components";


export const Container = styled.div`
flex: 1;
height: 100vh;
background-color:  ${({theme}) => theme.secondaryBackgroundColor};
@media (min-width: 992px) {
    margin-top: 8vh;
    margin-left: 12vw;
    height: 91.5vh;
    width: 87vw;
    padding: 0vh 0.5vw 0 0.5vw;
}
`

export const CardsContainer = styled.div`
padding: 20px;
@media (min-width: 992px) {
    display: flex;
    // width: 100%;
    padding: 0;
    justify-content: space-evenly; 
}
`