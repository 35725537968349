import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { TipoNavigate } from '../enums/enums';
import { NavigationContext } from '../context/navigationContext/NavigationContext';

export const useNavigationHook = () => {


    const { setSelectedNavigate } = useContext(NavigationContext);

    const navigate = useNavigate();

    const handleNavigate = (type: TipoNavigate) => {
        if (type === TipoNavigate.Atras) {            
            navigate(-1)
        } else {
            setSelectedNavigate(type)
            navigate(`/${type}`)
        }
    }




    return {
        handleNavigate
    }
}

