import styled from "styled-components";

export const Titulo = styled.p`
    margin: 0px;
    color: ${({theme}) => theme.primaryColor};
    font-weight: 700;
    font-size: 16px;
`

export const ContainerDia = styled.div`
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #DADADA;
`

export const Dia = styled.p`
margin: 0.4em 1.1em 0 0;
font-weight: 400;
font-size: 13px;
color: ${({theme}) => theme.primaryColor};
align-self: flex-end;
`

export const ContainerData = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 0.1em;
margin-top: 0.1em;
`

export const ContainerImagenes = styled.div`
display: flex;
height: 2.8em;
align-items: center;
`

export const ImgEEUU = styled.img`
margin-left: 1em;
width: 1.1em;
height: 1.1em; 
padding: 0em; 
background-color: #B00020;
border-radius: 50px;
margin-right: 0.4em;
`

export const ContainerEspecieNombre = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`

export const Especie = styled.p`
margin: 0px;
color: ${({theme}) => theme.primaryColor};
font-weight: 700;
font-size: 16px;
line-height: 0.8;
`

export const Nombre = styled.p`
margin: 0px;
color: ${({theme}) => theme.primaryColor};
font-size: 14px;
`

export const ContainerUltimoVariacion = styled.div`
display: flex;
margin-top: 0.5em;
`

export const UltimoExterior = styled.p`
margin: 0px;
font-weight: 700;
font-size: 14px;
text-align: center;
color: ${({theme}) => theme.accentColor};
margin-right: 0.9em;
`


export const Variacion = styled.p`
margin: 0px;
font-weight: 700;
font-size: 14px;
text-align: right;
width: 2.7em;
margin-right: 1em;
`