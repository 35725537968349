import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 92vh;
		width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
	}
`;

export const DataContainer = styled.div`
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    margin: 1em 1em 0 1em;
    border-radius: 0.5em;
    @media (min-width: 992px) {
        margin-top: 0em;
        height: auto;
        background-color: inherit;
    }
`;

export const ContainerButtons = styled.div`
@media (min-width: 992px) {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
`

export const Button = styled.button`
    display: flex;
    width: 100%;
    height: 2.3em;
    padding: 0 1em 0 0.5em;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.1em;
    border-bottom: ${({theme}) => `0.15em solid ${theme.grayToDarkGrayBackground}`};
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    color: ${colors.blueRava};
    @media (min-height: 730px) {
        height: 2.7em;
    }
    @media (min-width: 992px){
        height: 1.8em;
        padding: 0px;
        margin-bottom: 0.2em;
        border-radius: 10px;
        font-size: 2em;
        justify-content: space-between;
        cursor: pointer;
    }
`;

export const LastButton = styled.button`
    display: flex;
    width: 100%;
    height: 2.7em;
    padding: 0 1.5em 0 0.5em;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.1em;
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    color: ${colors.blueRava};
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
`;
export const ButtonText = styled.p`
    font-size: 1.1em;
    margin: 0;
    color: ${({theme}) => theme.fontColorWhiteToBlack};
    @media (min-width: 992px) {
        color: ${({theme}) => theme.primaryColor};
        font-size: 0.6em;
        font-weight: 700;
        margin-left: 0.3em;
    }
`;






export const AgregarAlertaButton = styled.button`
    display: flex;
    width: 100%;
    height: 2.7em;
    padding: 0 1.5em 0 0.5em;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.1em;
    border-bottom: 0.1em solid #EBEBEB;
    color: ${colors.blueRava};
`;

export const DateContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0.5em;
`;

export const DateText = styled.p`
    margin: 0;
    color: ${colors.blueRava};
`;

export const EstadoContainer = styled.div`
    margin-bottom: 5px;
    width: 65%;
`;

export const EstadoText = styled.p`
    font-weight: bold;
    text-align: end;
    font-size: 0.8em;
    color: #3C3C43;
    margin: 0;
`;

export const SinOrdenesContainer = styled.div`
    height:  70vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SinOrdenesText = styled.p`
    font-size: 1.3em;
    color: #8A8A8A;
`; 

export const FiltrosContainer = styled.div`
    display: flex;
    align-items: stretch;
`; 

export const InputContainer = styled.div`
    width: 48%;
    margin-right: 0.5em;
`; 

export const Input = styled.input`
    width: 92%;
    background: ${({ theme }) => theme.backgroundInput};
	box-shadow: 4px 4px 10px 0px ${({ theme }) => theme.inputShadow2} inset;
	border-radius: 5px;
	padding: 1em 0.8em 0.6em 0.8em;
	color: ${colors.blueRava};	
	border: none;
    ::placeholder {
        color: gray;
        font-weight: bold;
    }
    &:focus {
        outline: none;
        border: none;
    }
    -webkit-font-smoothing{
        border: none
    }
	@media (max-width: 992px) {
		font-size: 14px;
	}
`;

export const SelectContainer = styled.div`
    width: 48%;
    margin-left: 0.5em;
`; 

export const Select = styled.select`
    width: 100%;
    background-color: white;
	box-shadow: 4px 4px 10px 0px ${({theme}) => theme.inputShadow2} inset;
	border-radius: 5px;
	padding: 0.8em;
	color: ${colors.blueRava};	
	border: none;
    ::placeholder {
        color: gray;
        font-weight: bold;
    }
    &:focus {
        outline: none;
        border: none;
    }
    option{
        background-color: rgba(237, 237, 237, 0.8);
        font-size: 0.9em;
        width: 1em;
    }
	@media (max-width: 992px) {
		font-size: 14px;
	}
`;


export const Whatsapp = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0.1em solid white;
    border-radius: 1em;
    width: 7em;
    margin: 0 0.5em;
    cursor: pointer;
    padding: 0 1em 0 1em;
    cursor: pointer;
`

export const TituloWhatsapp = styled.p`
    text-align: center;
    font-size: 15px;
    margin: 0px;
    color: white;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.5em;
`