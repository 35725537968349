import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-grow: 1; 
    height: 80%;
    flex-direction: column;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px, rgba(0, 0, 0, 0.2) 0px 1px 3px;
    margin: 0.5em 1em 0em 1em;
`