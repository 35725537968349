import React, { useContext } from "react";
import { ListMenu } from '../../../views/AgregarAlerta/elements'
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MenuPopUp from '../../MenuPopUp/MenuPopUp';
import { Icon } from '@iconify/react';
import { Button, ButtonTipoAlerta, ButtonTipoAlertaContainer } from "../elements";
import { ThemeContext } from "styled-components";
import { TipoAlerta } from "../../../enums/enums";

interface Props {
    width: number,
    handleClickTipoAlerta: (event: React.MouseEvent<HTMLElement>) => void,
    actualTipoAlerta: string,
    tipoAlertasStrArr: string[],
    selectedTipoAlertaIndex: number,
    openTipoAlerta: HTMLElement | null,
    closeTipoAlerta: (op?: string) => void,
    handleButtonTipoAlerta: (opcion: string) => void,
}

export const SegundoMenu = ({ width, handleClickTipoAlerta, actualTipoAlerta, tipoAlertasStrArr, selectedTipoAlertaIndex, openTipoAlerta, closeTipoAlerta, handleButtonTipoAlerta }: Props) => {
    
    const themeContext = useContext(ThemeContext)
    const { primaryBackgroundColor, primaryColor } = themeContext;
    
    return (
        <div>
            {
                width < 992 ?
                    <div>
                        <ListMenu>
                            <ListItem
                                onClick={handleClickTipoAlerta}
                                style={styles.ListItem}
                            >
                                <Icon
                                    icon={
                                        actualTipoAlerta ? (actualTipoAlerta === TipoAlerta.Resistencia ?
                                            'mdi:arrow-up-circle-outline'
                                            : 'mdi:arrow-down-circle-outline')
                                            :
                                            (tipoAlertasStrArr[selectedTipoAlertaIndex] === TipoAlerta.Resistencia
                                                ? 'mdi:arrow-up-circle-outline'
                                                : 'mdi:arrow-down-circle-outline')}
                                    style={{ width: '1.3em', height: '1.3em', marginRight: '0.5em' }}
                                />
                                <ListItemText primary={actualTipoAlerta ? actualTipoAlerta : tipoAlertasStrArr[selectedTipoAlertaIndex]} />
                                <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '1.2em', height: '1.2em', marginRight: '1.5em' }} />
                            </ListItem>
                        </ListMenu>
                        <MenuPopUp
                            open={Boolean(openTipoAlerta)}
                            anchorEl={openTipoAlerta}
                            handleCloseMenu={closeTipoAlerta}
                            listaMenu={tipoAlertasStrArr}
                            actualMenu={actualTipoAlerta}
                        />
                    </div>
                    : 
                    <ButtonTipoAlertaContainer>
                        {tipoAlertasStrArr.map((opcion, index) => (
                            <ButtonTipoAlerta
                                key={index}
                                onClick={() => handleButtonTipoAlerta(opcion)}
                                style={{ 
                                    backgroundColor: opcion === actualTipoAlerta ? primaryColor : primaryBackgroundColor, 
                                    color: opcion === actualTipoAlerta ? primaryBackgroundColor : primaryColor, 
                                }}
                            >
                                <Icon
                                    icon={
                                        opcion === TipoAlerta.Resistencia ?
                                            'mdi:arrow-up-circle-outline'
                                            : 'mdi:arrow-down-circle-outline'
                                        }
                                    style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                                />
                                {opcion}
                            </ButtonTipoAlerta>
                        ))}
                    </ButtonTipoAlertaContainer>
        }
        </div>
    )
}

const styles = {
    ListItem: {
        padding: '0.2em 0.5em',
        marginLeft: '0.5em'
    },
}