import { useContext } from 'react'
import { DetalleContext } from '../../../context/detalleEspecieContext/DetalleContext'
import { OfertasInterface } from '../../../interfaces/mercadoInferfaces';
import { formatCurrency } from '../../../helpers/formatCurrency';
import Lottie from 'lottie-react';
import { Cantidad, Container, ContainerSinOfertas, ContainerTitulos, PrecioCompra, PrecioVenta, TextoSinOfertas, Titulos } from './elements';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import { ThemeContext as ContexTheme } from '../../../context/themeContext/ThemeContext';
import { Theme } from '../../../enums/theme';
import { OperarContext } from '../../../context/operarContext/OperarContext';
import { separacionDecimal } from '../../../helpers/separacionDecimal';


const CardOfertas = () => {

    const { ofertas, loadingOfertas, especie } = useContext(DetalleContext);

    const { width } = useWindowDimensions();

    const { setTextoTitulos, onChangePrecio, onChangeCantidad, deskOperar } = useContext(OperarContext);

    const { primaryBackgroundColor, alterHomeBackgrooundColor } = useContext(ThemeContext);

    const { theme } = useContext(ContexTheme);


    return (
        <Container>
            {
                loadingOfertas ?
                    <div style={{ overflow: 'hidden', height: '8em' }}>
                        <Lottie
                            animationData={
                                theme === Theme.Light
                                    ? require('../../../jsons-animation/loading-pagina-completa.json')
                                    : require('../../../jsons-animation/loading-pagina-completa-dark.json')}
                            autoPlay={true}
                            loop={true}
                            height={'8em'}
                            width={'auto'}
                        />
                    </div>
                    :
                    (
                        <div>
                            <ContainerTitulos>
                                <Titulos>
                                    C. Compra
                                </Titulos>
                                <Titulos>
                                    P. Compra
                                </Titulos>
                                <Titulos>
                                    P. Venta
                                </Titulos>
                                <Titulos>
                                    C. Venta
                                </Titulos>
                            </ContainerTitulos>
                            {!ofertas?.length ?
                                <ContainerSinOfertas>
                                    <TextoSinOfertas>No hay ofertas de esta especie</TextoSinOfertas>
                                </ContainerSinOfertas>
                                :
                                <>
                                    {
                                        ofertas.map((c: OfertasInterface, i: number) => (
                                            <ContainerTitulos key={i}
                                                style={{
                                                    backgroundColor: width < 992 ? undefined : i % 2 === 0 ? alterHomeBackgrooundColor : primaryBackgroundColor,
                                                    borderRadius: '10px,'
                                                }}
                                            >
                                                <Cantidad
                                                    onClick={() => {
                                                        if (!deskOperar) {
                                                            especie && setTextoTitulos(especie?.simbolo);
                                                            onChangeCantidad(String(c.cantidadCompra))
                                                        }
                                                    }}
                                                >
                                                    {c.cantidadCompra ? separacionDecimal(c.cantidadCompra) : "-"}
                                                </Cantidad>
                                                <PrecioCompra
                                                    onClick={() => {
                                                        if (!deskOperar) {
                                                            especie && setTextoTitulos(especie?.simbolo);
                                                            onChangePrecio(formatCurrency(c.precioCompra))
                                                        }
                                                    }}
                                                >
                                                    {c.precioCompra ? formatCurrency(c.precioCompra) : "-"}
                                                </PrecioCompra>
                                                <PrecioVenta
                                                    onClick={() => {
                                                        if (!deskOperar) {
                                                            especie && setTextoTitulos(especie?.simbolo);
                                                            onChangePrecio(formatCurrency(c.precioVenta))
                                                        }
                                                    }}
                                                >
                                                    {c.precioVenta ? formatCurrency(c.precioVenta) : "-"}
                                                </PrecioVenta>
                                                <Cantidad
                                                    onClick={() => {
                                                        if (!deskOperar) {
                                                            especie && setTextoTitulos(especie?.simbolo);
                                                            onChangeCantidad(String(c.cantidadVenta))
                                                        }
                                                    }}
                                                >
                                                    {c.cantidadVenta ? separacionDecimal(c.cantidadVenta) : "-"}
                                                </Cantidad>
                                            </ContainerTitulos>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    )
            }
        </Container>
    )
}

export default CardOfertas
