import styled from "styled-components";

export const Container = styled.div`
    background-color: white;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-top: 0.4em;
    display: flex;
    justify-content: space-between;
    height: 3.7em;
    align-items: center;
    @media (min-width: 992px) {
		border-radius: 0;
        box-shadow: none;
        margin: 0;
	}
`
export const ContainerEspecieCantidad = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1em;
    width: 4.5em;
    @media (min-width: 992px) {
        margin-left: 1.5em;
    }
`

export const TextoEspecie = styled.p`
    font-weight: 700;
    font-size: 16px;
    color: ${({theme}) => theme.primaryColor};;;
    margin: 0px;
    cursor: pointer;
`

export const TextoCantidad = styled.p`
    color: ${({theme}) => theme.primaryColor};;;
    margin: 0px;
    text-align: right;
    margin-right: 0.5em;
    width: 4.5em;
`


export const ContainerBotonPrecio = styled.div`
    display: flex;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    align-items: flex-start;
`

export const ContainerPrecioPorcentaje = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #EBEFF4;
    height: 100%;
`

export const PrecioCosto = styled.p`
    color: ${({theme}) => theme.primaryColor};;;
    font-size: 15px;
    margin: 0px;
    font-weight: 600;
    margin-left: 0.5em;
    text-align: right;
    width: 7em;
`

export const CostoHistorico = styled.p`
    color: ${({theme}) => theme.primaryColor};;;
    font-size: 15px;
    margin: 0px;
    font-weight: 600;
    margin-left: 0.5em;
    text-align: right;
    width: 7em;
`

export const TotalPrecioCantidad = styled.p`
    margin: 0px;
    margin-left: 0.5em;
    font-weight: 700;
    font-size: 15px;
    color: ${({theme}) => theme.primaryColor};;
    text-align: right;
    width: 7em;
`

export const ContainerPorcentajeActual = styled.div`

`

export const ContainerPorcentajeHistorico = styled.div`
    display: flex;
`

export const PorcentajePositivo = styled.p`
    color: #03DAC5;
    margin: 0px;
    @media (min-width: 992px) {
        font-weight: 700;
	}
`

export const PorcentajeNegativo = styled.p`
    color: ${({theme}) => theme.rojoPorcentaje};;
    margin: 0px;
    @media (min-width: 992px) {
        font-weight: 700;
	}
`
export const PorcentajeCero = styled.p`
    color: ${({theme}) => theme.primaryColor};;;
    margin: 0px;
`

export const PorcentajeCeroHistorico = styled.p`
    color: ${({theme}) => theme.primaryColor};;
    margin: 0px;
    margin-right: 0.7em;
`

export const BotonVender = styled.button`
    background-color: ${({theme}) => theme.circuloVenderBackground};
    // background-color: red;
    // background-color: transparent;
    color: #01AE9D;
    border-radius: 50px;
    border: none;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    padding: 0.1em;
    cursor: pointer;
    @media (min-width: 992px) {
		font-size: 12px;
        font-weight: 700;
        padding: 0;
        padding-left: 0.7em;
        margin-right: 2em;
	}
`

export const ContainerPrecioCantidadTotal = styled.div`
    display: flex;
    height: 2.7em;
    width: 12em; 
    justify-content: center;
    @media (min-width: 992px) {
        margin-left: 1em;
    }
`