import { useState } from 'react'

export const useModalHook = (accionAceptarModal: (() => Promise<void>) | (() => void), noCerrarModal?: boolean) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAceptarModal = () => {
        accionAceptarModal();
        !noCerrarModal && setOpen(false);
    }

    return {
        open,
        handleOpen,
        handleClose,
        handleAceptarModal
    }
}
