import { AltaCbuText, ArrowIcon, Button, ButtonText, ButtonsContainer, Container, ContainerAltaCbu, IconWithMediaQuery, Row, SecondaryContainer } from './elements'
import { useNavigate } from 'react-router-dom'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import AddIcon from '@mui/icons-material/Add';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const MiBilletera = () => {

    const navigate = useNavigate();
    const { primaryColor } = useContext(ThemeContext);
    const { width } = useWindowDimensions();

    return (
        <Container>
            <ResponsiveHeader title='Mi billetera' showSwitch={false} esMiBilletera={true} />
            <SecondaryContainer>
                {
                    width < 992
                        ? <ContainerAltaCbu
                            onClick={() => navigate("/agregar-cuenta")}
                        >
                            <AddIcon
                                style={{
                                    color: primaryColor,
                                }}
                            />
                            <AltaCbuText>ALTA CBU</AltaCbuText>
                        </ContainerAltaCbu>
                        : null
                }
                <ButtonsContainer>
                    <Button
                        onClick={() => navigate("/ingresar-dinero")}
                    >
                        <Row>
                            <IconWithMediaQuery icon="mdi:cash" />
                            <ButtonText>Ingresar dinero</ButtonText>
                        </Row>
                        <ArrowIcon icon="material-symbols:arrow-forward-ios-rounded" />
                    </Button>
                    <Button
                        onClick={() => navigate('/retirar-dinero')}
                    >
                        <Row>
                            <IconWithMediaQuery icon="mdi:cash" />
                            <ButtonText>Retirar dinero</ButtonText>
                        </Row>
                        <ArrowIcon icon="material-symbols:arrow-forward-ios-rounded" />
                    </Button>
                </ButtonsContainer>
            </SecondaryContainer>
        </Container>
    )
}
