import { useLocation } from 'react-router-dom';
import { Container, Img, ImgContainer, Row, Title } from './elements';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';

interface Props {
    seccion: string;
    fecha: string;
    titulo: string;
    texto: string;
    img: string;
}

export const DetalleNotificacion = () => {

    const location = useLocation();
    const { seccion, fecha, titulo, texto, img }: Props = location.state;

    return (
        <div>

            <ResponsiveHeader title="" showSwitch={false} />

            <Container>
                <Row>
                    <p>{seccion}</p>
                    <p>{fecha}</p>
                </Row>
                <Title>
                    {titulo}
                </Title>
                <p>{texto}</p>
                {
                    img !== "" &&
                    <ImgContainer>
                        <Img
                            src={img}
                        />
                    </ImgContainer>
                }
            </Container>
        </div>
    );
};