import React, { createContext, useEffect, useState } from 'react'
import { Theme } from '../../enums/theme';

type themeContextProps = {
    theme: Theme,
    HandleChangeThemeSwitch: (e: React.ChangeEvent<HTMLInputElement>) => void,
    checked: boolean,
}

export const ThemeContext = createContext({} as themeContextProps);


export const ThemeProvider = ({ children }: any) => {

    const storageTheme = localStorage.getItem('theme');

    const [theme, setTheme] = useState<Theme>(
        storageTheme === 'dark'
            ? Theme.Dark
            : Theme.Light
    );
    const [checked, setChecked] = useState(
        storageTheme === 'dark' ? 
        true :
        false
    );

    useEffect(() => {
        let theme = localStorage.getItem('theme');
        setTheme(theme === 'dark' ? Theme.Dark : Theme.Light)
        setChecked(theme === 'dark' ? true : false)
    }, [])

    const HandleChangeThemeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (theme === Theme.Light) {
            setTheme(Theme.Dark)
            localStorage.setItem("theme", Theme.Dark)
            setChecked(e.target.checked);
        } else {
            setTheme(Theme.Light)
            localStorage.setItem("theme", Theme.Light)
            setChecked(e.target.checked);
        }
    }

    return (
        <ThemeContext.Provider value={{
            theme,
            HandleChangeThemeSwitch,
            checked,
        }}>
            {children}
        </ThemeContext.Provider>
    );
}
