import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 92vh;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
		width: 87vw;
        padding: 0.5vh 0.5vw 0 0.5vw;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
	}
`;

export const DateContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin:  1.5em 0.5em 0.5em 0.5em;
    @media (min-width: 992px) {
        margin: 0;
        margin:  0.5em 1.2em 0 0em;
        // padding: 1em 1.2em 0 0em;
        // margin-right: 10px;
	}
`;

export const DateText = styled.p`
    margin: 0;
    color: ${({theme}) => theme.primaryColor};
`;

export const EstadoContainer = styled.div`
    margin-bottom: 5px;
    width: 65%;
    @media (min-width: 992px) {
        width: 54.5%;
	}
`;

export const EstadoText = styled.p`
    font-weight: bold;
    text-align: end;
    font-size: 0.8em;
    color: ${({theme}) => theme.accentColor};
    margin: 0;
    @media (min-width: 992px) {
        font-size: 1em;
        font-weight: 600;
	}
`;

export const SinOrdenesContainer = styled.div`
    height:  70vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SinOrdenesText = styled.p`
    font-size: 1.3em;
    color: ${colors.grayRava};
`; 

export const ErrorText = styled.p`
    color: ${({ theme }) => theme.primaryColor};	
    font-size: 1em;
    text-align: center;
    margin-bottom: 5em;
    @media (min-width: 992px) {
        font-size: 18px;
	}
`

export const FiltrosContainer = styled.div`
    display: flex;
    align-items: stretch;
    gap: 0.5em;
`; 

export const InputContainer = styled.div`
    width: 48%;
    margin-right: 0.5em;
    @media (min-width: 992px) {
        width: 100%;
        margin-right: 1em;
	}
`; 

export const Input = styled.input`
    width: 100%;
    background: ${({ theme }) => theme.primaryBackgroundColor};
	border-radius: 5px;
    padding: 0.5em;
    font-size: 1em;
	color: ${({ theme }) => theme.primaryColor};	
	border: none;
    margin: 0.2em 0;
    ::placeholder {
        color: ${({ theme }) => theme.alternativeAccentColor};	
        font-weight: bold;
    }
    &:focus {
        outline: none;
        border: none;
    }
    -webkit-font-smoothing{
        border: none
    }
	@media (min-width: 992px) {
        padding: 0.5em;
        font-size: 1em;
	}
`;

export const SelectContainer = styled.div`
    width: 48%;
    margin-left: 0.5em;
    @media (min-width: 992px) {
        width: 50%;
	}
`; 

export const Select = styled.select`
    width: 100%;
    background-color: white;
	box-shadow: 0px 0px 5px rgba(209, 205, 205, 0.49);
	border-radius: 5px;
	padding: 0.9em;
	color: ${colors.blueRava};	
	border: none;
    font-size: 0.9em;
    ::placeholder {
        color: gray;
        font-weight: bold;
    }
    &:focus {
        outline: none;
        border: none;
    }
    option{
        background-color: rgba(237, 237, 237, 0.8);
        font-size: 0.9em;
        width: 1em;
    }
	/* @media (min-width: 992px) {
	} */
`;

export const ListContainer = styled.div`
    height: 75vh;
    overflow-y: scroll;
`;


export const ContainerButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0.2em;
`
export const Botones = styled.div`
    text-align: center;
    margin: 0.5em 0em 0em;
    padding: 0 0.8em 0 0.8em;
    cursor: pointer;
    width: 100%;
`

export const TextoBotones = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0;
    padding: 0.2em 0 0.2em;
    border: solid 2px ${({ theme }) => theme.primaryColor};
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
`