import styled from "styled-components";

export const Container = styled.div`
    display: flex; 
    flex-direction: column; 
    flex-grow: 1; 
`

export const ContainerSecundario = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px;
    margin-top: 0.2em;
    height: 100%; 
    flex-grow: 1;
    max-height: 25em;
    min-height: 15em;
`;

export const Titulo = styled.h3`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0;
    margin-left: 0.4em;
    
`;
