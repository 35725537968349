import React, { useContext, useEffect, useState } from 'react'
import { DataEspecie } from '../interfaces/mercadoInferfaces'
import { EspeciesFavoritasContext } from '../context/especiesFavoritasContext/EspeciesFavoritasContext';
import { DetalleContext } from '../context/detalleEspecieContext/DetalleContext';
import { TipoNavigate } from '../enums/enums';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '../context/navigationContext/NavigationContext';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';

export const useCardEspecieHook = (especie: DataEspecie) => {

    const { sumarEspecie, borrarEspecie, buscarEspecie } = useContext(EspeciesFavoritasContext,);
    const { limpiarDatos, setEspecie, getHistorico, getIntradiariosEspecie, getOfertasEspecie } = useContext(DetalleContext);
    const [esFav, setEsFav] = useState(buscarEspecie(especie.especie));
    const navigate = useNavigate();
    const { tipoMercado } = useContext(MercadoContext)
    const { setSelectedNavigate } = useContext(NavigationContext);

    useEffect(() => {
        setEsFav(buscarEspecie(especie.especie))
    }, [especie])

    // agrega la especie a favoritos
    const addFav = async () => {
        await sumarEspecie(especie.especie);
        setEsFav(true);
    };

    // borra la especie de favoritos
    const deleteFav = async () => {
        await borrarEspecie(especie.especie);
        setEsFav(false);
    };


    // modifica el formato del horario, borra los segundos
    function cutHour(hour: string | null | undefined) {
        const searchTerm = ':';
        let hora = '-';
        if (hour !== undefined && hour !== null) {
            const lastIndexOf = hour.lastIndexOf(searchTerm);
            hora = hour.slice(0, lastIndexOf);
        }
        return hora;
    }

    // setea todos los datos necesarios para mostrar la informacion de Detalle de Especie
    const onClickEspecies = (e: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault();
        limpiarDatos();
        setEspecie(especie);
        getHistorico(especie?.especie);
        getIntradiariosEspecie(especie?.especie);
        getOfertasEspecie(especie?.especie);        
        setSelectedNavigate(TipoNavigate.Mercado)
        navigate(`/mercado/detalleEspecie/${especie.especie}/${tipoMercado}`);
    };

    return {
        esFav,
        addFav,
        deleteFav,
        cutHour,
        onClickEspecies,
    }
}
