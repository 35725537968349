import styled, { css } from "styled-components";
import { colors } from "../../theme/Themes";

export const Container = styled.div`
    background-color:  ${({theme}) => theme.secondaryBackgroundColor};
    height: 100vh;
    color: ${({theme}) => theme.primaryColor};
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        height: 91.5vh;
		width: 87vw;
        padding: 0vh 0.5vw 0 0.5vw;
	}
`;

export const BoxContainer = styled.div`
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    margin: 3em 1em;
    border-radius: 1em;
    max-height: 75vh;
    overflow-y: scroll;
    @media (min-width: 992px) {
        flex-grow: 1; 
        margin: 0 0 0em 1em;
        background-color: unset;
    }
`;

export const MisAlertasContainer = styled.div`
    height: 2.7em;
    padding-left: 0.5em;
    display: flex;
    align-items: center;
    border-bottom: 0.1em solid #EBEBEB;
    @media (min-width: 992px) {
        padding: 0px;
        border-bottom: none;
    }
`;

export const MisAlertasText = styled.p`
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 0.5em;
    @media (min-width: 992px) {
        margin: 2.5em 0 1em 0;
    }
`;

export const AgregarAlertaButton = styled.button`
    display: flex;
    width: 100%;
    height: 2.7em;
    padding: 0 1.5em 0 0.5em;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.1em;
    border-bottom: 0.1em solid ${({theme}) => theme.grayToDarkGrayBackground};
    color: ${colors.blueRava};
    cursor: pointer;
    @media (min-width: 992px) {
        background-color:${({theme}) => theme.primaryBackgroundColor};;
        border-bottom: none;
        margin: 1.5em 0 0.5em 0em;
        border-radius: 10px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
`;

export const AgregarAlertaText = styled.p`
    font-size: 1em;
    margin: 0em 0.5em;
    color: ${({theme}) => theme.primaryColor};
    @media (min-width: 992px) {
        font-size: 1.1em;
        margin: 0 1em;
    }
`;
