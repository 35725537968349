import React, { useContext } from 'react'
import { BotonComprarVender, ContainerButtons } from './elements'
import { ThemeContext } from 'styled-components'
import { TipoOperacion } from '../../../enums/enums'

interface Props {
    setActualOption: React.Dispatch<React.SetStateAction<TipoOperacion | null>>;
    continuar: (tipoOperacion: TipoOperacion) => Promise<void>;
}

const BotonesComprarVenderNuevo = ({ setActualOption, continuar }: Props) => {

    const { buyColor, rojoPorcentaje } = useContext(ThemeContext);

    const handleClickComprarVender = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tipoOperacion: TipoOperacion) => {
        e.preventDefault();
        setActualOption(tipoOperacion);
        continuar(tipoOperacion);
    }

    return (
        <ContainerButtons>
            <BotonComprarVender
                style={{
                    color: buyColor,
                    border: `2px solid ${buyColor}`,
                }}
                onClick={e => handleClickComprarVender(e, TipoOperacion.Compra)}
            >COMPRAR</BotonComprarVender>
            <BotonComprarVender
                style={{
                    color: rojoPorcentaje,
                    border: `2px solid ${rojoPorcentaje}`,
                }}
                onClick={e => handleClickComprarVender(e, TipoOperacion.Venta)}
            >VENDER</BotonComprarVender>
        </ContainerButtons>
    )
}

export default BotonesComprarVenderNuevo