import styled from "styled-components";


export const ContainerMercadoLocal = styled.div`
margin-top: 0.5em;
margin-right: 1em;
background-color: ${({ theme }) => theme.primaryBackgroundColor};
border-radius: 10px;
border-color: transparent;
height: 12.5em;
width: 20em;
@media (min-width: 992px) {
    width: 100%;
    margin-top: 0px;
    height: 9em;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding-top: 0.5em;
    padding-bottom: 0.2em;
}
`

export const Container = styled.div`
margin-right: 1em; 
`

export const ContainerTitulo = styled.div`
display: flex;
justify-content: space-between;
margin-left: 1em;
margin-top: 0.5em;
margin-bottom: 0.3em;
@media (min-width: 992px) {
    margin-top: 0em;
    margin-bottom: 0em;
}
`

export const Titulo = styled.p`
margin: 0px;
color: ${({ theme }) => theme.primaryColor};
font-weight: 700;
font-size: 18px;
`

export const Dia = styled.p`
margin: 0px;
font-weight: 400;
font-size: 13px;
color: ${({ theme }) => theme.accentColor};
align-self: flex-end;
`

export const ContainerData = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 0.3em;
margin-top: 0.5em;
@media (min-width: 992px) {
    margin-top: 0.1em;
    margin-bottom: 0em;
    align-items: center;
}
`

export const ContainerImagenes = styled.div`
display: flex;
height: 2.8em;
@media (min-width: 992px) {
    height: 2.3em;
    align-items: center;
}
`

export const ImgArg = styled.img`
margin-left: 0.6em;
width: 1.6em;
height: 1.6em;
padding: 0.5em;
`

export const ImgDolar = styled.img`
margin-left: 0.6em; 
width: 2.8em;
height: 2.8em;
`

export const ImgEEUU = styled.img`
margin-left: 0.6em;
width: 1.4em;
height: 1.4em; 
padding: 0.2em; 
background-color: #B00020;
border-radius: 50px;
margin-right: 0.6em;
`

export const ContainerEspecieNombre = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`

export const Especie = styled.p`
margin: 0px;
color: ${({ theme }) => theme.primaryColor};
font-weight: 700;
font-size: 16px;
line-height: 0.8;
`

export const Nombre = styled.p`
margin: 0px;
color: ${({ theme }) => theme.primaryColor};
font-size: 12px;
`

export const ContainerUltimoVariacion = styled.div`
display: flex;
`

export const UltimoLocal = styled.p`
margin: 0px;
font-weight: 700;
font-size: 14px;
text-align: center;
color: ${({ theme }) => theme.accentColor};
margin-right: 0.8em;
`

export const Variacion = styled.p`
margin: 0px;
font-weight: 700;
font-size: 14px;
text-align: right;
width: 2.7em;
`

export const ContainerSinInfo = styled.div`
display: flex;
justify-content: center;
align-items: center; 
height: 100%;
`

export const TextoSinInfo = styled.p`
font-size: 18px;
color: ${({ theme }) => theme.primaryColor};
font-weight: bold;
`