import React from 'react'
import { TenenciaMercadoExterior } from '../../../interfaces/tenenciaInterface'
import { Container, ContainerEspecies } from './elements';
import { changeColor } from '../../../helpers/changeColor';
import CardDetalleEspecieHomeExterior from '../CardDetalleEspecieHome/CardDetalleEspecieHomeExterior';

interface Props {
    tenencia: TenenciaMercadoExterior[];
}

const DetalleRentasExterior = ({ tenencia }: Props) => {
    return (
        <Container>
            <ContainerEspecies>
                {tenencia.map((t, i) => (
                    <CardDetalleEspecieHomeExterior
                        especie={t.ticker}
                        precio={t.precioActual}
                        cantidad={t.cantidad}
                        importe={t.importe}
                        key={t.nombreEspecie}
                        pintar={changeColor(i)}
                        costoHistorico={t.costoCompra}
                        variacionDia={t.variacionDia}
                        variacionHistorico={t.variacionCosto}
                    />
                ))}
            </ContainerEspecies>
        </Container>
    )
}

export default DetalleRentasExterior