import axios from "axios";
import { RespPublicToken } from "../interfaces/publicTokenInterface";

export async function getPublicToken() {
	try {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.append("grant_type", "client_credentials");
		urlSearchParams.append("client_id", "app");
		urlSearchParams.append("client_secret", "appVR260722");
		const baseURL = "https://clasico.rava.com/lib/restapi/oauth2";
		const apiInstance = axios.create({
			baseURL,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});

		const resp = await apiInstance.post<RespPublicToken>(
			"/token2.php",
			urlSearchParams
		);
		return resp
	} catch (error) {
		console.log(error);
	}
}

/*
import axios from 'axios';
import qs from 'qs';

//import { REACT_APP_URL_TOKEN } from '@env';

const REACT_APP_URL_TOKEN = process.env.REACT_APP_URL_TOKEN

const baseURL = REACT_APP_URL_TOKEN;

export async function getPublicToken() {
	let data = qs.stringify({
		grant_type: 'client_credentials',
		client_id: 'app',
		client_secret: 'appVR260722',
	});
	let config = {
		method: 'post',
		url: baseURL,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		data: data,
	};
	let resp = await axios(config);
	
	return resp;
}*/

