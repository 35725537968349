import styled from "styled-components";
import { colors } from "../../theme/Themes";

export const StyledReactInputVerificationCode = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 0.5rem;
    

	--ReactInputVerificationCode-itemWidth: 40px;
	--ReactInputVerificationCode-itemHeight: 38px;
	--ReactInputVerificationCode-itemSpacing: 8px;

	.ReactInputVerificationCode__item {
		font-size: 20px;
		font-weight: bold;
		color: ${({theme}) => theme.primaryColor};
		box-shadow: none;
        border-bottom: ${({theme}) => `1px solid ${theme.primaryColor}`};
        border-radius: 0;
	}

	.ReactInputVerificationCode__item.is-active {
		box-shadow: none;
		border-bottom: 1px solid #36c6d9;
	}

    @media (min-width: 992px) {
        --ReactInputVerificationCode-itemWidth: 50px;
        --ReactInputVerificationCode-itemHeight: 45px;
        --ReactInputVerificationCode-itemSpacing: 25px;
    }
`;
export const Container = styled.div`
    height: 100vh;
    background-color:  ${({theme}) => theme.alternativeBackgroundColor};
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-top: 4em;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-bottom: 3em;
    color: ${({theme}) => theme.primaryColor};
`;

export const Text = styled.p`
@media (min-width: 992px) {
    text-align: center;
}
`

export const ErrorContainer = styled.div`
    width: 70%;
    margin: 1em 0 0 0.5em;
    text-align: center;
    color: ${colors.error};
`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center; 
width: 100%;
padding-top: 15em;
@media (min-width: 992px) {
    padding-top: 5em;
}
`;